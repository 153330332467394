import { ILogin } from '../assets/interfaces/ILogin';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Box, InputAdornment, IconButton, TextField, Typography, Grid, Alert } from '@mui/material/';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import axios from 'axios';
import { loginUseStyles } from '../assets/loginUseStyles';
import { IFormState } from '../assets/interfaces/IFormState';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IData } from '../assets/interfaces/IData';
import { useAuthContext } from "../../Context/AuthContext";
import { IToken } from '../../interfaces/IToken';
import { AlertColor } from '@mui/material/Alert';
export function LoginRegistrationForm(props: ILogin) {
    const { registration } = props;
    const { token, setToken } = useAuthContext();
    const [logLoading, setLogLoading] = useState(false);
    const [severity, setSeverity] = useState<AlertColor>("error");
    const [isUser, setIsUser] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [formState, setFormState] = useState<IFormState>({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmedPassword: null,
        checkbox1: false,
        checkbox2: false,
    })
    const [authError, setAuthError] = useState<string[]>([]);
    const classes = loginUseStyles();

    const handleIsUser = () => {
        setIsUser(!isUser);
    }
    const history = useHistory();

    useEffect(() => {
        if (token.userType === 1 || token.userType === 2 || token.userType === 3) {
            history.push("/merge-profile");
        }
        else if (token.id) {
            history.push("/pricing");
        }
    }, []);

    const login = (e: any) => {
        e.preventDefault();
        setLogLoading(true);
        setSeverity("info");
        setAuthError(["Logging in..."]);

        const loginData = {
            email: formState.email,
            password: formState.password
        }

        axios
            .post('api/auth/authenticate', loginData)
            .then((result) => {
                if (result.status === 200) {
                    setSeverity("success");
                    setAuthError(["Success! Redirecting..."]);
                    setLogLoading(false);
                    setToken((prev: IToken) => ({ ...prev, ...result.data }));
                    console.log('result.data.userType', result.data.userType)
                    if (result.data.userType !== 1 && result.data.userType !== 2 && result.data.userType !== 3) {
                        history.push("/subscription");
                    } else {
                        
                        if (result.data.userType === 2)
                        {
                            history.push("/merge-options");
                        }
                        else {
                            history.push("/merge-profile");
                        }
                    }
                } else {
                    setSeverity("warning");
                    setAuthError(["Something went wrong, please try again later"]);
                    setLogLoading(false);
                }
            })
            .catch(({ response, response: { data } }) => {
                console.log(data);
                if (response.status === 400) {
                    const errors = data.errors;
                    let tempErrors: Array<string> = [];
                    setSeverity("error");
                    if (errors?.Email) tempErrors = [...tempErrors, ...errors.Email];
                    if (errors?.Password) tempErrors = [...tempErrors, ...errors.Password];
                    setAuthError(tempErrors);
                } else if (response.status === 401) {
                    setAuthError(["Invalid email or password validation"]);
                } else if (response.status === 404) {
                    setAuthError(["No such user exists"]);
                } else {
                    setAuthError(["Something went wrong, please try again later"]);
                }
                setLogLoading(false);
            })
    }

    const createUser = (e: any) => {
        e.preventDefault();
        setLogLoading(true);
        setAuthError([]);

        console.log(formState);
        if (!formState.confirmedPassword || !formState.password || !formState.lastName || !formState.firstName || !formState.email) {
            setSeverity("error");
            setAuthError(["All fields are required"]);
            setLogLoading(false);
            return;
        }
        if (!formState.checkbox1 || !formState.checkbox2)
        {
            setSeverity("error");
            setAuthError(["Checkboxes are required"]);
            setLogLoading(false);
            return;
        }
        if (formState.confirmedPassword !== formState.password) {
            setSeverity("error");
            setAuthError(["Passwords do not match"]);
            setLogLoading(false);
            return;
        }

        setSeverity("info");
        setAuthError(["Creating account..."]);

        const userData = {
            firstName: formState.firstName,
            lastName: formState.lastName,
            email: formState.email,
            password: formState.password,
            customerId: null,
            subscriptionId: null,
        }
        axios
            .post('/api/users/register', userData)
            .then((result) => {
                if (result.status === 200) {
                    setSeverity("success");
                    setAuthError(["Account created, logging you in..."]);
                    axios
                        .post('api/auth/authenticate', { email: formState.email, password: formState.password })
                        .then((result2) => {
                            setToken((prev: IToken) => ({ ...prev, ...result2.data }));
                            setLogLoading(false);

                            if (result.data.userType === 2) {
                                history.push('/merge-options');
                            }
                            else
                            {
                                history.push('/merge-profile');
                            }

                            //history.push('/pricing'); // 20220708 - allow free trial
                            //history.push('/merge-profile')
                        })
                        .catch((e) => {
                            setSeverity("warning");
                            setAuthError(["Account created, but failed to login. Please login manually"]);
                            setLogLoading(false);
                        });
                } else {
                    setAuthError(["Something went wrong, please try again later"])
                    setLogLoading(false);
                }

            })
            .catch((e) => {
                setSeverity("error");
                if (e.response.status === 400) {
                    const errors = e.response.data.errors;
                    let tempErrors: Array<string> = [];
                    if (errors?.Email) tempErrors = [...tempErrors, ...errors.Email];
                    if (errors?.LastName) tempErrors = [...tempErrors, ...errors.LastName];
                    if (errors?.FirstName) tempErrors = [...tempErrors, ...errors.FirstName];
                    if (errors?.Password) tempErrors = [...tempErrors, ...errors.Password];
                    if (errors?.Error)
                        console.log(tempErrors);
                    setAuthError(tempErrors);
                } else {
                    setAuthError(["Something went wrong, please try again later"])
                }
                setLogLoading(false);
            })
    }

    const loginData = [{ label: 'Email Address', onChange: 'email', type: 'email', placeholder: 'johndoe@gmail.com' }, { label: 'Password', onChange: 'password', type: 'password', placeholder: 'enter a password' }];
    const registrationData = [{ label: 'First Name', onChange: 'firstName', type: 'text', placeholder: 'John' }, { label: 'Last Name', onChange: 'lastName', type: 'text', placeholder: 'Doe' }, { label: 'SignupEmail', onChange: 'email', type: 'email', placeholder: 'johndoe@gmail.com' }, { label: 'Password', onChange: 'password', type: 'password', placeholder: 'Enter a Password' }, { label: 'Confirm Password', onChange: 'confirmedPassword', type: 'password', placeholder: 'Confirm a Password' }];
    //Email Address(Please use an email address that underwriters.)
    const createFields = (data: IData[]) => {
        return data.map((field, index) => {
            return (
                <TextField
                    className={classes.input}
                    key={index}
                    placeholder={field.placeholder}
                    disabled={logLoading}
                    label={field.label == 'SignupEmail' ? <div style={{ textOverflow: "ellipsis" }}> Email Address <span style={{ fontSize: "14px" }}>(Please use an email that u/w's can reply to).</span></div> : field.label}
                    type={field.type === 'password' && showPassword ? 'text' : field.type}
                    onChange={(e) => setFormState(prev => ({ ...prev, [field.onChange]: e.target.value }))}
                    InputProps={field.type === 'password' ? {
                        style: { paddingTop: '10px' },
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                tabIndex={-1}
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(prev => !prev)}
                                edge="end"
                                className="no-outline"

                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>,
                    } : { style: { paddingTop: '10px' } }}
                />
            )
        })
    }

    return (
        <>
            {!registration && (
                <Box style={{ width: '100%', marginTop: 32 }}>
                    <Typography className={classes.titleLogin} variant='h4'>Log In</Typography>
                    <form noValidate autoComplete="off" spellCheck={false} autoCapitalize="off" autoCorrect="off"  className={classes.form} onSubmit={e => login(e)} >
                        {createFields(loginData)}
                        {authError.length > 0 &&
                            <Alert severity={severity}>{authError.map((p, index) => {
                                return <p key={index}>{p}</p>
                            })}</Alert>
                        }
                        <Button type="submit" variant="contained" className={classes.loginButton} disabled={logLoading}>
                            Login
                        </Button>
                    </form>
                    <Grid container justifyContent="space-between">
                        <Box className={classes.create}>
                            <Typography className={classes.dontHaveAccount}>Don't have an account?</Typography>
                            <Link to='/sign-up' style={{ textDecoration: 'none' }} onClick={() => setIsUser(!isUser)}>
                                <Typography className={classes.createText}>Sign Up</Typography>
                            </Link>
                        </Box>
                        <Link style={{ textDecoration: 'none' }} to='/forgot-password'>
                            <Typography className={classes.dontHaveAccount}>Forgot password?</Typography>
                        </Link>
                    </Grid>
                </Box>
            )}
            {registration && (
                <Box style={{ width: '100%', marginTop: 32 }}>
                    <Typography className={classes.titleLogin} variant='h4'>Sign Up</Typography>
                    <form noValidate autoComplete="off" spellCheck={false} autoCapitalize="off" autoCorrect="off"  className={classes.form} onSubmit={e => createUser(e)}>
                        {/* {registrationFormFields} */}
                        {createFields(registrationData)}


                        <FormControlLabel style={{ fontSize: '10px' }} control={<Checkbox value={formState.checkbox1} onChange={(event, checked) => { setFormState(prev => ({ ...prev, checkbox1: checked })) }} required={true} />} label={<Typography style={{ fontSize: '13px' }}>[Required] I have read and agree to be bound by the<a style={{ color: "#007bff" }} href="./terms-of-service" target="_blank"> Software as a Service Licence and Subscription Agreement</a>.</Typography>}
                        />
                        <FormControlLabel control={<Checkbox value={formState.checkbox2} onChange={(event, checked) => { setFormState(prev => ({ ...prev, checkbox2: checked })) }} required={true} />} label={<Typography style={{ fontSize: '13px' }}> [Required] I have read and agree to be bound by the <a style={{ color: "#007bff" }} href="./privacy-policy" target="_blank">Privacy and Security Policy</a>.</Typography>}
                        />

                        {authError.length > 0 &&
                            <Alert severity={severity}>{authError.map((p, index) => {
                                return <p key={index} style={{ paddingBottom: 0 }}>{p}</p>
                            })}</Alert>
                        }
                        <Button type="submit" className={classes.loginButton} variant="contained" disabled={logLoading}>
                            Create Account
                        </Button>
                    </form>
                    <Box className={classes.create}>
                        <Typography className={classes.dontHaveAccount}>Already have an account?</Typography>
                        <Link to="/log-in" style={{ textDecoration: 'none' }} onClick={handleIsUser}>
                            <Typography className={classes.createText}>Login</Typography>
                        </Link>
                    </Box>
                </Box>
            )}
        </>
    );
}
