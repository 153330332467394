import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { TutorialSelectionStyles } from '../assets/TutorialSelectionStyles';
import { global } from '../../../global';

// components
import { MergeNav } from '../../MergeProcess/components/MergeNav';
import { Typography, Button, Grid } from '@mui/material';
import { HomeFooter } from '../../Home/components/HomeFooter/HomeFooter';
import { Link } from 'react-router-dom';


export default function TutorialSelection() {
    const classes = TutorialSelectionStyles();

    const [loading, setLoading] = useState(false);

    return (
        <div style={{
            flexGrow: 1,
            height: '100vh',
            position: 'relative',
            zIndex: 10
        }}>
            <Helmet>
                <title>Max Merge Solutions - Tutorial Selection</title>
            </Helmet>

            <div>
                <MergeNav active='profile' />
            </div>

            <div className={classes.root}>

                {/* need to fix back & skip tutorial button destination*/}
                <Typography variant="h1" className={classes.selectionTitle}>Would You Like to View a Tutorial Video on {<br />}How to Package Documents for Submission?</Typography>
                <div className={classes.tutorialButtonGroup}>
                    <Link to={{ pathname: '/how-to-package-documents', state: { fromTutorialSelection: true } }} >
                        <Button color='primary' className={[classes.options, classes.optionButtonCustom].join(" ")} disabled={loading} variant="outlined">Watch Tutorial</Button>
                    </Link>

                    <Link to={{ pathname: '/merge-profile', state: { documentPackaging: true } }}>
                        <Button color='primary' className={[classes.options, classes.optionButtonCustom].join(" ")} disabled={loading} variant="outlined">Skip Tutorial</Button>
                    </Link>
                </div>
                
                <Grid justifyContent="center" container style={{ width: '100%', marginTop: "22px" }}><Link style={{ color: global.black }} to={{ pathname: "merge-options" }}><Typography style={{ textDecorationLine: 'underline', fontFamily: global.fontFamily, fontWeight: 600 }}>Back</Typography></Link></Grid>
            </div>
            <HomeFooter mediumBreakpoint />
        </div>
    )
}