import React from "react";
import styled from "styled-components";
import { useAuthContext } from "../Context/AuthContext";
import { Link } from "react-router-dom";
const Sidebar = ({ sidebarOpen, links, margin, position }) => {
    const { token, logout } = useAuthContext();

    return (
        <SidebarWrapper margin={margin} position={position}>
            <div className={`sidebar ${sidebarOpen ? "side-active" : ""}`}>
                <ul>
                    {links[token.id ? "auth" : "anon"].map((link, index) => {
                        return link?.logout ? (
                            <li key={index} style={link.style}>
                                <button onClick={logout}>{link.title}</button>
                            </li>
                        ) : link.demo ? (
                            <li key={index} style={link.style}>
                                <a target="_blank" href={link.link}>{link.title}</a>
                            </li>
                        ) :
                            (
                                <li key={index} style={link.style}>
                                    <Link to={link.link}>{link.title}</Link>
                                </li>
                            );
                    })}
                </ul>
            </div>
        </SidebarWrapper>
    );
};

const SidebarWrapper = styled.aside`
  body {
    overflow: none;
  }

  .sidebar {
    width: 100vw;
    min-height: 100vh;
    transform: translateX(100%);
    position: ${(props) => props.position};
    right: 0;
    top: 0;
    transition: 0.2s;
    background-color: #1b1e1e;
    z-index: 2;
    margin-top: ${(props) => props.margin};
  }

  @media screen and (min-width: 750px) {
    .sidebar {
      max-width: 400px;
    }
  }

  @media screen and (max-width: 600px) {
    .sidebar {
      max-width: 400px;
      margin-top: 80px;
    }
  }

  .side-active {
    transform: translateX(0);
  }

  ul {
    list-style: none;
    padding: 50px 20px;

    a,
    button {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      padding-left: 30px;
      font-family: "Montserrat";
    }
    button {
      color: white;
    }

    li {
      padding-top: 8px;
      padding-bottom: 8px;
      transition: 0.15s;
      cursor: pointer;
    }

    li:hover {
      padding-left: 5px;
      background-color: #4b4e4e;
    }

    button:hover,
    button:active,
    button:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
`;

export default Sidebar;
