import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const MergeManagementStyle = makeStyles(() =>
  createStyles({
    input: {
        marginBottom: '30px',
        marginTop: "1rem",
        maxWidth: '20rem',
        borderRadius: 0,
        border: '#000 1px solid',
        'MuiMenuItem-root:hover': {
          backgroundColor: 'none! important',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1B1E1E',
          backgroundColor: 'none! important'
        },
        '&:before': {
          borderColor: '#1B1E1E',
        },
        '&:after': {
          borderColor: '#1B1E1E',
        },
      },
      dropdownStyle: {
        maxwidth: '20rem',
        marginTop: 10,
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 12%), 0px 8px 10px 1px rgb(0 0 0 / 8%), 0px 3px 14px 2px rgb(0 0 0 / 8%)'
      },
  })
);