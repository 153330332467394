import React from 'react';
import { Menu, MenuItem, Fade, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import styled from "styled-components"
import useWindowDimensions from './windowDimensions';

export function NavMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { width } = useWindowDimensions();

  const handleClick = (event: any) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (width > 768) return <LinksMenuWrapper>
    <Link to="/about-us">About</Link>
    <Link to="/pricing">Pricing</Link>
    <Link to="/sign-up" className="nav-btn">Sign Up</Link>
  </LinksMenuWrapper>;

  return (
    <NavMenuWrapper>
      {anchorEl && <div className="screen" />}
      <IconButton onClick={handleClick} className="menu-btn icon-btn">
        {
          !anchorEl ? <MenuIcon /> : <CloseIcon className="close-btn" />
        }
      </IconButton>
      <Menu
        id="fade-menu"
        className="landing-menu"
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: "none",
            right: 0,
            width: "250px",
            backgroundColor: "#EFEFF1",
          }
        }}
      // TransitionComponent={Fade}
      >
        <Link to="/about-us" onClick={handleClose} className="menu-item">
          <MenuItem className="menu-padding">About</MenuItem>
        </Link>
        <Link to="/pricing" onClick={handleClose} className="menu-item">
          <MenuItem className="menu-padding">Pricing</MenuItem>
        </Link>
        <Link to="/sign-up" onClick={handleClose} className="menu-item">
          <MenuItem className="menu-padding">Sign Up</MenuItem>
        </Link>
      </Menu>
    </NavMenuWrapper>
  );
}


const LinksMenuWrapper = styled.div`
    display: flex;
    align-items: center;
    a {
        color: white;
        margin-left: 40px;
        font-size: 18px;
        :hover {
            color: white;
            text-decoration: none;
        }
    }

    .nav-btn {
        padding: 8px 18px;
        background-color: #00939F;
    }
`

const NavMenuWrapper = styled.div`
    .close-btn {
        z-index: 1600;
    }

    .screen {
        background: rgba(0,0,0,0.6);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top:0;
        left: 0;
    }
`
