
interface IText {
  name: string;
  paragraph: string;
  paragraph2?: string;
}

export const categoryObject: IText[] = [
  {
    name: '',
    paragraph: 'How much of your day is spent organizing files? With Max Merge Solutions, you simply drag and drop or browse files on your computer into pre-built categories.',
    paragraph2: 'The result? A well-organized document package for your underwriter, faster turnaround time for your clients and peace of mind for you.',
  },
  {
    name: 'Income Documents',
        paragraph: 'This category will hold your client’s income confirmation. Premium users can include the year-to-date pay stub tracker to verify that your client’s income is on-par with the application.'
  },
  {
    name: 'Subject Property Documents',
      paragraph: 'Automatically sort your client’s subject property paperwork, such as MLS, purchase contract and addendums. Your underwriter will be able to access everything they need hassle-free.'
  },
  {
    name: 'Non-Subject Property Documents',
    paragraph: 'Mortgage statements, lease agreements and property tax bills for other owned properties.'
  },
  {
    name: 'Net Worth Documents',
    paragraph: 'All bank and investment statements required by the lender to satisfy your client’s net worth.'
  },
  {
    name: 'Debt Payout Documents',
      paragraph: 'Sort all debts that need to be paid out in this category box. Premium users can enter extra details like statement dates and balances.'
  },
  {
    name: 'Miscellaneous Documents',
    paragraph: 'For documents not applicable to other categories, for instance, separation agreements, child benefits, identification, etc.'
  },
  {
    name: 'Down Payment Documents',
      paragraph: 'Premium users can enter important details like financial institution account numbers, statement dates and balances, explanations for large deposits, gifted proceeds, and net proceeds from property sales.',
      paragraph2: 'We will keep a running balance of the down payment. It’s a comprehensive document that your underwriter will appreciate.',

  },
  {
    name: 'Compliance Documents',
      paragraph: 'Paperwork that demonstrates office compliance, such as disclosures, KYC, or IDs, can be filed under this category.'
  },
  {
    name: 'Supporting Documents',
      paragraph: 'Eliminate the need to upload files individually into your submission platform. Drop all your documents into this category and specify your file size.We will automatically merge and split your documents while maintaining your chosen file size requirement.'
  },
]
