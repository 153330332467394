import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { Paper, Box, Grid, Hidden, Typography, Button, TextField, InputLabel, Alert, IconButton, InputAdornment } from '@mui/material/';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { loginUseStyles } from '../assets/loginUseStyles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// Component Imports
import { NavBar } from '../../NavBar/NavBar';
import { global } from '../../../global';
import { getQuery } from '../../../helpers/getQuery';
import ConfirmResetPassword from './ConfirmResetPassword';


export default function ResetPassword() {
  const history = useHistory();
  const classes = loginUseStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVal, setPasswordVal] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('')

  const [resetSucc, setResetSucc] = useState(false);

  const checkPassword = () => {
    let tempErr = false
    if (password.length < 8) {
      setPasswordVal("Your password must be greater than 8 characters in length.")
      tempErr = true
    }
    if (password !== confirmPassword) {
      setPasswordVal("Your passwords do not match.")
      tempErr = true
    }
    return tempErr;
  }

  const email = getQuery('email');
  const token = getQuery('token');

  const resetPassword = () => {
    setLoading(true);
    setLoadingMsg('Resetting password...')
    if (checkPassword()) {
      return;
    }

    const body = {
      email: email,
      token: token,
      newPassword: password,
      rePassword: confirmPassword
    }

    const url = global.rootUrl + "api/auth/reset-password"
    axios.post(url, body).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setLoadingMsg('');
        setResetSucc(true)
      }
    })
  }

  return (
    <>
      {!resetSucc && (

        <>
          <Helmet>
            <title>Reset Password | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
          </Helmet>
          <NavBar color="white" active="" />
          <Paper square elevation={24} className={classes.paper}>
            <Grid className={classes.grid} container spacing={0} direction='row' justifyContent='center' alignItems='center'>
              <Hidden only={['xs', 'sm', 'md']} >
                <Grid className={classes.pictureContainer} md={5} item>
                  <img className={classes.picture} src="https://res.cloudinary.com/maxmergesolutions/image/upload/w_800,h_947,q_auto/v1637690055/iStock-1004109150_large_version_oyz7tc.jpg" alt="Office Setup" />
                </Grid>
              </Hidden>
              <Grid container md={7} xs={12} justifyContent='center' item>
                <Box style={{ width: '60%', fontFamily: global.fontFamily, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignItems: 'flex-start', height: '50vh', color: global.black }}>
                  <div>
                    <Typography className={classes.titleLogin} style={{ fontSize: 24 }} variant='h4'>Reset password</Typography>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start' }}>

                      Enter a new password below with a minimum of 8 characters.
                    </div>
                    <form noValidate  spellCheck={false} autoCapitalize="off" autoCorrect="off" autoComplete="off" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} onSubmit={resetPassword}>
                      <InputLabel style={{ fontWeight: 600, color: global.black, textAlign: 'left', marginTop: 30, paddingBottom: 0, marginBottom: 0 }} id="fullName">New Password</InputLabel>
                      <TextField
                        // className={classes.input}
                        style={{ width: '100%', marginBottom: '30px' }}
                        required={true}
                        type={showPassword ? 'text' : "password"}
                        id="standard-basic"
                        variant="standard"
                        placeholder=""
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        InputLabelProps={{ style: { color: global.black } }}
                        InputProps={{
                          className: classes.textField, endAdornment: <InputAdornment position="end">
                            <IconButton
                              tabIndex={-1}
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(prev => !prev)}
                              edge="end"
                              className="no-outline"

                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>,
                        }}
                      />

                      <InputLabel style={{ fontWeight: 600, color: global.black, textAlign: 'left', paddingBottom: 0, marginBottom: 0 }} id="fullName">Confirm Password</InputLabel>
                      <TextField
                        // className={classes.input}
                        style={{ width: '100%', marginBottom: '30px' }}
                        required={true}
                        id="standard-basic"
                        type={showPassword ? 'text' : "password"}
                        variant="standard"
                        placeholder=""
                        onChange={e => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        InputLabelProps={{ style: { color: global.black } }}
                        InputProps={{
                          className: classes.textField, endAdornment: <InputAdornment position="end">
                            <IconButton
                              tabIndex={-1}
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(prev => !prev)}
                              edge="end"
                              className="no-outline"

                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>,
                        }}
                      />
                      {passwordVal && <Alert variant="outlined" severity="error" style={{ color: '#ef5350', border: '0', padding: 0 }}>{passwordVal}</Alert>}
                    </form>

                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 30, flexDirection: 'column', alignItems: 'flex-start' }}>
                      {loading && <Alert variant="standard" severity="success">{loadingMsg}</Alert>}
                      <Button type="submit" style={{ backgroundColor: global.black, color: '#fff', width: 200, height: 54, marginTop: 15 }} onClick={resetPassword}>
                        Reset Password
                      </Button>

                    </div>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}

      {resetSucc && <ConfirmResetPassword />}
    </>
  );
}