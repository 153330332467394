import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import { DocumentTypeAccordion } from './DocumentTypeAccordion';
// helper functions
import { filterDocumentByType } from '../../../../../helpers/filters-and-sorters';
import { IMergeTaskInfo } from '../../../assets/interfaces/IMergeTaskInfo';
import { IDocument } from '../../../assets/interfaces/IDocument';
import { IDescription } from '../../../components/LenderSummarySheet/assets/interfaces/IDescription';
import axios from 'axios';
import { IDocumentTypeAccordion } from '../assets/interfaces/IDocumentAccordion';

interface IDocumentAccordionList {
    split: boolean;
    mergeTaskInfo: IMergeTaskInfo;
    descriptions: IDescription[];
    setDescriptions: React.Dispatch<React.SetStateAction<IDescription[]>>;
}

export function DocumentAccordionList(props: IDocumentTypeAccordion) {
    const { split, mergeTaskInfo, descriptions, setDescriptions, tiles, setError, errorFile } = props;
    const [newDocs, setNewDocs] = useState(mergeTaskInfo.documents);
    const [newMergeTask, setNewMergeTask] = useState(mergeTaskInfo);
    const [rerender, setRerender] = useState(false);

    const test = () => {
        axios
            .get(`api/merge-task/${mergeTaskInfo.id}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log('test being called');
                    setNewMergeTask(result.data);
                    setNewDocs(result.data.documents);
                    setRerender(true);
                }
            })
    }

    useEffect(() => {
        test();
    }, []);

    const handleDelete = (id: number) => {
        axios
            .delete(`api/merge-documents/${id}`)
            .then((result) => {
                if (result.status === 200) {
                    axios
                        .get(`api/merge-task/${mergeTaskInfo.id}`)
                        .then((result) => {
                            console.log(errorFile);
                            if (id == errorFile) {
                                setError('');
                            }
                            test();
                            console.log('new result', result.data);
                           /* setNewMergeTask(result.data);
                            setNewDocs(result.data.documents);*/
                        })

                    return true;
                } else {
                    return false;
                }
            })
            .catch(e => console.log('error deleting document:', e))
    }

    const documentTypes = [
        { id: 1, type: 'Income', documents: newMergeTask.documents.filter((doc: IDocument) => doc.type === 1) },
        { id: 2, type: 'Subject Property', documents: newMergeTask.documents.filter((doc: IDocument) => doc.type === 2) },
        { id: 3, type: 'Non-Subject Property', documents: newMergeTask.documents.filter((doc: IDocument) => doc.type === 3) },
        { id: 4, type: 'Net Worth', documents: newMergeTask.documents.filter((doc: IDocument) => doc.type === 4) },
        { id: 5, type: 'Debt Payout', documents: newMergeTask.documents.filter((doc: IDocument) => doc.type === 5) },
        { id: 6, type: 'Miscellaneous', documents: newMergeTask.documents.filter((doc: IDocument) => doc.type === 6) },
        { id: 7, type: 'Down Payment', documents: newMergeTask.documents.filter((doc: IDocument) => doc.type === 7) },

    ]
    const additionalTypes = [
        { id: 8, type: 'Compliance', documents: newMergeTask.documents.filter((doc: IDocument) => doc.type === 8) },
        { id: 9, type: 'Supporting', documents: newMergeTask.documents.filter((doc: IDocument) => doc.type === 9) },
    ]

    let documentAccordions;
    let additionalAccordions;
    if (rerender === true) {
        documentAccordions = documentTypes.map((docType, index: number) => <DocumentTypeAccordion errorFile={errorFile} setError={setError} handleDelete={handleDelete} documentType={docType.type} documents={docType.documents} key={docType.id} mergeTaskInfo={newMergeTask} split={split} id={docType.id} descriptions={descriptions} setDescriptions={setDescriptions} tiles={tiles} />)
        additionalAccordions = additionalTypes.map((docType, index: number) => <DocumentTypeAccordion errorFile={errorFile} setError={setError} handleDelete={handleDelete} documentType={docType.type} documents={docType.documents} key={docType.id} mergeTaskInfo={newMergeTask} split={split} id={docType.id} descriptions={descriptions} setDescriptions={setDescriptions} tiles={tiles} />)
    }

    //console.log('.... mergeTaskInfo.documents', mergeTaskInfo.documents);

    return (
        <>
            {!split && (
                <>
                    {documentAccordions}
                    {additionalAccordions}
                    {/*<DocumentTypeAccordion handleDelete={handleDelete} split={split} descriptions={descriptions} setDescriptions={setDescriptions} documentType="Compliance" id={8} documents={newMergeTask.documents.filter(doc => doc.type === 8)} key="Compliance" mergeTaskInfo={newMergeTask} tiles={tiles} />
          <DocumentTypeAccordion handleDelete={handleDelete} split={split} descriptions={descriptions} setDescriptions={setDescriptions} documentType="Supporting" id={9} documents={newMergeTask.documents.filter(doc => doc.type === 9)} key="Supporting" mergeTaskInfo={newMergeTask} tiles={tiles} />*/}
                </>
            )}
            {split && (
                <>
                    <Typography variant="h6" style={{ fontSize: '18px', marginBottom: '16px', fontWeight: 'bold' }}>Documents for the underwriter</Typography>
                    {documentAccordions}
                    <Typography variant="h6" style={{ fontSize: '18px', marginTop: '54px', marginBottom: '16px', fontWeight: 'bold' }}>Additional files</Typography>
                    {additionalAccordions}
                    {/*<DocumentTypeAccordion handleDelete={handleDelete} split={split} descriptions={descriptions} setDescriptions={setDescriptions} documentType="Compliance" id={8} documents={newMergeTask.documents.filter(doc => doc.type === 8)} key="Compliance" mergeTaskInfo={newMergeTask} tiles={tiles} />
          <DocumentTypeAccordion handleDelete={handleDelete} split={split} descriptions={descriptions} setDescriptions={setDescriptions} documentType="Supporting" id={9} documents={newMergeTask.documents.filter(doc => doc.type === 9)} key="Supporting" mergeTaskInfo={newMergeTask} tiles={tiles} />*/}
                    {/* <Button onClick={() => console.log('mergeTaskInfo:', mergeTaskInfo)}>MergeTaskInfoConsoleLog</Button> */}
                </>
            )}
        </>
    );
};
