import React, { useState, useEffect } from 'react';
import { aboutUsStyles } from './assets/AboutUsStyles';
import { HomeFooter } from '../Home/components/HomeFooter/HomeFooter';
import { NavBar } from '../NavBar/NavBar';
import { INavBar } from '../NavBar/interfaces/INavBar';
import { Helmet } from 'react-helmet';
import { useAuthContext } from '../Context/AuthContext';
import { theme } from '../../appTheme';
import Sidebar from '../Sidebar/Sidebar';
import { mainLinks } from '../Sidebar/Navlinks';
import { Box, Container, Grid, Typography, useMediaQuery, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

export default function AboutUs(props: INavBar) {
    const mediumBreakpoint = useMediaQuery(theme.breakpoints.up('md'))
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const { color } = props;
    const { token, setToken } = useAuthContext()
    const classes = aboutUsStyles();
    const [margin, setMargin] = useState("252px");

    useEffect(() => {
        setMargin(mediumBreakpoint ? "252px" : "150px");
    }, [mediumBreakpoint])

    return (
        <div className={classes.root}>
            <Helmet>
                <title>About Us | Max Merge Solutions - Submitting mortgage documents for a faster turnaround.</title>
            </Helmet>
            {/* header  */}
            {mediumBreakpoint ?
                <NavBar color='white' active='aboutUs' />
                :
                <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', padding: 20 }}>
                        {!token?.email && (
                            <Link to="/">
                                <img className={classes.fullLogoSmall} src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_64,q_auto,f_auto/v1633540731/fullLogoWhite_vxxqyf.png" alt="Max Merge Logo" />
                            </Link>
                        )}
                        {token?.email && (
                            <Link to="/merge-options">
                                <img className={classes.fullLogoSmall} src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_64,q_auto,f_auto/v1633540731/fullLogoWhite_vxxqyf.png" alt="Max Merge Logo" />
                            </Link>
                        )}
                        <IconButton onClick={() => setSidebarOpen(!sidebarOpen)} className="merge-menu-btn menu-btn-large" style={{ marginRight: 0, fontSize: 15 }}>
                            {
                                !sidebarOpen ? <MenuIcon fontSize="small" /> : <CloseIcon />
                            }
                        </IconButton>
                    </div>
                    <Sidebar sidebarOpen={sidebarOpen} links={mainLinks} margin={margin} position="absolute" />
                </div>
            }

            <div className={mediumBreakpoint ? classes.body : classes.smallBody}>
                {/* why max merge solutions  */}
                <Container className={mediumBreakpoint ? classes.container : classes.smallContainer}>
                    <Container className={classes.containerInner}>
                        <Box className={classes.aboutSections}>
                            <Typography className={mediumBreakpoint ? classes.title : classes.smallTitle}>
                                About Us
                            </Typography>

                            <div className={classes.about}>
                                <Typography className={classes.paragraph}>
                                    Max Merge Solutions was conceived to minimize the administrative burdens of the mortgage profession. Document handling in this sector is heavy and time-consuming. The industry had a clear need for a tool that accelerated processing with increased accuracy and quality.
                                </Typography>
                                <Typography className={classes.paragraph}>
                                    The vision: a document packaging platform that was streamlined and easy to use, from start to finish. Furnishing mortgage professionals with a solution that would elevate their business. To further target industry demand, extra features include a lender summary sheet, a comprehensive down payment section and a year-to-date pay stub tracker. 
                                </Typography>
                                <Typography className={classes.paragraph} style={{marginBottom:0}}>
                                    It's a product that underwriters get on board with - to expedite credit decisions. Get your time back without compromising quality. Max Merge Solutions was brought to market for mortgage professionals just like you.
                                </Typography>
                            </div>
                        </Box>

                        {/* one simple idea.... */}
                        {/*<Grid container spacing={8} className={classes.aboutSections}>*/}
                        {/*    {mediumBreakpoint ? (*/}
                        {/*        <>*/}
                        {/*            <Grid item xs={12} lg={5}>*/}
                        {/*                <div>*/}
                        {/*                    <img src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_460,c_scale/v1637690221/FJ-DSCF7147_hz6ojg.jpg" alt="Shamira Ketadia" />*/}
                        {/*                </div>*/}

                        {/*            </Grid>*/}
                        {/*        </>*/}
                        {/*    )*/}
                        {/*        : <></>}*/}
                        {/*    <Grid item xs={12} lg={7}>*/}
                        {/*        <Typography className={mediumBreakpoint ? classes.title : classes.smallTitle}>*/}
                        {/*            The Inception*/}
                        {/*        </Typography>*/}

                        {/*        <div className={classes.about}>*/}
                        {/*            <Typography className={classes.paragraph}>*/}
                        {/*                Working in the mortgage industry for the past decade, I have tried various merge software options available. I felt there should be a better solution to handle the document heavy aspect of this profession...a product that would speak to the simplicity and ease I was looking for. Max Merge Solutions was developed and brought to market specifically for this simple task.*/}
                        {/*            </Typography>*/}
                        {/*            <Typography className={classes.paragraph}>*/}
                        {/*                Document handling that works for your busy life.*/}
                        {/*            </Typography>*/}
                        {/*        </div>*/}
                        {/*        <div className={classes.about}>*/}
                        {/*            <Typography className={classes.signature}>*/}
                        {/*                Shamira Kotadia*/}
                        {/*            </Typography>*/}
                        {/*            <Typography>*/}
                        {/*                Founder & President, Max Merge Solutions*/}
                        {/*            </Typography>*/}
                        {/*        </div>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}

                        {/* food banks  */}
                        {/*<Grid container spacing={8} className={classes.aboutSections}>*/}

                        {/*    <Grid item xs={12}>*/}
                        {/*        <Typography className={mediumBreakpoint ? classes.title : classes.smallTitle}>*/}
                        {/*            Food Banks Canada*/}
                        {/*        </Typography>*/}

                        {/*        <div className={classes.about}>*/}
                        {/*            <Typography className={classes.paragraph}>*/}
                        {/*                As mortgage professionals, we place Canadians into homes by facilitating a smooth-running real estate finance transaction. Thriving communities need housing and food. That’s why with every monthly subscription we will donate a percentage to Food Banks of Canada.*/}
                        {/*            </Typography>*/}

                        {/*            <img src="https://res.cloudinary.com/maxmergesolutions/image/upload/w_177,h_54/v1637690772/In_Support_FBC_English_Logo_2_1_ylypgl.png" alt="Food Banks Canada" />*/}
                        {/*        </div>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}

                        <Grid container spacing={8} className={classes.aboutSections} style={{ marginBottom: 0 }}>
                            <Grid item xs={12}>
                                <Typography className={mediumBreakpoint ? classes.title : classes.smallTitle}>
                                    Our Contribution
                                </Typography>

                                <div className={classes.about}>
                                    <Typography className={classes.paragraph} style={{marginBottom:0}}>
                                        Home is the cornerstone of well-being. As mortgage professionals, we put Canadians into homes by helping real estate finance transactions go smoothly. But thriving communities need shelter and food. It’s up to us to contribute. That’s why a percentage of each member subscription is donated to Food Banks of Canada because they’re doing the real work to ensure every home has what it needs.
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>

                        {/*<hr style={{ backgroundColor: '#434343', height: '1px' }} />*/}
                    </Container>

                </Container>
            </div>

            {/* footer  */}
            {mediumBreakpoint ?
                <HomeFooter mediumBreakpoint={true} /> : <HomeFooter mediumBreakpoint={false} />
            }
        </div >
    )
}