import React from 'react'
import { CircularProgress } from '@mui/material';
import styled from "styled-components"
const Loader = () => {
    return (
        <WrapperLoader>
            <CircularProgress style={{width: "150px", height: "150px"}} />
        </WrapperLoader>
    )
}

const WrapperLoader = styled.div`
        width: 100%;
        padding: 100px 0;
        display: grid;
        place-items: center;
`

export default Loader
