import React from 'react'
import styled from "styled-components"
import { Container, Grid } from "@mui/material";
const Banner = () => {
    return (
        <BannerWrapper>
            <Container className="container small-container">
                <Grid container>
                    <Grid className="h2-container" xs={12} md={6} item>
                        <h2>What is Max <br/> Merge Solutions?</h2>
                    </Grid>
                    <Grid className="info" xs={12} md={6} item>
                        <p>
                            Max Merge Solutions is the only merge tool specifically tailored to the mortgage industry.
                        </p>
                        <p>
                            With one easy sign-up process, you’ll be organizing documents in no time. Service as sophisticated as the service you provide. 
                        </p>
                    </Grid>
                </Grid>
            </Container>
            <div className="bg-2"/>
        </BannerWrapper>
    )
}

const BannerWrapper = styled.div`
    position: relative;
    z-index: 1;

    h2 {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 20px;
        letter-spacing: 2px;
    }

    p {
        margin: 0;
        padding: 0;
        padding-bottom: 15px;
        font-size: 17px;
    }

    .container {
        background-color: #EFEFF1;
        z-index: 2;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .bg-2 {
        position: absolute;
        height: 100%;
        width: 50%;
        right: 0;
        top: 0;
        z-index: -1;
    }

    @media screen and (min-width: 900px) {
        .bg-2, .container {
            background-color: #1B1E1E;
        }
        background-color: #00939F;

        h2, p {
            color: white;
            margin: 0;
        }

        .h2-container {
            display: flex;
            align-items: center;
        }

        p {
            font-size: 18px;
        }

        .container {
            padding-left: 60px;
            padding-right: 60px;
        }
    }

    @media screen and (min-width: 1200px) {
        h2 {
            font-size: 35px;
        }
        
        .container {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
`

export default Banner
