import { createStyles, makeStyles } from "@mui/styles";
export const selectorUseStyles = makeStyles(() =>
    createStyles({
        root: {
            marginBottom: '32px'
        },

        settingsContainer: {
            marginTop: '146px',
        },

        account: {
            // width: '115px'
        },

        password: {
            // width: '135px'
        },
    })
);