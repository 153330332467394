import React, { useState } from 'react';
import { Typography, Button, Toolbar, AppBar, useTheme, useMediaQuery, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent } from '@mui/material/';
// import logo from '../assets/images/fullLogoWhite.png';
import { mergeOptionsStyles } from '../assets/MergeOptionsStyles';
import { KeyboardArrowDown, Close } from '@mui/icons-material';
import '../assets/MergeNav.css';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../Context/AuthContext';
import Sidebar from '../../Sidebar/Sidebar';
import { mergeLinks } from '../../Sidebar/Navlinks';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
interface IMergeNav {
    active: string;
}

export function MergeNav(props: any) {
    const [expandedPhone, setExpandedPhone] = useState(false);
    const theme = useTheme();
    const largeBreakpoint = useMediaQuery(theme.breakpoints.up(1280));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const classes = mergeOptionsStyles();
    const { token, logout } = useAuthContext();
    const { active } = props;
    // const currentUserId = useParams();
    const navButtons = 'navButtons';

    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
 
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open2 = Boolean(anchorEl2);
    const handleClick2 = (event: any) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleExpandPhone = (doc: any) => {
        setExpandedPhone(!expandedPhone);
    }

    if (!token.id)
        return (
            <div className={classes.main}>
                <AppBar style={{ color: 'white', background: '#1B1E1E', height: '90px' }} color="primary" elevation={0}>
                    <Toolbar style={{ height: "30px" }}>
                        <Typography variant="h4" className={classes.title}>
                            <Link to="/">
                                <img src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_100,q_auto,f_auto/v1633540731/fullLogoWhite_vxxqyf.png" alt="Max Merge Solutions" />
                            </Link>
                        </Typography>
                    </Toolbar>
                </AppBar>
            </div>
        )

    return (
        <>
            <div className={classes.main}>
                <Sidebar sidebarOpen={sidebarOpen} links={mergeLinks} margin="90px" position="fixed" />
                <AppBar style={{ color: 'white', background: '#1B1E1E', height: '90px' }} color="primary" elevation={0}>
                    <Toolbar style={{ height: "90px" }}>
                        <Typography variant="h4" className={classes.title}>
                            {!token?.email && (
                                <Link to="/">
                                    <img src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_100,q_auto,f_auto/v1633540731/fullLogoWhite_vxxqyf.png" alt="Max Merge Solutions" />
                                </Link>
                            )}

                            {token?.email && (
                                <Link to="/">
                                    <img src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_100,q_auto,f_auto/v1633540731/fullLogoWhite_vxxqyf.png" alt="Max Merge Solutions" />
                                </Link>

                            )}
                        </Typography>

                        {largeBreakpoint ? (
                            <>
                                <div className={classes.nav}>
                                    <Link color="inherit" className={active === 'profile' ? [classes.buttonCustom, classes.textShadow, navButtons, classes.active].join(" ") : [classes.buttonCustom, classes.textShadow, navButtons].join(" ")} to={token.userType === 1 ? "/merge-profile" : "/merge-options"}>Merge Profile</Link>
                                    {/* <Link color="inherit" className={active === 'merge' ? [classes.buttonCustom, classes.textShadow, navButtons, classes.active].join(" ") : [classes.buttonCustom, classes.textShadow, navButtons].join(" ")} to="/merge-docs">Merge Documents</Link> */}
                                    <Link color="inherit" className={active === 'split' ? [classes.buttonCustom, classes.textShadow, navButtons, classes.active].join(" ") : [classes.buttonCustom, classes.textShadow, navButtons].join(" ")} to="/split-docs">Split Documents</Link>
                                    <Link color="inherit" className={active === 'condense' ? [classes.buttonCustom, classes.textShadow, navButtons, classes.active].join(" ") : [classes.buttonCustom, classes.textShadow, navButtons].join(" ")} to="/condense-docs">Condense Documents</Link>
                                    <Link color="inherit" className={active === 'yearToDate' ? [classes.buttonCustom, classes.textShadow, navButtons, classes.active].join(" ") : [classes.buttonCustom, classes.textShadow, navButtons].join(" ")} to=
                                        "/ytd-tracker" >YTD Tracker</Link>
                                </div>

                                <div>
                                    <Button
                                        id="support-button"
                                        aria-controls="support-menu"
                                        aria-haspopup="true"
                                        aria-expanded={open2 ? 'true' : undefined}
                                        onClick={handleClick2}
                                        className={classes.navItem}
                                        style={{ color: 'white' }}
                                    >
                                        Support <KeyboardArrowDown />
                                    </Button>
                                    <Menu
                                        id="support-menu"
                                        anchorEl={anchorEl2}
                                        open={open2}
                                        onClose={handleClose2}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                        classes={{ paper: classes.dropdown }}
                                    >
                                        <Link to="/how-to-package-documents"><MenuItem>Tutorial Video</MenuItem></Link>
                                        <Link to="/contact-us" target="_blank"><MenuItem>Email Support</MenuItem></Link>
                                        <a onClick={() => { setExpandedPhone(true); }}><MenuItem>Phone Support</MenuItem></a>
                                        <a href="https://calendly.com/max-merge-solutions" target="_blank"><MenuItem>Book a Demo</MenuItem></a>
                                    </Menu>

                                    <Dialog
                                        fullScreen
                                        open={expandedPhone}
                                        onClose={() => setExpandedPhone(false)}
                                        style={{
                                            margin: "auto",
                                            width: "800px",
                                            height: "315px",
                                            borderRadius: 0,
                                        }}>
                                        <DialogTitle style={{ padding: 0, height: "76px", margin: 0 }}>
                                            <div style={{
                                                height: '100%',
                                                width: '100%',
                                                backgroundColor: '#1B1E1E',
                                                color: '#fff'
                                            }}>
                                                <Typography
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: 600,
                                                        left: 0,
                                                        marginLeft: "2vw",
                                                        paddingTop: "2.5vh"
                                                    }}>
                                                    Phone Support
                                                    <div style={{ display: "inline" }}>
                                                        <Button
                                                            onClick={() => setExpandedPhone(false)}
                                                            style={{ color: "#fff", padding: 0, float: "right" }}
                                                        >
                                                            <Close />
                                                        </Button>
                                                    </div>
                                                </Typography>
                                            </div>
                                        </DialogTitle>
                                        <DialogContent style={{ color: '#212525', overflow: "hidden", paddingTop: 20 }}>
                                            <p>Got questions?</p>
                                            <p>Call our support team; we're here to help!</p>
                                            <p><a href="tel:+18556963743" style={{ color: '#212525' }}>1-855-MY-MERGE</a></p>
                                            <p><a href="tel:+18556963743" style={{ color: '#212525' }}>1-855-696-3743</a></p>
                                            <p>*Support hours are Monday to Friday: 9 am-5 pm PST, excluding statutory holidays.</p>
                                        </DialogContent>
                                    </Dialog>
                                </div>

                                <div>
                                    <Button
                                        id="basic-button"
                                        aria-controls="basic-menu"
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        className={classes.navItem}
                                        style={{ color: 'white' }}>
                                        {token.firstName + " " + token.lastName} <KeyboardArrowDown />
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                        classes={{ paper: classes.dropdown }}
                                    >
                                        <Link to="/"><MenuItem>Home</MenuItem></Link>
                                        {/*  <a href="https://calendly.com/max-merge-solutions" target="_blank"><MenuItem>Book a Demo</MenuItem></a>
                                        <Link to="/explainer"><MenuItem>Explainer Video</MenuItem></Link>*/}
                                        <Link to="/settings"><MenuItem>Settings</MenuItem></Link>
                                        <Link to="/pricing"><MenuItem>Pricing</MenuItem></Link>
                                        <Link to="/about-us"><MenuItem>About us</MenuItem></Link>
                                        <MenuItem onClick={logout}>Logout</MenuItem>
                                     
                                    </Menu>
                                </div>
                            </>

                        ) :
                            <div>
                                <IconButton onClick={() => setSidebarOpen(!sidebarOpen)} className="merge-menu-btn">
                                    {
                                        !sidebarOpen ? <MenuIcon /> : <CloseIcon />
                                    }
                                </IconButton>
                            </div>
                        }

                    </Toolbar>
                </AppBar>

            </div>
        </>
    )
}
