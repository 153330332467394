import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import Loader from "./Loader";
import axios from 'axios';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import dateFormat from "dateformat";
import { ListStyleWrapper } from "./ListStyleWrapper"
import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent, Button, IconButton, Alert, Snackbar} from '@mui/material';
import { Link, useLocation } from "react-router-dom";

const ActiveMerge = () => {
    const [loading, setLoading] = useState(true);
    const [mergeTasks, setMergeTasks] = useState([]);
    const [error, setError] = useState("");
    const [dialog, setDialog] = useState(false);
    const [delId, setDelId] = useState(0);
    const [snackbar, setSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [severity, setSeverity] = useState("info");
    const location = useLocation();
    console.log(location.state);

    useEffect(()=> {
        setLoading(true);
        axios.get("./api/merge-task/profile").then((resp) => {
            setMergeTasks(filterTasks(resp.data));
            setLoading(false);
        }).catch(() => {
            setError("Unable to fetch data, please try again later");
            setLoading(false);
        })
    }, [])

    const filterTasks = (tasks) => {
        return tasks.filter(x => new Date(x.purgeDateTime) > new Date(Date.now()));   
    }

    const handleDialogDel = (id) => {
        setDelId(id);
        setDialog(true);
    }

    const confirmDel = () => {
        setDialog(false);
        setSnackbar(true);
        setSnackMessage("Deleting...");
        setSeverity("info");

        axios.delete(`./api/merge-task/${delId}`).then(()=> {
            setMergeTasks(prev => prev.filter((x)=> x.id !== delId));
            setSnackMessage("Succesfully deleted");
            setSeverity("success");
        }).catch(()=>{
            setSnackMessage("Failed to delete");
            setSeverity("error");
        })
    }

    if(loading)
        return <Loader />

    if(error)
        return <ActiveMergeWrapper>
                <Alert className="alert" severity={"error"}>{error}</Alert>
            </ActiveMergeWrapper>

    return (
        <ActiveMergeWrapper>
            {
                mergeTasks.length === 0 && 
                <Alert className="alert" severity="info">You have no active merge tasks. Click "New Merge" to begin.</Alert>
            }
            {
                mergeTasks.length > 0 &&
                <ListStyleWrapper>
                    <p>
                        Please select the task you would like to continue
                    </p>
                    <table>
                        <tr className='table-legends'>
                            <th>Name</th>
                            <th>Date Created</th>
                            <th>Task Expiring</th>
                            <th></th>        
                            <th></th>
                        </tr>
                        {mergeTasks.map((task)=> {
                            return<tr key={task.id} className="tr-row">
                                    <td className='td-file'>
                                        <span>
                                            <InsertDriveFileIcon />
                                            {task.name}
                                        </span>
                                    </td>
                                    <td className="td-time">{dateFormat(new Date(task.created), "dd/mm/yyyy")}</td>
                                    <td className="td-time">{dateFormat(new Date(task.purgeDateTime), "dd/mm/yyyy h:MM TT")}</td>
                                    <td>
                                        <Link 
                                        to={{ pathname:  "merge-docs" , state: location.state ? { id: task.id, documentPackaging: location.state.documentPackaging, mergeFiles: location.state.mergeFiles } : {  id: task.id , isAfterSave: true} }}>
                                            <Button>
                                                Resume
                                            </Button>
                                        </Link>
                                    </td>
                                    <td className="td-del">
                                        <IconButton onClick={() => handleDialogDel(task.id)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </td>                            
                            </tr>        
                        })
                    }
                    </table>
                </ListStyleWrapper>
            }

            <Dialog
                open={dialog}
                onClose={() => setDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                 <DialogTitle id="alert-dialog-title">
                    Delete task? 
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this task?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialog(false)} autoFocus>Cancel</Button>
                    <Button className="del-confirm" style={{color: "#ba000d"}} onClick={confirmDel}>
                        Confirm
                    </Button>
                 </DialogActions>
            </Dialog>
            
            <Snackbar open={snackbar} autoHideDuration={5000} onClose={() => setSnackbar(false)}>
                <Alert severity={severity} sx={{ width: '100%' }}>
                    {snackMessage}
                </Alert>
            </Snackbar>
        </ActiveMergeWrapper>
    )
}

const ActiveMergeWrapper = styled.section`  
    .alert {
        margin: 2rem 0;
    }
`

export default ActiveMerge
