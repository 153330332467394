// @flow
import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Button } from '@mui/material';
import { MergeNav } from '../../MergeProcess/components/MergeNav';
import { global } from '../../../global';
import { Link } from 'react-router-dom';
import axios from 'axios';



export function Goodbye() {
    const [invoiceDate, setInvoiceDate] = useState<null | Date>(null)

    const getSubscription = () => {
        axios
            .get(`api/subscriptions`)
            .then((result) => {
                const { brand, card, expMonth, expYear } = result.data.subscription.paymentMethod;
                const { currentPeriodEnd, status, userType } = result.data.subscription;
                const date = new Date(currentPeriodEnd)
                console.log(date);
                setInvoiceDate(date);
            })
            .catch((e) => console.log('error in getSubscription:', e))
    }

    useEffect(() => {
        getSubscription()
    }, [])

    return (
        <Container>
            <MergeNav active={null} />
            <Grid alignItems="center" style={{ height: '100vh' }} container>
                <Grid direction="column" container item>
                    <Typography style={{ fontSize: 32, marginBottom: 28, fontWeight: 700 }}>We're sad to see you go.</Typography>
                    {invoiceDate && <Typography style={{ fontSize: 18, marginBottom: 28 }}> {`You have successfully cancelled your subscription which will end on ${invoiceDate.getDate()}/${invoiceDate.getMonth() === 0 ? 12 : invoiceDate.getMonth() + 1}/${invoiceDate.getFullYear()}.`}</Typography>}
                    <Typography style={{ fontSize: 18, marginBottom: 10 }}>When your subscription ends, you will no longer</Typography>
                    <Typography style={{ fontSize: 18, marginBottom: 76 }}>have access to any of the features.</Typography>
                    <Grid container>
                        <Link to="/"><Button variant="contained" style={{ background: global.black, color: '#fff', marginRight: 25, width: 220, height: 52, marginBottom: 25 }}>Home</Button></Link>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};