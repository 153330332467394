import React from 'react'
import { MergeNav } from '../MergeProcess/components/MergeNav'
import styled from "styled-components"
import { useAuthContext } from '../Context/AuthContext';
import { Link } from "react-router-dom";


const Error = () => {

    const { token, setToken, setLoading, logout } = useAuthContext();

    return (
        <ErrorWrapper>
            <MergeNav active='none' />
            <div className="error-content">
                <h1>404</h1>
                <h2>Oops! Something went wrong</h2>
                <p>We couldn't find the page you're looking for.</p>
                {token.id ? (<Link to="/merge-docs">
                    <button>
                        &lt; Go back home
                </button>
                </Link>) : (<Link to="/">
                        <button>
                            &lt; Go back home
                        </button>
                </Link>)}
             
            </div>
        </ErrorWrapper>
    )
}

const ErrorWrapper = styled.div`

    .error-content {
        width: 100%;
        padding: 100px 0;
        height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-family: "Montserrat";
        color: #1B1E1E;
    }

        h1, h2, p {
            margin-bottom: 15px;
        }

        h1 {
            font-size: 40px;
            font-weight: 600;
        }

        h2 {
            font-size: 22px;
            font-weight: 500;
        }

        p {
            font-weight: 16px;
            margin-bottom: 20px;
        }

    button {

        font-size: 16px;
        font-weight: 600;
        padding: 13px 20px;
        color: white;
        border: none;
        outline: none;
        background-color: #1B1E1E;
        transition: 0.2s;
        :hover {
            background-color: black;
        }
    }
    
    @media screen and (min-width: 990px) {

        h1, h2, p {
            margin-bottom: 20px;
        }

        h1 {
            font-size: 58px;
            font-weight: 600;
        }

        h2 {
            font-size: 32px;
            font-weight: 500;
        }

        p {
            font-weight: 18px;
            margin-bottom: 25px;
        }
    
        button {
            font-size: 18px;
            padding: 15px 22px;
        }
    }

`

export default Error
