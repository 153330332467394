import styled from "styled-components"

export const ListStyleWrapper = styled.div`
    p {
        margin: 2rem 0;
    }

    td, th {
        padding-right: 4rem;
        padding-bottom: 1rem;
    }

    th {
        font-weight: 600;
    }

    td {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }

    .td-file {
        span {
            display: flex;
            align-items: center;
        }
        height: 100%;
        color: #1B1E1E;
        font-weight: 600;
        svg {
            margin-right: 10px;
            font-size: 16px;
            color: #00939F;
        }
    }

    .td-time {
        color: #B2B2B2;
    }

    .td-del {
        svg { 
            font-size: 16px;
            color: #1B1E1E; 
        };
        button {
            :hover,
            :active,
            :focus {
                outline: none;
            }
        }
    } 
`