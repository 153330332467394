import React, { useState } from 'react'
import styled from "styled-components"
import validator from "validator";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import useWindowDimensions from "./windowDimensions";

interface IFormState {
    maxwidth?: null | boolean,
    blackBtn?: null | boolean,
}

const Form = ({ maxwidth, blackBtn }: IFormState) => {
    const { width } = useWindowDimensions();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const [fnVal, setFnVal] = useState('');
    const [lnVal, setlnVal] = useState('');
    const [emailVal, setEmailVal] = useState('');
    const [error, setError] = useState(false);
    const [resp, setResp] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setResp(false);
        setError(false);
        setLoading(true);
        if (validateInputs()) {
            setLoading(false);
        } else {
            axios
                .post("/api/landing/send", {
                    email,
                    firstName,
                    lastName,

                })
                .then((resp) => {
           
                    if (resp.data.statusCode === 202) {
                        //do succesfull
                        setEmail("");
                        setFirstName("");
                        setLastName("");
                        setResp(true);
                        setError(false);
                    } else {
                        //do failed
                        setResp(true);
                        setError(true);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);

                    //do failed
                    setResp(true);
                    setError(true);
                    setLoading(false);
                });
        }
    }

    const validateInputs = () => {
        let tempErr = false;
        setFnVal('');
        setlnVal('');
        setEmailVal('');
        if (!email) {
            setEmailVal("Required");
            tempErr = true;
        } else if (!validator.isEmail(email)) {
            setEmailVal("Not valid");
            tempErr = true;
        }
        if (!lastName) {
            setlnVal('Required')
            tempErr = true;
        }
        if (!firstName) {
            setFnVal('Required')
            tempErr = true;
        }
        return tempErr;
    }

    return (
        <FormWrapper>
            <form className={maxwidth ? "maxw" : ""}>
                <div className={width > 900 ? "form-flex" : ""}>
                    <div className="frm-cntrl">
                        <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} disabled={loading} />
                        <div className="invalid">{fnVal}</div>
                    </div>
                    <div className="frm-cntrl">
                        <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} disabled={loading} />
                        <div className="invalid">{lnVal}</div>
                    </div>
                </div>
                <div className="frm-cntrl">
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={loading} />
                    <div className="invalid">{emailVal}</div>
                </div>
                {
                    !loading ?
                        <button type="submit" onClick={handleSubmit} disabled={loading} className={blackBtn ? "blackBtn" : ""}>
                            Submit
                        </button>
                        :
                        <div className="spinner">
                            <CircularProgress />
                        </div>
                }
                <div className={`resp ${error && "error-resp"}`}>
                    {
                        error && resp &&
                        <p className={blackBtn ? "text-black" : ""}>Oops! something went wrong. Please try again or contact us as <a href="mailto:info@maxmergesolutions.com">info@maxmergesolutions.com</a></p>
                    }
                    {
                        !error && resp &&
                        <p>Thank you for contacting us<br />We'll get back to you soon!</p>
                    }
                </div>
            </form>
        </FormWrapper>
    )
}



const FormWrapper = styled.div`
    padding: 5px 0 5px;
    display: flex;
    justify-content: center;

    .MuiOutlinedInput-root {
        background-color: white;
    }   

    .form-flex {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }

    form {
        width: 100%;
    }

    .maxw {
        max-width: 500px;
    }

    .resp {
        color: white;
        margin-top: 10px;
        height: 24px;
        p {
            margin-bottom: 20px;
        }
    }

    .text-black {
        color: #1B1E1E;
    }

    .error-resp {
        color: #f35d5d;
        a {
            color: white;
            :hover {
                color: #f1f1f1;
            }
        }
    }

    .spinner {
        width: 100%;
        display: flex;
        justify-content: center;
        min-height: 47px;
        margin-top: 5px;
    }

    .invalid {
        color: #f35d5d;
        font-size: 15px;
        position: absolute;
        top: 48px;
        left: 0;
        text-transform: capitalize;
    }

    .frm-cntrl {
        position: relative;
    }

    button {
        margin-top: 5px;
        background-color: #00939F;
        color: white;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        padding: 10px 0;
    }

    .blackBtn {
        background-color: #1B1E1E;
    }

    input {
        background-color: #FCFCFC;
        color: #1B1E1E;
        width: 100%;
        padding: 10px 20px;
        outline: none;
        border: none;
        font-size: 17px;
        margin-bottom: 27px;
        border: 1px solid #D6D6D6;

        :focus, :active, :hover {
            border: 1px solid #D6D6D6;
            outline: none;
        }
        ::placeholder {
            color: #829399;  
            font-size: 17px;
        }
    }

    input:disabled {
        background: #dddddd;
        color: #666666;
        ::placeholder {
            color: #b9b9b9;  
        }
    }

    button {
        outline: none;
        border: none;
        box-shadow: none;
        :hover, :active, :focus {
            outline: none;
            border: none;
            text-decoration: none;
        }
    }
`

export default Form
