// @flow
import { Grid, TextField, Typography, Button, Alert, IconButton, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { IStandardProps } from '../../interfaces/IStandardProps';
import { passwordUseStyles } from '../assets/passwordUseStyles';
import axios from 'axios';
import { useAuthContext } from '../../Context/AuthContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface IPasswordState {
  password: string | null;
  newPassword: string | null;
  confirmedNewPassword: string | null;
}

const errors = {
  enterPassword: 'Please enter your password',
  enterNewPassword: 'Please enter a new password',
  confirmPassword: 'Please confirm your password',
  doesNotMatch: 'Passwords do not match',
  incorrectCurrentPassword: 'Incorrect current password',
  validation: 'Password must be at least 8 characters long and contain at least one uppercase, lowercase, number and special character'
}

export function Password() {
  const classes = passwordUseStyles();
  const { token, setToken } = useAuthContext();
  const [passwordState, setPasswordState] = useState<IPasswordState>({
    password: null,
    newPassword: null,
    confirmedNewPassword: null,
  });
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState<null | boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('')

  const updatePassword = () => {
    setError(null);
    setSuccess(false);
    setLoading(true);
    setLoadingMsg('Changing password');
    axios
      .post('/api/users/update-password', {
        //id: token.id, // cannot trust the id supplied here...
        password: passwordState.password,
        newPassword: passwordState.newPassword
      })
      .then((result) => {
        console.log('passwordUpdated:', result)
        setTimeout(() => {
          setSuccess(true);
          setLoading(false);
          setLoadingMsg('');
        }, 500)
      })
      .catch((e) => {
        console.log('updatePasswordError:', e.response)
        if (e.response.status === 404) {
          setError(errors.incorrectCurrentPassword)
        }
        if (e.response.status === 400) {
          setError(errors.validation)
        }
      })
  }

  const checkAndUpdatePassword = async () => {
    !passwordState.password ? setError(errors.enterPassword)
      : !passwordState.newPassword ? setError(errors.enterNewPassword)
        : !passwordState.confirmedNewPassword ? setError(errors.confirmPassword)
          : passwordState.newPassword !== passwordState.confirmedNewPassword ? setError(errors.confirmPassword)
            : updatePassword();
    setTimeout(() => {
      console.log(error);

    }, 1000)
  }

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} style={{ marginBottom: '28px' }}>
        <Typography>Enter a new password with a minimum of 8 characters, one uppercase, lowercase, number and special character.</Typography>
      </Grid>
      <Grid item container lg={4} md={5} sm={8} xs={12}>
        <TextField onChange={(e) => setPasswordState((prev: IPasswordState) => ({ ...prev, password: e.target.value }))} className={error === errors.enterPassword ? classes.textFieldWithError : classes.textField} fullWidth type={showPassword ? 'text' : "password"} label="Type your current password" InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(prev => !prev)}
              edge="end"
              className="no-outline"

            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>,
        }} />

        {error === errors.enterPassword && <Alert className={classes.error} severity="warning">{error}</Alert>}

        <TextField onChange={(e) => setPasswordState((prev: IPasswordState) => ({ ...prev, newPassword: e.target.value }))} className={error === errors.enterNewPassword ? classes.textFieldWithError : classes.textField} fullWidth type={showPassword ? 'text' : "password"} label="Type new password" InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(prev => !prev)}
              edge="end"
              className="no-outline"

            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>,
        }} />

        {error === errors.enterNewPassword && <Alert className={classes.error} severity="warning">{error}</Alert>}

        <TextField onChange={(e) => setPasswordState((prev: IPasswordState) => ({ ...prev, confirmedNewPassword: e.target.value }))} style={error === errors.enterNewPassword || error === errors.doesNotMatch || error === errors.validation || error === errors.incorrectCurrentPassword || success ? { marginBottom: 0 } : { marginBottom: 42 }} className={classes.textField} fullWidth type={showPassword ? 'text' : "password"} label="Re-enter password" InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(prev => !prev)}
              edge="end"
              className="no-outline"

            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>,
        }} />

        {error === errors.confirmPassword && <Alert className={classes.error} severity="warning">{error}</Alert>}
        {error === errors.doesNotMatch && <Alert className={classes.error} severity="warning">{error}</Alert>}
        {error === errors.incorrectCurrentPassword && <Alert className={classes.error} severity="warning">{error}</Alert>}
        {error === errors.validation && <Alert className={classes.longError} severity="warning">{error}</Alert>}
        {success && <Alert className={classes.longError} severity="success">Password updated!</Alert>}

        {loading && <Alert variant="standard" severity="success">{loadingMsg}</Alert>}
        <Button onClick={checkAndUpdatePassword} color="primary" variant="contained" className={classes.buttonCustom}>Save</Button>
      </Grid>
    </Grid>
  );
};