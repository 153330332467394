import * as React from 'react';
import { useState, useEffect } from 'react';
import { IFormState } from '../assets/interfaces/IFormState';
import { Button, Container, Typography, useTheme, useMediaQuery, Dialog, DialogContent, DialogTitle, TextField, InputLabel, Alert } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';

import { MergeNav } from './MergeNav';
import { mergeOptionsStyles } from '../assets/MergeOptionsStyles';
import { IStandardProps } from '../../interfaces/IStandardProps';
import { global } from '../../../global';
import { useAuthContext } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { IMergeTaskInfo } from '../assets/interfaces/IMergeTaskInfo';
import { IDetails } from '../../YTDTracker/components/YearToDateOptions';
import { IPaymentState, IPayout } from './MergeDocs/assets/interfaces/IPaymentState';
import { IDocumentData } from './LenderSummarySheet/assets/interfaces/IDocumentData';
import { getIndividualItemsFromDocuments } from '../../../helpers/filters-and-sorters';
import * as ReactDOMServer from "react-dom/server";
import axios from 'axios';
import { useHistory } from 'react-router';

interface IMergeOptions {
    location: {
        state: {
            fromDownload: boolean;
            mergeTaskInfo: IMergeTaskInfo;
            details: IDetails;
            payments: IPaymentState[];
            payouts: IPayout[];
            attachedDoc: any;
        }
    }
}

export default function EmailConfirmation(props: IDocumentData) {
    const history = useHistory();
    const { token, setToken } = useAuthContext();
    const classes = mergeOptionsStyles();
    const { location: { state: { fromDownload, mergeTaskInfo } } } = props;
    const [email, setEmail] = useState('');
    const [name, setName] = useState<string>('');
    const [modal, setModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [nameVal, setNameVal] = useState("");
    const [severity, setSeverity] = useState<AlertColor>("info");
    const [alertInfo, setAlertInfo] = useState("");

    const back = () => {
        window.history.back();
    }

    const saveMerge = () => {
        window.history.back();
    }

    // not compiling to js 
    const gotoMergeProfile = () => {
        token.userType === 1 ? history.push("/merge-profile") : history.push("/merge-options");
    }

    const saveTask = () => {
        if (!name) {
            setNameVal("Project name is required");
            return;
        }
        setSaving(true);
        setAlertInfo("Saving...");
        axios.put(`./api/merge-task/${mergeTaskInfo.id}`, {
            name: name
        }).then((resp) => {
            setSeverity("success");
            setAlertInfo("Saved! Redirecting...");
            setTimeout(() => {
                history.push("/merge-options");
            }, 2000)

        }).catch(() => {
            setSeverity("error")
            setAlertInfo("Oops, something went wrong, unable to save.")
        })
    }

    return (
        <div>
            <div>
                <MergeNav />
            </div>

            <div className={classes.root} >
                {fromDownload && <div style={{ maxWidth: '640px', marginLeft: 'auto', marginRight: 'auto', marginBottom: "74px" }}>
                    <Alert style={{ width: "100%" }} severity="success">
                        <strong>Tip</strong> - Your files are ready and in a zip format; please unzip by moving files onto your computer, then upload your merged docs into a portal.
                    </Alert>
                </div>}

                <Typography variant="h5" className={classes.mergeHeading}>How Would You Like to Proceed?</Typography>
                <div style={{ marginTop: "40px", display: "flex", flexDirection: "row" }}>
                    <Button disableRipple onClick={() => { setModal(true); console.log('clicked') }} variant="outlined" className={[classes.options, classes.optionButtonCustom].join(" ")} style={{ width: 300, padding: 10, height: 80 }}> Save Merge </Button>
                    <Button onClick={() => gotoMergeProfile()} variant="outlined" className={[classes.options, classes.optionButtonCustom].join(" ")} style={{ width: 300, padding: 10, height: 80 }}> Delete Merge </Button>
                </div>
                <Typography onClick={() => back()} style={{ textDecorationLine: 'underline', fontFamily: global.fontFamily, fontWeight: 600, color: 'black', cursor: 'pointer', marginTop: '17px' }}> Back </Typography>
            </div>
            <Dialog
                fullScreen
                open={modal}
                onClose={() => setModal(false)}
                style={{
                    margin: 'auto',
                    width: '750px',
                    height: '430px',
                    borderRadius: 0
                }}
            >
                <DialogTitle style={{ padding: 0, height: '76px', margin: 0 }}>
                    <div className={classes.modalTopBar}>
                        <Typography style={{ fontSize: '18px', fontWeight: 600, left: 0, padding: '0 0 0 0', marginLeft: '2vw', paddingTop: '2.5vh' }}>
                            Save file
                        </Typography>
                    </div>
                </DialogTitle>

                <DialogContent style={{ padding: "3rem 6rem" }}>
                    {
                        saving ?
                            <>
                                <Alert severity={severity}>{alertInfo}</Alert>
                            </>
                            :
                            <>
                                <form onSubmit={saveTask}>
                                    <InputLabel style={{ fontSize: "20px" }}>Project Name</InputLabel>
                                    <input autoFocus type="text" value={name} onChange={(e) => setName(e.target.value)} className={classes.inputStyle} />
                                    <p style={{ fontSize: "14px", color: "#ba000d", marginTop: "10px" }}>{nameVal}</p>
                                    <div style={{ display: "flex", justifyContent: "end", marginTop: "40px" }}>
                                        <Button onClick={() => setModal(false)} className={classes.cancelButton}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" className={classes.saveButton}>
                                            Save
                                        </Button>
                                    </div>
                                </form>
                            </>
                    }
                </DialogContent>
            </Dialog>
        </div>
    )
}

