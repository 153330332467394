import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Login from "../Login/Login";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState({
    email: null,
    password: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    userType: null,
      id: null,
      isExpired:null,

  });

  const [loading, setLoading] = useState(true);

  function logout() {
    setLoading(true);
    axios
      .get("api/auth/unauthenticate")
      .then((response) => {
        setToken({
          email: null,
          firstName: null,
          lastName: null,
          password: null,
          phoneNumber: null,
          userType: null,
          isExpired: null,
          id: null,
        });
        console.log("cookies:", document.cookie);
        document.cookie = "";
        setLoading(false);
        return true;
      })
      .catch((e) => {
        setLoading(false);
        console.log("unauthentication error:", e);
        return false;
      });
  }

  useEffect(() => {
    if (token.id) setLoading(false);
  }, [token]);

  useEffect(() => {
    setLoading(true);

    axios
      .get("api/Users/logged-user")
        .then((result) => {
        setToken((prev) => ({ ...prev, ...result.data }));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const value = {
    token,
    logout,
    setToken,
    setLoading,
    loading,
    };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
    return useContext(AuthContext);
};
