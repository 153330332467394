import React, { useEffect } from 'react';
import { useHistory } from "react-router";
import { Paper, Box, Grid, Hidden, Typography, Button } from '@mui/material/';
import { useAuthContext } from '../../Context/AuthContext';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { loginUseStyles } from '../assets/loginUseStyles';

// Component Imports
import { NavBar } from '../../NavBar/NavBar';
import { global } from '../../../global';

// Interface


export default function ConfirmPasswordLink() {
  const { token, setToken } = useAuthContext();
  const history = useHistory();
  const classes = loginUseStyles();

  return (
    <>
      <Helmet>
        <title>Reset Password Link | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
      </Helmet>
      <NavBar color="white" active="" />
      <Paper square elevation={24} className={classes.paper}>
        <Grid className={classes.grid} container spacing={0} direction='row' justifyContent='center' alignItems='center'>
          <Hidden only={['xs', 'sm', 'md']} >
            <Grid className={classes.pictureContainer} md={5} item>
              <img className={classes.picture} src="https://res.cloudinary.com/maxmergesolutions/image/upload/w_800,h_947,q_auto/v1637690055/iStock-1004109150_large_version_oyz7tc.jpg" alt="Office Setup" />
            </Grid>
          </Hidden>
          <Grid container md={7} xs={12} justifyContent='center' item>
            <Box style={{ width: '60%', fontFamily: global.fontFamily, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignItems: 'flex-start', height: '50vh', color: global.black }}>
              <div>
                <Typography className={classes.titleLogin} style={{ fontSize: 24 }} variant='h4'>Reset your password</Typography>
                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start' }}>

                  Thank you for using Max Merge Solutions!
                  <span style={{ fontWeight: 600, textAlign: 'left', marginTop: 30 }}> A link has been sent to your email. Please follow the link to reset your password</span>
                </div>

              </div>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}