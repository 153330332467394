import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, Redirect, Prompt } from "react-router";
import { HomeLanding } from "./components/Home/HomeLanding.tsx";
import Login from "./components/Login/Login.tsx";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { global } from "./global";
import {
    MERGE_FILES_PRICE,
    DOCUMENT_PACKAGING_PRICE
} from './constants';
import "./custom.css";
import MergeDocuments from "./components/MergeProcess/components/MergeDocs/MergeDocs2";
import AddYearToDate from "./components/MergeProcess/components/AddYearToDate";
import YearToDate from "./components/YTDTracker/components/YearToDate";
import YearToDateFromMerge from "./components/YTDTracker/components/YearToDateFromMerge";
import MergeOptions from "./components/MergeProcess/components/MergeOptions";
import { SubscriptionPage } from "./components/SubscriptionPage/SubscriptionPage";
import { Settings } from "./components/Settings/Settings";
import { MergePDF } from "./components/MergeProcess/components/MergePDF/MergePDF";
import { DownloadPDF } from "./components/MergeProcess/components/DownLoadPDF/DownloadPDF";
import { DownloadPDFOverview } from "./components/MergeProcess/components/DownloadPDFOverview/DownloadPDFOverview";
import { LenderSummarySheet } from "./components/MergeProcess/components/LenderSummarySheet/LenderSummarySheet";
import { Pricing } from "./components/Pricing/Pricing";
import SplitDocuments from "./components/SplitDocuments/components/SplitDocuments";
import DownloadSplit from "./components/SplitDocuments/components/DownloadSplit";
import { theme } from "./appTheme";
import AboutUs from "./components/AboutUs/AboutUs";
import CompressDocuments from "./components/CondenseDocuments/components/CompressDocuments";
import DownloadCondensed from "./components/CondenseDocuments/components/DownloadCondensed";
import { YearToDateOptions } from "./components/YTDTracker/components/YearToDateOptions";
import Landing from "./components/Landing/Landing";
import { useAuthContext } from "./components/Context/AuthContext";
import Error from "./components/Error/Error.tsx";
import { Checkout } from "./components/CheckoutForm3/Checkout";
import ContactUs from "./components/ContactUs/components/ContactUs";
import ComingSoon from "./components/ComingSoon/components/ComingSoon";
import Privacy from "./components/Privacy/components/Privacy";
import TermsAndCondition from "./components/TermsAndCondition/components/TermsAndCondition";
import NoAccess from "./components/NoAccess/components/Noaccess"
import Explainer from "./components/Explainer/components/Explainer"
import PackageDocumentsTutorial from "./components/PackageDocumentsTutorial/components/PackageDocumentsTutorial"
import TutorialSelection from "./components/PackageDocumentsTutorial/components/TutorialSelection"

import MergeProfile from "./components/MergeProfile/MergeProfile";
import { SnackbarProvider } from "notistack";
import ConfirmEmail from "./components/Login/components/ConfirmEmail";
import ForgotPassword from "./components/Login/components/ForgotPassword";
import ResetPassword from "./components/Login/components/ResetPassword";
import { CancelSubscription } from "./components/Settings/components/CancelSubscription";
import { Goodbye } from "./components/Settings/components/Goodbye";
import EmailConfirmation from "./components/MergeProcess/components/EmailConfirmation";
import SaveMerge from "./components/MergeProcess/components/SaveMerge";
import MergeManagement from "./components/MergeManagement/MergeManagement.tsx";

const App = () => {
    const { logout, token, setToken } = useAuthContext();

    const history = useHistory();
    // const { currentUserId } = useParams();
    // const [clientSecret, setClientSecret] = useState("");

    const processLogout = () => {
        const logoutStatus = logout();
        if (logoutStatus) {
            history.push("/");
        }
        // document.cookie = '; expires=Wed, 05 Aug 2021 23:00:00 UTC';
    };

    const Routes = () => {
        return (
            <Switch>
                <Route
                    exact
                    path="/"
                    children={<HomeLanding logout={processLogout} />}
                />

                <Route
                    exact
                    path="/log-in"
                    children={<Login logout={processLogout} registration={false} />}
                />

                <Route
                    exact
                    path="/sign-up"
                    children={<Login logout={processLogout} registration={true} />}
                />

                <Route exact path="/confirm-email" children={<ConfirmEmail />} />

                <Route exact path="/forgot-password" children={<ForgotPassword />} />

                <Route path="/reset-password" children={<ResetPassword />} />

                <Route
                    exact
                    path="/checkout"
                    children={
                        <Checkout
                            userType={1}
                            priceId={"price_1MPVzqALbh1IzL7wwLoL1AgI"}
                            price={global.appSettings[MERGE_FILES_PRICE]}
                            edit={false}
                        />
                    }
                />

                <Route
                    exact
                    path="/checkout-premium"
                    children={
                        <Checkout
                            userType={2}
                            priceId={"price_1MPWGlALbh1IzL7wccUs5CTN"}
                            price={global.appSettings[DOCUMENT_PACKAGING_PRICE]}
                            edit={false}
                        />
                    }
                />

                <Route
                    exact
                    path="/about-us"
                    children={<AboutUs setToken={setToken} token={token} />}
                />

                <Route
                    exact
                    path="/contact-us"
                    children={<ContactUs setToken={setToken} token={token} />}
                />

                <Route exact path="/subscription" children={<SubscriptionPage />} />

                <Route
                    exact
                    path="/coming-soon"
                    children={<ComingSoon setToken={setToken} token={token} />}
                />
                <Route
                    exact
                    path="/privacy-policy"
                    children={<Privacy setToken={setToken} token={token} />}
                />
                <Route
                    exact
                    path="/explainer"
                    children={<Explainer setToken={setToken} token={token} />}
                />
                <Route
                    exact
                    path="/how-to-package-documents"
                    children={<PackageDocumentsTutorial setToken={setToken} token={token} />}
                />
                <Route
                    exact
                    path="/terms-of-service"
                    children={<TermsAndCondition setToken={setToken} token={token} />}
                />

                <PrivateRoute exact path="/settings" component={Settings} />

                <Route
                    exact
                    path="/cancel-subscription"
                    component={CancelSubscription}
                />
                <PrivateRoute exact path="/goodbye" component={Goodbye} />

                <PrivateRoute exact path="/merge-docs" component={MergeDocuments} />

                <PrivateRoute
                    exact
                    path="/condense-docs"
                    component={CompressDocuments}
                />

                <PrivateRoute
                    exact
                    path="/download-condensed-doc"
                    component={DownloadCondensed}
                />

                <PrivateRoute exact path="/split-docs" component={SplitDocuments} />

                <PrivateRoute
                    exact
                    path="/download-split-doc"
                    component={DownloadSplit}
                />

                <PremiumRoute path={`/merge-pdf`} component={MergePDF} />

                <PrivateRoute
                    exact
                    path="/merge-options"
                    component={MergeOptions}
                // render={({ location }) => (
                //   <MergeOptions
                //     mergeTaskInfo={location.state.mergeTaskInfo}
                //     payments={location.state.payments}
                //     payouts={location.state.payouts}
                //   />
                // )}
                />

                <PrivateRoute
                    exact
                    path="/download-pdf"
                    component={DownloadPDF}
                // render={({ location }) => {
                //   return (
                //     <DownloadPDF
                //       mergeTaskInfo={location.state.mergeTaskInfo}
                //       payments={location.state.payments}
                //     />
                //   );
                // }}
                />

                <PrivateRoute
                    exact
                    path="/download-overview"
                    component={DownloadPDFOverview}
                />

                <PremiumRoute
                    exact
                    path="/lender-summary"
                    component={LenderSummarySheet}
                />
                <PremiumRoute
                    exact
                    path="/email-confirmation"
                    component={EmailConfirmation}
                />
                <Route
                    exact
                    path="/save-merge"
                    component={SaveMerge}
                />
            

                <Route
                    exact
                    path="/pricing"
                    render={() => {
                        return <Pricing renew={false} />;
                    }}
                />

                <Route
                    exact
                    path="/change-subscription"
                    render={() => {
                        return <Pricing renew={true} />;
                    }}
                />
                <Route
                    exact
                    path="/merge-management"
                    render={() => {
                        return <MergeManagement />;
                    }}
                />
                

                {/* <Route exact path="/pricing" children={<Pricing />} /> */}
                <PrivateRoute exact path="/merge-profile" component={MergeProfile} />

                <PrivateRoute exact path={`/add-ytd`} component={AddYearToDate} />

                <PremiumRoute exact path={`/ytd-tracker`} component={YearToDate} />
                <PrivateRoute
                    exact
                    path={`/merge-ytd-tracker`}
                    component={token.userType == 2 ? YearToDateFromMerge : <Pricing renew={true} />}
                />

                <PrivateRoute
                    exact
                    path="/ytd-tracker-options"
                    component={token.userType == 2 ? YearToDateOptions : <Pricing renew={true} />}
                />
                <Route exact path="/get-started" component={Landing} />

                <PrivateRoute exact path="/tutorial-selection" component={token.userType == 2 ? TutorialSelection : <Pricing renew={true} />} />

                <Route path="*">
                    <Error />
                </Route>
            </Switch>
        );
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={15}>
                    <CssBaseline />
                    <Routes />
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;

const PrivateRoute = ({ component: Component, exact, path, ...rest }) => {
    const { token, loading } = useAuthContext();

    if (loading) {
        return null;
    }

    return (
        <Route
            exact={exact}
            path={path}
            render={(props) => {
                return !token.id ? (
                    <Redirect to="/log-in" />
                ) : token.userType === 4 || 0 ? (
                    <Redirect to="/pricing" />
                ) : (
                    <Component {...props} {...rest} />
                );
            }}
        ></Route>
    );
};
const PremiumRoute = ({ component: Component, exact, path, ...rest }) => {
    const { token, loading } = useAuthContext();

    if (loading) {
        return null;
    }

    return (
        <Route
            exact={exact}
            path={path}
            render={(props) => {
                return !token.id ? (
                    <Redirect to="/log-in" />
                ) : token.userType === 4 || 0 ? (
                    <Redirect to="/pricing" />
                ) : token.userType === 1 ? (
                    <NoAccess />
                ) :
                (
                    <Component {...props} {...rest} />
                );
            }}
        ></Route>
    );
};
