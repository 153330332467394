import React, { useEffect, useState } from 'react';
import { subscriptionPageStyles } from './assets/SubscriptionPageStyles';
import { NavBar } from '../NavBar/NavBar';
import { Typography, Box, Container, Link, TextField, Button, Card, CardContent, Grid, Paper, useMediaQuery, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { IStandardProps } from '../interfaces/IStandardProps';
import { global } from "../../global";
import {
    MERGE_FILES_PRICE,
    DOCUMENT_PACKAGING_PRICE
} from '../../constants';
import { standardFeatures, premiumFeatures, missingPremiumFeatures } from './assets/features';
import { useAuthContext } from '../Context/AuthContext';
import axios from "axios";
import { Link as ReactLink } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { HomeFooter } from '../Home/components/HomeFooter/HomeFooter';
import { theme } from '../../appTheme';
import Sidebar from '../Sidebar/Sidebar';
import { mainLinks } from '../Sidebar/Navlinks';
import freeTrailIcon from './assets/img/free-trial-7.png';
import { relative } from 'path';

export function Divider() {
    return (
        <hr
            style={{
                // color: '#000',
                backgroundColor: '#E9E9E9',
                height: '1px'
            }}
        />
    )
}

export function SubscriptionPage() {
    const classes = subscriptionPageStyles();
    const mediumBreakpoint = useMediaQuery(theme.breakpoints.up('md'))
    const { token, setToken } = useAuthContext();
    const history = useHistory();
    const [margin, setMargin] = useState("252px");
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

    useEffect(() => {
        setMargin(mediumBreakpoint ? "252px" : "150px");
    }, [mediumBreakpoint])

    useEffect(() => {
        if (token.id != null) {
            history.push('/merge-options');
        }
    }, [])

    return (
        <div className={classes.body}>
            <Helmet>
                <title>Pricing | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
            </Helmet>
            {/* top nav bar */}
            {mediumBreakpoint ?
                <NavBar color="black" active='pricing' />
                :
                <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', padding: 20, width: '100vw' }}>
                        {!token?.email && (
                            <ReactLink to="/">
                                <img className={classes.fullLogoSmall} src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_64,q_auto,f_auto/v1633540731/fullLogoWhite_vxxqyf.png" alt="Max Merge Logo" />
                            </ReactLink>
                        )}
                        {token?.email && (
                            <ReactLink to="/merge-options">
                                <img className={classes.fullLogoSmall} src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_64,q_auto,f_auto/v1633540731/fullLogoWhite_vxxqyf.png" alt="Max Merge Logo" />
                            </ReactLink>
                        )}
                        <IconButton onClick={() => setSidebarOpen(!sidebarOpen)} className="merge-menu-btn menu-btn-large" style={{ marginRight: 0, fontSize: 15 }}>
                            {
                                !sidebarOpen ? <MenuIcon fontSize="small" /> : <CloseIcon />
                            }
                        </IconButton>
                    </div>
                    <Sidebar sidebarOpen={sidebarOpen} links={mainLinks} margin={margin} position="absolute" />
                </div>
            }

            {mediumBreakpoint ? <div>
                {/* header */}
                <div className={classes.header}>
                    <Typography className={classes.title}>
                        Choose Your Plan
                    </Typography>
                    {token.isExpired ? <p style={{ color: 'Red', fontSize: '28px', marginBottom: '2vh' }}>Your free-trial has expired, please subscribe to continue.</p> : null}

                    <p>
                        Get started today with our simple subscription plans.<br />We will donate 10% of your subscription fee, after deduction of payment processor fees, to Food Banks Canada (Registered Charity No.: 13064 3737 RR0001).
                    </p>
                </div>

                {/* subscription cards */}
                <div className={classes.subscriptionOptions}>
                    <Grid container spacing={0} >

                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Card className={classes.premiumSubscriptionCard}>
                                <CardContent>
                                    <div style={{ padding: '0 25px 10px 50px' }}>
                                        <div className={classes.subscriptionHeader}>
                                            <Typography className={classes.subscription}>
                                                Merge Mortgage Files
                                            </Typography>

                                            <img alt="free-trial" src={freeTrailIcon} width="80" height="110" />

                                        </div>
                                        <p style={{ fontSize: '14px' }}>
                                            A merge product curated for busy mortgage professionals.
                                        </p>

                                        <Typography sx={{ fontSize: 32, fontWeight: 700 }} >
                                            ${global.appSettings[MERGE_FILES_PRICE]} CAD<p style={{ fontWeight: 400 }} className={classes.pricing}> + applicable taxes</p>
                                        </Typography>
                                        <p className={classes.pricing}>
                                            Per Month
                                        </p>

                                        {token?.email ? (
                                            <ReactLink to="/checkout">
                                                <Button
                                                    // createCheckoutSession("prod_KbSWv8Od6OL76K")
                                                    className={classes.paymentButton} variant="contained" color="primary" style={{ backgroundColor: "#00939F" }}>
                                                    Get Started
                                                </Button>
                                            </ReactLink>
                                        ) :
                                            (
                                                <ReactLink to="/sign-up">
                                                    <Button
                                                        // createCheckoutSession("prod_KbSWv8Od6OL76K")
                                                        className={classes.paymentButton} variant="contained" color="primary" style={{ backgroundColor: "#00939F" }}>
                                                        Get Started
                                                    </Button>
                                                </ReactLink>
                                            )
                                        }

                                        <div>
                                            <Typography variant="h6" style={{ fontSize: '14px', color: '#929292', marginBottom: '38px' }}>*Credit Card not required for 7-day free trial.</Typography>
                                            <Divider />
                                            <ul className={classes.featureUL}>
                                                {standardFeatures.map((feature, index) => {
                                                    return (
                                                        <li className={classes.featureList} key={index}>{feature}</li>
                                                    )
                                                })}
                                                {missingPremiumFeatures.map((feature, index) => {
                                                    return (
                                                        <li className={classes.missingFeatureList} key={index}>{feature}</li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Card className={classes.premiumSubscriptionCard} >

                                <CardContent>
                                    <div style={{ padding: '0 25px 10px 50px' }}>
                                        <div className={classes.subscriptionHeader}>
                                            <Typography className={classes.subscription}>
                                                Document Packaging
                                            </Typography>
                                            <img alt="free-trial" src={freeTrailIcon} width="80" height="110" />
                                        </div>
                                        <p style={{ fontSize: '14px' }}>
                                            Maximize productivity when preparing documents for submission.
                                        </p>

                                        <Typography sx={{ fontSize: 32, fontWeight: 700, display: "inline" }} >
                                            ${global.appSettings[DOCUMENT_PACKAGING_PRICE]} CAD<p style={{ fontWeight: 400 }} className={classes.pricing}> + applicable taxes</p>
                                        </Typography>

                                        <p className={classes.pricing}>
                                            Per Month
                                        </p>
                                        {token?.email ? (
                                            <ReactLink to="/checkout-premium">
                                                <Button
                                                    // createCheckoutSession("prod_KbSWv8Od6OL76K")
                                                    className={classes.paymentButton} variant="contained" color="primary" style={{ backgroundColor: "#00939F" }}>
                                                    Get Started
                                                </Button>
                                            </ReactLink>
                                        ) :
                                            (
                                                <ReactLink to="/sign-up">
                                                    <Button
                                                        // createCheckoutSession("prod_KbSWv8Od6OL76K")
                                                        className={classes.paymentButton} variant="contained" color="primary" style={{ backgroundColor: "#00939F" }}>
                                                        Get Started
                                                    </Button>
                                                </ReactLink>
                                            )
                                        }

                                        <div>
                                            <Typography variant="h6" style={{ fontSize: '14px', color: '#929292', marginBottom: '38px' }}>*Credit Card not required for 7-day free trial.</Typography>
                                            <Divider />

                                            <ul className={classes.featureUL}>
                                                {premiumFeatures.map((feature, index) => {
                                                    return (
                                                        <li key={index} className={classes.featureList}>{feature}</li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>

                <HomeFooter mediumBreakpoint={true} />
            </div> :
                <div>
                    {/* header */}
                    <div className={classes.smallHeader}>
                        <Typography className={classes.smallTitle}>
                            Choose Your Plan
                        </Typography>
                        <p>
                            Get started today with our simple subscription plans.<br /><br />We will donate 10% of your subscription fee, after deduction of payment processor fees, to Food Banks Canada (Registered Charity No.: 13064 3737 RR0001).
                        </p>
                    </div>

                    {/* plans */}
                    <div className={classes.smallPremium}>



                        <div className={classes.subscriptionHeaderMobile}>
                            <Typography className={classes.smallSubscription}>
                                Merge Mortgage Files
                            </Typography>
                            <div className={classes.subscriptionHeaderImg}>
                                <img alt="free-trial" src={freeTrailIcon} width="80" height="110" />
                            </div>
                        </div>

                        <Typography className={classes.smallDescription} style={{ marginTop: 0, paddingTop: 0 }}>
                            A merge product curated for busy mortgage professionals.
                        </Typography>

                        <Typography className={classes.smallSubscription} style={{ marginBottom: 0, paddingBottom: 0 }}>
                            ${global.appSettings[MERGE_FILES_PRICE]} CAD
                        </Typography>
                        <p className={classes.smallPricing}>
                            Per Month
                        </p>

                        {token?.email ? (
                            <ReactLink to="/checkout">
                                <Button
                                    // createCheckoutSession("prod_KbSWv8Od6OL76K")
                                    className={classes.paymentButton} variant="contained" color="primary" style={{ backgroundColor: "#00939F" }}>
                                    Get Started
                                </Button>
                            </ReactLink>
                        ) :
                            (
                                <ReactLink to="/sign-up">
                                    <Button
                                        // createCheckoutSession("prod_KbSWv8Od6OL76K")
                                        className={classes.paymentButton} variant="contained" color="primary" style={{ backgroundColor: "#00939F" }}>
                                        Get Started
                                    </Button>
                                </ReactLink>
                            )
                        }

                        <div style={{ paddingBottom: '30px' }}>
                            <Typography variant="h6" style={{ fontSize: '14px', color: 'white' }}>*Credit Card not required for 7-day free trial.</Typography>
                            <hr style={{ backgroundColor: '#434343', height: '1px' }} />

                            <ul style={{ paddingLeft: 22 }}>
                                {standardFeatures.map((feature, index) => {
                                    return (
                                        <li key={index} className={classes.featureList} style={{ color: 'white', fontFamily: global.fontFamily }}>{feature}</li>
                                    )
                                })}
                                {missingPremiumFeatures.map((feature, index) => {
                                    return (
                                        <li className={classes.missingFeatureList} key={index}>{feature}</li>
                                    )
                                })}
                            </ul>
                        </div>
                        <Divider />
                        <div className={classes.smallMostPopular}>Most Popular</div>

                        <div className={classes.subscriptionHeaderMobile}>
                            <Typography className={classes.smallSubscription}>
                                Document Packaging
                            </Typography>
                            <div className={classes.subscriptionHeaderImg}>
                                <img alt="free-trial" src={freeTrailIcon} width="80" height="110" />
                            </div>
                        </div>

                        <Typography className={classes.smallDescription} style={{ marginTop: 0, paddingTop: 0 }}>
                            Maximize productivity when preparing documents for submission.
                        </Typography>

                        <Typography className={classes.smallSubscription} style={{ marginBottom: 0, paddingBottom: 0 }}>
                            ${global.appSettings[DOCUMENT_PACKAGING_PRICE]} CAD
                        </Typography>
                        <p className={classes.smallPricing}>
                            Per Month
                        </p>

                        {token?.email ? (
                            <ReactLink to="/checkout-premium">
                                <Button
                                    // createCheckoutSession("prod_KbSWv8Od6OL76K")
                                    className={classes.paymentButton} variant="contained" color="primary" style={{ backgroundColor: "#00939F" }}>
                                    Get Started
                                </Button>
                            </ReactLink>
                        ) :
                            (
                                <ReactLink to="/sign-up">
                                    <Button
                                        // createCheckoutSession("prod_KbSWv8Od6OL76K")
                                        className={classes.paymentButton} variant="contained" color="primary" style={{ backgroundColor: "#00939F" }}>
                                        Get Started
                                    </Button>
                                </ReactLink>
                            )
                        }

                        <div>
                            <Typography variant="h6" style={{ fontSize: '14px', color: '#929292' }}>*Credit Card not required for 7-day free trial.</Typography>
                            <hr style={{ backgroundColor: '#434343', height: '1px' }} />

                            <ul style={{ paddingLeft: 22, marginBottom: 50 }}>
                                {premiumFeatures.map((feature, index) => {
                                    return (
                                        <li key={index} className={classes.featureList} style={{ color: 'white', fontFamily: global.fontFamily }}>{feature}</li>
                                    )
                                })}
                            </ul>
                        </div>
                        <hr style={{ backgroundColor: '#434343', height: '1px', marginTop: 50 }} />
                    </div>





                    <HomeFooter mediumBreakpoint={false} />
                </div>}

        </div>
    )
}