import { IDescription } from "../components/MergeProcess/components/LenderSummarySheet/assets/interfaces/IDescription"
export const updateDescriptions = (descriptions: IDescription[], type: number, value: string) => {
    const newDescriptions = [...descriptions]
    if (value === '') {
        return newDescriptions.filter(description => {
            return description.id !== type
        })
    }
    if (newDescriptions.length === 0 || newDescriptions.filter(description => description.id === type).length === 0) {
        newDescriptions.push({
            id: type,
            value: null,
        })
    }
    console.log('descriptions:', newDescriptions)

    newDescriptions.map(description => {
        if (description.id === type) {
            description.value = value;
        }
        return description;
    })
    return newDescriptions;
}