import React, { useEffect } from 'react';
import { useHistory } from "react-router";
import { Paper, Container, Grid, Hidden } from '@mui/material/';
import { loginUseStyles } from './assets/loginUseStyles';
import { useAuthContext } from "../Context/AuthContext";
import { Helmet } from 'react-helmet';

// Component Imports
import { NavBar } from '../NavBar/NavBar';

// Interface
import { ILogin } from './assets/interfaces/ILogin';
import { LoginRegistrationForm } from './components/LoginRegistrationForm';


export default function Login(props: ILogin) {
  const { registration } = props;
  const { token, setToken } = useAuthContext();
  const classes = loginUseStyles();
  const history = useHistory();

  useEffect(() => {
    if (token.id)
      history.push("/merge-profile");
  }, [token])

  return (
    <>
      <Helmet>
        <title>Log In | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
      </Helmet>
      <NavBar color="white" active='logIn' />
      <Paper square elevation={24} className={classes.paper}>
        <Grid className={classes.grid} container spacing={0} direction='row' justifyContent='center' alignItems='center'>
          <Hidden only={['xs', 'sm', 'md']} >
            <Grid className={classes.pictureContainer} md={5} item>
              <img className={classes.picture} src="https://res.cloudinary.com/maxmergesolutions/image/upload/w_800,h_947,q_auto/v1637690055/iStock-1004109150_large_version_oyz7tc.jpg" alt="Office Setup" />
            </Grid>
          </Hidden>
          <Grid className={classes.pictureContentContainer} container md={7} xs={12} justifyContent='center' item>
            <Grid xs={11} sm={7} md={6} container item className={classes.container}>
              &nbsp;
              <LoginRegistrationForm registration={registration} />
              &nbsp;
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}