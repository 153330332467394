import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Input } from '@mui/material';

interface ICancelSubscriptionOptions {
    selection: string | null;
    setSelection: React.Dispatch<React.SetStateAction<string | null>>;
    other: string | null;
    setOther: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function CancelSubscriptionOptions(props: ICancelSubscriptionOptions) {
    const { selection, setSelection, other, setOther } = props;
    const [disabled, setDisabled] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelection((event.target as HTMLInputElement).value);
    };


    return (
        <FormControl component="fieldset" style={{ marginBottom: 62 }}>
            <RadioGroup
                aria-label=""
                defaultValue=""
                name="radio-buttons-group"
                value={selection}
                onChange={handleChange}
            >
                <FormControlLabel value="Too expensive" control={<Radio />} label="Too expensive" />
                <FormControlLabel value="I don't think the product is useful" control={<Radio />} label="I don't think the product is useful" />
                <FormControlLabel value="It is too complicated to use" control={<Radio />} label="It is too complicated to use" />
                <FormControlLabel value="It doesn't work properly" control={<Radio />} label="It doesn't work properly" />
                <FormControlLabel value="I only needed it for a short period" control={<Radio />} label="I only needed it for a short period" />
                <FormControlLabel value="I don't use it enough" control={<Radio />} label="I don't use it enough" />
                <FormControlLabel value="other" onClick={() => setDisabled(true)} control={<Radio />} label="Other, please specify:" />
            </RadioGroup>
            <Input onChange={handleChange} disabled={!disabled} style={{ width: '50%', marginLeft: 40 }} inputProps={{ style: { fontSize: 18 } }} />
        </FormControl>
    );
}