const getFileName = (fileNameArray) => {
  // what if no extension

  if (fileNameArray.length < 2) {
    return null;
  }

  return fileNameArray.slice(0, -1).join("");
};

const getExtension = (fileNameArray) => {
  if (fileNameArray.length < 2) {
    return null;
  }

  return fileNameArray.at(-1);
};

export { getFileName, getExtension };
