import * as React from 'react';
import { condenseDocumentsStyles } from '../assets/CondenseDocumentsStyles';
import { ICompressionBox } from '../assets/interfaces/ICompressionBox';

import { Box, Typography, Button } from '@mui/material';

export default function CompressionBox(props: ICompressionBox) {
  const { heading, description, quality, setScreenType } = props;
  const classes = condenseDocumentsStyles();

  return (
    <Button className={classes.thumbnail} disableRipple color='secondary' value={quality || 0} onClick={() => setScreenType(quality!)}>
      <Typography style={{ fontSize: '16px', fontWeight: 600, marginBottom: '1vh' }}>
        {heading}
      </Typography>
      <p style={{ fontSize: '16px' }}>{description}</p>
    </Button>
  )
}