import React from 'react'
import styled from "styled-components";
import { Container} from "@mui/material";
import Form from "./Form"

const HeroMobile = () => {
    return (
        <HeroMobileWrapper>
            <div className="hero">
                <Container>
                    <div className="hero-info">
                        <h1>Architecture for<br/>Mortgage Professionals</h1>
                       <p>
                            Be one of the first mortgage professionals to get exclusive access and the latest news on our product directly in your inbox.
                        </p>
                    </div>
                </Container>
                </div>
                <div className="divider"/>
                <div className="form-container">
                    <Container>
                        <h2>
                            First 30 mortgage
                            <br/>
                            professionals get
                        </h2>
                        <h1>
                            30% OFF
                        </h1>
                        <Form maxwidth={true}/>
                    </Container>
                </div>
        </HeroMobileWrapper>
    )
}

const HeroMobileWrapper = styled.div`

    .form-container {
        background: #1B1E1E;
        padding: 25px 25px 30px;
        color: white;
        h1, h2 {
            text-align: center;
        }

        h2 {
            font-weight: 500;
            font-size: 28px;
            margin-bottom: 20px;
        }

        h1  {
            font-size: 45px;
            letter-spacing: 2px;
            word-spacing: 7x;
            margin-bottom: 40px;
        }
    }

    .hero {
        display: grid;
        place-items: center;

        padding: 20px;

        @media screen and (min-width: 450px) {
            padding: 50px;
        }

         background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
         url("https://res.cloudinary.com/maxmergesolutions/image/upload/v1635438272/LandingPageAssets/hero_nn2bwt.png") center/cover no-repeat;
    }

    .hero-info {
        background: rgba(0,0,0,0.6);
        padding: 30px 15px;
        h1, p {
            margin: 0;
            padding: 0;
            color: white;
            text-align: center;
        }
        h1 {
            font-size: 38px;
            margin-bottom: 40px;
            line-height: 1.4;
            letter-spacing: 3px;
        }
        p {
            font-size: 18px;
            word-spacing: 1.5px;
            line-height: 1.5;
        }
    }
    .divider {
        width: 100%;
        background: #00939F;
        height: 40px;
    }
`

export default HeroMobile
