import { IQuote } from './interfaces/IQuote';
import { IDocument } from '../components/MergeProcess/assets/interfaces/IDocument';

export const sortQuotes = (quotes: IQuote[], category: number) => {
    return quotes.filter((quote: IQuote) => {
        return quote.category === category;
    })
}

export const filterDocumentByType = (documents: IDocument[], id: number) => {
    return documents.filter(doc => doc.type === id)
}



export const getIndividualItemsFromDocuments = (documents: IDocument[]) => {
    const documentTypes = [
        { id: 1, name: 'Income', documents: filterDocumentByType(documents, 1) },
        { id: 2, name: 'Subject Property', documents: filterDocumentByType(documents, 2) },
        { id: 3, name: 'Non-Subject Property', documents: filterDocumentByType(documents, 3) },
        { id: 4, name: 'Net Worth', documents: filterDocumentByType(documents, 4) },
        { id: 5, name: 'Debt Payout', documents: filterDocumentByType(documents, 5) },
        { id: 6, name: 'Miscellaneous', documents: filterDocumentByType(documents, 6) },
        { id: 7, name: 'Down Payment', documents: filterDocumentByType(documents, 7) },
        { id: 8, name: 'Compliance', documents: filterDocumentByType(documents, 8) },
        { id: 9, name: 'Supporting', documents: filterDocumentByType(documents, 9) },

    ]
    const individualItemsFromDocuments = [];
    for (const typeObj of documentTypes) {
        if (typeObj.documents.length > 0) {
            individualItemsFromDocuments.push({ name: typeObj.name, id: typeObj.id })
        }
    }
    return individualItemsFromDocuments;
}
