import React, { useEffect, useState } from 'react';
import { MergeNav } from '../../MergeProcess/components/MergeNav';
import { condenseDocumentsStyles } from '../assets/CondenseDocumentsStyles';
import { IStandardProps } from '../../interfaces/IStandardProps';
import CompressionBox from './CompressionBox';
import { getFileName, getExtension } from '../../../helpers/fileName';

// mui imports
import { Alert, CircularProgress } from '@mui/material';
import { Container, Typography, Paper, Button } from '@mui/material';
import { CloudUploadOutlined } from '@mui/icons-material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import DownloadCondensed from './DownloadCondensed';
import { useAuthContext } from '../../Context/AuthContext';
import { Helmet } from 'react-helmet';


export default function CondenseDocuments(props: IStandardProps) {
  const temp = require('../assets/compressionOptions');
  const { token, setToken } = useAuthContext();
  const classes = condenseDocumentsStyles();

  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const [screenType, setScreenType] = React.useState(1);
  const [error, setError] = React.useState<string>("");
  const [compressedSize, setCompressedSize] = React.useState(0);
  const [blobLink, setBlobLink] = React.useState("");
  const [dragging, setDragging] = React.useState<number>(0);
  const [options, setOptions] = React.useState(temp.compressionOptions.pdf);
  const [loading, setLoading] = React.useState(false);

  console.log(temp.compressionOptions.pdf);


  const handleDelete = (event: any) => {
    setSelectedFile(null);
  }

  const fileUpload = async (e: any) => {
    handleFile(e.target.files[0]);
  }

  const dragEnter = (e: any) => {
    e.preventDefault();
    setDragging((prev) => prev + 1);
  }

  const dragOver = (e: any) => {
    e.preventDefault();
  }

  const dragLeave = (e: any) => {
    e.preventDefault();
    setDragging((prev) => prev - 1);
  }

  const fileDrop = (e: any) => {
    e.preventDefault();
    setDragging(0);
    handleFile(e.dataTransfer.files[0]);
  }

  //basic validation, could be coded better but requires refractoring this page
  const handleFile = (file: File) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

    if (validTypes.indexOf(file.type) === -1) {
      setError("The file you uploaded is not a PDF or an image.");
      setSelectedFile(null);
      return;
    }

    setSelectedFile(file);

    if (selectedFile !== null && selectedFile?.type === 'application/pdf') {
      setOptions(temp.compressionOptions.pdf)

    } else if (selectedFile !== null && selectedFile?.type === 'image/png') {
      // in this case, quality is hard coded at 100, but these numbers represent ratio
      setOptions(temp.compressionOptions.png)

    } else if (selectedFile !== null && (selectedFile?.type === 'image/jpeg' || selectedFile.type === 'image/jpg')) {
      setOptions(temp.compressionOptions.jpeg)
    }

    setError("");
  }

  const uploadDocuments = async (selected: any) => {

    setLoading(true);

    const file = selected;
    const fileName = selected?.name

    if (file !== null) {
      let url = `/api/documents/compress`

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        let base64File;
        if (reader.result !== null && typeof reader.result === 'string') base64File = reader?.result.split(',')[1]

        // handling for different file versions 
        if (file.type === 'application/pdf') {
          let body = {
            fileName: getFileName(fileName.split('.')),
            screenType: screenType,
            base64: base64File,
            grayScale: true,
            compressFonts: true
          }
          await axios
            .post(url, body)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                setBlobLink(res.data.url);
                setCompressedSize(res.data.fileSize);
              }
            })
            .catch((e) => {
              setError("Failed to compress.");
            })
        } else if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
          url = '/api/documents/images'

          let body = {
            extension: getExtension(fileName.split('.')),
            applicationType: 'image/jpeg',
            fileName: getFileName(fileName.split('.')),
            type: screenType,
            //ratio: screenType,
            base64: base64File,
          }

          await axios
            .post(url, body)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                setBlobLink(res.data.url);
                setCompressedSize(res.data.fileSize);
              }
            })
            .catch((e) => {
              setError("Failed to compress.");
            })
        } else if (file.type === 'image/png') {
          url = '/api/documents/images'

          let body = {
            extension: getExtension(fileName.split('.')),
            applicationType: 'image/png',
            fileName: getFileName(fileName.split('.')),
            type: screenType,
            //ratio: screenType,
            base64: base64File
          }

          await axios
            .post(url, body)
            .then((res) => {
              setLoading(false);
              if (res.status === 200) {
                setBlobLink(res.data.url);
                setCompressedSize(res.data.fileSize);
              }
            })
            .catch((e) => {
              setError("Failed to compress.");
            })
        }
      };
    }
  }

  // rendering file information on screen after upload
  const file = (
    <ul className={classes.fileList}>
      <li style={{ flex: 1 }}>
        <FileCopyOutlinedIcon fontSize="small" style={{ marginRight: '0.2vw', padding: 0 }} /> {selectedFile?.name}
              <span style={{ fontSize: '14px', color: '#CDD2D4', fontWeight: 400, marginLeft: '0.3vw' }}>{(parseInt(selectedFile?.size) / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 })}KB</span>
        <span className={classes.fileListItem}><Button style={{ padding: 0, color: "#000", width: '5px' }} onClick={handleDelete}><CloseIcon fontSize="small" /></Button></span>
      </li>
    </ul>
  );


  return (
    <div style={{ marginBottom: '5vh' }}>
      <Helmet>
        <title>Compress Documents | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
      </Helmet>
      <MergeNav active="condense" />
      {!blobLink ?
        (
          <div style={{ marginTop: 0 }}>
            <div className={classes.condenseDocumentsHeader} >
              <Container style={{
                padding: 0
              }}>
                <Typography variant="h5" className={classes.headerText
                } > Condense Documents</Typography>
              </Container>
            </div>

            {/* upload documents box */}
            <div className={classes.body}>
              <Paper className={classes.condenseBody}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDragOver={dragOver}
                onDrop={fileDrop}>

                <div className={dragging === 0 ? classes.uploadArea : [classes.uploadArea, classes.dragOver].join(" ")}
                >
                  <CloudUploadOutlined style={{ paddingRight: '3px', color: '#252323' }} /> Drag and drop your file here.
                  <div className={classes.buttonDiv}>
                    <form method="POST" action="#">
                      <label htmlFor="actual-btn" className={classes.upload}><FolderOpenIcon style={{ paddingRight: '3px' }} /> Choose File</label>
                      <input type="file" name="file" id="actual-btn" accept="application/pdf, image/jpeg, image/png" hidden multiple onChange={(e) => fileUpload(e)} />
                    </form>
                  </div>
                  {
                    error &&
                    <Alert severity="error">{error}</Alert>
                  }
                </div>
              </Paper>


              {/* list of file(s) */}
              {selectedFile && (

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  {file}



                  <div className={classes.compressionOptions}>
                    {options && options?.map((option: any, index: number) => {
                      return <CompressionBox key={index} heading={option.type} description={option.description} quality={option.quality} setScreenType={setScreenType} />
                    })}
                  </div>
                  <div className={classes.compressButtonDiv}>
                    <Button className={classes.upload} onClick={() => uploadDocuments(selectedFile)}>Condense</Button>
                  </div>
                </div>
              )}

              {loading && <CircularProgress />}
            </div >
          </div >

        ) : !loading && <DownloadCondensed token={token} setToken={setToken} name={selectedFile?.name} compressedSize={compressedSize} originalSize={(parseInt(selectedFile?.size))} blob={blobLink} />
      }
    </div >
  )
}