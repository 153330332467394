import { createStyles, makeStyles } from "@mui/styles";
export const pricingUseStyles = makeStyles(() =>
    createStyles({
        root: {
            marginTop: 20,
            marginBottom: 140
        },
        heading: {
            fontSize: 48,
            fontWeight: 700,
            marginBottom: 10
        },
        image: {
            height: 32,
            width: 230,
            margin: '25px 0 0 25px'
        },
        asterisk: {
            marginBottom: 35
        },
        mobile: {
            marginBottom: 50
        },
        none: {

        }
    })
);