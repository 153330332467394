import { createStyles, makeStyles } from "@mui/styles";
export const accountUseStyles = makeStyles(() =>
    createStyles({
        button: {
            padding: 0,
            fontWeight:'bold',
            "&:hover": {
                backgroundColor: "inherit",
            },
        },
        listItem: {
            marginBottom: '8px',
            listStyleType: 'none',
            fontSize: 13,
            fontWeight: 700,
            '&::before': {
                position: 'relative',
                content: '"✓"',
                right: '20px',
                fontWeight: '800',
            }
        }

    })
);