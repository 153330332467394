import { makeStyles, createStyles } from "@mui/styles";

export const aboutUsStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: '#1B1E1E',
            backgroundImage: `url("https://res.cloudinary.com/dp94c6f82/image/upload/v1667952924/avasd_moglkn.jpg")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '140vh',
       
        },
        container: {
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', textAlign: 'left', 
            width: '900px',

            
            backgroundColor: '#FFF',
            padding: '48px',
            paddingTop: '72px',
            paddingBottom: '72px',
            // marginTop: '80px',
            // marginBottom: '80px'
        },
        containerInner: {
            // maxWidth: '1024px',
            margin: 'auto'
        },
        smallContainer: {
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', textAlign: 'left', marginTop: '30px',
        },
        body: {
            paddingTop: '84px',
            paddingBottom: '84px',
            // height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            //backgroundImage: `url("https://res.cloudinary.com/dp94c6f82/image/upload/c_crop,w_2600,h_1300/v1661817578/iStock-697225474_sjmat5.jpg")`,
            //backgroundPosition: 'center',
            //backgroundSize: 'cover',
            //backgroundRepeat: 'no-repeat'
        },
        smallBody: {
            marginTop: '30px',
            // height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#1B1E1E',
        },
        title: {
            fontSize: '35px',
            fontWeight: 700,
            color: '#1B1E1E'
        },
        smallTitle: {
            fontSize: '20px',
            fontWeight: 700,
            color: '#1B1E1E'
        },
        about: {
            fontSize: 18,
            color: '#1B1E1E',
            marginTop: '1vh',
            // marginBottom: '20vh'
            lineHeight: 1.8
        },
        aboutSections: {
            marginBottom: '72px'
        },
        paragraph: {
            marginBottom: 20,
            fontSize: 16,
            lineHeight: 1.8,
            fontWeight: 300

        },
        signature: {
            marginBottom: 5,
            fontFamily: 'Allison, cursive',
            fontSize: '3rem',
        },
        fullLogoSmall: {
            width: 200,
            height: 'auto',
        },
    })
);