import React from 'react';
import { Paper, Container, Grid, Hidden, Typography } from '@mui/material/';

// Component Imports
import { NavBar } from '../NavBar/NavBar';

// Interface
import { ILogin } from '../Login/assets/interfaces/ILogin';
import { CheckoutForm } from './CheckoutForm';
import { IStandardProps } from '../interfaces/IStandardProps';
import { checkoutUseStyles } from './assets/checkoutUseStyles';
import { ThemeProvider } from '@mui/private-theming';
import { checkoutTheme } from './assets/checkoutTheme';
import { global } from '../../global';
import { standardFeatures, premiumFeatures, missingPremiumFeatures } from '../SubscriptionPage/assets/features';


interface ICheckout {
    userType: number;
    price: number | null;
    priceId: string | null;
    edit: boolean
}

export function Checkout(props: ICheckout) {
    const { userType, priceId, price, edit } = props;
    const classes = checkoutUseStyles();
    const features = (userType === 2 ? premiumFeatures : standardFeatures).map((feature, index) => <li key={index} className={classes.featureList}>{feature}</li>)
    const MissingFeatures = missingPremiumFeatures.map((feature, index) => <li key={index} className={classes.missingFeatureList}>{feature}</li>)
    return (
        <>
            {/* <NavBar color="white" /> */}
            <Paper square elevation={0} className={classes.paper}>
                <Grid className={classes.grid} container spacing={0} direction='row' justifyContent='center' alignItems='flex-start'>
                    {/* <Hidden only={['xs', 'sm', 'md']} > */}
                    <Grid container className={classes.pictureContainer} direction="column" justifyContent="flex-start" md={5} item>
                        <Container className={classes.container}>
                            {!edit && (
                                <>
                                    {/* <img className={classes.picture} src="https://res.cloudinary.com/maxmergesolutions/image/upload/w_800,h_947,q_auto,f_auto/v1633539000/laptop.png" alt="Office Setup" /> */}
                                    <Typography className={classes.heading}>{userType === 2 ? 'Document Packaging' : 'Merge Mortgage Files'}</Typography>
                                    <Typography className={classes.price}>{`$${price} CAD per month plus applicable taxes.`}</Typography>
                                    <Grid container direction="column">
                                        <ul>{features}</ul>
                                        {userType == 1 && <ul>{MissingFeatures}</ul>}
                                    </Grid>
                                </>
                            )}
                            {edit && (
                                <Typography className={classes.price}>Edit Payment Information</Typography>
                            )}
                        </Container>
                    </Grid>
                    {/* </Hidden> */}
                    <Grid style={{ marginTop: 40 }} container md={7} xs={12} direction='column' item>
                        <Container className={classes.container}>
                            <ThemeProvider theme={checkoutTheme}>
                                <CheckoutForm edit={edit} userType={userType} price={price} priceId={priceId} />
                            </ThemeProvider>
                        </Container>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}