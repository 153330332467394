import * as React from 'react';
import { useState } from 'react';
import { Typography, Button, Grid, Box, TextField, Alert } from '@mui/material/';
import { MergeNav } from '../MergeNav';
import { mergePDFUseStyles } from './assets/mergePDFUseStyles';
import { global } from '../../../../global';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

// interfaces
import { IFormState } from '../../assets/interfaces/IFormState';
import { useAuthContext } from '../../../Context/AuthContext';
import { Helmet } from 'react-helmet';
import { IMergeTaskInfo } from '../../assets/interfaces/IMergeTaskInfo';
import { IDetails } from '../../../YTDTracker/components/YearToDateOptions';
import { IPaymentState, IPayout } from '../MergeDocs/assets/interfaces/IPaymentState';

interface IMergePDF {
    location: {
        state: {
            mergeTaskInfo: IMergeTaskInfo;
            details: IDetails;
            payments: IPaymentState[];
            payouts: IPayout[];
            documentPackaging: any;
            varIsAfterSave: boolean;
        }
    }
}

export function MergePDF(props: IMergePDF) {
    const classes = mergePDFUseStyles();
    const history = useHistory();
    const { token, setToken } = useAuthContext();
    const { location: { state: { mergeTaskInfo, payments, payouts, documentPackaging } } } = props;
    const [error, setError] = useState<string | null>(null);
    const varIsAfterSave = props.location.state?.varIsAfterSave;
    const [formState, setFormState] = useState<IFormState>(
        props.location.state?.details ? props.location.state.details
            : {
                underWriterName: null,
                propertyAddress: null,
                email: null,
                notes: null,
                subject: null,
            })

    const onFormSubmit = (e: any) => {
        e.preventDefault();
        validateForm();
    }
    console.log(varIsAfterSave);

    const validateForm = () => {
        !formState.underWriterName || formState.underWriterName === '' ? setError('Please enter an underwriter first name.')
            : !formState.propertyAddress ? setError('Please enter a subject property address.')
                : !formState.email ? setError('Please enter an email address for the underwriter.')
                    : history.push({ pathname: '/add-ytd', state: { mergeTaskInfo, details: { ...formState }, payments, payouts, fromUnderwriter: true } })
    }

    return (
        <Box style={{ height: "100vh", width: "100vw" }}>

            <Helmet>
                <title>Underwriter Information | Merge Documents | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
            </Helmet>

            <Box>
                <MergeNav active='none' />
            </Box>

            <Box className={classes.root}>
                {/* How would you like to use Max Merge Solutions? */}
                <Typography variant="h5" className={classes.mergeHeading} style={{ marginBottom: 0 }}>Lender Summary Sheet Details</Typography>
                <Box style={{ marginTop: "88px", display: "flex", flexDirection: "column" }}>
                    <form noValidate onSubmit={onFormSubmit} autoComplete="off" className={error ? classes.errorForm : classes.form} spellCheck={false} autoCapitalize="off" autoCorrect="off">
                        <TextField
                            className={classes.input}
                            label="Subject Line"
                            id="standard-basic"
                            variant="standard"
                            value={formState.subject}
                            placeholder='Client name and lender ref no.'
                            onChange={(event) => setFormState((prev) => ({ ...prev, subject: event.target.value }))}
                        />
                        <TextField
                            className={classes.input}
                            label="Underwriter's First Name"
                            id="standard-basic"
                            variant="standard"
                            value={formState.underWriterName}

                            placeholder="Underwriter's First Name"
                            onChange={(event) => setFormState((prev) => ({ ...prev, underWriterName: event.target.value }))}
                        />

                        <TextField
                            className={classes.input}
                            label="Subject Property Address"
                            id="standard-basic"
                            variant="standard"
                            placeholder='1234 Broadway St.'
                            value={formState.propertyAddress}

                            onChange={(event) => setFormState((prev) => ({ ...prev, propertyAddress: event.target.value }))}
                        />

                        <TextField
                            className={classes.input}
                            label="Underwriter's Email"
                            id="standard-basic"
                            variant="standard"
                            value={formState.email}

                            placeholder="example@gmail.com"
                            onChange={(event) => setFormState((prev) => ({ ...prev, email: event.target.value }))}
                        />
                        {error && <Alert style={{ width: 500 }} severity="warning">{error}</Alert>}


                        {/* should do a POST to MergeTasks */}
                        <Grid container justifyContent="center" className={classes.buttons}>
                            <Link to={{ pathname: !token.isExpired ? '/merge-docs' : documentPackaging ? 'merge-docs' : '/merge-options', state: { documentPackaging: varIsAfterSave ? documentPackaging : false, mergeTaskInfo, payments, payouts, rePromptSelection: true, varIsAfterSave: false } }}><Button variant="outlined" className={[classes.buttonCustom, classes.darkButton].join(' ')}>Back</Button></Link>
                            {/* <Link to={{ pathname: '/merge-pdf', state: { mergeTaskInfo, details: { ...formState }, payments, payouts } }}><Button variant="contained" className={classes.buttonCustom} style={{ background: global.primary }}>Start Merging</Button></Link> */}


                            <Button type="submit" variant="contained" className={classes.buttonCustom} style={{ background: global.primary }}>Continue</Button>

                        </Grid>
                    </form>
                </Box>
            </Box>



        </Box>
    )
}

