import React from 'react'
import { AppBar, Container } from '@mui/material';
import styled from 'styled-components';
import { NavMenu } from './NavMenu';
// import "../assets/styles/menu.css"
import { Link } from "react-router-dom";

const Nav = () => {
    require("../assets/styles/menu.css");
    return (
        <NavWrapper>
            <img src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_150/LandingPageAssets/corner_pwgkdo.png" alt="corner" className="corner" />
            <AppBar position="static">
                <Container className="nav-container container">
                        <Link to="/">
                            <img src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_30/LandingPageAssets/logo_mkuwiu.png" alt="logo" className="logo"/>
                        </Link>
                        <NavMenu/>
                </Container>
            </AppBar>
        </NavWrapper>
    )
}

const NavWrapper = styled.div`
    .logo {
        height: 30px;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .MuiAppBar-root {
        background-color: #1B1E1E;
        height: 90px;
        display: flex;
        justify-content: center;
        box-shadow: none;
    }

    .corner {
        height: 100px;
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        @media screen and (min-width: 1100px) {
            display: block;
        }

        @media screen and (min-width: 1420px) {
            height: 150px;
        }
    }
`

export default Nav;
