import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { Paper, Box, Grid, Hidden, Typography, Button, TextField, InputLabel, Alert } from '@mui/material/';
import { useAuthContext } from '../../Context/AuthContext';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { loginUseStyles } from '../assets/loginUseStyles';
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";

// Component Imports
import { NavBar } from '../../NavBar/NavBar';
import { global } from '../../../global';
import ResetPassword from './ResetPassword';
import ConfirmPasswordLink from './ConfirmPasswordLink';

// Interface
export default function ForgotPassword() {
    const history = useHistory();
    const classes = loginUseStyles();
    const [email, setEmail] = useState("");
    const [recapcha, setRecapcha] = useState("");

    const [emailVal, setEmailVal] = useState("");
    const [succ, setSucc] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('')

    const validateEmail = () => {
        let tempErr = false;
        if (!email) {

            tempErr = true;
        } else if (!validator.isEmail(email)) {
            tempErr = true;
        }

        return tempErr
    }
    const recaptchaRef = React.createRef();
    function onRecapchaChange(value:any) {
        console.log(value);
        setRecapcha(value);
    }
    const resetPassword = () => {
        setLoading(true);
        setLoadingMsg('Sending link...');
        const url = global.rootUrl + "api/auth/forgot-password"
        if (validateEmail()) {
            setLoading(false);
            setEmailVal("Recaptcha validation failed or you entered it incorrectly.");
            return;
        }

        axios.post(url, {
            email,
            RecapchaVal: recapcha
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setLoadingMsg('');
                setEmailVal('');
                setSucc(true);
            }
        }).catch(e => {
            setLoading(false);
            console.log(e);
            setEmailVal("Recaptcha validation failed or a user or you entered it incorrectly.");
        })
    }

    return (
        <>
            {!succ && (
                <>
                    <Helmet>
                        <title>Forgot Password | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
                    </Helmet>
                    <NavBar color="white" active="forgotPassword" />
                    <Paper square elevation={24} className={classes.paper}>
                        <Grid className={classes.grid} container spacing={0} direction='row' justifyContent='center' alignItems='center'>
                            <Hidden only={['xs', 'sm', 'md']} >
                                <Grid className={classes.pictureContainer} md={5} item>
                                    <img className={classes.picture} src="https://res.cloudinary.com/maxmergesolutions/image/upload/w_800,h_947,q_auto/v1637690055/iStock-1004109150_large_version_oyz7tc.jpg" alt="Office Setup" />
                                </Grid>
                            </Hidden>
                            <Grid container md={7} xs={12} justifyContent='center' item>
                                <Box style={{ width: '60%', fontFamily: global.fontFamily, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignItems: 'flex-start', height: '50vh', color: global.black }}>
                                    <div>
                                        <Typography className={classes.titleLogin} style={{ fontSize: 24 }} variant='h4'>Forgot your password?</Typography>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start' }}>

                                            No problem! Enter your email, and we will send you a link to reset your password.
                                        </div>
                                        <form noValidate autoComplete="on" spellCheck={false} autoCapitalize="off" autoCorrect="off"  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} onSubmit={resetPassword}>
                                            <InputLabel style={{ fontWeight: 600, color: global.black, textAlign: 'left', marginTop: 30, paddingBottom: 0 }} id="fullName">Email Address</InputLabel>
                                            <TextField
                                                // className={classes.input}
                                                style={{ width: '100%', marginBottom: '30px' }}
                                                required={true}
                                                id="standard-basic"
                                                variant="standard"
                                                placeholder=""
                                                onChange={e => setEmail(e.target.value)}
                                                value={email}
                                                InputLabelProps={{ style: { color: global.black } }}
                                                InputProps={{ className: classes.textField }}
                                            />
                                            {emailVal && !loading && <Alert variant="outlined" severity="error" style={{ color: '#ef5350', border: '0', padding: 0 }}>{emailVal}</Alert>}
                                        </form>

                                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 30, flexDirection: 'column', alignItems: 'flex-start' }}>
                                            {loading && <Alert variant="standard" severity="success">{loadingMsg}</Alert>}
                                            <ReCAPTCHA
                                                sitekey="6LdXpN0fAAAAADXnPzFfIJwVp4ii7eUNbxXL5Z9g"
                                                onChange={onRecapchaChange}
                                            />
                                            <Button type="submit" style={{ backgroundColor: global.black, color: '#fff', width: 200, height: 54, marginTop: 15 }} onClick={resetPassword}>
                                                Send Link
                                            </Button>

                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </>

            )}

            {succ && (
                <ConfirmPasswordLink />
            )}
        </>
    );
}