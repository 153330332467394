import React, { useState } from 'react';
import validator from 'validator';
import { Helmet } from 'react-helmet';
import axios from "axios";
// styling
import { comingSoonUseStyles } from '../assets/comingSoonUseStyles';

// components
import { NavBar } from '../../NavBar/NavBar';
import { Typography, Button, Dialog, DialogContent, DialogTitle, InputLabel, TextField } from '@mui/material';
import { HomeFooter } from '../../Home/components/HomeFooter/HomeFooter';

const cleanInfoState = ({
  name: "",
  email: ""
})

export default function ComingSoon() {
  const classes = comingSoonUseStyles();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState(cleanInfoState);
  const [notice, setNotice] = useState('');
  const [error, setError] = useState(false);


  const handleSubmit = () => {
    setLoading(true);
    setError(false);
    setNotice("");
    if(contactInfo.email && validator.isEmail(contactInfo.email)) {
      axios.post("/api/newsletter", contactInfo).then((resp)=> {
        if(resp.data.success) {
          setContactInfo(cleanInfoState);
          setLoading(false);
          setNotice("Success! We'll keep you updated.");
        } else {
          setError(true);
          setNotice("Oops, something went wrong, try again later.");
          setLoading(false);
        }
      }).catch(()=> {
        setError(true);
        setNotice("Oops, something went wrong, try again later.");
        setLoading(false);
      })
    } else {
      setError(true);
      setNotice("A valid email is required");
      setLoading(false);
    }
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Coming Soon | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
      </Helmet>

      <NavBar active="" color="black" />

      <div className={classes.body}>


        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          <Typography className={classes.title}>
            Coming soon!
          </Typography>
          <p>
            We're currently working on something exciting.<br /> Come back soon or subscribe to our newsletters.
          </p>

          <div>
            <Button variant="contained" disableElevation disableRipple className={classes.contact} onClick={() => setModal(true)}>Sign up for newsletters</Button>
          </div>
        </div>

        {/* modal stuff */}
        <Dialog
          fullScreen
          open={modal}
          onClose={() => setModal(false)}
          style={{
            margin: 'auto',
            width: '750px',
            height: '430px',
            borderRadius: 0
          }}
        >
          <DialogTitle style={{ padding: 0, height: '76px', margin: 0 }}>
            <div className={classes.modalTopBar}>
              <Typography style={{ fontSize: '18px', fontWeight: 600, left: 0, padding: '0 0 0 0', marginLeft: '2vw', paddingTop: '2.5vh' }}>
                Sign up for our newsletters
                <div style={{ display: 'inline' }}>
                  <Button disableRipple onClick={() => setModal(false)} style={{ color: '#fff', padding: 0, float: 'right' }}>
                    —
                  </Button>
                </div>
              </Typography>
            </div>

          </DialogTitle>

          <DialogContent style={{ marginTop: '4vh', padding: 0, marginLeft: '1.2vw' }}>

            <div style={{ marginLeft: '1vw', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start' }}>
              {/* main form */}
              <div style={{ width: '50%' }}>
                <form noValidate autoComplete="off" className={classes.form}  spellCheck={false} autoCapitalize="off" autoCorrect="off">
                  {/* full name */}
                  <InputLabel style={{ fontWeight: 600, color: '#000' }} id="name">Full Name</InputLabel>
                  <TextField
                    // className={classes.input}
                    style={{ width: '100%', marginBottom: '30px' }}
                    required={true}
                    id="standard-basic"
                    variant="standard"
                    placeholder="Name"
                    onChange={e => setContactInfo({ ...contactInfo, name: e.target.value })}
                    value={contactInfo.name}
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ className: classes.textField }}
                  />

                  <InputLabel style={{ fontWeight: 600, color: '#000' }} id="email">Email</InputLabel>
                  <TextField
                    // className={classes.input}
                    style={{ width: '100%', marginBottom: '30px' }}
                    required={true}
                    id="standard-basic"
                    variant="standard"
                    placeholder="johndoe@email.com"
                    onChange={e => setContactInfo({ ...contactInfo, email: e.target.value })}
                    value={contactInfo.email}
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ className: classes.textField }}
                  />
                  <div className={error ? classes.invalid : classes.valid}>{notice}</div>
                </form>
              </div>
              <Button disabled={loading} variant="contained" disableElevation disableRipple className={classes.contact} onClick={handleSubmit} style={{ width: '150px' }}>Sign up</Button>
            </div>

          </DialogContent>
        </Dialog>
      </div>
      <HomeFooter mediumBreakpoint />
    </div>
  )
}