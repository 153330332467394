import { createTheme } from "@mui/material";
import montserrat from "typeface-montserrat";
import { global } from "../../../global";

export const checkoutTheme = createTheme({
  palette: {
    primary: {
      main: global.primary,
    },
    secondary: {
      main: global.neutral,
    },
    lightNeutral: {
      main: global.neutral,
    },
    black: {
      main: global.black,
    },
  },
  typography: {
    fontFamily: global.fontFamily,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [montserrat],
      },
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        muiinputprops: {
          fontSize: "17px",
        },
        variant: "outlined",
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "17px",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: true,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&:hover": {},
          fontFamily: global.fontFamily,
          fontSize: "18px",
          borderRadius: "0",
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          fontSize: "17px",
          color: "#1b1e1e",
          borderBottom: "1.5px solid #6a6a6a",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
        focused: false,
      },
      styleOverrides: {
        root: {
          //   fontSize: "17px",
          color: "#1b1e1e",
          fontWeight: 500,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: global.fontFamily,
        },
      },
    },
  },
});
