// @flow
import * as React from "react";
import { useState, useEffect } from "react";
import {
    Grid,
    Container,
    Typography,
    TextField,
    useMediaQuery,
    useTheme,
    Button,
    Box,
    Alert,
} from "@mui/material";
import {
    ArrowDropDown,
    ArrowDropUp,
    Check,
    KeyboardArrowDown,
} from "@mui/icons-material";
import { IStandardProps } from "../../../interfaces/IStandardProps";
import { MergeNav } from "../MergeNav";
import { IFormState } from "../../assets/interfaces/IFormState";
import { LenderSummarySheetForm } from "./components/LenderSummarySheetForm";
import { documentData } from "../DownloadPDFOverview/assets/testData";
import { IDocumentData } from "./assets/interfaces/IDocumentData";
import { getIndividualItemsFromDocuments } from "../../../../helpers/filters-and-sorters";
import { lenderSummarySheetUseStyles } from "./assets/lenderSummarySheetUseStyles";
import { useAuthContext } from "../../../Context/AuthContext";
import { Helmet } from "react-helmet";
import { DocumentAccordionList } from "../../../MergeProcess/components/DownloadPDFOverview/components/DocumentAccordionList";
import { IDocument } from "../../assets/interfaces/IDocument";
import { IDescription } from "./assets/interfaces/IDescription";
import axios from "axios";
import { useHistory } from "react-router";
import { groupBy } from 'lodash';

import SizeDialog from "./components/SizeDialog";
import {
    getBalance,
    getPaymentValue,
} from "../../../../helpers/updatePayments";
import * as ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";

export function LenderSummarySheet(props: IDocumentData) {
    const history = useHistory();
    const {
        location: {
            state: {
                currentYearStartDate,
                probationBool,
                mergeTaskInfo,
                details,
                setDetails,
                results,
                payments,
                payouts,
            },
        },
    } = props;
    const [documents, setDocuments] = useState<any[]>([]);
    const { token, setToken } = useAuthContext();
    const theme = useTheme();
    const classes = lenderSummarySheetUseStyles();
    const [dropdown, setDropdown] = useState(false);
    const [errorFile, setErrorFile] = useState<string>("");
    const [sizeDialog, setSizeDialog] = useState(false);
    const [sizeSelected, setSizeSelected] = useState(false);
    const [formState, setFormState] = useState<IFormState>(
        details.email
            ? details
            : {
                underWriterName: null,
                propertyAddress: null,
                email: null,
                notes: null,
                subject: null,
            }
    );
    const [descriptions, setDescriptions] = useState<IDescription[]>([]);

    const largeBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));

    const getAttributes = (mergeTaskId: number | null) => {
        axios
            .get(`/api/mergeTaskAttribute/${mergeTaskId}`)
            .then((result) => console.log("attributeResult:", result));
    };

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [name, setName] = useState("");
    const [isSent, setIsSent] = useState(false);
    const [filteredPayout, setfilteredPayout] = useState<any[]>([]);
    const [filteredPayment, setfilteredPayment] = useState<any[]>([]);

    useEffect(() => {
        axios.get("api/users/logged-user").then((res) => {
            setEmail(res.data.email);
            setName(`${res.data.firstName} ${res.data.lastName}`);
        });

        /*setFormState((prev) => ({ ...prev, notes: localStorage.getItem("notes") }));
        document.getElementById("notes")!.innerHTML! =
          localStorage.getItem("notes")!;*/
    }, []);

    const sendEmail = (type: any) => {
        const desIndex = getIndividualItemsFromDocuments(
            mergeTaskInfo.documents
        ).map((des) => des.id);
        let notes = "";

        //        descriptions.filter(des => des.id && desIndex.includes(des.id)).forEach(des => notes += `${des.value} <br/>`)
        let attached = "";
        // attached += ReactDOMServer.renderToStaticMarkup(
        //   <Typography
        //     variant="h6"
        //     style={{ fontSize: "17px", marginBottom: "18px", width: "577px" }}
        //   >
        //     Subject Property Address: {details.propertyAddress}{" "}
        //   </Typography>
        // );
        for (let document of attachedDocuments) {
            attached += ReactDOMServer.renderToStaticMarkup(document);
        }
        if (results !== []) {
            results.forEach((result, index) => {

                attached += ReactDOMServer.renderToStaticMarkup(
                    <div>
                        <br />
                        <Typography
                            style={{
                                fontSize: "17px",
                                marginTop: "38px",
                                marginLeft: "0px",
                                marginBottom: "10px",
                                lineHeight: "1.5",
                            }}
                        >
                            <strong>{result.borrower}:</strong>
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "17px",
                                marginTop: "0px",
                                marginLeft: "15px",
                                marginBottom: "4px",
                                lineHeight: "1.5",
                            }}
                        >
                            Based on frequency: Paystub {result.currentPeriod} out of {" "}
                            {result.currentYearStartDate}{" "}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "17px",
                                marginTop: "0px",
                                marginLeft: "15px",
                                marginBottom: "4px",
                                lineHeight: "1.5",
                            }}
                        >
                            {result.income
                                ? (result.currentYearStartDate ? "Annualized Projected Income: $" : "Income tracking as: $") +
                                new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                }).format(result.income)
                                : ""}
                        </Typography>
                        {result.currentYearStartDate && (
                            <Typography
                                style={{
                                    fontSize: "17px",
                                    marginTop: "0px",
                                    marginLeft: "15px",
                                    marginBottom: "4px",
                                    lineHeight: "1.5",
                                }}
                            >{`Employment Start Date: ${result.currentYearStartDate.toLocaleDateString(
                                "en-US",
                                { month: "long", day: "numeric", year: "numeric" }
                            )}`}</Typography>
                        )}
                        {result.currentYearStartDate && result.probationBool && (
                            <Typography
                                style={{
                                    fontSize: "17px",
                                    marginTop: "0px",
                                    marginLeft: "15px",
                                    marginBottom: "4px",
                                    lineHeight: "1.5",
                                }}
                            >{`Probationary Period: ${result.probationBool == "yes" ? "Yes" : "No"
                                }`}</Typography>
                        )}
                    </div>
                );
            }

            )
        }

        if (formState.notes) notes += formState.notes;

        const emailInfo = {
            email: email,
            name: name,
            mergeId: mergeTaskInfo.id,
            underWriterEmail: details.email,
            underWriterName: details.underWriterName,
            Subject: details.subject,
            propertyAddress: details.propertyAddress,
            notes: notes,
            items: attached,
            Type: type,
        };
        axios
            .post("/api/summary-sheet", emailInfo)
            .then((res) => {
                if (res.status === 200) {
                    setIsSent(true);
                    setTimeout(() => {
                        history.push("/merge-docs");
                    }, 3000);
                }
            })
            .catch(async (error: any) => {
                if (error.response) {
                    const errorResponse = error.response.data;

                    setError(errorResponse.message.replace(/ *\([^)]*\) */g, ""));
                    setErrorFile(errorResponse.id);
                }
            });
    };
    const gotoEmailConfirmation = () => {
        const note = formState.notes;
        let markup = "";
        for (let document of attachedDocuments) {
            markup += ReactDOMServer.renderToStaticMarkup(document);
        }
        history.push({
            pathname: "/email-confirmation",
            state: {
                mergeTaskInfo,
                details: { ...formState },
                payments,
                payouts,
                note: note,
                attachedDoc: markup,
                results: results,
                currentYearStartDate,
                probationBool,
            },
        });

        // history.push({ pathname: '/email-confirmation', state: { mergeTaskInfo, details: { ...formState }, payments, payouts } )
    };

    const downloadFiles = () => {
        let markup = "";
        for (let document of attachedDocuments) {
            markup += ReactDOMServer.renderToStaticMarkup(document);
        }
        if (results !== []) {
            history.push({
                pathname: "/download-pdf",
                state: {
                    mergeTaskInfo,
                    details: { ...formState },
                    payments,
                    payouts,
                    results,
                    currentYearStartDate,
                    probationBool,
                    fromUnderwriter: false,
                    fromLenderSummary: true,
                    note: formState.notes,
                    descriptions,
                    attachedDoc: markup,
                },
            });
        } else {
            history.push({
                pathname: "/download-pdf",
                state: {
                    mergeTaskInfo,
                    details: { ...formState },
                    payments,
                    payouts,
                    fromUnderwriter: false,
                    fromLenderSummary: true,
                    note: formState.notes,
                    descriptions,
                    attachedDoc: markup,
                },
            });
        }
        /*const method = 'GET';
    
            const url = `/api/merge-task/${mergeTaskInfo.id}/merge`
    
    
            axios
                .request({
                    url,
                    method,
                    responseType: 'blob',
                })
                .then((res) => {
                    console.log(res);
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'merged.zip');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(async (error: any) => {
                    if (error.response) {
                        const errorResponse = JSON.parse(await error.response.data.text());
    
                        setError(errorResponse.message.replace(/ *\([^)]*\) g, ""));
                        console.log(errorResponse);
                        setErrorFile(errorResponse.id);
    
                    }
                 
                })*/
    };

    const back = () => {
        //var notes = (document.getElementById("notes") as HTMLInputElement).value;
        //localStorage.setItem("notes", notes);

        history.push({
            pathname: "/merge-pdf",
            state: { mergeTaskInfo, details: { ...formState }, payments, payouts },
        });
    };

    useEffect(() => {
        // setDocuments(documentData);
        //  getAttributes(mergeTaskInfo.id);
        const temp: any[] = [];
        mergeTaskInfo.documents.forEach((x: any) => {
            temp.push(x.id);
        });
        let orderedPayment = mergeById(
            mergeTaskInfo.tiles[9].documents,
            payments
        ).sort((a: any, b: any) => Number(a.order) - Number(b.order));
        let orderedPayOut = mergeById(
            mergeTaskInfo.tiles[7].documents,
            payouts
        ).sort((a: any, b: any) => Number(a.order) - Number(b.order));

        if (temp.length > 0) {
            if (payments && payments.length > 0) {
                var modifiedPayments = orderedPayment
                    .filter((x: any) => temp.includes(x.documentId));
                    //.sort((a: any, b: any) => new Date(a.statement).getTime() - new Date(b.statement).getTime())
                const filteredPayment = [];
                var groupedPayments = groupBy(modifiedPayments, 'account');
                for (const [key, value] of Object.entries(groupedPayments)) {
                    const maxDate = value.reduce((max: any, obj: any) => {
                        const statementBalanceDate = new Date(obj.statementDate);
                        if (statementBalanceDate == null)
                        {
                            return max
                        }
                        return statementBalanceDate > max ? statementBalanceDate : max;
                    }, new Date('1900-01-01'));
                    console.log("maxDate data", maxDate);

                    const updatedData = value.map(obj => {
                        const statementBalanceDate = new Date(obj.statementDate);
                        if (statementBalanceDate.getTime() < maxDate.getTime()) {
                            obj.statementBalance = '*******'; 
                        }
                        return obj;
                    });
                    filteredPayment.push(updatedData);
                }   
                setfilteredPayment(filteredPayment.flat(1));
            }
            if (payouts && payouts.length > 0) {
                setfilteredPayout(
                    orderedPayOut.filter((x: any) => temp.includes(x.documentId))
                );
            }
        }
    }, []);
    const mergeById = (a1: any, a2: any) =>
        a1.map((itm: any) => ({
            ...a2.find((item: any) => item.documentId === itm.id && item),
            ...itm,
        }));
    const attachedDocuments = getIndividualItemsFromDocuments(
        mergeTaskInfo.documents
    ).map((item) => {
        return (
            <>
                <Grid
                    container
                    style={{ marginLeft: "5px", marginTop: "15px", marginBottom: "5px" }}
                >
                    {/* <Check style={{ marginRight: "5px", fontSize:"17px" }} /> */}
                    <Typography
                        style={{
                            fontSize: "17px",
                            marginRight: "7px",
                            display: "inline",
                            marginLeft: "5px",
                        }}
                    >
                        <strong> ✓ {item.name} Documents </strong>
                    </Typography>
                    {descriptions.filter((desc) => desc.id === item.id).length > 0 && (
                        <Typography
                            style={{ fontWeight: 600, display: "inline", lineHeight: "1.5" }}
                        >
                            {" "}
                            ({descriptions.filter((desc) => desc.id === item.id)[0].value})
                        </Typography>
                    )}
                </Grid>

                {item.name === "Down Payment" && (
                    <Grid>
                        {filteredPayment.map((payment, index) => {
                            return (
                                <Box
                                    key={index}
                                    style={{
                                        display: "flex",
                                        marginTop: "12px",
                                        alignItems: "flex-start!important",
                                        position: "relative",
                                        paddingLeft: "40px",
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: "inline-block",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                marginLeft: "25px",
                                                marginTop: "0px",
                                                lineHeight: "1.5",
                                                width: "15px",
                                            }}
                                        >
                                            <strong>{index + 1}.</strong>
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "inline-block", width: "90%" }}>
                                        <Typography
                                            style={{
                                                marginLeft: "25px",
                                                marginBottom: "10px",
                                                marginTop: "0",
                                                lineHeight: "1.5",
                                            }}
                                        >
                                            {payment.financialInstitution} ****
                                            {payment.account}
                                        </Typography>
                                        {payment.statementDate && (
                                            <Typography
                                                style={{
                                                    marginLeft: "25px",
                                                    marginBottom: "10px",
                                                    marginTop: "0",
                                                    lineHeight: "1.5",
                                                }}
                                            >
                                                Statement Date: &nbsp;
                                                {payment.statementDate}
                                            </Typography>
                                        )}
                                        {payment.largeDepositsExplained && (
                                            <Typography
                                                style={{
                                                    marginLeft: "25px",
                                                    marginBottom: "10px",
                                                    marginTop: "0",
                                                    lineHeight: "1.5",
                                                }}
                                            >
                                                Notes: &nbsp;
                                                {payment.largeDepositsExplained}
                                            </Typography>
                                        )}
                                        {payment.statementBalance && (
                                            <Typography
                                                style={{
                                                    marginLeft: "25px",
                                                    marginBottom: "10px",
                                                    marginTop: "0",
                                                    lineHeight: "1.5",
                                                }}
                                            >
                                                Balance Towards Down Payment: &nbsp;$
                                                {payment.statementBalance
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </Typography>
                                        )}
                                        {payment.giftedProceeds && (
                                            <Typography
                                                style={{
                                                    marginLeft: "25px",
                                                    marginBottom: "10px",
                                                    marginTop: "0",
                                                    lineHeight: "1.5",
                                                }}
                                            >
                                                Gifted Proceeds: &nbsp;$
                                                {new Intl.NumberFormat("en-US", {
                                                    minimumFractionDigits: 2,
                                                }).format(payment.giftedProceeds)}
                                            </Typography>
                                        )}
                                        {payment.salesProceedsBalance && (
                                            <Typography
                                                style={{
                                                    marginLeft: "25px",
                                                    marginBottom: "10px",
                                                    marginTop: "0",
                                                    lineHeight: "1.5",
                                                }}
                                            >
                                                Sales Proceeds: &nbsp;${payment.salesProceedsBalance}
                                            </Typography>
                                        )}
                                    </Box>
                                    {/* <Typography>Net Sales Proceeds: {payment.giftedProceeds}</Typography>     */}
                                </Box>
                            );
                        })}
                        {filteredPayment.length > 0 && (
                            <Typography
                                style={{
                                    marginLeft: "25px",
                                    marginBottom: "10px",
                                    marginTop: "5px",
                                    lineHeight: "1.5",
                                }}
                            >
                                <strong>Total Down Payment: </strong>&nbsp;$
                                {new Intl.NumberFormat("en-US", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                }).format(getPaymentValue(payments, null))}
                            </Typography>
                        )}
                    </Grid>
                )}

                {item.name === "Debt Payout" && (
                    <Grid>
                        {filteredPayout.map((payout, index) => {
                            return (
                                <Box
                                    key={index}
                                    style={{
                                        display: "flex",
                                        marginTop: "12px",
                                        alignItems: "flex-start!important",
                                        position: "relative",
                                        paddingLeft: "40px",
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: "inline-block",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                marginLeft: "25px",
                                                marginTop: "0px",
                                                lineHeight: "1.5",
                                                width: "15px",
                                            }}
                                        >
                                            <strong>{index + 1}.</strong>
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "inline-block" }}>
                                        {payout.financialInstitution && (
                                            <Typography
                                                style={{
                                                    marginLeft: "25px",
                                                    marginBottom: "10px",
                                                    marginTop: "0",
                                                    lineHeight: "1.5",
                                                }}
                                            >
                                                Debt Being Paid Out: &nbsp;{payout.financialInstitution}
                                            </Typography>
                                        )}

                                        {payout.statementDate && (
                                            <Typography
                                                style={{
                                                    marginLeft: "25px",
                                                    marginBottom: "10px",
                                                    marginTop: "0",
                                                    lineHeight: "1.5",
                                                }}
                                            >
                                                Statement Date: &nbsp;{payout.statementDate}
                                            </Typography>
                                        )}
                                        {payout.statementBalance && (
                                            <Typography
                                                style={{
                                                    marginLeft: "25px",
                                                    marginBottom: "10px",
                                                    marginTop: "0",
                                                    lineHeight: "1.5",
                                                }}
                                            >
                                                Statement Balance: &nbsp;$
                                                {payout.statementBalance
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </Typography>
                                        )}
                                        <Typography
                                            style={{
                                                marginLeft: "25px",
                                                marginBottom: "10px",
                                                marginTop: "0",
                                                lineHeight: "1.5",
                                            }}
                                        >
                                            Statement Attached: &nbsp;
                                            {payout.statementAttached ? "Yes" : "No"}
                                        </Typography>
                                    </Box>
                                    {/* <Typography>Net Sales Proceeds: {payment.giftedProceeds}</Typography>     */}
                                </Box>
                            );
                        })}
                    </Grid>
                )}
            </>
        );
    });

    return (
        <>
            <Helmet>
                <title>
                    Lender Summary Sheet | Merge Documents | Max Merge Solutions - A
                    document handling tool for mortgage professionals
                </title>
            </Helmet>
            <SizeDialog
                setSizeSelected={setSizeSelected}
                mergeTaskInfo={mergeTaskInfo}
                details={details}
                payments={payments}
                payouts={payouts}
                sizeDialog={sizeDialog}
                setSizeDialog={setSizeDialog}
                sendEmail={() => sendEmail}
            />
            <MergeNav active="none" />
            <Container
                style={{ marginTop: "176px", marginBottom: "100px", maxWidth: 1400 }}
            >
                <Typography
                    variant="h5"
                    className={classes.mergeHeading}
                    style={{
                        marginBottom: "72px",
                        textAlign: "center",
                    }}
                >
                    Lender Summary Sheet
                </Typography>
                <Grid container>
                    <Grid
                        md={12}
                        lg={6}
                        style={
                            largeBreakpoint
                                ? { borderRight: "1px solid #cdd2d4", padding: "0 60px 0 0" }
                                : { padding: "0 20px 100px 20px" }
                        }
                        item
                    >
                        <DocumentAccordionList
                            errorFile={errorFile}
                            setError={setError}
                            documentType={"one"}
                            documents={mergeTaskInfo.documents}
                            id={1}
                            handleDelete={() => 0}
                            tiles={mergeTaskInfo.tiles}
                            descriptions={descriptions}
                            setDescriptions={setDescriptions}
                            mergeTaskInfo={mergeTaskInfo}
                            split={true}
                        />
                    </Grid>
                    <Grid
                        md={12}
                        lg={6}
                        style={
                            largeBreakpoint
                                ? { padding: "0 0 0 60px" }
                                : { padding: "0 20px 0 20px" }
                        }
                        item
                        id="context"
                    >
                        <LenderSummarySheetForm
                            details={formState}
                            setDetails={setFormState}
                        />
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: "17px",
                                color: "#cdd2d4",
                                marginBottom: "10px",
                            }}
                        >
                            Message
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: "20px",
                                marginBottom: "0px",
                                width: "577px",
                                fontWeight: "bold",
                            }}
                        >
                            {details.subject}{" "}
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: "20px",
                                marginBottom: "18px",
                                width: "577px",
                                fontWeight: "bold",
                            }}
                        >
                            Subject Property Address: {details.propertyAddress}{" "}
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{ fontSize: "17px", marginBottom: "12px" }}
                        >
                            Hi {details.underWriterName},
                        </Typography>

                        <Typography
                            variant="h6"
                            style={{ fontSize: "17px", marginBottom: "18px", width: "577px" }}
                        >
                            Please find attached the following documents for review:{" "}
                        </Typography>
                        <Box style={{ marginBottom: 20 }}>{attachedDocuments}</Box>
                        {results ? <Box style={{ marginTop: 38, marginBottom: 20 }}>* The information used for the year-to-date paystub tracker is derived from information entered by the user and is intended as a guide only:</Box> : null}
                        {results && results.map((result, index) => (
                            <div key={index}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontSize: "17px",
                                        marginTop: "20px",
                                        marginLeft: "0px",
                                        marginBottom: "10px",
                                        lineHeight: "1.5",
                                    }}
                                >
                                    <strong>{result.borrower}:</strong>
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: "17px",
                                        marginTop: "0px",
                                        marginLeft: "15px",
                                        marginBottom: "10px",
                                        lineHeight: "1.5",
                                    }}
                                >
                                    {result.amount
                                        ? "Gross Regular Earning: $" +
                                        new Intl.NumberFormat("en-US", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        }).format(result.amount)
                                        : ""}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: "17px",
                                        marginTop: "0px",
                                        marginLeft: "15px",
                                        marginBottom: "10px",
                                        lineHeight: "1.5",
                                    }}
                                >
                                    {result.ytd
                                        ? "Year to Date Gross Earnings: $" +
                                        new Intl.NumberFormat("en-US", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        }).format(result.ytd)
                                        : ""}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: "17px",
                                        marginTop: "0px",
                                        marginLeft: "15px",
                                        marginBottom: "10px",
                                        lineHeight: "1.5",
                                    }}
                                >
                                    Based on frequency: Paystub {result.currentPeriod} out
                                    of {result.totalPeriod}{" "}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: "17px",
                                        marginTop: "0px",
                                        marginLeft: "15px",
                                        marginBottom: "10px",
                                        lineHeight: "1.5",
                                    }}
                                >
                                    {result.income
                                        ? (result.currentYearStartDate ? "Annualized Projected Income: $" : "Income tracking as: $") +
                                        new Intl.NumberFormat("en-US", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        }).format(result.income)
                                        : ""}
                                </Typography>
                                {result.currentYearStartDate && (
                                    <Typography
                                        style={{
                                            fontSize: "17px",
                                            marginTop: "0px",
                                            marginLeft: "15px",
                                            marginBottom: "10px",
                                            lineHeight: "1.5",
                                        }}
                                    >{`Employment Start Date: ${result.currentYearStartDate.toLocaleDateString(
                                        "en-US",
                                        { month: "long", day: "numeric", year: "numeric" }
                                    )}`}</Typography>
                                )}
                                {result.currentYearStartDate && result.probationBool && (
                                    <Typography
                                        style={{
                                            fontSize: "17px",
                                            marginTop: "0px",
                                            marginLeft: "15px",
                                            marginBottom: "10px",
                                            lineHeight: "1.5",
                                        }}
                                    >{`Probationary Period: ${result.probationBool == "yes" ? "Yes" : "No"
                                        }`}</Typography>
                                )}
                            </div>
                        ))}
                        <TextField
                            style={{ margin: "20px 0 10px 0", maxWidth: 642 }}
                            className={classes.input}
                            label="Additional Notes:"
                            id="notes"
                            variant="standard"
                            multiline
                            maxRows={4}
                            placeholder="Include any additional notes to the underwriter here."
                            onChange={(event) =>
                                setFormState((prev) => ({ ...prev, notes: event.target.value }))
                            }
                        />
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: "17px",
                                marginBottom: "4px",
                                marginTop: "38px",
                            }}
                        >
                            Thank you,
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: "17px",
                                paddingBottom: "30px",
                                marginBottom: "12px",
                                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                            }}
                        >
                            {token.firstName}
                        </Typography>
                        {error && (
                            <h5 style={{ marginTop: "1.5rem", color: "red", width: "30rem" }}>
                                {" "}
                                {error}. Further compression is not possible. Please delete
                                problem file to continue.
                            </h5>
                        )}

                        {isSent && (
                            <Alert style={{ width: "100%" }} severity="success">
                                Sent! If you and/or recipient do not see this email in a few
                                minutes, check your "junk mail" folder or "spam" folder.
                            </Alert>
                        )}

                        <div
                            style={{
                                marginTop: "40px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                            }}
                        >
                            <Button
                                onClick={() => gotoEmailConfirmation()}
                                variant="outlined"
                                className={[classes.options, classes.optionButtonCustom].join(
                                    " "
                                )}
                            >
                                {" "}
                                Email Documents{" "}
                            </Button>

                            <Button
                                onClick={() => downloadFiles()}
                                variant="outlined"
                                className={[classes.options, classes.optionButtonCustom].join(
                                    " "
                                )}
                            >
                                {" "}
                                Portal Upload{" "}
                            </Button>
                        </div>
                        <Typography
                            onClick={() => back()}
                            style={{
                                textAlign: "center",
                                textDecorationLine: "underline",
                                fontWeight: 600,
                                color: "black",
                                cursor: "pointer",
                                marginTop: "22px",
                            }}
                        >
                            {" "}
                            Back{" "}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
