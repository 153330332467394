import React from 'react'
import styled from "styled-components"
import { Container } from "@mui/material";
import { ILanding } from "../interfaces/ILanding";
import Form from './Form';
const Hero = ({openModal}: ILanding) => {

    return (
        <HeroWrapper>
            <Container className="small-container container">
                <div className="left-side">
                    <h1>Architecture for<br/>Mortgage Professionals</h1>
                    <p>
                        Be one of the first mortgage professionals to get<br/>
                        exclusive access and the latest news on our product<br/> directly in your inbox.
                    </p>
                    <Form maxwidth={true} blackBtn={true}/>
                </div>
                <div className="right-side">
                    <div className="promo">
                        <p>First 30 mortgage<br/>professionals get</p>
                        <h1>
                            30% OFF
                        </h1>
                        <button onClick={openModal}>
                            Get Early Access
                        </button>
                    </div>
                    <img src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_400/LandingPageAssets/hero_nn2bwt.png" alt="hero"/>
                </div>
            </Container>
        </HeroWrapper>
    )
}

const HeroWrapper = styled.div`
    padding: 100px 0;
    background: #E5E5E5;
    overflow: hidden;

    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
    }

    .left-side {
        display: grid;
        justify-content: start;
        
        h1, p {
            color: black;
        }

        h1 {
            letter-spacing: 2px;
            margin-bottom: 40px;
        }

        p {
            font-size: 18px;
            margin-bottom: 40px;
        }
    }

    .right-side {
        position: relative;
        display: grid;
        align-items: center;
        justify-content: end;
        padding-bottom: 100px;
        padding-left: 50px;
            &:after {
            content: "";
            position: absolute;
            bottom: 150px;
            left: 50%;
            z-index: 0;
            width: 100vw;
            background-color: #00939F;
            height: 100px;
        }
        .promo {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #1B1E1E;
            padding: 30px 40px;
            z-index: 2;
            border-radius: 11px;
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            gap: 20px;
            @media screen and (min-width: 1200px) {
                transform: translateX(20%);
            }
            p, h1 {
                color: white;
                padding: 0;
                margin: 0;
                text-align: center;
            }

            p {
                font-size: 20px;
            }

            h1 {
                font-size: 50px;
                font-weight: 600;
            }

            button {
                color: white;
                background-color: #00939F;
                padding: 10px, 20px;
                border-radius: 8px;           
                font-weight: 400;
                font-size: 20px;
            }
        }
    }


    img {
        border-radius: 11px;
        height: 400px;
        object-fit: cover;
        z-index: 1;
        position: relative;
    }


`

export default Hero
