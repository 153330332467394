import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import '../../../../../custom.css'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { Box } from '@mui/system';
import { List, ListItem, ListItemText, ListItemIcon, Typography, Input, Grid, useMediaQuery } from '@mui/material';
import { downloadPDFOverviewUseStyles } from './../assets/downloadPDFOverviewUseStyles'
import { TextField } from '@mui/material';

import MuiAccordionSummary, {

    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { DocumentList } from './DocumentList';
import { IDocument } from '../../../assets/interfaces/IDocument';
import { IDescription } from '../../LenderSummarySheet/assets/interfaces/IDescription';
import { updateDescriptions } from '../../../../../helpers/updateDescriptions';
import { IMergeTaskInfo } from '../../../assets/interfaces/IMergeTaskInfo';

import axios from 'axios';
import { ITile } from '../../../assets/interfaces/ITile';
import { useTheme } from '@mui/material/styles';

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface IDocumentTypeAccordion {
    documentType: string | null;
    documents: IDocument[];
    split: boolean;
    id: number;
    descriptions: IDescription[];
    mergeTaskInfo: IMergeTaskInfo;
    tiles: ITile[];
    setDescriptions: React.Dispatch<React.SetStateAction<IDescription[]>>;
    handleDelete: any;
    setError: any;
    errorFile: any;
}

export function DocumentTypeAccordion(props: IDocumentTypeAccordion) {
    const classes = downloadPDFOverviewUseStyles();

    const { id, documentType, documents, mergeTaskInfo, descriptions, setDescriptions, tiles, split, setError, errorFile, handleDelete } = props;
    const [expanded, setExpanded] = useState<string | false>('panel1');
    /*const [newDocs, setNewDocs] = useState(documents);
    console.log(newDocs);*/
    const [newMergeTask, setNewMergeTask] = useState(mergeTaskInfo);
    const theme = useTheme();
    const largeBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
    const extraLargeBreakpoint = useMediaQuery(theme.breakpoints.up('xl'));
    const AccordionSummary = styled((props: AccordionSummaryProps) => (

        <MuiAccordionSummary
            expandIcon={<>
                <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} style={{ visibility: documents.length > 0 ? "visible" : "hidden" }} />
                {split && <div style={{ fontSize: '0.9rem', visibility: documents.length > 0 ? "visible" : "hidden" }}></div>}
            </>}
            {...props} />

    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '$ .MuiAccordionSummary-expandIconWrapper':
        {
            position: 'absolute',
            left: 0,
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',

        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },

    }));

    // const test = () => {
    //   axios
    //     .get(`api/merge-task/${mergeTaskInfo.id}`)
    //     .then((result) => {
    //       console.log('!!!!', result);
    //       if (result.status === 200) {
    //         setNewMergeTask(result.data)
    //         setNewDocs(result.data.documents);
    //       }
    //     })
    // }

    // useEffect(() => {
    //   console.log('useEffect');
    //   test()
    // }, []);
    /*
        const handleDelete = (id: number) => {
            axios
                .delete(`api/merge-documents/${id}`)
                .then((result) => {
                    if (result.status === 200) {
                        axios
                            .get(`api/merge-task/${mergeTaskInfo.id}`)
                            .then((result) => {
                                console.log(errorFile);
                                if (id == errorFile) {
    
                                    setError('');
                                }
                                console.log('new result', result.data);
                                setNewMergeTask(result.data);
                                setNewDocs(result.data.documents);
                            })
    
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch(e => console.log('error deleting document:', e))
        }
    */
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            if (documents.length > 0) {
                setExpanded(newExpanded ? panel : false);
            }
        };

    return (
        <Accordion style={{ position: 'relative', background: 'white', width: '100%', borderLeft: 'none', borderRight: 'none', pointerEvents: documents.length > 0 ? 'inherit' : 'none' }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary style={{ background: 'white' }} aria-controls="panel3d-content" id="panel3d-header">
                <Typography style={{ fontSize: extraLargeBreakpoint ? 18 : largeBreakpoint && split ? 14 : 18, marginRight: 10 }}>{documentType} Document ({documents.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {split &&
                    <TextField
                        className={classes.input}
                        label="Add details"
                        id="standard-basic"
                        variant="standard"
                        placeholder="Type optional details here"
                        InputLabelProps={{
                            style: { color: 'black' },
                        }}
                        onChange={(e) => setDescriptions(prev => ([...updateDescriptions(prev, id, e.target.value)]))}
                    />
                    /*<input className='description' style={{ border: 'none', fontSize: largeBreakpoint ? 16 : 14, zIndex: 99, }} placeholder='Add Details Here (Optional)' onChange={(e) => setDescriptions(prev => ([...updateDescriptions(prev, id, e.target.value)]))} />*/
                }
                <DocumentList documents={documents} mergeTaskInfo={newMergeTask} handleDelete={handleDelete} newDocs={documents} newMergeTask={newMergeTask} tiles={tiles} descriptions={descriptions} setDescriptions={setDescriptions} />
            </AccordionDetails>
        </Accordion>
    );
}