import React from 'react'
import styled from "styled-components"
import { dataBody } from '../assets/data'
import { Container } from "@mui/material";
import InfoCard from './InfoCard';
import { ILanding } from "../interfaces/ILanding";

const InfoBody = ({ openModal }: ILanding) => {
    return (
        <InfoBodyWrapper>
            <Container>
                {
                    dataBody.map((val, index)=> {
                        return <InfoCard key={index} data={val} openModal={ openModal }/>
                    })
                }
            </Container>
        </InfoBodyWrapper>
    )
}

const InfoBodyWrapper = styled.div`
     background-color: #EFF0F2;
     padding: 40px 0 80px;
     
     @media screen and (min-width: 900px) {
        padding-top: 0;
     }
`

export default InfoBody
