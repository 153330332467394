import { Container } from "@mui/material";
import React, { useState } from "react";
import { MergeNav } from "../MergeProcess/components/MergeNav";
import { IStandardProps } from "../interfaces/IStandardProps";
import { settingsUseStyles } from "./assets/settingsUseStyles";
import { Selector } from "./components/Selector";
import { Account } from "./components/Account";
import { Password } from "./components/Password";
import { useAuthContext } from "../Context/AuthContext";
import { Helmet } from 'react-helmet';

export function Settings(props: IStandardProps) {

  const { token, setToken } = useAuthContext();
  const [selected, setSelected] = useState(1);
  const classes = settingsUseStyles();

  return (
    <>
      <Helmet>
        <title>Settings | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
      </Helmet>
      <MergeNav active='none' />
      <Container className={classes.settingsContainer}>
        {/* The Selecter that chooses whether to display the account page or the password page */}
        <Selector selected={selected} setSelected={setSelected} />
        {/* Account Page */}
        {selected === 1 && <Account />}
        {selected === 2 && <Password />}
      </Container>
    </>
  );
}
