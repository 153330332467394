const data = {
    biweekly: {
        frequency: 14,
    },
    weekly: {
        frequency: 7,
    },
};

const calculate = (
    payPeriod,
    payPeriodStart,
    payPeriodEnd,
    currentYearStartDate,
    amount,
    ytd,
    employeeDate
) => {
    // the date we use depends on whether the pay period end date or date employee paid is later
    let dateToUse;
    if (payPeriodEnd.getTime() > employeeDate.getTime())
        dateToUse = payPeriodEnd;
    if (payPeriodEnd.getTime() <= employeeDate.getTime())
        dateToUse = employeeDate;
    if (payPeriodEnd.getFullYear() < employeeDate.getFullYear())
        dateToUse = employeeDate;

    console.log('-------------------------');
    console.log('payPeriod', payPeriod);
    console.log('payPeriodStart', payPeriodStart);
    console.log('payPeriodEnd', payPeriodEnd);
    console.log('currentYearStartDate', currentYearStartDate);
    console.log('amount', amount);
    console.log('ytd', ytd);
    console.log('employeeDate', employeeDate); // this is the paid date
    console.log('dateToUse', dateToUse);
    console.log('-------------------------');

    //getTotalPeriod = (payPeriod, payEnd, currentYearStart, payStart)
    const totalPeriods = getTotalPeriod(payPeriod, payPeriodEnd, null, payPeriodStart, employeeDate);

    //getCurrentPeriod = (payPeriod, payEnd, payStart, payDate)
    const currentPeriod = getCurrentPeriod(payPeriod, payPeriodEnd, payPeriodStart, employeeDate);
    var incomeTracking = ytd + amount * (totalPeriods - currentPeriod);

    if (currentYearStartDate !== null) {

        console.log('calculation with currentYearStartDate', currentYearStartDate);

        var _payStart = new Date(currentYearStartDate);
        var _payEnd = new Date(currentYearStartDate);
        var _payDate = new Date(currentYearStartDate);

        if (payPeriod === 'semimonthly') {
            //console.log('IN!! (semimonthly)', currentYearStartDate);

            _payStart = currentYearStartDate;

            if (currentYearStartDate.getDate() <= 15) {
                //console.log('111', currentYearStartDate.getDate());
                _payEnd.setDate(15);
                _payDate.setDate(15);
            } else {
                //console.log('222');
                _payEnd = new Date(currentYearStartDate.getFullYear(), currentYearStartDate.getMonth() + 1, 0);
                _payDate = new Date(currentYearStartDate.getFullYear(), currentYearStartDate.getMonth() + 1, 0);
                //console.log('222', _payEnd);
            }
        } else if (payPeriod === 'weekly' || payPeriod === 'biweekly') {
            var diffDays = (employeeDate.getTime() - payPeriodStart.getTime()) / (1000 * 3600 * 24);
            _payDate.setDate(currentYearStartDate.getDate() + diffDays);
        }

        console.log('get startDatePeriod')
        //getCurrentPeriod = (payPeriod, payEnd, payStart, payDate)
        const startDatePeriod = getCurrentPeriod(payPeriod, _payEnd, _payStart, _payDate, true) - 1;
        console.log('startDatePeriod', startDatePeriod);
        console.log(`adding ${startDatePeriod} x ${amount}`, startDatePeriod * amount);
        incomeTracking += startDatePeriod * amount;
    }

    return {
        currentPeriod: currentPeriod,
        totalPeriods: totalPeriods,
        income: incomeTracking,
    };
};

const getCurrentPeriod = (payPeriod, payEnd, payStart, payDate, isLog) => {

    if (isLog) {
        console.log('getCurrentPeriod = (payPeriod, payEnd, payStart, payDate)');
        console.log(payPeriod);
        console.log(payStart);
        console.log(payEnd);
        console.log(payDate);
    }

    if (payPeriod === "monthly")
        return payEnd.getMonth() + 1;
    else if (payPeriod === "semimonthly") {
        console.log('----- semimonthly -----');
        console.log(payStart);
        console.log(payEnd);
        console.log(payDate);

        return calculateSemiMonthlyPeriod(payStart, payEnd);
    }
    else if (payPeriod === "biweekly" || payPeriod === "weekly") {
        console.log('getCurrentPeriod = (payPeriod, payEnd, payStart, payDate)');
        console.log(payPeriod);
        console.log(payEnd);
        console.log(payStart);
        console.log(payDate);
        return getStartToEnd(payPeriod, new Date(payDate), payEnd);
    }

    return getStartToEnd(payPeriod, new Date(payEnd), payEnd);
};

const getTotalPeriod = (payPeriod, payEnd, currentYearStart, payStart, payDate) => {
    console.log('=========== getTotalPeriod (START) ===========');

    if (payPeriod === "monthly")
        return currentYearStart ? 12 - currentYearStart.getMonth() : 12;
    else if (payPeriod === "semimonthly")
        return currentYearStart ? 24 - calculateSemiMonthlyPeriod(payStart, payEnd) : 24;

    const year = payEnd.getFullYear();
    const start = currentYearStart ? new Date(currentYearStart) : new Date(payDate);
    const defDate = new Date(payEnd);
    const end = new Date(`December 31, ${year} 00:00:00`);

    console.log('---- inputs (START) ----')
    console.log('payPeriod', payPeriod);
    console.log('payStart', payStart);
    console.log('payEnd', payEnd);
    console.log('payDate', payDate);
    console.log('currentYearStart', currentYearStart);
    console.log('---- inputs (END) ----')

    console.log('year', year);
    console.log('start', start);
    console.log('defDate', defDate);
    console.log('end', end);

    console.log('=========== getTotalPeriod (END) ===========');

    console.log('=========== getStartToEnd #1 (START) ===========');
    var s = getStartToEnd(payPeriod, start, defDate);
    console.log('=========== getStartToEnd #1 (END) ===========');

    console.log('=========== getStartToEnd #2 (START) ===========');
    var e = getStartToEnd(payPeriod, new Date(payDate), end, true);
    console.log('=========== getStartToEnd #2 (END) ===========');

    console.log('s', s);
    console.log('e', e);

    return s + e - 1;
};

function getStartToEnd(payPeriod, start, current, end = false, isLog = false) {
    if (isLog) {
        console.log('=========== getStartToEnd (START) ===========');
        console.log('start', start);
        console.log('current', current);
        console.log('end', end);
    }

    let periods = 0;

    while (start.getFullYear() === current.getFullYear()) {
        periods++;

        if (isLog)
            console.log(start, periods);
        
        if (end) {
            start.setDate(start.getDate() + data[payPeriod].frequency);
        } else {
            start.setDate(start.getDate() - data[payPeriod].frequency);
        }
    }
    
    if (isLog) {
        console.log('# periods', periods);
        console.log('=========== getStartToEnd (END) ===========');
    }

    return periods;
}

function calculateSemiMonthlyPeriod(start, end) {
    // e.g. June 8, start.getDate() === 5;
    // Pay Period => 5 * 2 + 1 = 11

    let base = end.getMonth() * 2;
    return base + (start.getDate() <= 15 ? 1 : 2);
}

export { calculate };
