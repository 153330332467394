import React, {useState, useEffect} from 'react'
import styled from "styled-components"
import Loader from "./Loader";
import axios from 'axios';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import dateFormat from "dateformat";
import { ListStyleWrapper } from "./ListStyleWrapper"
import {  Alert } from '@mui/material';


const HistoryMerge = () => {
    const [loading, setLoading] = useState(true);
    const [mergeTasks, setMergeTasks] = useState([]);
    const [error, setError] = useState("");


    useEffect(()=> {
        setLoading(true);
        axios.get("./api/merge-task/history").then((resp) => {
            setMergeTasks(filterTasks(resp.data));
            setLoading(false);
        }).catch(() => {
            setError("Unable to fetch data, please try again later");
            setLoading(false);
        })
    }, [])

    const filterTasks = (tasks) => {
        return tasks.filter(x => new Date(x.purgeDateTime) < new Date(Date.now()) || x.taskStatus === 1);
    }

    
    if(loading)
        return <Loader />

    if(error)
        return <HistoryWrapper>
                <Alert className="alert" severity={"error"}>{error}</Alert>
            </HistoryWrapper>

    return (
        <HistoryWrapper>
            {
                mergeTasks.length === 0 && 
                <Alert className="alert" severity="info">You have no merge task in your history</Alert>
            }
            {
                mergeTasks.length > 0 &&
                <ListStyleWrapper>
                    <table>
                        <tr className='table-legends'>
                            <th>Name</th>
                            <th>Date Expired</th>
                            <th>Status</th>
                        </tr>
                        {mergeTasks.map((task)=> {
                            return<tr key={task.id} className="tr-row">
                                    <td className='td-file'>
                                        <span>
                                            <InsertDriveFileIcon />
                                            {task.name ? task.name : "N/A"}
                                        </span>
                                    </td>
                                    <td className="td-time">{dateFormat(new Date(task.purgeDateTime), "dd/mm/yyyy")}</td>
                                    <td className="td-time">{task.taskStatus === 1 ? "Completed" : "Expired"}</td>                 
                            </tr>        
                        })
                    }
                    </table>
                </ListStyleWrapper>
            }
        </HistoryWrapper>
    )
}


const HistoryWrapper = styled.section`
     .alert {
        margin: 2rem 0;
    }

    table {
        margin-top: 2rem;
    }
`

export default HistoryMerge
