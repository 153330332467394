import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from "@mui/system";

export const mergePDFUseStyles = makeStyles((theme:Theme) =>
    createStyles({
        main: {
            flexGrow: 1,
            height: '84px',
            position: "fixed",
            zIndex: 10
        },
        '*:focus': {
            outline: 0,
            border: 0
        },
        root: {
            width: '100%',
            height: '800px',
            margin: '0 auto',
            paddingTop: "100px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
        },
        nav: {
            position: "fixed",
            left: '50%',
            transform: "translateX(-50%)",
        },
        options: {
            padding: '30px 40px 30px 40px',
            width: "470px",
            margin: '10px 0 10px 0',
            outlineColor: '#00939F',
            '&:hover': {
                backgroundColor: '#00939F',
            }
        },
        back: {
            width: "100%",
            height: '100px',
            position: "fixed",
            zIndex: 5,
            background: "#1B1E1E"
        },
        title: {
            flexGrow: 1,
            "& img": {
                position: "relative",
                zIndex: 6,
                height: 100,
                width: 100
            }
        },
        buttonCustom: {
            width: '198px',
            height: '52px',
            marginLeft: '12px',
            marginRight: '12px',
            textTransform: 'none',
        },
        darkButton: {
            color: 'black',
            borderColor: 'black',
        },
        buttons: {
            width: '100%',
        },
        textShadow: {
            textShadow: "0 0 5px #1B1E1E"
        },
        mergeHeading: {
            fontWeight: 700,
            fontSize: 32,
            marginBottom: 24,

            [theme.breakpoints.down(1480)]: {
                fontSize: 26,
                marginBottom: 18,
            }
        },
        mergeHeader: {
            marginTop: '90px',
            height: '120px',
            backgroundColor: '#1B1E1E',
            display: 'flex',
            alignItems: 'center',
            padding: 0
        },
        headerText: {
            color: "#ffffff",
            fontWeight: 600,
            padding: 0
        },
        mergeBody: {
            marginTop: '50px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: 0
        },
        mergeButtons: {
            textTransform: 'none',
            fontWeight: 600,
            fontSize: '15px',
            borderRadius: '0px',
            borderWidth: '1px',
            borderColor: '#00939F',
            width: '186px',
            color: '#000'
        },
        thumbnail: {
            border: '1px dashed #252323',
            width: '100%',
            height: '264px',
            color: '#000',
            fontSize: '16px',
            display: 'grid',
            gridTemplateRows: '1fr 1fr 1fr',
            // rowGap: '30px',
            textAlign: 'center'
        },
        documentBody: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridTemplateRows: '1fr 1fr 1fr',
            gridRowEnd: 'auto',
            columnGap: '28px',
            rowGap: '28px',
            marginTop: '2vh',
            marginBottom: '50px',
        },
        editButton: {
            float: 'right',
            color: '#252323',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        upload: {
            backgroundColor: '#00939F',
            color: '#fff',
            borderRadius: 0,
            width: '170px',
            textTransform: 'none',
            fontWeight: 500,
            top: '25%',
            bottom: '5px',
            paddingTop: '4px',
            paddingBottom: '4px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        buttonDiv: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            marginTop: '1vh',
            height: '100%',
            width: '100%'
        },
        modal: {
            width: '1000px',
            height: '500px',
            opacity: 1,
            borderRadius: 0
        },
        content: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        action: {
            right: 0
        },
        modalTopBar: {
            height: '100%',
            width: '100%',
            backgroundColor: '#1B1E1E',
            color: '#fff',
            padding: 0,
        },
        fileList: {
            listStyleType: 'none',
            border: '1px #CDD2D4 solid',
            height: '54px',
            width: '630px',
            display: 'inline-flex',
            alignItems: 'center',
            fontWeight: 500,
            marginLeft: '1vw',
            marginBottom: '1.5vh',
        },
        fileListItem: {
            float: 'right',
            // visibility: 'hidden',
            '&:hover': {
                // visibility: 'visible'
                background: 'transparent'
            },
            '&:focus': {
                background: 'transparent',
                outline: 0,
                border: 0
            }
        },
        form: {
            display: 'inline-flex',
            width: '577px',
            fontSize: '18px',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'spaceBetween',
            alignItems: 'center',
            textAlign: 'left',
            color: '#1B1E1E',
            marginBottom: '78px'
        },
        errorForm: {
            display: 'inline-flex',
            width: '577px',
            fontSize: '18px',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'spaceBetween',
            alignItems: 'center',
            textAlign: 'left',
            color: '#1B1E1E',
            marginBottom: 30
        },
        input: {
            width: '500px',
            height: '82px',
            fontSize: '18px',
            padding: '10px 0',
            marginBottom: '10px',
            "& .MuiInputBase-root": {
                color: '#1B1E1E',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1B1E1E',
            },

        },
    }),
);