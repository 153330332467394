import * as React from 'react';
import { useEffect, useState } from 'react';
import { Typography, Button, Grid, Box, FormControlLabel, RadioGroup, Radio, Input, Alert } from '@mui/material/';
import { MergeNav } from '../MergeNav';
import { downloadPDFUseStyles } from './assets/downloadPDFUseStyles';
import jsPDF from 'jspdf';

// interfaces
import { IStandardProps } from '../../../interfaces/IStandardProps';
import { global } from '../../../../global';
import { useAuthContext } from '../../../Context/AuthContext';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { IMergeTaskInfo } from '../../assets/interfaces/IMergeTaskInfo';
import { IPaymentState, IPayout } from '../MergeDocs/assets/interfaces/IPaymentState';
import { IDetails } from '../../../YTDTracker/components/YearToDateOptions';

interface IDownloadPDF {
    location: {
        state: {
            mergeTaskInfo: IMergeTaskInfo;
            payments: IPaymentState[];
            payouts: IPayout[];
            dialog?: boolean | null;
            setSizeDialog?: React.Dispatch<React.SetStateAction<boolean>>;
            setSizeSelected?: React.Dispatch<React.SetStateAction<boolean>>;
            fromUnderwriter?: boolean | null;
            details: IDetails;
        }
    }
}

export function DownloadPDF(props: any) {
    const classes = downloadPDFUseStyles();
    const { location: { state: { mergeFileFromProfile, mergeTaskInfo, payments, payouts, dialog, setSizeDialog, setSizeSelected, fromUnderwriter, details, fromLenderSummary, note, results, currentYearStartDate, probationBool, descriptions, attachedDoc } } } = props;
    const { token, setToken } = useAuthContext();
    console.log(mergeFileFromProfile);
    // form object instead of separate useState calls for each input
    const [size, setSize] = useState<null | number>(null);
    const [other, setOther] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [inputVal, setInputVal] = useState<null | number>(null);
    const [FileSizeLimit, setFileSizeLimit] = useState<boolean>(false);
    const handleSize = (selectedSize: number) => {
        (document?.getElementById("other") as HTMLInputElement).value = "";

        setSize(selectedSize);
        setFileSizeLimit(false);
        setOther(false);
        setError(false);
    }

    const updateMergeTaskFileSize = (fileSize: number | null) => {
        const newFileSize = fileSize ? fileSize * 0.98 : 100000000000;
        axios
            .put(`./api/merge-task/${mergeTaskInfo.id}`, {
                maxFileSize: newFileSize,
            })
            .then((result) => {
                console.log('putToMergeTaskWithSize:', result.data)
                if (setSizeDialog && setSizeSelected) {
                    setSizeDialog(false)
                    setSizeSelected(true)
                }
            })
            .catch((e) => {
                console.log('error updating mergeTask in download pdf:', e)
            })
    }

    return (
        <Box style={{ height: "100vh", width: "100vw" }}>

            <Box>
                {!dialog && <MergeNav active="none" />}
            </Box>

            <Box className={classes.root}>
                {/* How would you like to use Max Merge Solutions? */}
                <Typography variant="h5" className={classes.mergeHeading}>{fromUnderwriter ? 'Select File Size' : fromLenderSummary ? 'Select File Size' : 'Select File Size'}</Typography>
                <Typography variant="h6" style={{ fontWeight: 400, fontSize: '16px' }}>Please select the maximum file download size.</Typography>
                <Box style={{ marginTop: "36px", display: "flex", flexDirection: "column" }}>
                    <form noValidate autoComplete="off" className={error ? classes.formWithError : classes.form} spellCheck={false} autoCapitalize="off" autoCorrect="off">
                        <RadioGroup
                            aria-label="gender"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            <Grid justifyContent="center" container>
                                <Grid xs={12} item>
                                    <FormControlLabel onClick={() => { handleSize(10000000) }} value="10Mb" control={<Radio />} label="10Mb" />
                                    <FormControlLabel onClick={() => { handleSize(14000000) }} value="14Mb" control={<Radio />} label="14Mb" />
                                </Grid>
                                <Grid xs={12} justifyContent="flex-end" style={{ marginRight: '87px' }} container item>
                                    <FormControlLabel onClick={() => { handleSize(25000000) }} value="25Mb" control={<Radio />} label="25Mb" />
                                    <Grid item>
                                        <FormControlLabel onClick={() => {
                                            setSize(null);
                                            setOther(true);
                                            setError(false);
                                        }} value="other" control={<Radio />} label="Other" />
                                        <Input id="other" style={{ width: '93px' }} disabled={!other} type='number'
                                            onChange={(e) => {
                                                setError(false);
                                                if (parseInt(e.target.value) < 10) {
                                                    setSize(parseInt(e.target.value) * 1000000);
                                                    setFileSizeLimit(true);
                                                }
                                                else {
                                                    setSize(parseInt(e.target.value) * 1000000);
                                                    setFileSizeLimit(false);
                                                }

                                            }}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                            placeholder="MB" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </form>
                    {error && <Grid container justifyContent="center"><Alert severity="error" style={{ marginBottom: 20, width: 420 }}>Please select a size</Alert></Grid>}
                    {FileSizeLimit && <Grid container justifyContent="center"><Alert severity="error" style={{ marginBottom: 20, width: 420 }}>The file size must be greater or equal to 10 MB</Alert></Grid>}
                    <Grid container justifyContent="center" className={classes.buttons}>
                        {!dialog && (
                            <>
                                {!fromLenderSummary && <Link style={{ textDecoration: 'none' }} to={{ pathname: token.isExpired ? (token.usertype == 1 ? '/merge-options' : '/merge-docs') : '/merge-docs', state: { mergeFiles: mergeFileFromProfile, mergeTaskInfo, payments, payouts } }}><Button variant="outlined" className={[classes.buttonCustom, classes.darkButton].join(' ')}>Back</Button></Link>}
                                {fromLenderSummary && <Link style={{ textDecoration: 'none' }} to={{ pathname: "/lender-summary", state: { mergeTaskInfo, details, payments, payouts } }}><Button variant="outlined" className={[classes.buttonCustom, classes.darkButton].join(' ')}>Back</Button></Link>}


                                {!FileSizeLimit && size != null && (
                                    <Link style={{ textDecoration: 'none' }} to={fromUnderwriter ? { pathname: size === null ? "" : "/add-ytd", state: { mergeTaskInfo, details, payments, payouts } } : { pathname: size === null ? "" : "/download-overview", state: { mergeTaskInfo, payments, payouts, dialog, setSizeDialog, setSizeSelected, fromUnderwriter, details, fromLenderSummary, note, results, currentYearStartDate, probationBool, descriptions, attachedDoc } }}><Button onClick={() => {
                                        size === null ? setError(true) : updateMergeTaskFileSize(size)

                                    }} variant="contained" className={classes.buttonCustom} style={{ background: global.primary }}>{fromUnderwriter ? 'Set Size' : 'Continue'}</Button></Link>)}
                                {(FileSizeLimit || size == null) && (
                                    <Link style={{ textDecoration: 'none' }} to={fromUnderwriter ? { pathname: size === null ? "" : "/add-ytd", state: { mergeTaskInfo, details, payments, payouts } } : { pathname: size === null ? "" : "/download-overview", state: { mergeTaskInfo, payments, payouts, dialog, setSizeDialog, setSizeSelected, fromUnderwriter, details, fromLenderSummary, note, results, currentYearStartDate, probationBool, descriptions, attachedDoc } }}> <Button onClick={() => {
                                        size === null ? setError(true) : updateMergeTaskFileSize(size)
                                    }} variant="contained" className={classes.buttonCustom} style={{ background: global.primary }}>{fromUnderwriter ? 'Set Size' : 'Continue'}</Button></Link>)}
                            </>
                        )}
                        {dialog && setSizeDialog && (
                            <>
                                <Button onClick={() => {
                                    setSizeDialog(false)
                                }} variant="outlined" className={[classes.buttonCustom, classes.darkButton].join(' ')}>Close</Button>
                                <Button onClick={() => {
                                    size === null ? setError(true) : updateMergeTaskFileSize(size)
                                    console.log('mergeTaskInfo:', mergeTaskInfo)
                                }} variant="contained"

                                    className={classes.buttonCustom} style={{ background: global.primary }}>{props.location.state.dialog ? 'Set Size' : 'Continue'}</Button>
                            </>
                        )}
                    </Grid>

                </Box>
            </Box>



        </Box>
    )
}

