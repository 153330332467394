import { IMergeTaskInfo } from "../components/MergeProcess/assets/interfaces/IMergeTaskInfo";
import { ITile } from "../components/MergeProcess/assets/interfaces/ITile";
import { IDocument } from "../components/MergeProcess/assets/interfaces/IDocument";

export const removeDocument = (
  mergeInfo: IMergeTaskInfo,
  tileId: number,
  documentId: number
) => {
  const newMergeInfo = { ...mergeInfo };
  const newDocuments = [...mergeInfo.documents].filter(
    (doc) => doc.id !== documentId
  );
  const newTiles = mergeInfo.tiles.map((tile: any) => {
    console.log("tileInFunction:", tile);
    if (tile.id === tileId) {
      tile.documents = tile.documents.filter(
        (doc: IDocument) => doc.id !== documentId
      );
      return tile;
    }
    return tile;
  });
  newMergeInfo.tiles = [...newTiles];
  newMergeInfo.documents = newDocuments;
  console.log("mergeTaskInfoAfterDocumentRemoved:", mergeInfo);
  return newMergeInfo;
};

/*export const removePayment =
    (payment, id) =>
    {
        console.log(payment,id);
    }*/
export const swapDocument = (
    mergeInfo: IMergeTaskInfo,
    tileId: number,
    document: any,
    variation: any | null
) => {
    const newMergeInfo = { ...mergeInfo };
    /*const newDocuments = [...mergeInfo.documents].filter(
        (doc) => doc.id === documentId
    );*/
    const newTiles = mergeInfo.tiles.map((tile: any) => {
        console.log("tileInFunction:", tile);
        if (tile.id === tileId && tile.variation === variation) {
            tile.documents = document;
            return tile;
        }
        return tile;
    });
    newMergeInfo.tiles = [...newTiles];
    console.log("swap:", mergeInfo);
    return newMergeInfo;
};