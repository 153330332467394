import { makeStyles } from "@mui/styles";
export const planCardUseStyles = makeStyles(() => ({
    root: {
        padding: '42px 52px 48px 52px',
        minHeight: 600,
        border: '0.1px solid #afafaf'
    },
    contentGrid: {
        minHeight: 600
    },
    heading: {
        fontSize: 24,
        fontWeight: 700,
        marginBottom: 28
    },
    divider: {
        background: '#cdcdcd',
        marginBottom: 20,
    },
    cost: {
        fontSize: 32,
        fontWeight: 700
    },
    frequency: {
        marginBottom: 30,
        color: '#929292'
    },
    smallName: {
        marginBottom: 5
    },
    list: {
        lineHeight: 2
    },
    listItem: {
        marginBottom: '8px',
        listStyleType: 'none',
        fontSize: 13,
        fontWeight: 700,
        '&::before': {
            position: 'relative',
            content: '"✓"',
            right: '20px',
            fontWeight: '800',
            color: "green",
            marginRight: "-10px",

        }

    },
    MissinglistItem: {
        marginBottom: '8px',
        listStyleType: 'none',
        fontSize: 13,
        fontWeight: 700,
        '&::before': {
            position: 'relative',
            content: '"x"',
            right: '20px',
            fontWeight: '800',
            color: "red",
            marginRight: "-10px",
        }
    },
    button: {
        width: '100%',
        height: 55
    },
}))