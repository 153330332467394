import React, { useState } from 'react';
import validator from 'validator';
import { Helmet } from 'react-helmet';
import axios from "axios";

// components
import { NavBar } from '../../NavBar/NavBar';
import { Typography, Button, Dialog, DialogContent, DialogTitle, InputLabel, TextField } from '@mui/material';
import { HomeFooter } from '../../Home/components/HomeFooter/HomeFooter';
import { Link, useLocation } from 'react-router-dom';

const cleanInfoState = ({
    name: "",
    email: ""
})

export default function PackageDocumentsTutorial() {

    const location = useLocation();

    return (
        <div style={{
            flexGrow: 1,
            height: '100vh',
            position: 'relative',
            zIndex: 10
        }}>
            <Helmet>
                <title>How to Package Documents Tutorial</title>
            </Helmet>

            <NavBar active="" color="black" />

            <div style={{
                margin: 'auto',
                backgroundColor: 'white',
                marginTop: '4%',
                padding: '5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                flexDirection: 'column',
            }}>
                <video controls={true} style={{width:'60rem' }} src="/tutorial.mp4">
                </video>


                {location.state ? (<div style={{ display: 'flex', paddingTop: '50px', gap: '25px' }}>
                    <Link to={'/tutorial-selection'} >
                        <Button color='primary' variant="contained"> Back </Button>
                    </Link>
                    <Link to={{ pathname: '/merge-profile', state: { documentPackaging: true } }}>
                        <Button color='primary' variant="contained"> Next </Button>
                    </Link>
                </div>) : true}
                


            </div>
            <HomeFooter mediumBreakpoint />
        </div>
    )
}