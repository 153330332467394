// @flow
import React, { useState } from 'react';
import { Card, Divider, Typography, Box, Button, Grid, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { global } from '../../global';
import { Link } from 'react-router-dom';
import { planCardUseStyles } from './assets/planCardUseStyles';
import { useAuthContext } from '../Context/AuthContext';
import axios from 'axios';
import { useHistory } from 'react-router';
const qs = require('querystring');

interface IPlanCard {
    name: string;
    cost: number;
    features: string[];
    missingFeature: string[];
    renew: boolean | null;
};

export function PlanCard(props: IPlanCard) {
    const { name, cost, features, renew, missingFeature } = props;
    const { token, setToken } = useAuthContext();
    const classes = planCardUseStyles();
    const [error, setError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const history = useHistory();

    const featureItems = features.map((feature, index) => {
        return (
            <li className={classes.listItem} key={index}>{feature}</li>
        )
    })
    const MissingfeatureItems = missingFeature.map((feature, index) => {
        return (
            <li className={classes.MissinglistItem} key={index}>{feature}</li>
        )
    })

    const switchPlan = () => {
        setSaving(true);
        axios
            .put('api/subscriptions', {
                userTypeId: name === 'Document Packaging' ? 2 : 1
            })
            .then(result => {
                console.log('/api/subscriptions', result);

                if (result.data.success) {
                    setSaving(false);
                    setSuccess(true);

                    console.log('set token1....', result.data.subscription);
                    if (result.data.subscription !== null) {
                        setToken((prev: any) => ({ ...prev, userType: result.data.subscription.userType.id }));
                    }

                    setTimeout(() => {
                        setSuccess(false);
                    }, 1000)
                    setTimeout(() => {
                        history.push('/settings')
                    }, 3000)
                } else {
                    if (result.data.type === 1) {
                        // no address
                        console.log('error no address');

                        // redirect to checkout
                        var url = (name === 'Merge Mortgage Files') ? '/checkout' : '/checkout-premium';
                        console.log(url);
                        history.push(url);
                    }
                }
            })
            .catch(e => {
                setSaving(false);
                setError(true);
                setTimeout(() => {
                    setError(false)
                }, 2000)
                console.log('error switching plans', e)
            })
    }

    return (
        <Box className={classes.root}>
            <Grid className={classes.contentGrid} justifyContent="space-between" container direction="column">
                <Grid item>
                    <Typography className={classes.heading}>{name}</Typography>
                    <Divider className={classes.divider} />
                    <Typography className={classes.cost}>{`$${cost} CAD`}  <p style={{ fontWeight: 400, fontSize: '20px' }} className={classes.frequency}> + applicable taxes</p></Typography>
                    <Typography className={classes.frequency}>Per Month</Typography>
                    <Typography className={classes.smallName}>{name}</Typography>
                    <Divider style={{ marginBottom: 12 }} className={classes.divider} />
                    <ul className={classes.list}>
                        {featureItems}
                        {MissingfeatureItems}

                    </ul>
                </Grid>
                <Grid item>
                    {(!renew || token.userType === 4) && (
                        <Link to={name === 'Merge Mortgage Files' ? '/checkout' : '/checkout-premium'}><Button style={name === 'Merge Mortgage Files' ? {} : { background: global.primary }} className={classes.button} variant={name === 'Merge Mortgage Files' ? 'outlined' : 'contained'}>Get Started
                        </Button></Link>
                    )}
                    {(renew && token.userType !== 4) && <Button onClick={() => switchPlan()} style={name === 'Merge Mortgage Files' ? {} : { background: global.primary }} className={classes.button} variant={name === 'Merge Mortgage Files' ? 'outlined' : 'contained'}>{
                        error ? 'Error changing plan' : saving ? <CircularProgress style={{ color: name === 'Document Packaging' ? 'white' : global.primary }} /> : success ? 'Plan successfully changed'
                            : token.userType === 1 && name === 'Merge Mortgage Files' ? 'Current Plan'
                                : token.userType === 2 && name === 'Document Packaging' ? 'Current Plan'
                                    : token.userType === 1 && name === 'Document Packaging' ? 'Upgrade'
                                        : token.userType === 2 && name === 'Merge Mortgage Files' ? 'Switch Plans'
                                            : 'Get Started'
                    }</Button>}
                </Grid>
            </Grid>
        </Box>
    );
};