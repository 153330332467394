import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const condenseDocumentsStyles = makeStyles((theme) =>
  createStyles({
    main: {
      flexGrow: 1,
      height: '84px',
      position: "fixed",
      zIndex: 10
    },
    root: {
      width: '100%',
      height: '50%',
      margin: 'auto auto',
      paddingTop: "180px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      textAlign: 'center'
    },
    buttonCustom: {
      borderRadius: '0',
      margin: '31px 24px',
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: 400,
    },
    condenseDocumentsHeader: {
      marginTop: '65px',
      height: '240px',
      backgroundColor: '#1B1E1E',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: 0
    },
    headerText: {
      color: "#ffffff",
      fontWeight: "bold",
      padding: 0,
      marginTop: '45px',
      display: 'flex',
      justifyContent: 'center',
      fontSize:"24px"
    },
    condenseBody: {
      width: 990,
      height: 330,
      // margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 0
    },
    body: {
      margin: 'auto',
      marginTop: '-120px',
      display: 'flex',
      width: '65vw',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    dragOver: {
      background: "rgba(243,243,243, 1)",
      pointerEvents: "none"
    },
    uploadArea: {
      width: '95%',
      height: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'dashed 2px #CDD2D4',
    },
    upload: {
      backgroundColor: '#00939F',
      color: '#fff',
      borderRadius: 0,
      width: '200px',
      textTransform: 'none',
      fontWeight: 400,
      cursor: "pointer",
      bottom: '5px',
      paddingTop: '8px',
      paddingBottom: '8px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: '#00939F',
        color: '#fff',
        textDecoration: 'none'
      },
      '&:focus': {
        outline: '0!important',
        textDecoration: 'none'
      }
    },
    uploadDrag: {
      backgroundColor: "#6b9397"
    },
    buttonDiv: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginTop: '2vh',
      width: '100%'
    },
    fileList: {
      listStyleType: 'none',
      border: '1px #CDD2D4 solid',
      height: '54px',
      padding: 20,
      display: 'inline-flex',
      alignItems: 'center',
      fontWeight: 500,
      marginTop: '6vh',
      marginBottom: '6vh',
      boxShadow: '2px 4px 4px rgba(140, 140, 140, 0.15)'
    },
    fileListItem: {
      float: 'right',
      // visibility: 'hidden',
      '&:hover': {
        // visibility: 'visible'
        background: 'transparent'
      },
      '&:focus': {
        background: 'transparent',
        outline: '0 !important',
        border: '0 !important'
      },
      '&:active': {
        outline: '0! important',
        border: '0 !important'
      }
    },
    thumbnail: {
      width: 300,
      height: 135,
      border: 'solid 1px #CDD2D4',
      margin: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      textAlign: 'left',
      paddingLeft: '2vw',
      paddingTop: '1vh',
      borderRadius: 0,
      textTransform: 'none',
      color: '#212525',
      '&:focus': {
        backgroundColor: '#00939F',
        color: '#fff',
        outline: '0 !important',
      },
    },
    compressionOptions: {
      display: 'flex',
      flexDirection: 'row'
    },
    compressButtonDiv: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginTop: '4vh',
      height: '100%',
      width: '100%'
    },
    downloadButtonDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 0,
      height: '100%',
      width: '100%',
      flexDirection: 'column'
    },
  }),
);