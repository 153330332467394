// @flow
import React, { useState, useEffect } from 'react';
import { InputAdornment, TextField, Button, Grid, Alert } from '@mui/material';
import { mergeOptionsStyles } from '../../../assets/MergeOptionsStyles';
import { IMergeTaskInfo } from "../../../assets/interfaces/IMergeTaskInfo";
import { IDownPaymentForm } from '../assets/interfaces/IDownPaymentForm';
import { IPaymentState } from '../assets/interfaces/IPaymentState';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { getPaymentValue, updatePayments } from '../../../../../helpers/updatePayments';

export function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      ddecimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
    // isNumericString
    />
  );
}


export function DownPaymentForm(props: IDownPaymentForm) {
  const { payments, setPayments, mergeTaskInfo, variation, tileId, selectedDocument, setSelectedDocument, documents } = props;
  const classes = mergeOptionsStyles();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
    const [saving, setSaving] = useState<string | null>(null);

  // useEffect(() => {
  //     setPaymentState(prev => ({ ...prev, totalBalance: prev.salesProceedsBalance + prev.statementBalance }))
  // }, [paymentState.salesProceedsBalance, paymentState.statementBalance])
    console.log("!!!", payments);
  const addAttribute = () => {

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    setSaving('Saving');

    const selectedPayment = payments.filter((payment: IPaymentState) => payment.documentId === selectedDocument)[0];

    let data =
    {
      MergeTaskAttributes:
        [
          {
            Value: selectedPayment.financialInstitution,
            TypeVariation: variation,
            MergeTaskDocumentId: selectedDocument,
            MergeTaskAttributeTypeID: 1,
            MergeTaskID: mergeTaskInfo.id
          },
          {
            Value: selectedPayment.account,
            TypeVariation: variation,
            TileType: tileId,
            MergeTaskDocumentId: selectedDocument,
            MergeTaskAttributeTypeID: 2,
            MergeTaskID: mergeTaskInfo.id
          },
          {
            Value: selectedPayment.statementDate,
            TypeVariation: variation,
            TileType: tileId,
            MergeTaskDocumentId: selectedDocument,
            MergeTaskAttributeTypeID: 3,
            MergeTaskID: mergeTaskInfo.id
          },
          {
            Value: selectedPayment.statementBalance,
            TypeVariation: variation,
            TileType: tileId,
            MergeTaskDocumentId: selectedDocument,
            MergeTaskAttributeTypeID: 4,
            MergeTaskID: mergeTaskInfo.id
          },
          {
            Value: selectedPayment.largeDepositsExplained,
            TypeVariation: variation,
            TileType: tileId,
            MergeTaskDocumentId: selectedDocument,
            MergeTaskAttributeTypeID: 5,
            MergeTaskID: mergeTaskInfo.id
          },
          {
            Value: selectedPayment.giftedProceeds,
            TypeVariation: variation,
            TileType: tileId,
            MergeTaskDocumentId: selectedDocument,
            MergeTaskAttributeTypeID: 6,
            MergeTaskID: mergeTaskInfo.id
          },
          {
            Value: selectedPayment.salesProceedsBalance,
            TypeVariation: variation,
            TileType: tileId,
            MergeTaskDocumentId: selectedDocument,
            MergeTaskAttributeTypeID: 7,
            MergeTaskID: mergeTaskInfo.id
          },
        ]
    }

    axios
      .post(`/api/mergeTaskAttribute/${mergeTaskInfo.id}`,
        JSON.stringify(data),
        axiosConfig

      )
      .then((result) => {
        // setAttributes((prev: IAttribute) => ([...prev, result.data]))
        console.log('result from post to mergeTaskAttributes:', result)
        setSaving(null)
        setSuccess('Saved down payment')
        setTimeout(() => {
          setSuccess(null)
        }, 500)
      })
      .catch(e => {
        console.log('error:', e)
        setSaving(null)
        setSuccess('Saved down payment')
        setTimeout(() => {
          setSuccess(null)
        }, 500)
      })
  }

  const onSuccess = () => {
    setError(null);
    addAttribute()
    setPayments((prev: any) => (updatePayments(prev, selectedDocument, 'saved', true)))
    for (let i = 0; i < documents.length; i++) {
      if (documents[i].id === selectedDocument) {
        setSelectedDocument(prev => documents[i + 1] ? documents[i + 1].id : documents[i].id);
      }
    }
  }

  const checkDate = (date: string) => {
    const month = date[0] + date[1];
    const day = date[3] + date[4];
    const year = date[6] + date[7] + date[8] + date[9];
    if (parseInt(month) > 12 || parseInt(day) > 31) {
      return false
    }
    return true
  }

  const handlePayments = () => {
    const payment = payments.filter(payment => payment.documentId === selectedDocument)[0];
    if (payment) {
            onSuccess();
    }
  }

  // useEffect(() => {
  //     if (payments.filter(payment))
  //     setPayments((prev) => ([...prev, {
  //         financialInstitution: null,
  //         account: null,
  //         statementDate: null,
  //         largeDepositsExplained: null,
  //         giftedProceeds: 0,
  //         salesProceedsBalance: 0,
  //         statementBalance: 0,
  //         totalBalance: 0,
  //         documentId: selectedDocument,
  //     }]))
  // }, [selectedDocument])

    return (
        <Grid container direction="column" item xs={5} style={{ overflow: "scroll", height: "100%", flexWrap: "nowrap",  overflowX: "hidden" }}>
            <form noValidate autoComplete="off" className={classes.form} spellCheck={false} autoCapitalize="off" autoCorrect="off">
        <div>
          <TextField
            className={classes.input}
            label="Name of Financial Institution"
            variant="standard"
                      value={payments?.filter(payment => payment.documentId === selectedDocument).length === 0 ? '' : payments.filter(payment => payment.documentId === selectedDocument)[0].financialInstitution ?? ''}
            onChange={(event) => {
              setPayments((prev: any) => ([...updatePayments(prev, selectedDocument, 'financialInstitution', event.target.value)]))
              // console.log(payments);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
              color: 'secondary',
              type: "text"
            }}
            InputLabelProps={{
              color: 'secondary',
              style: { fontSize: '20px' }
            }}
          />

          <TextField
            // className={classes.expiry}
            label="Account # (enter last 4 digits)"
            className={classes.input}
            // customInput={TextField}
            placeholder='xxxx'
            inputProps={{ maxLength: 4 }}
            // format="####"
            value={payments?.filter(payment => payment.documentId === selectedDocument).length === 0 ? '' : payments.filter(payment => payment.documentId === selectedDocument)[0].account ?? ''}
            onChange={(event) => {
              setPayments((prev: any) => (updatePayments(prev, selectedDocument, 'account', event.target.value)))
              console.log(payments);
            }}
          />

          <NumberFormat
            // className={classes.expiry}
            label="Statement Date"
            className={classes.input}
            customInput={TextField}
            placeholder='MM/DD/YYYY'
            format="##/##/####"
                      value={payments?.filter(payment => payment.documentId === selectedDocument).length === 0 ? '' : payments.filter(payment => payment.documentId === selectedDocument)[0].statementDate ?? ''}
            onChange={(event) => {
              setPayments((prev: any) => (updatePayments(prev, selectedDocument, 'statementDate', event.target.value)))
              console.log(payments);
            }}
          />

          <TextField
            label="Balance Towards Down Payment"
            className={classes.input}
            // defaultValue=""
                      value={payments?.filter(payment => payment.documentId === selectedDocument).length === 0 ? '' : payments.filter(payment => payment.documentId === selectedDocument)[0].statementBalance ?? ''}
            onChange={(event) => {
              setPayments((prev: any) => (updatePayments(prev, selectedDocument, 'statementBalance', event.target.value)))
              console.log(payments);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              color: 'secondary',
              inputComponent: NumberFormatCustom
              // type: "text"
            }}
            InputLabelProps={{
              color: 'secondary',
            }}
          />

          <TextField
            className={classes.input}
            label="Notes (e.g. large deposits)"
            variant="standard"
                      value={payments?.filter(payment => payment.documentId === selectedDocument).length === 0 ? '' : payments.filter(payment => payment.documentId === selectedDocument)[0].largeDepositsExplained ?? ''}
            onChange={(event) => {
              setPayments((prev: any) => (updatePayments(prev, selectedDocument, 'largeDepositsExplained', event.target.value)))
              console.log(payments);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
              color: 'secondary',
              type: "text"
            }}
            InputLabelProps={{
              color: 'secondary',
              style: { fontSize: '20px' }
            }}
          />

          <TextField
                        label={<label style={{ fontSize: '20px' }}>Gifted DP-For Funds not Deposited</label>}
            style={{ whiteSpace: 'pre-line'}}
            className={classes.input}
                      value={payments?.filter(payment => payment.documentId === selectedDocument).length === 0 ? '' : payments.filter(payment => payment.documentId === selectedDocument)[0].giftedProceeds ?? ''}
            onChange={(event) => {
              setPayments((prev: any) => (updatePayments(prev, selectedDocument, 'giftedProceeds', event.target.value)))
              console.log(payments);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              color: 'secondary',
              inputComponent: NumberFormatCustom
              // type: "text"
            }}
            InputLabelProps={{
                color: 'secondary',
              
            }}
          />

          <TextField
                        label={<label style={{ fontSize: '20px' }}>Property Net Sale Proceeds-For Funds not Deposited</label>}

                        
            className={classes.input}
                      value={payments?.filter(payment => payment.documentId === selectedDocument).length === 0 ? '' : payments.filter(payment => payment.documentId === selectedDocument)[0].salesProceedsBalance ?? ''}
            onChange={(event) => {
              setPayments((prev: any) => (updatePayments(prev, selectedDocument, 'salesProceedsBalance', event.target.value)))
              console.log(payments);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              color: 'secondary',
              inputComponent: NumberFormatCustom
              // type: "text"
            }}
            InputLabelProps={{
              color: 'secondary',
            }}
          />

        </div>
      </form>
      <div style={{ width: '84%', marginBottom: 20 }}>
              <span style={{ fontWeight: 400, fontSize: 18 }}>Total Down Payment</span>
                <span style={{ fontWeight: 700, fontSize: 24, float: 'right' }}>${new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                }).format(getPaymentValue(payments, payments.filter(payment => payment.documentId === selectedDocument)[0]?.account))}</span>
      </div>
      {error && <Alert style={{ marginBottom: 20 }} severity='warning'>{error}</Alert>}
      {saving && <Alert style={{ marginBottom: 20 }} severity='info'>{saving}</Alert>}
      {success && <Alert style={{ marginBottom: 20 }} severity='success'>{success}</Alert>}
      <Grid container>
                <Button style={{height: 38, marginBottom: 30, padding: 0 }}  onClick={() => {
          handlePayments()

        }}>Save and Continue</Button>
      </Grid>
    </Grid>

  );
};