
export const dataBody = [
    {
        title: "Arrange your documents to your heart’s content.",
        text: "Effortlessly allocate documents by category, knowing they’re confidently arranged.",
        image: "https://res.cloudinary.com/maxmergesolutions/image/upload/w_400/LandingPageAssets/landing01_ajznaq.jpg",
        position: 2
    },
    {
        title: "Eliminate unnecessary time spent detailing the documents being attached",
        text: "Max Merge Solutions has the option of automatically sending your underwriter a Lender Summary Sheet that outlines everything included in your documents package.",
        image: "https://res.cloudinary.com/maxmergesolutions/image/upload/w_400/LandingPageAssets/landing02_ce00jw.jpg",
        position: 3
    },
    {
        title: "Create a well put together file or application",
        text: "In a few simple steps, trusting that your final PDF will be just as professional as you.",
        image: "https://res.cloudinary.com/maxmergesolutions/image/upload/w_400/LandingPageAssets/landing03_suresa.jpg",
        position: 4
    }
]