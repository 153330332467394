import * as React from 'react';
import { useState, useEffect } from 'react';
import { MergeNav } from '../MergeNav';
import { mergeOptionsStyles } from '../../assets/MergeOptionsStyles';
import { Button, Container, Typography, useTheme, useMediaQuery, Dialog, DialogContent, DialogTitle, TextField, InputLabel, Alert } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import { FileCopy } from '@mui/icons-material';
import DocumentBox from './components/DocumentBox';
import axios from 'axios';
import { Link, useHistory, Prompt, useLocation } from 'react-router-dom';


//interfaces
import { IMergeTaskInfo } from '../../assets/interfaces/IMergeTaskInfo';
import { ITile } from '../../assets/interfaces/ITile';
import { useAuthContext } from '../../../Context/AuthContext';
import { Helmet } from 'react-helmet';
import { Divider } from '../../../Divider/Divider';
import { IPaymentState, IPayout } from './assets/interfaces/IPaymentState';

interface IMergeDocuments {
    location: any;
}

/* TS compiling issue, this is the new file for MergeDocs.tsx */ 
export default function MergeDocuments(props: IMergeDocuments) {
    const history = useHistory();
    const { token, setToken } = useAuthContext();
    const classes = mergeOptionsStyles();
    const theme = useTheme();
    const largeBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
    const [selectedDocument, setSelectedDocument] = useState<number | null>(0);
    const [payments, setPayments] = useState<IPaymentState[] | []>(
        props.location.state?.payments ? props.location.state.payments
            : []
    )
    const [payouts, setPayouts] = useState<IPayout[] | []>(
        props.location.state?.payouts ? props.location.state.payouts
            : []
    )
    const [reload, setReload] = useState(0);
    const [downPayments, setDownPayments] = useState<any[] | []>([]);
    const [name, setName] = useState<string>('');
    const [modal, setModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [nameVal, setNameVal] = useState("");
    const [severity, setSeverity] = useState<AlertColor>("info");
    const [alertInfo, setAlertInfo] = useState("");
    const [documentPackaging, setDocumentPackaging] = useState<any[] | []>(
        props.location.state?.documentPackaging ? props.location.state.documentPackaging
            : false );
    var mergeFiles = props.location.state?.mergeFiles;
    var varIsAfterSave = props.location.state?.varIsAfterSave;
    var rePromptSelection = props.location.state?.rePromptSelection;
    //console.log("rePromptSelection",rePromptSelection);
    const [mergeTaskInfo, setMergeTaskInfo] = useState<IMergeTaskInfo>(
        props.location.state?.mergeTaskInfo ? props.location.state.mergeTaskInfo
            : {
                numberBorrowers: 0,
                id: null,
                maxFileSize: null,
                propertyAddress: null,
                recipientEmailAddress: null,
                recipientName: null,
                tiles: [],
                type: null,
                documents: [],
                name: null
            });
    console.log("mergeFiles", mergeFiles);

    
    const getAttributes = (id: Number, data: any) => {
        axios
            .get(`/api/mergeTaskAttribute`, {
                params: {
                    mergeTaskID: id
                }
            })
            .then((result) => {
                const temp: any[] = [];
                data.documents.forEach((x: any) => {
                    temp.push(x.id);
                });

                const filteredResult = result.data.length > 0 ? result.data.filter((x: any) => temp.includes(x.documentId)) : null;
                const paymentArray = [];
                const payoutArray = [];
                if (filteredResult) {
                    for (let item of filteredResult) {

                        const tempPayout = {

                            financialInstitution: null,
                            statementDate: null,
                            statementBalance: null,
                            statementAttached: false,
                            documentId: item.documentId,
                            order: null,

                            saved: true,
                        }

                        const tempPayment =
                        {
                            account: null,
                            documentId: item.documentId,
                            financialInstitution: null,
                            giftedProceeds: null,
                            largeDepositsExplained: null,
                            order: null,
                            salesProceedsBalance: null,
                            saved: true,
                            statementBalance: null,
                            statementDate: null,
                            totalBalance: null

                        }
                        for (let value of item.attributes) {
                            if (value.typeID == 1) {
                                tempPayment.financialInstitution = value.value;
                            }
                            else if (value.typeID == 2) {
                                tempPayment.account = value.value;

                            }
                            else if (value.typeID == 3) {
                                tempPayment.statementDate = value.value;
                            }
                            else if (value.typeID == 4) {
                                tempPayment.statementBalance = value.value;
                            }
                            else if (value.typeID == 5) {
                                tempPayment.largeDepositsExplained = value.value;

                            }
                            else if (value.typeID == 6) {
                                tempPayment.giftedProceeds = value.value;

                            }
                            else if (value.typeID == 7) {
                                tempPayment.salesProceedsBalance = value.value;

                            }
                            else if (value.typeID == 8) {
                                tempPayout.statementBalance = value.value;
                            }
                            else if (value.typeID == 9) {
                                tempPayout.statementDate = value.value;
                            }
                            else if (value.typeID == 10) {
                                tempPayout.statementAttached = value.value == 'true' ? true : false;
                            }
                            else if (value.typeID == 12) {
                                tempPayout.financialInstitution = value.value;
                            }
                        }
                        if (item.attributes.some((x: any) => x.typeID >= 8)) {
                            payoutArray.push(tempPayout);
                        }
                        else {
                            paymentArray.push(tempPayment);
                        };
                    }
                }
                setPayments(paymentArray);
                setPayouts(payoutArray);
            })
    }
    const getMergeTask = (id: Number) => {
        axios.get(`api/merge-task/${id}`).then((result) => {
            if (result.status === 200) {
                setMergeTaskInfo(result.data)
                setName(result.data.name);
                getAttributes(id, result.data);
            }
        }).catch((e) => {
            console.log('error :::::::::', e)
        })
    }

    const makeMergeTask = async () => {
        // creates new merge task (project) for the current user
        await
            axios
                .put(`/api/merge-task/`, {})
                .then((result) => {
                    if (result.status === 200) {
                        setMergeTaskInfo(result.data);
                        // history.push(`/merge-docs/${result.data[0].id}`)
                    }
                })
                .catch((e) => {
                    console.log('mergeTaskCreation:', e);
                })
    }
    
    useEffect(() => {
            console.log("merge taskid",props.location.state?.id);
        if (mergeTaskInfo.id !== null || props.location.state?.id) {
            getMergeTask(mergeTaskInfo.id ? mergeTaskInfo.id : props.location.state.id)
        } else {
            makeMergeTask();
        }
    }, []);

    const saveTask = () => {
        if (!name) {
            setNameVal("Project name is required");
            return;
        }
        setSaving(true);
        setAlertInfo("Saving...");
        axios.put(`./api/merge-task/${mergeTaskInfo.id}`, {
            name: name
        }).then((resp) => {
            setSeverity("success");
            setAlertInfo("Saved! Redirecting...");
            setTimeout(() => {
                history.push("/merge-profile");
            }, 2000)

        }).catch(() => {
            setSeverity("error")
            setAlertInfo("Oops, something went wrong, unable to save.")
        })
    }

    const documentBoxes = (mergeTaskInfo.tiles as Array<ITile>).map((tile: ITile, index: number) => {
        return <DocumentBox payments={payments} setPayments={setPayments} payouts={payouts} setPayouts={setPayouts} key={index} tile={tile} mergeTaskInfo={mergeTaskInfo} setMergeTaskInfo={setMergeTaskInfo} />
    })


    return (
        <div>
            {token.isExpired ?
                <Prompt
                message={(params) =>
                        severity != "success" && (params.pathname != '/download-pdf' && params.pathname != '/merge-pdf' && params.pathname != '/merge-options' && params.pathname != '/merge-docs' && params.pathname != '/download-pdf') && mergeTaskInfo.tiles.some((x: any) => x.documents.length > 0) ? "You are about to exit this page and will lose your work. Are you sure you want to do that?" : true
                } /> : documentPackaging ?
                <Prompt
                    message={(params) =>
                        severity != "success" &&  (params.pathname != '/merge-pdf' && params.pathname != '/merge-docs') && mergeTaskInfo.tiles.some((x: any) => x.documents.length > 0) ? "You are about to exit this page and will lose your work. Are you sure you want to do that?" : true
                    } /> :
                <Prompt
                        message={(params) =>
                            severity != "success" && (params.pathname != '/download-pdf' && params.pathname != '/merge-pdf' && params.pathname != '/merge-docs') && mergeTaskInfo.tiles.some((x: any) => x.documents.length > 0) ? "You are about to exit this page and will lose your work. Are you sure you want to do that?" : true
                        } />
            } 
            
            
            <Helmet>
                <title>Merge Documents | Max Merge Solutions  - Submitting mortgage documents for a faster turnaround</title>
            </Helmet>

            <div>
                <MergeNav active='merge' />

                {/* header - merge documents  */}
                <div className={largeBreakpoint ? classes.mergeHeader : classes.smallMergeHeader} >
                    <Container style={{ padding: 0 }}>
                        <Typography variant="h5" className={classes.headerText}>Merge Documents</Typography>
                    </Container>
                </div>

                <Container className={largeBreakpoint ? classes.mergeBody : classes.smallMergeBody} >

                    {/* buttons and top divider */}
                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "flex-end" }}> */}
                    {/* <Button variant="outlined" className={classes.mergeButtons} style={{ marginRight: '10px' }} onClick={() => { addBorrowers() }}>Add Borrowers</Button> */}
                    {/* </div> */}

                    {/* main body stuff */}
                    <div className={largeBreakpoint ? classes.documentBody : classes.smallDocumentBody}>
                        {mergeTaskInfo.tiles && documentBoxes}
                    </div>

                    <div className={classes.footer}>
                        <Button style={{ padding: 0, fontSize: 16 }} onClick={() => { makeMergeTask() }}>Clear all</Button>
                        <div className={classes.save}>


                            <Link to={{ pathname: token.isExpired ? (token.userType == 1 ? "/download-pdf" : documentPackaging ? "/merge-pdf" : mergeFiles ? "/download-pdf" : "/merge-options") : rePromptSelection ? "/merge-pdf" : !documentPackaging ? "/download-pdf" : "/merge-pdf", state: { mergeFileFromProfile: mergeFiles, varIsAfterSave, documentPackaging, mergeTaskInfo, rePromptSelection,payments: payments.filter((payment: IPaymentState) => payment.saved === true), payouts: payouts.filter((payout: IPayout) => payout.saved === true) } }}>
                                    <Button variant="contained" disableElevation className={classes.mergeButtons} style={{ backgroundColor: '#00939F', color: '#fff', paddingTop: '6px', paddingBottom: '6px', marginBottom: '2vh' }}><FileCopy style={{ paddingRight: '5px' }} />Merge Files</Button>
                                </Link>
                            
                            

                            {/* add onClick handler to open modal to save project name */}
                            <Button disableRipple variant="text" style={{ fontSize: '1em' }} onClick={() => { setModal(true); console.log('clicked') }}>
                                Save and continue at a later time
                            </Button>
                        </div>
                    </div>
                </Container>

                {/* modal stuff */}
                <Dialog
                    fullScreen
                    open={modal}
                    onClose={() => setModal(false)}
                    style={{
                        margin: 'auto',
                        width: '750px',
                        height: '430px',
                        borderRadius: 0
                    }}
                >
                    <DialogTitle style={{ padding: 0, height: '76px', margin: 0 }}>
                        <div className={classes.modalTopBar}>
                            <Typography style={{ fontSize: '18px', fontWeight: 600, left: 0, padding: '0 0 0 0', marginLeft: '2vw', paddingTop: '2.5vh' }}>
                                Save file
                            </Typography>
                        </div>
                    </DialogTitle>

                    <DialogContent style={{ padding: "3rem 6rem" }}>
                        {
                            saving ?
                                <>
                                    <Alert severity={severity}>{alertInfo}</Alert>
                                </>
                                :
                                <>
                                    <form onSubmit={saveTask}>
                                        <InputLabel style={{ fontSize: "20px" }}>Project Name</InputLabel>
                                        <input autoFocus type="text" value={name} onChange={(e) => setName(e.target.value)} className={classes.inputStyle} />
                                        <p style={{ fontSize: "14px", color: "#ba000d", marginTop: "10px" }}>{nameVal}</p>
                                        <div style={{ display: "flex", justifyContent: "end", marginTop: "40px" }}>
                                            <Button onClick={() => setModal(false)} className={classes.cancelButton}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" className={classes.saveButton}>
                                                Save
                                            </Button>
                                        </div>
                                    </form>
                                </>
                        }
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}
// const addBorrowers = () => {

//   if (mergeTaskInfo.numberBorrowers) {

//     // logic now dictates that adding more borrowers only affects state and the ui after post is successful, PESSIMISTIC rendering
//     const mergeTaskInfoWithMoreBorrowers = { ...mergeTaskInfo, numberBorrowers: mergeTaskInfo.numberBorrowers + 4 }

//     axios
//       .put('api/merge-task', mergeTaskInfoWithMoreBorrowers)
//       .then((result) => {
//         if (result.status === 200) {
//           setMergeTaskInfo(result.data);
//           return true;
//         } else {
//           return false;
//         }
//       })
//       .catch((e) => {
//         console.log('mergeTaskAddBorrowers:', e);
//       })
//   } else {
//   }
// }

// const redirectToOptions = () => {
//   history.push(`/merge-options`);
// }
