import React, { useEffect, useState } from 'react';
import { MergeNav } from '../../MergeProcess/components/MergeNav';
import { IStandardProps } from '../../interfaces/IStandardProps';
import { splitDocumentsStyles } from '../assets/SplitDocumentsStyles';
import { getFileName } from '../../../helpers/fileName';
import { Document, pdfjs, Page } from 'react-pdf';
import axios from 'axios';
import { Alert, CircularProgress } from '@mui/material';

import { Container, Typography, Paper, TextField, Button } from '@mui/material';
import { CloudUploadOutlined } from '@mui/icons-material';
import { FolderOpen } from '@mui/icons-material';
import { useAuthContext } from '../../Context/AuthContext';
import { Helmet } from 'react-helmet';
import DownloadSplit from './DownloadSplit';
import '../../../custom.css';


export default function SplitDocuments(props: IStandardProps) {
    const { token, setToken } = useAuthContext();
    const classes = splitDocumentsStyles();

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [loading, setLoading] = useState(false);
    const [pdf, setPdf] = useState<any>();

    const [pages, setPages] = useState<any>([]);
    const [pagesToKeepObject, setPagesToKeepObject] = useState<any>({});
    const [text, setText] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [blobLink, setBlobLink] = useState('');
    const [newSize, setNewSize] = useState(0);
    const [dragging, setDragging] = useState<number>(0);
    const [error, setError] = useState<string>("");
    const [errorSucc, setErrorSucc] = useState(false);

    const isNumeric = (input: string) => !Number.isNaN(Number(input));
    const isOrdered = (start: string, end: string) => parseInt(start) < parseInt(end);
    const isRangeValid = (range: string[]) => range.length === 2 && range.every(isNumeric) && isOrdered(range[0], range[1]);
    const isSingleValid = (single: string[]) => single.length === 1 && isNumeric(single[0]);

    const handleChange = (e: any) => {
        setError("");
        setText(e.target.value);
        if (e.target.value.trim() === "") {
            setError("Value is required");
            return;
        }
        let inputs = e.target.value.split(',').map((x: string) => x.trim());
        console.log(inputs);
        for (let i = 0; i < inputs.length; i++) {
            const splitRange = inputs[i].split('-');
            if (inputs[i] === '' && inputs[i + 1] === '' || (inputs[i].substr(-1) == '-' && inputs[i].substr(-2, 1) == '-')) {
                setError("Format is not valid, example format: '1-5, 6, 9-10'");
                return;
            }
            if (!isSingleValid(splitRange) && !isRangeValid(splitRange) && (inputs[i].substr(-1) != '-')) {
                setError("Format is not valid, example format: '1-5, 6, 9-10'");
                return;
            }
        }
        const newPagesToKeepObject = pages.reduce((o: any, key: any) => ({ ...o, [key]: false }), {});

        inputs.forEach((ranges: string) => {
            let rangeToArr = ranges.split("-");
            for (const r of rangeToArr) {
                if (parseInt(r) > pages.length) {
                    setError("The number you have input is bigger than the pages of the document.");
                    return;
                }
            }

            newPagesToKeepObject[rangeToArr[0].trim()] = true;
            if (rangeToArr.length === 2) {
                for (let i = Number(rangeToArr[0].trim()) + 1; i <= Number(rangeToArr[1].trim()); i++) {
                    newPagesToKeepObject[i + ""] = true;
                }
            }
        });

        setPagesToKeepObject(newPagesToKeepObject);
    }

    const pageClick = (page: any) => {
        setError("");
        // have to do this shit because setpages is set async, so need this
        const tempObj = { ...pagesToKeepObject, [page]: !pagesToKeepObject[page] };
        const tempArray = Object.keys(tempObj).map((key) => tempObj[key]);
        setPagesToKeepObject({ ...pagesToKeepObject, [page]: !pagesToKeepObject[page] });

        let stringVal = "";
        //tempArray = [true, false, false ....];  
        const maxPages = tempArray.length;
        for (let i = 0; i < maxPages; i++) {

            if (tempArray[i]) {
                if (stringVal.length !== 0) {
                    stringVal += ", "
                }

                stringVal += (i + 1);

                for (let j = i + 1, k = 0; j <= maxPages; j++) {
                    if (j === maxPages) break;
                    if (tempArray[j])
                        k = j;

                    if (!tempArray[j] || j === maxPages) {
                        if (k > i) {
                            stringVal += "-" + (k + 1);
                            i = j - 1;
                        }
                        break;
                    }
                }
            }
        }
        setText(stringVal);
    }


    const dragEnter = (e: any) => {
        e.preventDefault();
        setDragging((prev) => prev + 1);
    }

    const dragOver = (e: any) => {
        e.preventDefault();
    }

    const dragLeave = (e: any) => {
        e.preventDefault();
        setDragging((prev) => prev - 1);
    }

    const fileDrop = (e: any) => {
        e.preventDefault();
        setDragging(0);
        handleFile(e.dataTransfer.files[0]);
    }

    const uploadFile = (e: any) => {
        e.preventDefault();
        handleFile(e.target.files[0]);
    }

    const handleFile = async (file: File) => {
        setLoading(true);

        console.log(file);
        if (!file || file.type !== "application/pdf") {
            //handle error
            setError("Incorrect file type or invalid file");
        }
        else if (file.size > 100000000) {
            setError("The File size must be less than 10MB");
        }
        else {
            setError("");
            setSelectedFile(file);

            setPdf(URL.createObjectURL(file));
            setLoading(false);
        }
    }

    function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
        if (numPages === 0) {
            setError("pdf has no pages");

            setPdf(null);
            return;
        }

        const tempArray = Array.from({ length: numPages }, (_, i) => i + 1);
        setPages(tempArray);
        setPagesToKeepObject(tempArray.reduce((o, key) => ({ ...o, [key]: false }), {}));
    }

    const getPagesToRemoveIntArray = () => {
        const tempArray = [];
        for (const [key, value] of Object.entries(pagesToKeepObject)) {
            if (!value)
                tempArray.push(Number(key));
        }
        return tempArray;
    }

    // const checkErrors = () => {
    //   let tempErr = false
    //   if (text[text.length - 1] === '-') {
    //     tempErr = true;
    //     setError("Format is not valid, example format: '1-5, 6, 9-10'");
    //   }

    //   return tempErr;
    // }


    const uploadDocuments = async (selected: any) => {
        const file = selected;
        const fileName = selected?.name;

        // if (checkErrors()) {
        //   return;
        // }

        if (file !== null) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                let base64File;
                if (reader.result !== null && typeof reader.result === 'string') base64File = reader?.result.split(',')[1]

                const body = {
                    fileName: getFileName(fileName.split('.')),
                    pagesToRemove: getPagesToRemoveIntArray(),
                    base64: base64File
                }

                await axios
                    .post(`/api/documents/split`, body)
                    .then((res) => {
                        if (res.status === 200) {
                            setBlobLink(res.data.url);
                            setNewSize(res.data.fileSize);
                        }
                    })
                    .catch((e) => {
                        console.log("error", e.response);
                        setError("File type not supported, please try again.")
                    })
            };
        }
    }

    const clearFile = () => {
        setPdf(null);
        setPages([]);
        setPagesToKeepObject({});
        setSelectedFile(null);
        setText('')
    }


    return (
        <div>
            <Helmet>
                <title>Split Documents | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
            </Helmet>
            <div>
                <MergeNav active="split" />
            </div>

            <div style={{ marginTop: 0 }}>
                {/* header - split documents  */}
                {selectedFile === null && !blobLink && (
                    <div className={classes.splitDocumentsHeader} >
                        <Container style={{ padding: 0 }}>
                            <Typography variant="h5" className={classes.headerText}>Split Documents</Typography>
                        </Container>
                    </div >)}

                {/* once a file is uploaded but link not grabbed yet */}
                {selectedFile && !blobLink && (
                    <div className={classes.splitDocumentsHeader} >
                        <Container style={{ padding: 0 }}>
                            <Typography variant="h5" className={classes.headerText}>Split Documents</Typography>
                            <div className={classes.pageEntry}>
                                <p className={classes.splitDescription}>Please select all the pages you want to download or enter the page range separated by commas.</p>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {!error && selectedFile ?
                                        <>
                                            <TextField value={text} onChange={handleChange} variant="filled" size="small" color="secondary" className={classes.pageNumbers} inputProps={{ style: { paddingRight: 0, paddingLeft: 5, paddingTop: '5px', width: '300px' } }} />
                                            <div style={{ marginTop: 15, padding: 0 }}>
                                                <Button className={classes.upload} onClick={clearFile}>Clear File</Button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <TextField value={text} onChange={handleChange} variant="filled" size="small" color="secondary" className={classes.pageNumbers} inputProps={{ style: { paddingRight: 0, paddingLeft: 5, paddingTop: '5px', width: '300px' } }} />
                                            <div className="errorMessage" style={{ paddingTop: '14px' }}>
                                                <Alert variant="outlined" severity="error" style={{ color: ' #ef5350', border: '0' }}>
                                                    {error}
                                                </Alert>
                                            </div>
                                        </>}
                                </div>
                            </div>
                        </Container>
                    </div>
                )}

                {/* upload document area - if a file has been uploaded - render the else. if not, the upload area will render */}
                <div className={classes.body}>

                    {!blobLink ? (
                        <>
                            {!selectedFile && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Paper className={classes.splitBody}
                                        onDragEnter={dragEnter}
                                        onDragLeave={dragLeave}
                                        onDragOver={dragOver}
                                        onDrop={fileDrop}>
                                        <div className={dragging === 0 ? classes.uploadArea : [classes.uploadArea, classes.dragOver].join(" ")}>
                                            <CloudUploadOutlined style={{ paddingRight: '3px', color: '#252323' }} /> Drag and drop your file here.
                      <div className={classes.buttonDiv}>
                                                <form method="POST" action="#">
                                                    <label htmlFor="actual-btn" className={classes.upload}><FolderOpen style={{ paddingRight: '3px' }} /> Choose File</label>
                                                    <input type="file" name="file" id="actual-btn" accept="application/pdf" hidden multiple onChange={uploadFile} />
                                                </form>
                                            </div>
                                            {
                                                error &&
                                                <Alert severity="error">{error}</Alert>
                                            }
                                        </div>
                                    </Paper>
                                </div>
                            )}

                            {selectedFile && (
                                <>
                                    {
                                        loading &&
                                        <div>
                                            <CircularProgress />
                                        </div>
                                    }
                                    <div className={classes.pagePreviews}>
                                        {
                                            (pdf && !loading) &&
                                            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} className="d-container">
                                                {
                                                    (pagesToKeepObject && pages) &&
                                                    pages.map((page: any) => {
                                                        return <div key={page} onClick={() => pageClick(page)}>
                                                            <Page pageNumber={page} className={`page ${pagesToKeepObject[page] ? "selected" : ""}`} />
                                                            <p style={{ textAlign: 'center', marginTop: 8, fontSize: '14px' }}>{page}</p>
                                                        </div>
                                                    })
                                                }
                                            </Document>
                                        }
                                    </div>
                                    <div
                                        style={{
                                            position: 'fixed',
                                            left: '0',
                                            bottom: '0',
                                            width: '100%',
                                            backgroundColor: 'black',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '96px',
                                            paddingTop: '5px',
                                        }}
                                    >
                                        <Button className={classes.upload} onClick={() => uploadDocuments(selectedFile)} >Split</Button>
                                    </div>
                                </>
                            )}
                        </>
                    ) : <DownloadSplit blobLink={blobLink} newSize={newSize} name={selectedFile?.name} setToken={setToken} />}
                </div>
            </div >
        </div>
    )
}