import React, { useState } from 'react'
import Nav from './components/Nav'
import Footer from './components/Footer'
import styled from "styled-components"
import Hero from './components/Hero'
import Banner from './components/Banner'
import Title from './components/Title'
import InfoBody from './components/InfoBody'
import EndingBanner from './components/EndingBanner'
import useWindowDimensions from "./components/windowDimensions";
import HeroMobile from "./components/HeroMobile";
import Modal from '@mui/material/Modal';
import Form from './components/Form'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
// import "./assets/styles/landing.css"
import { Helmet } from 'react-helmet';

const Landing = () => {
    require("./assets/styles/landing.css");
    const [modalOpen, setModalOpen] = useState(false);
    
    const closeModal = () => {
        setModalOpen(false);
    }

     const openModal = () => {
        setModalOpen(true);
    }

    const {width} = useWindowDimensions();
 
    return (
        <LandingWrapper>
            <Helmet>
                <title>Get Started</title>
                 <meta
                    name="description"
                    content="Get started with Max Merge Solutions today!"
                    data-react-helmet="true"
                    />
            </Helmet>
        <Modal
            open={modalOpen}
            className="landing-page"
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container>
                <Box>
                    <div className="close-btn-modal">
                        <IconButton onClick={closeModal} >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <h1 className="modal-h1" 
                    >
                        Get Started Today with <br/> Max Merge Solutions
                    </h1>
                    <Form/>
                </Box>
            </Container>
        </Modal>
            <Nav />
            {
                width > 1000 ? 
                <Hero openModal={openModal}/>
                :
                <HeroMobile/>
            }
            <Banner />
            <Title />
            <InfoBody openModal={openModal}/>
            <EndingBanner />
            <Footer />
        </LandingWrapper>
    )
}

const LandingWrapper = styled.div`

    background-color: #EFF0F2;

    .small-container {
        @media screen and (max-width: 900px) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    button {
        outline: none;
        border: none;
        box-shadow: none;
    }

    button:hover,
    button:active,
    button:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }

`

export default Landing
