import { createTheme } from "@mui/material";
import montserrat from "typeface-montserrat";
import { global } from "./global";

export const theme = createTheme({
  palette: {
    primary: {
      main: global.primary,
    },
    secondary: {
      main: global.neutral,
    },
    lightNeutral: {
      main: global.neutral,
    },
    black: {
      main: global.black,
    },
  },
  typography: {
    fontFamily: global.fontFamily,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [montserrat],
      },
    },
    MuiMenuItem: {
      root: {
        "&:selected": {
          backgroundColor: "none", // updated backgroundColor
        },
        "&:hover": {
          backgroundColor: "none",
        },
        "&:focus": {
          backgroundColor: "none",
        },
      },
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        muiinputprops: {
          fontSize: "17px",
        },
        variant: "standard",
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "17px",
          fontFamily: global.fontFamily,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: true,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&:hover": {
            backgroundColor: "inherit",
          },
          "&:active": {
            outline: "0 !important",
          },
          "&:focus": {
            outline: "0 !important",
          },
          fontFamily: global.fontFamily,
          fontSize: "18px",
          borderRadius: "0",
        },
        "&:active": {
          outline: "0 !important",
        },
        "&:focus": {
          outline: "0 !important",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "none! important",
          "&:hover": {
            //backgroundColor: "inherit",
            textDecoration: "underline",
            backgroundColor: "transparent",
          },
          "&:active": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          color: "#1b1e1e",
          backgroundColor: "#fff",
          "&-input:focus": {
            backgroundColor: "#fff",
          },
          "&-input:selected": {
            backgroundColor: "none! important", // updated backgroundColor
          },
          "&-input:hover": {
            backgroundColor: "none! important",
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          fontSize: "17px",
          // color: "#1b1e1e",
          borderBottom: "1.5px solid #6a6a6a",
          "&-input:selected": {
            backgroundColor: "none! important", // updated backgroundColor
          },
          "&-input:hover": {
            backgroundColor: "none! important",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "17px",
          // color: "#1b1e1e",
          backgroundColor: "transparent! important",
          "&:focus": {
            backgroundColor: "transparent !important",
          },
          "&.MuiSelect-select": {
            paddingLeft: "1em",
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
        focused: false,
      },
      styleOverrides: {
        root: {
          fontSize: "20px",
          color: "#1b1e1e",
          fontWeight: 500,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "&-MuiAlert-root .MuiAlert-icon": {
            marginRight: "8px",
          },
        },
      },
    },
  },
});
