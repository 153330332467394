// @flow
import React, { useState, useEffect } from 'react';
import { ListItem, ListItemIcon, ListItemText, List, Button, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { FileCopy, Close } from '@mui/icons-material';
import PreviewIcon from '@mui/icons-material/Preview';
import ClearIcon from '@mui/icons-material/Clear';
import { removeDocument } from '../../../../../helpers/removeDocument';
import axios from 'axios';

import { IDocument } from '../../../assets/interfaces/IDocument';
import { IMergeTaskInfo } from '../../../assets/interfaces/IMergeTaskInfo';
import { IDescription } from '../../LenderSummarySheet/assets/interfaces/IDescription';

interface Props {
    documents: IDocument[]
    mergeTaskInfo: IMergeTaskInfo
    tiles: any;
    newDocs: IDocument[];
    newMergeTask: any;
    handleDelete: any;
    descriptions: IDescription[];
    setDescriptions: React.Dispatch<React.SetStateAction<IDescription[]>>;
};

export function DocumentList(props: Props) {
    const { newDocs, handleDelete, newMergeTask, mergeTaskInfo } = props;

    const [data, setData] = useState(newDocs);

    useEffect(() => {
        setData(newDocs);
    }, [newDocs]);
    const handlePreview = (doc: IDocument, res: boolean, index: number) => {
        doc.expanded = res;

        let newArr = [...data];
        newArr[index] = doc;
        setData(newArr);
    }

    const listItemDocuments = data.map((doc: IDocument, index: number) => {
       
        return (
            <>
                <ListItem style={{ margin: '0' }} key={index}>
                    <ListItemIcon style={{ minWidth: 0 }}>
                        <Button style={{ color: 'rgba(0,0,0,.54)', minWidth: 0 }} onClick={() => handlePreview(doc, !doc.expanded, index)}>
                            {!doc.expanded ? <PreviewIcon /> : <Close /> }
                        </Button>
                    </ListItemIcon>
                    <ListItemText primary={doc.fileName} />
                    <ListItemIcon>
                        <Button style={{ color: '#1B1E1E' }} onClick={() => handleDelete(doc.id)}>
                            {/* <ClearIcon /> */}
                            <img style={{ width:24, height:'auto'}} src="https://res.cloudinary.com/synicsoftware/image/upload/v1644623372/77A32B36-6582-4D24-9187-6B989041D62A-removebg-preview_pgzjkt.png" />
                        </Button>
                    </ListItemIcon>
                </ListItem>

                <Dialog
                    fullScreen
                    open={doc.expanded}
                    onClose={() => handlePreview(doc, false, index)}
                    style={{
                        margin: "auto",
                        width: "1200px",
                        height: "800px",
                        maxHeight: "96vh",
                        borderRadius: 0,
                    }}
                >
                    <DialogTitle style={{ padding: 0, height: "76px", margin: 0 }}>
                        <div style={{
                            height: '100%',
                            width: '100%',
                            backgroundColor: '#1B1E1E',
                            color: '#fff',
                            padding: 0
                        }}>
                            <Typography
                                style={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    left: 0,
                                    padding: "0 0 0 0",
                                    marginLeft: "2vw",
                                    paddingTop: "2.5vh",
                                }}
                            >
                                Preview Document
                                <div style={{ display: "inline" }}>
                                    <Button
                                        onClick={() => handlePreview(doc, false, index)}
                                        style={{ color: "#fff", padding: 0, float: "right" }}
                                    >
                                        <Close />
                                    </Button>
                                </div>
                            </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ padding: 0, overflow: "hidden" }}>
                        <embed src={doc.url + '#zoom=60'} style={{ width: '100%', height: '724px', maxHeight: '100%' }} />
                    </DialogContent>
                </Dialog>
            </>
        )
    })

    return (
        <List>
            {listItemDocuments}
        </List>
    );
};