import * as React from 'react';
import { Typography, Button, Grid } from '@mui/material/';
import { MergeNav } from './MergeNav';
import { mergeOptionsStyles } from '../assets/MergeOptionsStyles';
import { IStandardProps } from '../../interfaces/IStandardProps';
import { global } from '../../../global';
import { useAuthContext } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { IMergeTaskInfo } from '../assets/interfaces/IMergeTaskInfo';
import { IPaymentState, IPayout } from './MergeDocs/assets/interfaces/IPaymentState';
import { useHistory } from "react-router";

interface IMergeOptions {
    // mergeTaskInfo: IMergeTaskInfo;
    // payments: IPaymentState[];
    // payouts: IPayout[];
    location: {
        state: {
            mergeTaskInfo: IMergeTaskInfo;
            payments: IPaymentState[];
            payouts: IPayout[];
            isAfterSave: boolean;
            id: number;
            rePromptSelection: boolean;
        }
    }
}

export default function MergeOptions(props: IMergeOptions) {
    const classes = mergeOptionsStyles();
    const { token, setToken } = useAuthContext();
    const history = useHistory();

    // const { location: { state: { mergeTaskInfo, payments } } } = props;
    let varMergeTaskInfo, varPayments, varPayouts;
    let varIsAfterSave = props.location.state ? props.location.state.isAfterSave : false;
    let taskId = props.location.state ? props.location.state.id : null;
    var rePromptSelection = token.IsTutorialVisited;

    // will have to test on normal paid account
/*    if (token.isExpired) {
        const { location: { state: { mergeTaskInfo, payments, payouts } } } = props;
        varMergeTaskInfo = mergeTaskInfo;
        varPayments = payments;
        varPayouts = payouts;
    }*/
    if (token.userType === 1)
    {
        history.push('/merge-profile');
    }

    const isExpired = token.isExpired;

    return (
        <div >
            <div>
                <MergeNav active='none' />
            </div>

            <div className={classes.root}>
                {/* How would you like to use Max Merge Solutions? */}
                {token.userType == 2 && <Typography variant="h5" className={classes.mergeHeading}>How Would You Like to Use Max Merge?</Typography>}

                <div style={{ marginTop: "40px", display: "flex", flexDirection: "row" }}>
                    {/* should do a POST to MergeTasks */}

                    {token.userType == 2 && <Link to={{ pathname: '/merge-profile', state: { id: taskId, varIsAfterSave, mergeTaskInfo: varMergeTaskInfo, payments: varPayments, payouts: varPayouts, mergeFiles: true } }} style={{ textDecoration: 'none', marginBottom: 20 }}><Button variant="outlined" className={[classes.options, classes.optionButtonCustom].join(" ")}>{'Merge Files'}</Button></Link>}
                    {token.userType == 1 && <Link to={{ pathname: "/download-pdf", state: { mergeTaskInfo: varMergeTaskInfo, payments: varPayments, payouts: varPayouts } }} style={{ textDecoration: 'none', marginBottom: 20 }}><Button variant="outlined" className={[classes.options, classes.optionButtonCustom].join(" ")}>Merge & Finish</Button></Link>}
                    {token && token.userType == 2 &&
                        <Link to={{ pathname: rePromptSelection ? "/merge-pdf" : '/tutorial-selection', state: { varIsAfterSave, id: taskId, mergeTaskInfo: varMergeTaskInfo, payments: varPayments, payouts: varPayouts, documentPackaging: true } }}><Button variant="outlined" className={[classes.options, classes.optionButtonCustom].join(" ")}>{'Document Packaging'}</Button></Link>
                    }
                    {/* <Link style={{ color: global.black }} to={{ pathname: "merge-docs", state: { mergeTaskInfo, payments } }}><Typography style={{ textDecorationLine: 'underline', fontFamily: global.fontFamily, fontWeight: 600, display: 'flex', justifyContent: 'center' }}>Back</Typography> */}
                    {/* </Link> */}
                </div>
                <Grid justifyContent="center" container style={{ width: '100%', marginTop: "22px" }}><Link style={{ color: global.black }} to={{ pathname: '/', state: varIsAfterSave ? null : { varIsAfterSave, mergeTaskInfo: varMergeTaskInfo, payments: varPayments, payouts: varPayouts } }}><Typography style={{ textDecorationLine: 'underline', fontFamily: global.fontFamily, fontWeight: 600 }}>Back</Typography></Link></Grid>
            </div>
        </div >
    )
}