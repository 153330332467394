import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Container, TextField, Grid, useMediaQuery, List, ListItem } from '@mui/material/';
import { homeLandingUseStyles } from './assets/homeLandingUseStyles';
import { useTheme } from '@mui/material/styles';
import { useAuthContext } from '../Context/AuthContext';
import { Link } from "react-router-dom";
// Component Imports
import { HomeHeader } from './components/HomeHeader/HomeHeader';
import { Categories } from './components/Categories/Categories';
import { HomeFooter } from './components/HomeFooter/HomeFooter';
import { Carousel } from './components/Carousel';

// Interface TS
import { IHomeLanding } from './assets/interfaces/IHomeLanding';
import { global } from '../../global';
import axios from 'axios';
import { IToken } from '../interfaces/IToken';

import { Helmet } from 'react-helmet';

export function HomeLanding(props: IHomeLanding) {
    const { success } = props;
    const { token, setToken, setLoading, logout } = useAuthContext();
    const [selectedTestimonial, setSelectedTestimonial] = useState(1);
    const selected = { fontSize: '18px', fontWeight: 700, borderBottom: `4px solid ${global.primary}`, padding: '21px 28px 12px 28px', fontFamily: global.fontFamily };
    const notSelected = { fontSize: '18px', padding: '21px 28px 12px 28px', borderBottom: `1px solid ${global.black}`, fontFamily: global.fontFamily, cursor: 'pointer' };
    // const [quotes, setQuotes] = useState([]);
    const classes = homeLandingUseStyles();
    const theme = useTheme();
    const mediumBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
    const smallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
    const largeBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

    const changeUserType = (type: number) => {
        setLoading(true);
        axios
            .put(`api/users/${token.id}`, { type })
            .then((result) => {
                setToken((prev: IToken) => ({ ...prev, ...result.data }));
                setLoading(false);
            })
            .catch((e) => { console.log('Put Error:', e); setLoading(false); })
    }

    useEffect(() => {
        if (success && token.email !== null) {
            changeUserType(3)
        }
    }, [token])

    return (

        <Box className={classes.root}>
            <Helmet>
                <title>Max Merge Solutions - Submitting mortgage documents for a faster turnaround.</title>
            </Helmet>

            {/* Header */}
            <HomeHeader />

            {smallBreakpoint ? <img src="https://res.cloudinary.com/synicsoftware/image/upload/v1643132888/w_ummodz.jpg" style={{ width: '100%', height: '240px', objectFit: "cover" }} alt="MMS Landing Page" /> : <img src="https://res.cloudinary.com/synicsoftware/image/upload/v1643132888/w_ummodz.jpg" style={{ width: '100%', height: '660px', objectFit: "cover" }} alt="MMS Landing Page" />}
            <Box className={classes.homeUnderwritter}>
                <Box className={classes.ContainerLg}>
                    <Grid container spacing={4} alignItems={'center'}>
                        <Grid lg={5} md={5} sm={6} xs={12} className={classes.homeUnderwritterTitle}>
                            Less Stress. <br />
                            Quicker Turnaround.
                        </Grid>
                        <Grid direction={'column'} lg={6} md={7} sm={6} xs={12} className={classes.homeUnderwritterParagraph}>
                            <Box mb={2}>
                            An underwriter's inbox consists of hundreds of files, with numerous
                                bulk documents to sort through.
                            </Box>
                            <Box mb={2}>
                            Submit deals that get picked up and reviewed faster, with precise
                                document packaging on one seamless platform.</Box>
                            <Box mb={2}>
                            With our automation, you will save time and maximize efficiency.
                            </Box>

                            <Box mb={2}>
                                Your greatest asset to assemble and prepare documents for submission.
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box className={classes.homeBenefit}>
                <div className={classes.ContainerLg}>
                    <div className={classes.flexColumnAround}>
                        <div className={classes.homeBenefitTitle}>Benefits & Features</div>
                        <Box className={classes.flexAround} flexDirection={{ xs: 'column', md: 'row' }}>
                            <Box className={classes.homeBenefitThumnails} padding={{xs: '30px 15px', md: '15px 15px', lg: '30px 15px'}}>
                                <Box className={classes.homeBenefitThumnailsTitle} fontSize={{ xs: '23px', md: '16px', lg: '21px' }}>Automation</Box>
                                <Box component='ul' marginBottom={{ md: 0 }} paddingLeft='20px'>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Auto-labelling</Box>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Automated Summary Sheet for Portal Uploads </Box>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Automated Emails</Box>
                                </Box>
                            </Box>
                            <Box className={classes.homeBenefitThumnails} padding={{ xs: '30px 15px', md: '15px 15px', lg: '30px 15px' }}>
                                <Box className={classes.homeBenefitThumnailsTitle} fontSize={{ xs: '23px', md: '16px', lg: '21px' }}>Efficiency</Box>
                                <Box component='ul' marginBottom={{ md: 0 }} paddingLeft='20px'>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Easily Sort Bulk Documents</Box>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Auto Totalling of Down Payment  </Box>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Specify File Size for
                                        Portal Uploads</Box>
                                </Box>
                            </Box>
                            <Box className={classes.homeBenefitThumnails} padding={{ xs: '30px 15px', md: '15px 15px', lg: '30px 15px' }}>
                                <Box className={classes.homeBenefitThumnailsTitle} fontSize={{ xs: '23px', md: '14px', lg: '21px' }}>Quicker Approvals</Box>
                                <Box component='ul' marginBottom={{ md: 0 }} paddingLeft='20px'>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Identify & Resolve Discrepancies Before Submitting Documents</Box>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Confirm Income with our Year-to-Date Paystub Calculator</Box>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Comprehensive Presentation Assembly</Box>
                                </Box>
                            </Box>
                            <Box className={classes.homeBenefitThumnails} padding={{ xs: '30px 15px', md: '15px 15px', lg: '30px 15px' }}>
                                <Box className={classes.homeBenefitThumnailsTitle} fontSize={{ xs: '23px', md: '16px', lg: '21px' }}>Optimization</Box>
                                <Box component='ul' marginBottom={{ md: 0 }} paddingLeft='20px'>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Proactive Approach</Box>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Less Wasted Time </Box>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Gain Back Time to
                                        Build Your Business</Box>
                                </Box>
                            </Box>
                            <Box className={classes.homeBenefitThumnails} padding={{ xs: '30px 15px', md: '15px 15px', lg: '30px 15px' }}>
                                <Box className={classes.homeBenefitThumnailsTitle} fontSize={{ xs: '23px', md: '14px', lg: '21px' }}>Increased Revenues</Box>
                                <Box component='ul' marginBottom={{ md: 0 }} paddingLeft='20px'>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Close More Deals</Box>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{ xs: '10px', md: '4px', lg: '10px' }}>Happier Clients & More Referrals </Box>
                                    <Box component='li' className={classes.homeBenefitThumnailsList} fontSize={{ xs: '15px', md: '12px', lg: '15px' }} marginBottom={{xs: '10px', md: '4px', lg: '10px'}}>Work on Multiple Deals Simultaneously</Box>
                                </Box>
                            </Box>

                        </Box>
                    </div>
                </div>
            </Box>
            <div className={classes.homeExplainer}>
                {mediumBreakpoint && (
                    <video controls={true} style={{ width: '60rem' }} src="/explainer.mp4">
                    </video>
                    )
                }
                {smallBreakpoint && (
                      <video controls={true} style={{ width: '30rem' }} src="/explainer.mp4">
                    </video>
                )}
              
            </div>
            {/* Categories */}
            <Categories />

            {/* Third partition on landing page */}
            {mediumBreakpoint && (
                <Box className={classes.third}>
                    <Container className={classes.flexContainer}>
                        <Grid item lg={7} md={7} sm={8} xs={10} style={{ marginBottom: '32px' }}>
                            <Typography style={{ fontWeight: 600, fontSize: '24px', marginBottom: '16px', fontFamily: global.fontFamily, lineHeight: '32px' }} variant="h2">Faster credit decisions. Uncompromising quality. Peace of Mind. </Typography>
                            <Typography style={{ fontWeight: 600, fontSize: '16px', marginBottom: '16px', fontFamily: global.fontFamily, lineHeight: '24px' }} variant="h2">Begin the merge process by sorting bulk documents into mortgage categories and end with an auto-composed summary sheet for your underwriter.</Typography>
                            <Typography style={{ fontWeight: 600, fontSize: '16px', marginBottom: '16px', fontFamily: global.fontFamily, lineHeight: '24px' }} variant="h2">From start to finish, our platform guides you through the document packaging process so you can submit with confidence.</Typography>
                            {/*{token.id ? (<Link to="/merge-docs">
                <Button color='primary' style={{ fontWeight: 600, margin: '0', padding: '5px 20px', borderRadius: '0', textTransform: 'none', fontSize: '18px', width: '182px', height: '52px', fontFamily: global.fontFamily, background: global.primary }} variant="contained">Get started</Button>

              </Link>) : (<Link to="/sign-up">
                <Button color='primary' style={{ fontWeight: 600, margin: '0', padding: '5px 20px', borderRadius: '0', textTransform: 'none', fontSize: '18px', width: '182px', height: '52px', fontFamily: global.fontFamily, background: global.primary }} variant="contained">Get started</Button>

              </Link>)}*/}
                        </Grid>
                    </Container>
                </Box>)}

            {smallBreakpoint && <Box className={classes.thirdSmall}>
                <img src="https://res.cloudinary.com/maxmergesolutions/image/upload/v1637691900/iStock-1163330565_large_version_h4o6lb.jpg" height="141px" width="100%" alt="Year To Date Tracker" />

                <div style={{ padding: 20, paddingTop: 40 }}>
                    <Typography style={{ fontWeight: 600, fontSize: '24px', marginBottom: '16px', fontFamily: global.fontFamily }} variant="h2">Faster credit decisions. Uncompromising quality. Peace of Mind. </Typography>
                    <Typography style={{ fontWeight: 500, fontSize: '16px', marginBottom: '16px', fontFamily: global.fontFamily }} variant="h2">Begin the merge process by sorting bulk documents into mortgage categories and end with an auto-composed summary sheet for your underwriter.</Typography>
                    <Typography style={{ fontWeight: 500, fontSize: '16px', marginBottom: '16px', fontFamily: global.fontFamily }} variant="h2">From start to finish, our platform guides you through the document packaging process so you can submit with confidence.</Typography>

                </div>

            </Box>}

            {/* TO BE ADDED BACK IN AFTER LAUNCH!!!!!!!!!!! */}
            {/* TO BE ADDED BACK IN AFTER LAUNCH!!!!!!!!!!! */}
            {/* TO BE ADDED BACK IN AFTER LAUNCH!!!!!!!!!!! */}
            {/* TO BE ADDED BACK IN AFTER LAUNCH!!!!!!!!!!! */}

            {/* Fourth partition on landing page */}
            {/* <Box className={largeBreakpoint ? classes.fourth : classes.fourthSmallScreenSize}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item lg={6} md={8} sm={10}>
              <Typography style={
                mediumBreakpoint
                  ? { fontWeight: 'bold', fontSize: '40px', marginBottom: '38px', fontFamily: global.fontFamily }
                  : { fontWeight: 'bold', fontSize: '30px', marginBottom: '30px', fontFamily: global.fontFamily }}>
                Featured FYI
              </Typography>
              <Typography style={
                mediumBreakpoint
                  ? { fontSize: '18px', fontFamily: global.fontFamily }
                  : { fontSize: '13px', fontFamily: global.fontFamily }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu mi quis neque accumsan vestibulum eget non est. Maecenas rutrum sapien a faucibus porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu mi quis neque accumsan vestibulum.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box> */}

            {/* Fifth partition on landing page */}
            <Box className={classes.fifth} style={largeBreakpoint ? { display: 'flex' } : { display: 'none' }}>
                <Container style={{ height: '52%' }}>
                    <Typography variant="h2" style={{ fontWeight: 'bold', fontSize: '40px', fontFamily: global.fontFamily, textAlign: 'center' }}>Testimonials</Typography>

                    <Carousel />
                </Container>
            </Box>

            {/* Sixth partition on landing page */}
            <HomeFooter mediumBreakpoint={mediumBreakpoint} />

        </Box >

    )
}
