import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { navBarUseStyles } from './assets/NavBarUseStyles';
import { IStandardProps } from '../interfaces/IStandardProps';
import { global } from '../../global';
import { Link } from 'react-router-dom';
import { useAuthContext } from "../Context/AuthContext";

interface Props {
    color: string | null;
    active: string | null;
}

export function NavBar(props: Props) {
    const { color, active } = props;
    const { token, logout } = useAuthContext();
    const classes = navBarUseStyles();
    console.log('token', token);
    return (
        <div className={color !== 'white' ? classes.main : (active === 'logIn' || active === 'forgotPassword' ? classes.main3 : classes.main2)}>
            <AppBar style={color === 'white' ? { color: '#1B1E1E', height: '90px', background: 'transparent', position: 'static' } : { color: 'white', background: '#1B1E1E', height: '90px' }} color="primary" elevation={0}>
                <Toolbar style={{ height: "90px", marginRight: '11px' }}>
                    <Typography variant="h4" className={classes.title}>
                        {color !== 'white1' && (
                            <Link to="/">
                                <img src={color !== 'white' ? "https://res.cloudinary.com/maxmergesolutions/image/upload/h_100,q_auto,f_auto/v1633540731/fullLogoWhite_vxxqyf.png" : "https://res.cloudinary.com/maxmergesolutions/image/upload/h_100,q_auto,f_auto/v1633539055/fullLogo.png" } alt="Max Merge Solutions" />
                            </Link>
                        )}
                    </Typography>
                    <div>
                        {
                            (window.location.pathname === "/log-in" || window.location.pathname === "/sign-up") &&
                            <Link to="" style={{ marginRight: '15px' }}>
                                <Button style={active === 'home' ? { borderBottom: (color === 'white' ? '1px solid #1B1E1E' : '1px solid white') } : {}} color="inherit" className={color === 'white' ? [classes.buttonCustom, classes.whiteButtonCustom].join(' ') : classes.buttonCustom} >Home</Button>
                            </Link>
                        }
                        <Link style={{ marginRight: '15px' }} to='/about-us'>
                            <Button style={active === 'aboutUs' ? { borderBottom: (color === 'white' ? '1px solid #1B1E1E' : '1px solid white') } : {}} color="inherit" className={color === 'white' ? [classes.buttonCustom, classes.whiteButtonCustom].join(' ') : classes.buttonCustom}>About Us</Button>
                        </Link>
                        <Link style={{ marginRight: '15px' }} to="/subscription">
                            <Button style={active === 'pricing' ? { borderBottom: (color === 'white' ? '1px solid #1B1E1E' : '1px solid white') } : {}} color="inherit" className={color === 'white' ? [classes.buttonCustom, classes.whiteButtonCustom].join(' ') : classes.buttonCustom} >
                                {token?.userType == 1 || token?.userType == 2 ? 'Merge Profile' : 'Pricing'}
                            </Button>
                        </Link>
                        {(!token.email && (window.location.pathname !== "/log-in" && window.location.pathname !== "/sign-up")) && (
                            <>
                                <Link style={{ marginRight: '15px' }} to='/log-in'>
                                    <Button style={active === 'logIn' ? { borderBottom: (color === 'white' ? '1px solid #1B1E1E' : '1px solid white') } : {}} color="inherit" className={color === 'white' ? [classes.buttonCustom, classes.whiteButtonCustom].join(' ') : classes.buttonCustom}>Log In</Button>
                                </Link>

                            </>
                        )}
                        {(token.email && (window.location.pathname !== "/log-in" && window.location.pathname !== "/sign-up")) && (
                            <Button color="primary" variant="contained" className={classes.buttonCustom} onClick={logout} >Log Out</Button>
                        )}
                    </div>

                </Toolbar>
            </AppBar>
        </div>
    );
}