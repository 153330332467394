import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import validator from 'validator';
import { AlertColor } from '@mui/material/Alert';
import axios from "axios";
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthContext } from '../../Context/AuthContext';

import Sidebar from '../../Sidebar/Sidebar';
import { theme } from '../../../appTheme';
import { mainLinks } from '../../Sidebar/Navlinks';


// components
import { NavBar } from '../../NavBar/NavBar';
import { Typography, InputLabel, TextField, Button, IconButton, useMediaQuery } from '@mui/material';

// styling
import { contactUsUseStyles } from '../assets/contactUsUseStyles';
import { HomeFooter } from '../../Home/components/HomeFooter/HomeFooter';
import { formatPhone } from "../assets/formatPhone";
import { Alert } from '@mui/material';

const cleanInfoState = {
  name: "",
  email: "",
  phone: "",
  message: "",
}

export default function ContactUs() {
    const mediumBreakpoint = useMediaQuery(theme.breakpoints.up('md'))

    const classes = contactUsUseStyles();
    const { token, setToken } = useAuthContext()

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("info");
  const [contactInfo, setContactInfo] = useState(cleanInfoState);
    const [error, setError] = useState<string[]>([]);
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

    const [margin, setMargin] = useState("252px");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setInfo("Sending...");
    setSeverity("info");
    setError([]);

    if (handleValidation()) {
      setSeverity("error");
      setLoading(false);
      return;
    }

    axios.post("/api/contact-us", contactInfo).then((res) => {
      if (res.data.success) {
        setContactInfo(cleanInfoState);
        setInfo("Successfully sent! We'll contact you soon.")
        setSeverity("success");
        setLoading(false);
      } else {
        handleError();
      }
    }).catch(() => {
      handleError();
    })
  }

    useEffect(() => {
        setMargin(mediumBreakpoint ? "252px" : "150px");
    }, [mediumBreakpoint])
  const handleError = () => {
    setError(["Oops, something went wrong, please try again later"]);
    setSeverity("error");
    setLoading(false);
  }

  const handleValidation = () => {
    let tempErr = false;

    for (const [key, value] of Object.entries(contactInfo))
      if (!value) {
        setError(prev => [...prev, `${key} is required`]);
        tempErr = true;
      }


    if (contactInfo.email && !validator.isEmail(contactInfo.email)) {
      setError(prev => [...prev, "Email is not valid"]);
      tempErr = true;
    }

    if (contactInfo.phone && contactInfo.phone.length !== 14) {
      setError(prev => [...prev, "Phone has to be 10 digits long"]);
      tempErr = true;
    }

    return tempErr;
  }

  return (
    <div className={classes.body}>

      <Helmet>
        <title>Contact Us | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
      </Helmet>

         
              <NavBar active="" color="black" />
          
          

      {/* header */}
      <div className={classes.header}>
        <Typography className={classes.title}>
          Contact Us
        </Typography>
        <p>
          Have questions? Get in touch.
        </p>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
        {/* main form */}
        <div style={{ width: '55%' }}>
                  <div style={{ background: "url('https://res.cloudinary.com/maxmergesolutions/image/upload/v1638813688/g12_ftnf4d.png')", position: 'absolute', right: 100, bottom: '13%', width: '500px', height: '230px', backgroundSize: 'cover' }}></div>
          <form noValidate autoComplete="off" className={classes.form}  spellCheck={false} autoCapitalize="off" autoCorrect="off">
            {/* full name */}
            <InputLabel style={{ fontWeight: 600, color: '#fff' }} id="fullName">Full Name</InputLabel>
            <TextField
              // className={classes.input}
              style={{ width: '100%', marginBottom: '30px' }}
              required={true}
              id="standard-basic"
              variant="standard"
              placeholder="Full Name"
              onChange={e => setContactInfo({ ...contactInfo, name: e.target.value })}
              value={contactInfo.name}
              InputLabelProps={{ style: { color: '#fff' } }}
              InputProps={{ className: classes.textField }}
            />

            <InputLabel style={{ fontWeight: 600, color: '#fff' }} id="email">Email</InputLabel>
            <TextField
              // className={classes.input}
              style={{ width: '100%', marginBottom: '30px' }}
              required={true}
              id="standard-basic"
              variant="standard"
              placeholder="johndoe@email.com"
              onChange={e => setContactInfo({ ...contactInfo, email: e.target.value })}
              value={contactInfo.email}
              InputLabelProps={{ style: { color: '#fff' } }}
              InputProps={{ className: classes.textField }}
            />

            <InputLabel style={{ fontWeight: 600, color: '#fff' }} id="phone">Phone Number</InputLabel>
            <TextField
              // className={classes.input}
              style={{ width: '100%', marginBottom: '30px' }}
              required={true}
              id="standard-basic"
              variant="standard"
              placeholder="604-123-4567"
              onChange={e => setContactInfo({ ...contactInfo, phone: formatPhone(e.target.value) })}
              value={contactInfo.phone}
              InputLabelProps={{ style: { color: '#fff' } }}
              InputProps={{ className: classes.textField }}
            />

            <InputLabel style={{ fontWeight: 600, color: '#fff' }} id="message">Message</InputLabel>
            <TextField
              // className={classes.input}
              style={{ width: '100%', marginBottom: '30px' }}
              required={true}
              id="standard-basic"
              variant="standard"
              placeholder="Type your message here"
              rows={5}
              onChange={e => setContactInfo({ ...contactInfo, message: e.target.value })}
              value={contactInfo.message}
              InputLabelProps={{ style: { color: '#fff' } }}
              InputProps={{ className: classes.textField }}
            />
          </form>
          {
            info &&
            <Alert severity={severity} className={classes.alert}>
              {
                error?.length > 0 ?
                  (
                    error?.map((err, key) => {
                      return <p key={key}>{err}</p>
                    })
                  )
                  :
                  <p>
                    {info}
                  </p>
              }
            </Alert>
          }
        </div>
        <Button variant="contained" disableElevation className={classes.contact} onClick={handleSubmit} disabled={loading}>Send</Button>
      </div>

      <HomeFooter mediumBreakpoint />

    </div>
  );
}