import React, { useState } from 'react';
import validator from 'validator';
import { Helmet } from 'react-helmet';
import axios from "axios";

// components
import { NavBar } from '../../NavBar/NavBar';
import { Typography, Button, Dialog, DialogContent, DialogTitle, InputLabel, TextField } from '@mui/material';
import { HomeFooter } from '../../Home/components/HomeFooter/HomeFooter';

const cleanInfoState = ({
    name: "",
    email: ""
})

export default function Explainer() {
    return (
        <div style={{
            flexGrow: 1,
            height: '100vh',
            position: 'relative',
            zIndex: 10
        }}>
            <Helmet>
                <title>Explainer Video</title>
            </Helmet>

            <NavBar active="" color="black" />

            <div style={{
                margin: 'auto',
                backgroundColor: 'white',
                marginTop: '4%',
                padding: '5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1
            }}>
                <video controls={true} style={{width:'60rem' }} src="/explainer.mp4">
                </video>
            </div>
            <HomeFooter mediumBreakpoint />
        </div>
    )
}