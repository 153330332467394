// @flow
import React, { useState } from 'react';
import { Typography, Button, Container, TextField, Grid, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { homeFooterUseStyles } from './homeFooterUseStyles';
import { global } from '../../../../global';
import validator from "validator";
import axios from "axios";
import { theme } from '../../../../appTheme';
import Checkbox from '@mui/material/Checkbox';

interface IHomeFooter {
    mediumBreakpoint: boolean;
}

export function HomeFooter(props: IHomeFooter) {
    const { mediumBreakpoint } = props;
    const classes = homeFooterUseStyles();
    const [checked, setChecked] = useState(false);

    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [notice, setNotice] = useState('');
    const [error, setError] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        setError(false);
        setNotice("");
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };
        if (firstName && email && validator.isEmail(email)) {

            if (!checked) {
                setError(true);
                setNotice("The checkbox should be checked to proceed");
                setLoading(false);
            }
            else {
                axios.post("/api/newsletter", { Email: email, Name: firstName }, axiosConfig).then((resp) => {
                    if (resp.data.success) {
                        setEmail(email);
                        setLoading(false);
                        setNotice("Success! We'll keep you updated.");
                    } else {
                        setError(true);
                        setNotice("Oops, something went wrong, try again later.");
                        setLoading(false);
                    }
                }).catch(() => {
                    setError(true);
                    setNotice("Oops, something went wrong, try again later.");
                    setLoading(false);
                })
            }
        } else {
            setError(true);
            setNotice("Your first name and a valid email is required");
            setLoading(false);
        }
    }

    return (
        <Grid item xs={12} style={{ paddingTop: "103px" }} container className={classes.sixth}>
            <Container style={{ color: '#fff' }}>
                <Grid item xs={12} style={{ height: '100%' }} container>
                    <Grid item xs={12} md={5} lg={6} container alignItems="start">
                        <Grid item xs={12} md={5} direction="column" alignItems="flex-start" container>
                            <Typography style={{ fontWeight: 'bold', marginBottom: '6px', fontFamily: global.fontFamily, color: '#00939F' }}>Resources</Typography>
                            {window.location.pathname != "/" &&
                                <Link className={classes.link} to="/">Home</Link>}
                            <a className={classes.link} target="_blank" href="https://calendly.com/max-merge-solutions" >Book a Demo</a>
                            <Link className={classes.link} target="_blank" to="/explainer">Explainer Video</Link>
                            <Link className={classes.link} target="_blank" to="/how-to-package-documents">Tutorial Video</Link>
                            <Link className={classes.link} target="_blank" to="/contact-us">Contact Us</Link>
                        </Grid>
                        <Grid item xs={12} md={5} direction="column" alignItems="flex-start" container>
                            <Typography style={{ fontWeight: 'bold', marginBottom: '6px', fontFamily: global.fontFamily, color: '#00939F' }}>Features</Typography>
                            <Link to="/merge-profile" className={classes.link}>
                                Merge Documents
                            </Link>
                            <Link to="/split-docs" className={classes.link}>
                                Split Documents
                            </Link>
                            <Link to="/condense-docs" className={classes.link}>
                                Condense Documents
                            </Link>
                            <Link to="/ytd-tracker" className={classes.link}>
                                Year to Date Calculator
                            </Link>
                        </Grid>
                    </Grid>

                    {mediumBreakpoint ? (
                        <>
                            <Grid item xs={8} md={7} lg={6} container alignItems="center">
                                <Grid item xs={12} md={12} style={mediumBreakpoint ? { height: 'auto' } : { height: 'auto' }} direction="row" alignItems="center" justifyContent="flex-start" container>
                                    <Typography style={{ fontSize: '32px', marginBottom: '20px', fontFamily: 'Noto, sans-serif' }}>Join our growing community to receive insightful newsletters and resources.</Typography>
                                    {/* <TextField className={classes.textField} InputLabelProps={{ style: { color: global.neutral, fontFamily: global.fontFamily } }} color="secondary" label="EMAIL ADDRESS" InputProps={{ className: classes.textField }} /> */}
                                    <TextField variant="standard" placeholder="First Name" InputLabelProps={{ style: { color: '#fff', fontFamily: global.fontFamily } }} InputProps={{ className: classes.textField }} value={firstName} onChange={(e) => setFirstName(e.target.value)} style={{ marginBottom: 24 }} />
                                    <TextField variant="standard" placeholder="Email Address" InputLabelProps={{ style: { color: '#fff', fontFamily: global.fontFamily } }} InputProps={{ className: classes.textField }} value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {
                                        notice && <div><p style={{ marginTop: "10px" }} className={error ? classes.invalid : classes.valid}>{notice}</p></div>
                                    }
                                </Grid>
                                <Grid item xs={12} md={12} style={mediumBreakpoint ? { height: '150px', marginTop: '15px' } : { height: '50px', marginTop:'15px' }} alignItems="flex-end" container>
                                    <p><Checkbox value={checked} style={{
                                        color: "white",
                                        paddingLeft: '0px',
                                    }} onChange={(event, checked) => { setChecked(checked)}} required={true} />Yes, I consent to receiving marketing communications from Max Merge Solutions. Our <a href="/privacy-policy" target="_blank"><u>Privacy Policy</u></a> details our information practices. You can opt-out at any time.</p>                                    
                                    <Button color='primary' className={classes.signUp} onClick={handleSubmit} disabled={loading} variant="contained">Subscribe</Button>
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" justifyContent="center" flexDirection="column" style={{ marginTop: "103px", marginBottom: "5%" }}>
                                <div><Typography style={{ fontWeight: 500, fontSize: "14px", marginBottom: '6px', fontFamily: global.fontFamily, display: 'block' }}>Max Merge Solutions Inc. || 1200 - 805 West Broadway, Vancouver, B.C., V5Z 1K1, Canada || 1-855-MY-MERGE (1-855-696-3743) (M-F: 9AM - 5PM PST )</Typography></div>
                                <div><Typography style={{ fontWeight: 500, fontSize: "14px", marginBottom: '6px', fontFamily: global.fontFamily, display: 'block' }}><a href="/terms-of-service">Terms and Conditions</a> || <a href="/privacy-policy">Privacy Policy</a></Typography></div>
                                <div><Typography style={{ fontWeight: 500, fontSize: "14px", marginBottom: '6px', fontFamily: global.fontFamily, display: 'block' }}>Copyright @ 2022 - Max Merge Solutions Inc., All Rights Reserved.</Typography></div>
                            </Grid>
                        </>
                    )
                        : <></>}
                </Grid>
            </Container>
        </Grid >
    );
};