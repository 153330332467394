import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const yearToDateStyles = makeStyles((theme) =>
  createStyles({
    main: {
      flexGrow: 1,
      height: '84px',
      position: "fixed",
      zIndex: 10
    },
    root: {
      width: '100%',
      height: '50%',
      margin: 'auto auto',
      paddingTop: "180px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      textAlign: 'center',
      overflow: 'hidden'
    },
    ytdHeader: {
      marginTop: '65px',
      height: '112px',
      backgroundColor: '#1B1E1E',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0
    },
    headerText: {
      color: "#ffffff",
      fontWeight: "bold",
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      fontSize:"24px"
      // marginTop: '3vh'
    },
    ytdBody: {
      marginTop: '5vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'justify-start',
    },
    calculateButtonDiv: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginTop: '2vh',
      height: '100%',
      width: '100%'
    },
    form: {
      display: 'inline-flex',
      marginTop: '0.5vh',
      fontSize: '17px',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'spaceBetween',
      alignItems: 'center',
      textAlign: 'left',
      color: '#1B1E1E'
    },
    input: {
      marginBottom: '30px',
      width: '100%',
      borderRadius: 0,
      border: '#000 1px solid',
      'MuiMenuItem-root:hover': {
        backgroundColor: 'none! important',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1B1E1E',
        backgroundColor: 'none! important'
      },
      '&:before': {
        borderColor: '#1B1E1E',
      },
      '&:after': {
        borderColor: '#1B1E1E',
      },
    },
    dropdownStyle: {
      width: 520,
      marginTop: 10,
      boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 12%), 0px 8px 10px 1px rgb(0 0 0 / 8%), 0px 3px 14px 2px rgb(0 0 0 / 8%)'
    },
    projectedEarnings: {
      width: '100%',
      height: 'fit-content',
      padding: 15,
      // height: 200,
      backgroundColor: '#1B1E1E',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 0,
      marginBottom:20
    },
    calculateButton: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '15px',
      borderRadius: '0px',
      borderWidth: '1px',
      borderColor: '#00939F',
      width: '186px',
      height: '52px',
      backgroundColor: '#00939F',
      color: '#fff',
      '&:focus': {
        outline: '0!important',
        textDecoration: 'none'
      },
      '&:hover': {
        backgroundColor: '#00939F! important'
      }
    },

    backButton: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '15px',
      borderRadius: '0px',
      borderWidth: '1px',
      borderColor: '#000000',
      width: '186px',
      height: '52px',
      backgroundColor: '#fffffff',
      color: '#000000',
      marginRight: '1em',
      '&:focus': {
        outline: '0!important',
        textDecoration: 'none'
      }
    },
    finish: {
      borderColor: '#000000',
      color: "#000000",
      fontSize: '16px',
      width: '186px',
      height: '52px',
      fontWeight: 600,
      '&:focus': {
        outline: '0!important',
        textDecoration: 'none'
      }
    }
  }),
);