// @flow
import React, { useState } from 'react';
import { global } from '../../../../global';
import { Box, Container, Grid, Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import { DocumentList } from '../DocumentList/DocumentList';
import { useAuthContext } from '../../../Context/AuthContext'

import { categoriesUseStyles } from './categoriesUseStyles';
import { CategoryText } from './CategoryText';
import { Link } from "react-router-dom";

export function Categories() {
  const { token } = useAuthContext();
  const classes = categoriesUseStyles();
  const theme = useTheme();
  const largeBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
  const smallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const [category, setCategory] = useState(0);
  return (
    <Box className={largeBreakpoint ? classes.second : classes.secondSmallScreenSize}>
      <Container className={classes.secondContainer} >
        {smallBreakpoint ? <></> : <Typography className={classes.secondHeading} variant="h2">Sort &amp; Merge Bulk Documents on One Simple Platform</Typography>}
        <Grid container spacing={10}>
          <Grid item container justifyContent="center" xs={12} lg={6} style={{ paddingTop: 0 }}>
            <Box className={classes.documentList}>
              <DocumentList setCategory={setCategory} category={category} />
            </Box>
          </Grid>
          {smallBreakpoint ? <></> : (
            <Grid item xs={12} lg={6} style={{
              alignItems: 'end', marginBottom: '7px'
            }} className={largeBreakpoint ? classes.secondTextContent : [classes.secondTextContent, classes.secondTextContentSmallScreenSize].join(' ')}>
              <CategoryText category={category} />
              {token.id ? (<Link to="/merge-profile">
                <Button color='primary' style={{ fontWeight: 600, margin: '0', padding: '5px 20px', borderRadius: '0', textTransform: 'none', fontSize: '18px', width: '182px', height: '52px', fontFamily: global.fontFamily, background: global.primary }} variant="contained">Get started</Button>

              </Link>) : (<Link to="/sign-up">
                <Button color='primary' style={{ fontWeight: 600, margin: '0', padding: '5px 20px', borderRadius: '0', textTransform: 'none', fontSize: '18px', width: '182px', height: '52px', fontFamily: global.fontFamily, background: global.primary }} variant="contained">Get started</Button>

              </Link>)}
            </Grid>
          )}
        </Grid>
      </Container>
    </Box >
  );
};