// @flow
import { Grid, TextField, Typography, Button, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../Context/AuthContext';
import { IStandardProps } from '../../interfaces/IStandardProps';
import { accountUseStyles } from '../assets/accountingUseStyles';
import { premiumFeatures, standardFeatures } from '../../SubscriptionPage/assets/features';
import { IToken } from '../../interfaces/IToken';
import axios from 'axios';
import EditPaymentMethodModal from './EditPaymentMethodModal';
import { Link } from 'react-router-dom';
const qs = require('querystring');

/*const standard = 'price_1KWTGvALbh1IzL7wKtoHJsTH';
const premium = 'price_1KWTGMALbh1IzL7wkVWeI40k';
*/
export function Account() {
    const { token, setToken } = useAuthContext();
    const classes = accountUseStyles();
    const [formState, setFormState] = useState({
        firstName: token.firstName,
        lastName: token.lastName,
        email: token.email,
        userType: token.userType,
    })
    const [paymentMethod, setPaymentMethod] = useState({
        id: null,
        brand: null,
        number: null,
        expMonth: null,
        expYear: null,
        status: null,
    })
    const [subscriptionType, setSubscriptionType] = useState({
        id: null,
        name: null,
        price: null,
        features: null
    })
    const [error, setError] = useState<string | null>(null);
    const [saving, setSaving] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [invoiceDate, setInvoiceDate] = useState<null | Date>(null)
    const [unsubscribeModal, setUnsubscribeModal] = useState<boolean>(false);
    const [editPaymentMethodModal, setEditPaymentMethodModal] = useState<boolean>(false);
    // const features = 
    const createFeatureList = (type: any) => {
        return type === 2
            ? premiumFeatures.map((feature: string) => <li className={classes.listItem}>{feature}</li>)
            : standardFeatures.map((feature: string) => <li className={classes.listItem}>{feature}</li>)
    }

    const updatePaymentMethod = (id: number) => {
        axios
            .post(`api/payment-methods/${id}`, {
                customerId: token.customerId,
                paymentMethodId: paymentMethod.id
            })
    }

    const getSubscription = () => {
        axios
            .get(`api/subscriptions`)
            .then((result) => {
                console.log(result);

                if (!result.data.subscription.freeTrial) {
                    const { brand, card, expMonth, expYear } = result.data?.subscription?.paymentMethod;
                    const { currentPeriodEnd, status, userType } = result.data.subscription;
               
                    setPaymentMethod((prev) => ({ ...prev, id: brand, number: card, expMonth, expYear, status }))
                    const date = new Date(currentPeriodEnd)
                    setInvoiceDate(date);

                    setSubscriptionType((prev) => ({ ...prev, id: userType.id, price: userType.price, name: userType.name }))
                }
                else
                {
                    const { userType } = result.data.subscription;
                    console.log('usertype', userType)
                    setSubscriptionType((prev) => ({ ...prev, id: userType.id, name: userType.name, price: userType.price, features: userType.features }))
                }
            })
            .catch((e) => console.log('error in getSubscription:', e))
    }

    const getUpcomingInvoice = () => {
        axios
            .get(`api/subscriptions/invoices`)
            .then((result) => {
                // const card = result.data[0].card;

                console.log('result from getUpcomingInvoice:', result);
                const date = new Date(result.data.nextPaymentAttempt)
                setInvoiceDate(date);
                // setSubscription((prev) => ({ ...prev, brand: card.brand, number: card.last4, expMonth: card.expMonth, expYear: card.expYear }));
            })
            .catch((e) => console.log('error in getUpcomingInvoice:', e))
    }

    const updateUser = () => {
        setSaving('Updating account information...')
        axios
            .put(`api/users/${token.id}`, {
                ...token,
                firstName: formState.firstName,
                lastName: formState.lastName,
                email: formState.email,
                userType: formState.userType,
            })
            .then(result => {
                setSuccess('Update completed!');
                setSaving(null);
                setTimeout(() => {
                    setSuccess(null);
                }, 1500)
                setToken((prev: IToken) => ({ ...prev, ...result.data }))
            })
            .catch(e => {
                console.log('error updating user in settings:', e.response.data)
                setSaving(null);
                if (e.response.data.status === 400) {
                    setError('Name and email address are the same.')
                } else {
                    setError('Error updating user.')
                }
                setTimeout(() => {
                    setError(null);
                }, 2000)
            })
    }

    useEffect(() => {
        getSubscription()
    }, [])

    return (
        <>
            <EditPaymentMethodModal editPaymentMethodModal={editPaymentMethodModal} setEditPaymentMethodModal={setEditPaymentMethodModal} />
            <Grid item container xs={12}>
                <Grid container item lg={6} md={9} sm={12} spacing={6}>
                    <Grid item lg={6} md={9} xs={12}>
                        <TextField fullWidth label="First Name" variant="standard" inputProps={{ spellCheck: "false" }} value={formState.firstName} onChange={(e) => setFormState((prev) => ({ ...prev, firstName: e.target.value }))} />
                    </Grid>
                    <Grid item lg={6} md={9} xs={12}>
                        <TextField fullWidth label="Last name" variant="standard" inputProps={{ spellCheck: "false" }} value={formState.lastName} onChange={(e) => setFormState((prev) => ({ ...prev, lastName: e.target.value }))} />
                    </Grid>
                    <Grid item container lg={12} md={9} xs={12}>
                        <TextField style={{ marginBottom: 20 }} fullWidth label="Email Address" variant="standard" inputProps={{ spellCheck: "false" }} value={formState.email} onChange={(e) => setFormState((prev) => ({ ...prev, email: e.target.value }))} />
                        {saving === 'Updating account information...' && <Alert style={{ width: '100%' }} severity="info">{saving}</Alert>}
                        {success && <Alert style={{ width: '100%' }} severity="success">{success}</Alert>}
                        {error === 'Error updating user.' || error === 'Name and email address are the same.' && <Alert style={{ width: '100%' }} severity="warning">{error}</Alert>}
                        <Button style={{ marginTop: 30 }} className={classes.button} onClick={updateUser}>Save</Button>
                    </Grid>
                    <Grid item lg={12} md={9} xs={12}>
                        <Typography style={{ borderBottom: '1px solid #6a6a6a', paddingBottom: '5px', marginBottom: '24px', fontWeight: 'bold' }}>Current Plan</Typography>
                        <Grid item container justifyContent="space-between" xs={12}>
                            <Typography style={{ paddingBottom: '5px', fontWeight: 'bold' }}>{subscriptionType.name}</Typography>
                            <Link to="/change-subscription"><Button className={classes.button} onClick={() => { }}>Change</Button></Link>
                        </Grid>
                        <Grid container alignItems="center" style={{ marginBottom: '8px' }}>
                            <Typography style={{ marginRight: '5px', fontWeight: 'bold' }}>{subscriptionType.price}</Typography>
                            <Typography style={{ fontSize: '15px' }}>per month plus applicable taxes.</Typography>
                        </Grid>
                        <Grid item container justifyContent="space-between" xs={12}>
                            <Typography style={{ fontWeight: 'bold', marginBottom: '8px' }}>Payment Method:</Typography>
                            <Button className={classes.button} onClick={() => { setEditPaymentMethodModal(true) }}>Edit</Button>
                        </Grid>
                        <Grid item style={{ marginBottom: '38px' }} container justifyContent="space-between" lg={6} md={6} sm={6} xs={12}>
                            <Typography>{paymentMethod.number}</Typography>
                            <Typography>Expiry Date: {paymentMethod.expMonth}/{paymentMethod.expYear}</Typography>
                        </Grid>
                        <Grid alignItems="center" justifyContent="space-between" container style={{ marginBottom: 38 }}>
                            {invoiceDate && <Typography style={{ fontWeight: 500 }}>{paymentMethod.status === 'canceled' ? `Your plan expires on ${invoiceDate.getDate()}/${invoiceDate.getMonth() === 0 ? 12 : invoiceDate.getMonth() + 1}/${invoiceDate.getFullYear()}.` : `Your plan will auto renew on the ${invoiceDate.getDate()}th of each month.`}</Typography>}
                            {paymentMethod.status === 'canceled' && <Link to={{ pathname: "/change-subscription" }}><Button className={classes.button}>Renew Subscription</Button></Link>}
                        </Grid>
                        <Typography style={{ fontWeight: 600, marginBottom: '15px' }}>Plan Includes:</Typography>
                        <ul style={{ marginBottom: '25px', }}>
                            {createFeatureList(subscriptionType.id)}
                        </ul>
                        {console.log('setsubscriptiontyp', subscriptionType)}
                        {paymentMethod.status !== 'canceled' && <Link to="cancel-subscription"><Button className={classes.button}>Cancel Subscription</Button></Link>}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};