import React, { useState } from 'react';
import validator from 'validator';
import { MergeNav } from '../../MergeProcess/components/MergeNav';

import { Helmet } from 'react-helmet';
import axios from "axios";
// styling
import { noAccessUseStyles } from '../assets/noAccessUseStyles';
import { Route, Switch, useHistory, Redirect, Prompt } from "react-router";

// components
import { NavBar } from '../../NavBar/NavBar';
import { Typography, Button, Dialog, DialogContent, DialogTitle, InputLabel, TextField, Container } from '@mui/material';
import { HomeFooter } from '../../Home/components/HomeFooter/HomeFooter';

const cleanInfoState = ({
    name: "",
    email: ""
})

export default function NoAccess() {
    const classes = noAccessUseStyles();
    const history = useHistory();

    const handleSubscribe = () => {
        history.push('/settings');
    };



    return (
        <div className={classes.root}>
            <Helmet>
                <title>No Access</title>
            </Helmet>

            <MergeNav />

            <div className={classes.body}>
                <div style={{ marginTop: 0, width: '100%' }}>
                    <div className={classes.splitDocumentsHeader} >
                        <Container style={{ padding: 0 }}>
                            <Typography variant="h5" className={classes.headerText}>Year to Date Pay Stub Tracker</Typography>
                        </Container>
                    </div >

                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%', background: 'white', marginTop: '5rem', marginBottom: '8rem' }}>
                        <div className={classes.noAccessContainer}>
                         
                            <p style={{fontSize:'18px',lineHeight:'20px'}}>
                                To access the YTD Tracker, please subscribe 
                            </p>
                            <p style={{ fontSize: '18px', lineHeight: '20px' }}>
                                for the premium package.
                                </p>

                            <div>
                                <Button variant="contained" disableElevation disableRipple className={classes.contact} onClick={() => handleSubscribe()}>Switch Plans</Button>
                            </div>
                        </div>
                    </div>

                    {/* modal stuff */}
                </div>
            </div>
            <HomeFooter mediumBreakpoint />
        </div>
    )
}