// @flow
import React, { useState, useEffect } from 'react';
import { Button, Typography, Grid, Box, useTheme, useMediaQuery, IconButton } from '@mui/material';
import { homeHeaderUseStyles } from './homeHeaderUseStyles';
import { Link } from 'react-router-dom';
// component imports
import { global } from '../../../../global';
import { useAuthContext } from "../../../Context/AuthContext"
import Sidebar from "../../../Sidebar/Sidebar";
import { mainLinks } from "../../../Sidebar/Navlinks";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export function HomeHeader(props: any) {
    const { logout, token } = useAuthContext();
    const theme = useTheme();
    const largeBreakpoint = useMediaQuery(theme.breakpoints.up('xl'))
    const mediumBreakpoint = useMediaQuery(theme.breakpoints.up('lg'))
    const smallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = homeHeaderUseStyles();
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [margin, setMargin] = useState("252px");

    useEffect(() => {
        setMargin(mediumBreakpoint ? "252px" : "150px");
    }, [mediumBreakpoint])

    return (
        <>
            <Grid container className={mediumBreakpoint ? classes.first : classes.firstSmallScreenSize}>
                {mediumBreakpoint ? <Grid item xs={1} md={4}></Grid> : <></>}

                <Grid item xs={8} md={4} className={classes.headerContent}>
                    {!token?.email && (
                        <Link to="/">
                            <img className={mediumBreakpoint ? classes.fullLogo : classes.fullLogoSmall} src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_64,q_auto,f_auto/v1633540731/fullLogoWhite_vxxqyf.png" alt="Max Merge Logo" />
                        </Link>
                    )}
                    {token?.email && (
                        <Link to="/merge-options">
                            <img className={mediumBreakpoint ? classes.fullLogo : classes.fullLogoSmall} src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_64,q_auto,f_auto/v1633540731/fullLogoWhite_vxxqyf.png" alt="Max Merge Logo" />
                        </Link>
                    )}
                    {mediumBreakpoint && <Typography className={classes.firstMainText} variant="h4">Curated for mortgage professionals to collate and assemble documents.</Typography>}
                </Grid>
                <Grid justifyContent="flex-end" item xs={4} md={4} className={mediumBreakpoint ? classes.navContainer : classes.navContainerSmall} >
                    {mediumBreakpoint && (
                        <Box className={classes.innerNavContainer}>
                            <Link to="/about-us">
                                <Button color="inherit" variant="text" className={classes.buttonCustom} style={{ whiteSpace: 'nowrap' }}>About Us</Button>
                            </Link>
                            <Link to="/subscription">
                                <Button color="inherit" variant="text" className={classes.buttonCustom} >{token?.userType == 1 || token?.userType == 2 ? 'Merge Profile' : 'Pricing'}</Button>
                            </Link>
                            {!token?.email && (
                                <>
                                    <Link to="/log-in">
                                        <Button color="inherit" className={classes.buttonCustom} style={{ whiteSpace: 'nowrap' }}>Log In</Button>
                                    </Link>
                                    <Link to="/sign-up">
                                        <Button color="primary" variant="contained" className={classes.buttonCustom} style={{ background: global.primary, whiteSpace: 'nowrap' }} >Sign Up</Button>
                                    </Link>
                                </>
                            )}
                            {token?.email && (
                                <Button color="primary" variant="contained" className={classes.buttonCustom} style={{ background: global.primary, whiteSpace: 'nowrap' }} onClick={logout} >Log Out</Button>
                            )}
                        </Box>
                    )}
                    {!mediumBreakpoint && (
                        <>
                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                                <IconButton onClick={() => setSidebarOpen(!sidebarOpen)} className="merge-menu-btn menu-btn-large" style={{ marginRight: 0, fontSize: 15 }}>
                                    {
                                        !sidebarOpen ? <MenuIcon fontSize="small" /> : <CloseIcon />
                                    }
                                </IconButton>
                            </div>
                            <Sidebar sidebarOpen={sidebarOpen} links={mainLinks} margin={margin} position="absolute" />
                        </>
                    )}
                </Grid>

                {smallBreakpoint ?
                    <Grid item xs={12} style={{ paddingTop: 20 }} >
                        {smallBreakpoint && <Typography style={{ fontSize: 15, textAlign: 'center' }} variant="h6">Curated for mortgage professionals to collate and assemble documents.</Typography>}
                    </Grid>
                    :
                    <></>
                }
            </Grid>
        </>
    );
};