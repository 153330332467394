// @flow
import React, { useState } from 'react';
import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import CancelSubscriptionOptions from './CancelSubscriptionOptions';
import { MergeNav } from '../../MergeProcess/components/MergeNav';
import { global } from '../../../global';
import axios from 'axios';
interface Props {

};
export function CancelSubscription(props: Props) {
    const [selection, setSelection] = useState<string | null>(null);
    const [other, setOther] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();
    const unsubscribe = () => {
        setLoading(true)
        axios
            .delete(`api/subscriptions/`, {
                data:
                {
                    CancelReason: selection
                }
            }
            )
            .then((result) => {
                console.log('deleteSubscriptionsResult:', result);
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 750)
                // if (result.data.subscription.status === 'canceled') {
                //     axios
                //         .put(`api/users/${token.id}`, {
                //             ...token,
                //             userType: 4,
                //             subscriptionId: null
                //         })
                //         .then((result) => {
                //             // setToken((prev: IToken) => ({ ...prev, userType: 4, subscriptionId: null }));
                //         })
                // } else {
                //     setError(true);
                // }
                setTimeout(() => {
                    setError(false);
                    setSuccess(false);
                }, error ? 2000 : 750)
                setTimeout(() => {
                    history.push('/goodbye');
                }, 3000)
            })
            .catch((e) => {
                setLoading(false);
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000)
            })
    }
    return (
        <Container>
            <MergeNav active={null} />
            <Grid alignItems="center" style={{ height: '100vh' }} container>
                <Grid direction="column" container item>
                    <Typography style={{ fontSize: 32, marginBottom: 28, fontWeight: 700 }}>Cancel Subscription</Typography>
                    <Typography style={{ fontSize: 18, marginBottom: 62 }}>Before you go, we would love some feedback to improve our software</Typography>
                    <CancelSubscriptionOptions selection={selection} setSelection={setSelection} other={other} setOther={setOther} />
                    {error && <Alert severity="error">Error canceling subscription</Alert>}
                    {success && <Alert severity="success">Subscription successfully canceled</Alert>}
                    {loading && <Alert severity="info">Canceling subscription...</Alert>}
                    <Grid container >
                        <Button onClick={unsubscribe} variant="contained" style={{ background: global.black, color: '#fff', marginRight: 25, width: 220, height: 52, marginBottom: 25 }}>Cancel Subscription</Button>
                        <Button variant="outlined" style={{ color: global.black, borderColor: global.black, width: 220, height: 52, marginBottom: 25 }}>No, Never Mind</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};