import React from 'react'
import styled from "styled-components"
import { Container } from "@mui/material";
import Form from './Form';

const EndingBanner = () => {
    return (
        <EndingBannerWrapper>
            <Container className="container small-container">
                <img src="https://res.cloudinary.com/maxmergesolutions/image/upload/h_130/LandingPageAssets/coffee2_byieye.png" alt="coffee" />
               <div className="body-container">
                   <div className="info-text">
                        <h2>
                            Get Started with Max <br/> Merge Solutions
                        </h2>
                        <p>
                            Be one the first 30 mortgage professionals to get exclusive discount and access and the latest news on our product directly in your inbox.
                        </p>
                   </div>
                   <div className="form-container">
                        <Form />
                   </div>
               </div>
            </Container>
            <div className="bg-2">
            </div>
        </EndingBannerWrapper>
    )
}

const EndingBannerWrapper = styled.div`
    background-color: #1B1E1E;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 1000px) {
        .form-container {
            display: grid;
            align-items: center;
            padding-top: 30px;
        }
    }

    h2 {
        color: white;
        font-size: 30;
        font-weight: 600;
        letter-spacing: 2px;
        margin-bottom: 40px;
    }

    p {
        color: white;
        line-height: 1.5;
        font-size: 17px;
        margin-bottom: 40px;
    }

    @media screen and (min-width: 1000px) {
        .body-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-left: 100px;
            gap: 100px;
            .info-text {
                text-align: end;
            }
            h2 {
                font-size: 36px;
                line-height: 1.7;
            }
            p {
                font-size: 18px;
                line-height: 1.7;
                margin-bottom: 0;
            }
        }
    }

    .container {
        padding: 60px 0;
        z-index: 2;
        position: relative;
    }
    
    .bg-2 {
        position: absolute;
        height: 100%;
        width: 50%;
        right: 0;
        top: 0;
        z-index: -1;
    }

    img {
        display: none;
    }

    @media screen and (min-width: 1000px) {
        .bg-2, .container {
            background-color: #1B1E1E;
        }
        background-color: #d6d6d6;

        img {
            display: block;
            height: 130px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-52%, -50%);
        }
        
    }
`

export default EndingBanner
