import { createStyles, makeStyles } from "@mui/styles";
export const settingsUseStyles = makeStyles(() =>
    createStyles({
        settingsContainer: {
            marginTop: '146px',
            marginBottom: '146px'
        },

        account: {
            // width: '115px'
        },

        password: {
            // width: '135px'
        },
    })
);