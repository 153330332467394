import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { global } from '../../global';

interface ICustomTextField {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    label: string | null;
    placeholder: string | undefined;
    required?: boolean;
    isSubmitted?: boolean;
}

export default function CustomTextField(props: ICustomTextField) {
    const { onChange, label, placeholder, required, isSubmitted } = props;

    return (
        <FormControl style={{ marginBottom: 10, width: '100%' }} variant="standard" required={required}>
            <label style={{ marginBottom: 10, fontSize: '18px', fontFamily: global.fontFamily }}>{label}{(required ? ' *' : null)}</label>
            <input placeholder={placeholder} style={{ border: `1px solid ${(required && isSubmitted ? '#F00' : global.neutral)}`, borderRadius: '5px', padding: 10, fontFamily: global.fontFamily }} id="component-simple" onChange={onChange} />
        </FormControl>
    );
}