export const mergeLinks = {
    auth: [
        {
            title: "Merge Profile",
            link: "/merge-options",
        },
        {
            title: "Split Documents",
            link: "split-docs",
        },
        {
            title: "Condense Documents",
            link: "/condense-docs",
        },
        {
            title: "YTD Tracker",
            link: "/ytd-tracker",
        },
        {
            style: {
                marginTop: "60px",
            },
            title: "Settings",
            link: "/settings",
        },
        {
            title: "Explainer Video",
            link: "/explainer",
        },
        {
            title: "Package Documents Tutorial",
            link: "/how-to-package-documents",
        },
        {
            title: "Book a Demo",
            link: "https://calendly.com/max-merge-solutions",
            demo: true,
        },

        {
            title: "Logout",
            logout: true,
        },
    ],
    anon: [],
};

export const mainLinks = {
    auth: [
        {
            title: "Settings",
            link: "/settings",
        },
        {
            title: "Logout",
            logout: true,
        },
    ],
    anon: [
        {
            title: "About Us",
            link: "/about-us",
        },
        {
            title: "Pricing",
            link: "/subscription",
        },
        {
            title: "Log in",
            link: "/log-in",
        },
        {
            title: "Sign up",
            link: "/sign-up",
        },
    ],
};
