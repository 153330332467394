// @flow
import React, { useState, useEffect } from 'react';
import { InputAdornment, Alert, TextField, Button, Grid, Box, FormControlLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import { mergeOptionsStyles } from '../../../assets/MergeOptionsStyles';
import NumberFormat from 'react-number-format';
import { IMergeTaskInfo } from '../../../assets/interfaces/IMergeTaskInfo';
import axios from 'axios';
import { global } from '../../../../../global';
import { IPayout } from '../assets/interfaces/IPaymentState';
import { updatePayouts } from '../../../../../helpers/updatePayments';
import { IDocument } from '../../../assets/interfaces/IDocument';

export interface IPayoutState {
    statementDate: string | null;
    statementBalance: number;
    statementAttached: boolean | null;
}

interface IDebtPayoutForm {
    mergeTaskInfo: IMergeTaskInfo;
    variation: number;
    tileId: number;
    selectedDocument: null | number;
    payouts: IPayout[];
    setPayouts: React.Dispatch<React.SetStateAction<IPayout[]>>;
    setSelectedDocument: React.Dispatch<React.SetStateAction<number | null>>;
    documents: IDocument[];
};

export function NumberFormatCustom(props: any) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale={true}
        // isNumericString
        />
    );
}

export function DebtPayoutForm(props: IDebtPayoutForm) {
    const { mergeTaskInfo, variation, tileId, selectedDocument, setSelectedDocument, payouts, setPayouts, documents } = props;
    const classes = mergeOptionsStyles();
    const [payoutState, setPayoutState] = useState<IPayoutState>({
        statementDate: null,
        statementBalance: 0,
        statementAttached: null,
    })
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [saving, setSaving] = useState<string | null>(null);
    const addAttribute = () => {
        setSaving('Saving');

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        }; 
        const selectedPayOut = payouts.filter((payout: any) => payout.documentId === selectedDocument)[0];

        let data =
        {
            MergeTaskAttributes:
                [
                    {
                        Value: selectedPayOut.statementBalance,
                        TypeVariation: variation,
                        MergeTaskID: mergeTaskInfo.id,
                        MergeTaskDocumentId: selectedDocument,

                        MergeTaskAttributeTypeID: 8
                    },
                    {
                        Value: selectedPayOut.statementDate,
                        TypeVariation: variation,
                        MergeTaskID: mergeTaskInfo.id,
                        MergeTaskDocumentId: selectedDocument,

                        MergeTaskAttributeTypeID: 9
                    },
                    {
                        Value: selectedPayOut.statementAttached!.toString(),
                        TypeVariation: variation,
                        MergeTaskID: mergeTaskInfo.id,
                        MergeTaskDocumentId: selectedDocument,

                        MergeTaskAttributeTypeID: 10
                    },
                    {
                        Value: selectedPayOut.financialInstitution,
                        TypeVariation: variation,
                        MergeTaskID: mergeTaskInfo.id,
                        MergeTaskDocumentId: selectedDocument,

                        MergeTaskAttributeTypeID: 12
                    },
                ]
        };
        
        console.log(data);
        axios
            .post(`/api/mergeTaskAttribute/${mergeTaskInfo.id}`, JSON.stringify(data),
                axiosConfig
            )
            .then((result) => {
                // setAttributes((prev: IAttribute) => ([...prev, result.data]))
                console.log('result from post to mergeTaskAttributes:', result)
                setSaving(null)
                setSuccess('Saved debt payout')
                setTimeout(() => {
                    setSuccess(null)
                }, 500)
            })
            .catch((e) => {
                console.log('error:', e)
                setSaving(null)
                setSuccess('Saved debt payout')
                setTimeout(() => {
                    setSuccess(null)
                }, 500)
            })
    }

    const onSuccess = () => {
        setError(null);
        addAttribute()
        setPayouts((prev: any) => (updatePayouts(prev, selectedDocument, 'saved', true)))
        for (let i = 0; i < documents.length; i++) {
            if (documents[i].id === selectedDocument) {
                setSelectedDocument(prev => documents[i + 1] ? documents[i + 1].id : documents[i].id);
            }
        }
    }

    const checkDate = (date: string) => {
        const month = date[0] + date[1];
        const day = date[3] + date[4];
        const year = date[6] + date[7] + date[8] + date[9];
        if (parseInt(month) > 12 || parseInt(day) > 31) {
            return false
        }
        return true
    }

    const handlePayouts = () => {
        const payout = payouts.filter(payout => payout.documentId === selectedDocument)[0];
        if (payout) {
            !payout.financialInstitution ? setError('Please enter a Debt Being Paid Out')
                // : payout.account?.length !== 4 ? setError('Please enter the last four digits of your account number')
                : !payout.statementDate || !checkDate(payout.statementDate) ? setError('Please enter a statement date in the correct format')
                    : !payout.statementBalance ? setError('Please enter a statement balance')
                        : payout.statementAttached === null ? setError('Please select whether the statement has been attached')
                            // : !payout.giftedProceeds ? setError('Please enter any gifted proceeds')
                            //   : !payout.salesProceedsBalance ? setError('Please enter the sales proceeds balance')
                            : onSuccess();
        }
    }
    return (
        <Grid container direction="column" item xs={5} style={{ overflow: "scroll", height: "100%", flexWrap: "nowrap" }}>
            <form noValidate autoComplete="off" style={{ marginBottom: 40 }} className={classes.form} spellCheck={false} autoCapitalize="off" autoCorrect="off">
                <div>
                    <TextField
                        className={classes.input}
                        label="Debt Being Paid Out"
                        variant="standard"
                        value={payouts?.filter(payout => payout.documentId === selectedDocument).length === 0 ? '' : payouts.filter(payout => payout.documentId === selectedDocument)[0].financialInstitution ?? ''}
                        onChange={(event) => {
                            setPayouts((prev: any) => ([...updatePayouts(prev, selectedDocument, 'financialInstitution', event.target.value)]))
                            // console.log(payments);
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                            color: 'secondary',
                            type: "text"
                        }}
                        InputLabelProps={{
                            color: 'secondary',
                            style: { fontSize: '20px' }
                        }}
                    />

                    <NumberFormat
                        // className={classes.expiry}
                        label="Statement Date"
                        className={classes.input}
                        customInput={TextField}
                        placeholder='MM/DD/YYYY'
                        format="##/##/####"
                        value={payouts?.filter(payout => payout.documentId === selectedDocument).length === 0 ? '' : payouts.filter(payout => payout.documentId === selectedDocument)[0].statementDate ?? '' }
                        onChange={(event) => {
                            setPayouts((prev: any) => ([...updatePayouts(prev, selectedDocument, 'statementDate', event.target.value)]))
                        }}
                    />

                    <TextField
                        label="Statement Balance"
                        className={classes.input}
                        value={payouts?.filter(payout => payout.documentId === selectedDocument).length === 0 ? '' : payouts.filter(payout => payout.documentId === selectedDocument)[0].statementBalance ?? ''}
                        onChange={(event) => {
                            setPayouts((prev: any) => ([...updatePayouts(prev, selectedDocument, 'statementBalance', event.target.value)]))
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            color: 'secondary',
                            inputComponent: NumberFormatCustom
                            // type: "text"
                        }}
                        InputLabelProps={{
                            color: 'secondary',
                        }}
                    />
                    <FormLabel style={{ fontWeight: 500, color: global.black }} component="legend">Statement Attached?</FormLabel>
                    <Grid container justifyContent="space-between" item xs={5}>
                        <RadioGroup
                            aria-label="Statement Attached"
                            row
                            defaultValue={false}
                            name="radio-buttons-group"
                            value={payouts?.filter(payout => payout.documentId === selectedDocument).length === 0 ? false : payouts.filter(payout => payout.documentId === selectedDocument)[0].statementAttached ?? ''}
                        >
                            <FormControlLabel onClick={() => {
                                setPayouts((prev: any) => ([...updatePayouts(prev, selectedDocument, 'statementAttached', true)]))
                            }} value="true" control={<Radio size="small" />} label="Yes" />
                            <FormControlLabel onClick={() => {
                                setPayouts((prev: any) => ([...updatePayouts(prev, selectedDocument, 'statementAttached', false)]))
                            }} value="false" control={<Radio size="small" />} label="No" />
                        </RadioGroup>
                    </Grid>

                </div>
            </form>
            {error && <Alert style={{ marginBottom: 20 }} severity='warning'>{error}</Alert>}
            {saving && <Alert style={{ marginBottom: 20 }} severity='info'>{saving}</Alert>}
            {success && <Alert style={{ marginBottom: 20 }} severity='success'>{success}</Alert>}
            <Grid container>
                <Button style={{ background: '#00939F', width: 117, height: 38 }} variant="contained" onClick={() => {
                    // addAttribute();
                    // setPayouts((prev: any) => (updatePayouts(prev, selectedDocument, 'saved', true)))
                    // for (let i = 0; i < documents.length; i++) {
                    //     if (documents[i].id === selectedDocument) {
                    //         setSelectedDocument(prev => documents[i + 1].id);
                    //     }
                    // }
                    handlePayouts();
                }}>Save</Button>
            </Grid>
        </Grid>

    );
};