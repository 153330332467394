import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "@mui/material";
import { MergeNav } from "../MergeProcess/components/MergeNav";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Link, useLocation } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import HistoryMerge from "./components/HistoryMerge";
import ActiveMerge from "./components/ActiveMerge";

const HISTORY = "history";
const ACTIVE = "active";

const MergeProfile = () => {
    const [tab, setTab] = useState(ACTIVE);
    const location = useLocation();

  return (
    <MergeProfileWrapper>
      <MergeNav active="profile" />
      <div className="banner">
        <Container style={{ padding: 0 }}>
          <Typography
            variant="h5"
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              padding: 0,
              // marginTop: "45px",
              display: "flex",
              justifyContent: "center",
              fontSize:"24px"
            }}
          >
            Merge Profile
          </Typography>
        </Container>
      </div>
      <Container>
        <nav>
          <div className="tabs">
            <button
              className={`${tab === ACTIVE ? "active-tab" : ""}`}
              onClick={() => setTab(ACTIVE)}
            >
              {" "}
              Active{" "}
            </button>
            <button
              className={`${tab === HISTORY ? "active-tab" : ""}`}
              onClick={() => setTab(HISTORY)}
            >
              {" "}
              History{" "}
            </button>
                  </div>
                  <Link to={{ pathname: "/merge-docs", state: location.state ? { documentPackaging: location.state.documentPackaging, mergeFiles: location.state.mergeFiles } : { isAfterSave: true} }} >
            <Button className="new-file-btn">
              <span>
                <FileCopyIcon /> New Merge
              </span>
            </Button>
          </Link>
        </nav>
        <div className="merge-profile-body">
          {tab === ACTIVE && <ActiveMerge />}
          {tab === HISTORY && <HistoryMerge />}
        </div>
      </Container>
    </MergeProfileWrapper>
  );
};

const MergeProfileWrapper = styled.div`
  font-family: "Montserrat";

  .loader {
    width: 100%;
    padding: 100px 0;
    display: grid;
    place-items: center;
  }

  .new-file-btn {
    border: none;
    background-color: #00939f;
    padding: 6px 15px;
    word-spacing: 3px;
    font-weight: 500;
    height: 100%;
    svg {
      color: white;
      font-size: 16px;
      margin-right: 3px;
    }

    span {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: white;
    }
  }

  .tabs button {
    border: none;
    background: none;
    outline: none;
    color: #2f2e2e;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 3px solid transparent;
    padding: 15px 25px;
    padding-bottom: 12px;
    transition: 0.2s linear ease;
    margin-bottom: -1px;
  }

  .active-tab {
    border-bottom: 3px solid #00939f !important;
  }

  .tabs {
    display: flex;
    border-bottom: 1px solid #e9e9e9;
  }

  nav {
    display: flex;
    align-items: center;
    margin-top: 4rem;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
  }

  .banner {
    background-color: #1b1e1e;
    padding: 0px;
    font-weight: 600;
    color: #ffffff;
    font-size: 27px;
    font-family: "Montserrat";
    height: 112px;
    margin-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export default MergeProfile;
