import React, { useState } from 'react';
import validator from 'validator';
import { Helmet } from 'react-helmet';
import axios from "axios";

// components
import { NavBar } from '../../NavBar/NavBar';
import { Typography, Button, Dialog, DialogContent, DialogTitle, InputLabel, TextField } from '@mui/material';
import { HomeFooter } from '../../Home/components/HomeFooter/HomeFooter';

const cleanInfoState = ({
    name: "",
    email: ""
})

export default function TermsAndCondition() {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contactInfo, setContactInfo] = useState(cleanInfoState);
    const [notice, setNotice] = useState('');
    const [error, setError] = useState(false);


    const handleSubmit = () => {
        setLoading(true);
        setError(false);
        setNotice("");
        if (contactInfo.email && validator.isEmail(contactInfo.email)) {
            axios.post("/api/newsletter", contactInfo).then((resp) => {
                if (resp.data.success) {
                    setContactInfo(cleanInfoState);
                    setLoading(false);
                    setNotice("Success! We'll keep you updated.");
                } else {
                    setError(true);
                    setNotice("Oops, something went wrong, try again later.");
                    setLoading(false);
                }
            }).catch(() => {
                setError(true);
                setNotice("Oops, something went wrong, try again later.");
                setLoading(false);
            })
        } else {
            setError(true);
            setNotice("A valid email is required");
            setLoading(false);
        }
    }

    return (
        <div style={{
            flexGrow: 1,
            height: '100vh',
            position: 'relative',
            zIndex: 10
        }}>
            <Helmet>
                <title>Terms and Conditions</title>
            </Helmet>

            <NavBar active="" color="black" />

            <div style={{
                margin: 'auto',
                backgroundColor: 'white',
                marginTop: '4%',
                padding: '5rem',
                flexGrow: 1
            }}>
                <h2><strong>Last Updated: January 31, 2024</strong></h2>
                <br /><br />
                <h5 style={{ textAlign: 'center' }}><strong>Software-as-a-Service Licence and Subscription Agreement</strong></h5>
                <br />
                <p>
                    This Software-as-a-Service Licence and Subscription Agreement, including the Order Form (as defined
                    below) and the Privacy and Security Policy (as defined below) which by their reference is incorporated
                    herein (this “Agreement”), is a binding agreement between MAX MERGE SOLUTIONS INC., (also
                    doing business as Max Assist Mortgage Solutions) a British
                    Columbia corporation, whose incorporation number is BC1313667, and whose registered and records
                    office is c/o Stewart, Aulinger & Company, 1200 – 805 West Broadway, Vancouver, B.C., V5Z 1K1, and who is authorized to conduct mortgage broker services as authorized by the British Columbia Financial Services Authority (the
                    “<b>Licensor</b>”) and the person or entity identified in the Order Form as the licensee of the Services (as
                    defined below) (the “<b>Licensee</b>”, and together with the Licensor, the "<b>Parties</b>", and each, a "<b>Party</b>").
                </p>
                <br />
                <p>
                    THE LICENSOR PROVIDES THE SERVICES SOLELY ON THE TERMS AND CONDITIONS SET
                    FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT THE LICENSEE ACCEPTS AND
                    COMPLIES WITH THEM. BY CLICKING THE "ACCEPT" BUTTON YOU (A) ACCEPT THIS
                    AGREEMENT AND AGREE THAT THE LICENSEE IS LEGALLY BOUND BY ITS TERMS; AND (B)
                    REPRESENT AND WARRANT THAT: (I) YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING
                    AGREEMENT; AND (II) IF THE LICENSEE IS A CORPORATION, GOVERNMENTAL
                    ORGANIZATION, OR OTHER LEGAL ENTITY, YOU HAVE THE RIGHT, POWER, AND AUTHORITY
                    TO ENTER INTO THIS AGREEMENT ON BEHALF OF THE LICENSEE AND BIND THE LICENSEE
                    TO ITS TERMS. IF THE LICENSEE DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT,
                    THE LICENSOR WILL NOT AND DOES NOT LICENCE THE SERVICES AND SOFTWARE TO THE
                    LICENSEE AND YOU MUST NOT ACCESS, DOWNLOAD, INSTALL, OR UTILIZE THE SERVICES
                    AND SOFTWARE OR THE LICENSOR’S MATERIALS.
                </p>
                <br />
                <p>
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT OR YOUR OR THE
                    LICENSEE'S ACCEPTANCE OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, NO
                    LICENCE IS GRANTED (WHETHER EXPRESSLY, BY IMPLICATION OR OTHERWISE) UNDER THIS
                    AGREEMENT, AND THIS AGREEMENT EXPRESSLY EXCLUDES ANY RIGHT CONCERNING ANY
                    SERVICES OR SOFTWARE THAT THE LICENSEE DID NOT ACQUIRE LAWFULLY OR THAT IS
                    NOT A LEGITIMATE, AUTHORIZED LICENCE OR COPY OF THE LICENSOR'S SERVICE AND
                    SOFTWARE.
                </p>
                <br />
                <p>
                    <u>QUEBEC</u>: THE SERVICES AND THE SOFTWARE OFFERED BY THE LICENSOR ARE NOT
                    AVAILABLE TO BUSINESSES AND/OR INDIVIDUALS BASED IN QUEBEC. / LES SERVICES ET LE
                    LOGICIEL OFFERTS PAR LE CONCÉDANT NE SONT PAS DISPONIBLES POUR LES
                    ENTREPRISES ET/OU LES PARTICULIERS BASÉS AU QUÉBEC.
                </p>
                <br />
                <h5><strong><u>1. Definitions.</u> </strong></h5>
                <p>For the purposes of this Agreement, the following terms have the following meanings:</p>
                <p><strong>“Access Credentials”</strong> &nbsp; means any user name, identification number, password,
                    licence or security key, security token, personal identification
                    number (PIN) or other security code, method, technology, or
                    device used alone or in combination, to verify an individual's
                    identity and authorization to access and use the Services and
                    provided by or created for (as the case may be) the Licensee
                    and its Authorized Users;</p>
                <p><strong>“Action”</strong> &nbsp; means any claim, action, cause of action, demand, lawsuit,
                    arbitration, inquiry, audit, notice of violation, proceeding,
                    litigation, citation, summons, subpoena, or investigation of any nature, civil, criminal, administrative, investigative,
                    regulatory, or other, whether at Law, in equity, or otherwise; </p>
                <p><strong>“Agreement”</strong> &nbsp; has the meaning set forth in the preamble above; </p>
                <p><strong>“Authorized Users”</strong> &nbsp;means the Licensee’s employees, consultants, contractors,
                    and agents (a) who are authorized by the Licensee to access
                    and use the Services under the rights granted to the Licensee
                    pursuant to this Agreement; (b) for whom access to the
                    Services has been purchased hereunder; and (c) must
                    access the Services using the Access Credentials; </p>

                <p><strong>“Business Day”</strong> &nbsp;means any day other than a Saturday, Sunday or any other
                    day on which banks located in the City of Vancouver, British
                    Columbia, Canada are authorized or required by Law to be
                    closed for business; </p>

                <p><strong>“Confidential Information”</strong> &nbsp; has the meaning set forth in Section 10.1; </p>
                <p><strong>“Disclosing Party"</strong> &nbsp; has the meaning set forth in Section 10.1; </p>
                <p><strong>“Discount”</strong> &nbsp; has the meaning set forth in Section 8.8; </p>
                <p><strong>“Fees”</strong> &nbsp; has the meaning set forth in Section 8.1; </p>

                <p><strong>“Force Majeure Event”</strong> &nbsp; has the meaning set forth in Section 15.1; </p>
                <p><strong>“Governmental Authority”</strong> &nbsp; means any federal, provincial, territorial, municipal, or foreign
                    government or political subdivision thereof, or any agency or
                    instrumentality of such government or political subdivision, or
                    any self-regulated organization or other non-governmental
                    regulatory authority or quasi-governmental authority (to the
                    extent that the rules, regulations, or orders of such
                    organization or authority have the force of Law), or any
                    arbitrator, court, or tribunal of competent jurisdiction;
                </p>
                <p><strong>“Governmental Order”</strong> &nbsp; means any order, writ, judgment, injunction, decree,
                    stipulation, award, or determination entered by or with any
                    Governmental Authority; </p>
                <p><strong>“Harmful Code”</strong> &nbsp; means any software, hardware, or other technology, device,
                    or means, including any virus, trojan horse, worm, backdoor,
                    malware, or other malicious computer code, in any form and
                    deployed in any manner, whether innocently, negligently, with
                    or without the prior knowledge of the Licensee, the purpose
                    or effect of which is to: (a) permit unauthorized access to, or
                    to destroy, disrupt, disable, distort, or otherwise harm or
                    impede in any manner any: (i) computer, software, firmware,
                    hardware, system, or network; or (ii) application or function of
                    any of the foregoing or the security, integrity, confidentiality,
                    or use of the Services, the Licensor Systems, or any data
                    Processed thereby; or (b) destroy, disrupt, disable, distort, or
                    otherwise harm or impede the Services, the Licensor
                    Systems, the Processing, or the Resultant Data; </p>
                <p><strong>“Indemnitee”</strong> &nbsp; has the meaning set forth in Section 13.2 </p>
                <p><strong>“Indemnitor”</strong> &nbsp; has the meaning set forth in Section 13.2 </p>

                <p><strong>“Initial Term”</strong> &nbsp; has the meaning set forth in Section 11.1; </p>
                <p><strong>“IP Rights” </strong> &nbsp; means any and all registered and unregistered rights granted,
                    applied for or otherwise now or hereafter in existence under
                    or related to any patent, copyright, trademark, trade secret,
                    database protection, or other intellectual property rights Laws,
                    and all similar or equivalent rights or forms of protection in any
                    part of the world;
                </p>
                <p><strong>“Law” </strong> &nbsp; means any statute, ordinance, regulation, rule, code,
                    constitution, treaty, common law, Governmental Order, or
                    other requirement or rule of law of any Governmental
                    Authority;

                </p>
                <p><strong>“Licensee Failure” </strong> &nbsp; has the meaning set forth in Section 4.2;

                </p>
                <p><strong>“Licensee” </strong> &nbsp; has the meaning set forth in Section 4.2;
                </p>
                <p><strong>“Licensee Systems" </strong> &nbsp; means the Licensee’s information technology infrastructure,
                    including computers, software, hardware, databases,
                    electronic systems (including database management
                    systems), and networks, whether operated directly by the
                    Licensee or through the use of third-party services;

                </p>
                <p><strong>“Licensor” </strong> &nbsp; has the meaning set forth in the preamble above;

                </p>
                <p><strong>“Licensor Indemnitee" </strong> &nbsp; has the meaning set forth in Section 13.1;


                </p>
                <p><strong>“Licensor Materials” </strong> &nbsp; means any manuals, instructions or other documents or
                    materials the Licensor provides or makes available to the
                    Licensee in any form or medium and which describe the
                    functionality, components, features or requirements of the
                    Services, including any aspect of the installation,
                    configuration, integration, operation, use, support, or
                    maintenance thereof and all other information, data,
                    documents, materials, works and other content, devices,
                    methods, processes, hardware, software, and other
                    technologies, and inventions, including any technical or
                    functional descriptions, requirements, plans, or reports, that
                    are provided to the Licensee by the Licensor or any
                    Subcontractor, as amended, updated, or revised from time to
                    time, in connection with the Services or otherwise comprise
                    or relate to the Services or the Licensor Systems, including
                    any information, data, or other content derived from the
                    Licensor’s monitoring of the Licensee’s access to or use of
                    the Services, but does not include Licensee Data;


                </p>
                <p><strong>“Licensor Personnel" </strong> &nbsp; means all individuals involved in the performance of Services
                    as employees, agents, or independent contractors of the
                    Licensor or any Subcontractor;


                </p>
                <p><strong>“Licensor Systems" </strong> &nbsp; means the information technology infrastructure used by or
                    on behalf of the Licensor in supplying the Services, including
                    all computers, software, hardware, databases, electronic
                    systems (including database management systems), and
                    networks, whether operated directly by the Licensor or
                    through the use of third-party services;


                </p>
                <p><strong>“Losses” </strong> &nbsp; mean all losses, damages, liabilities, deficiencies, claims,
                    actions, judgments, settlements, interest, awards, penalties,
                    fines, costs, or expenses of whatever kind, including legal
                    fees, disbursements, and charges, and the cost of enforcing
                    any right to indemnification hereunder and the cost of
                    pursuing any insurance providers;


                </p>
                <p><strong>“Order Form” </strong> &nbsp; means the order form, accessible through the Website, filled
                    out and submitted by or on behalf of Licensee, and accepted
                    by Licensor, for the Licensee's purchase of the licence for the
                    Services granted under this Agreement;


                </p>
                <p><strong>“Payment Services Provider” </strong> &nbsp; has the meaning set forth in Section 8.9;


                </p>
                <p><strong>“Person” </strong> &nbsp; means an individual, corporation, partnership, unlimited
                    liability company, Governmental Authority, unincorporated
                    organization, trust, association, or any other entity;



                </p>
                <p><strong>“Personal Information”  </strong> &nbsp; means information about an identifiable individual and
                    includes employee personal information and any information
                    that relates to an individual person and identifies or can be
                    used to identify, locate, or contact that individual alone or
                    when combined with other personal or identifying information
                    that is or can be associated with that specific individual,
                    including, but not limited to (a) first and last name; (b) home
                    or other physical address, including street name and name of
                    city or town and/or province or territory; (c) email address or
                    other online information, such as a user name and password;
                    (d) telephone number; (e) government-issued identification or
                    other number; (f) financial or payment card account number;
                    (g) date of birth; (h) health information; or (i) tax information,
                    including any unique tax identification numbers, and (j) any
                    information that is combined with any of (a) through (i) above;



                </p>
                <p><strong>“Privacy and Security Policy” </strong> &nbsp; has the meaning set forth in Section 7.1, a current copy of
                    which may be accessed on the Website at  &nbsp;
                    <a style={{ color: "#007bff" }} href="./privacy-policy">https://www.maxmergesolutions.ca/privacy-policy;</a>



                </p>
                <p><strong>“Process” </strong> &nbsp; means to take any action or perform any operation or set of
                    operations that the Software and the Services are capable of
                    taking or performing on any data, information, or other
                    content, including to collect, receive, input, upload, download,
                    record, reproduce, store, organize, compile, combine, log,
                    catalog, cross-reference, manage, maintain, copy, adapt,
                    alter, translate, or make other derivative works or
                    improvements, process, retrieve, output, consult, use,
                    perform, display, disseminate, transmit, submit, post, transfer,
                    disclose, or otherwise provide or make available, or block,
                    erase, or destroy, and <strong>"Processing"</strong> and <strong>"Processed"</strong> have
                    correlative meanings;
                </p>
                <p><strong>“Receiving Party” </strong> &nbsp; has the meaning set forth in Section 10.1;
                </p>
                <p><strong>“Renewal Term” </strong> &nbsp; has the meaning set forth in Section 11.2;

                </p>
                <p><strong>“Representatives” </strong> &nbsp; means, with respect to a Party, that Party's respective
                    employees, officers, directors, consultants, agents,
                    independent contractors, subcontractors, service providers,
                    sublicensees, and legal advisors;

                </p>
                <p><strong>“Resultant Data” </strong> &nbsp; means outputted data and information, including Personal
                    Information, in whichever format, that is the product of the
                    Processing by the Services and the Software of the Licensee
                    Data as supplied by the Licensee or its Authorized Users
                    when using the Services;


                </p>
                <p><strong>“Scheduled Downtime” </strong> &nbsp; has the meaning set forth in Section 5.2;


                </p>
                <p><strong>“Services” </strong> &nbsp; means (i) the supply of a subscription based software-as-aservice by the Licensor to the Licensee and its Authorized
                    Users of the Software that, inter alia, enables the Licensee
                    and its Authorized Users to Process the Licensee’s Data
                    using the Software on the Licensor Systems, including the
                    Resultant Data, and (ii) the Support Services;


                </p>
                <p><strong>“Software” </strong> &nbsp; means the software-as-a-service programs for which the
                    Licensee is purchasing a licence pursuant to this Agreement,
                    as expressly set forth in the Order Form and which may only
                    be accessed through the Services and for the purposes of this
                    Agreement “Software” shall be construed as including any
                    and all of the Licensor’s (a) computer programs, including any
                    and all software implementation of algorithms, models and
                    methodologies, whether in source code, object code, human
                    readable form or other form (b) databases and compilations,
                    including any and all data and collections of data, whether
                    machine readable or otherwise, (c) descriptions, flow charts
                    and other work products used to design, plan, organize and
                    develop any of the foregoing, (d) screens, user interfaces,
                    report formats, firmware, development tools, templates,
                    menus, buttons and icons and (e) documentation, including
                    user manuals and other training documentation, relating to
                    any of the foregoing;


                </p>
                <p><strong>“Subcontractor”</strong> &nbsp; has the meaning set forth in Section 2.7;


                </p>
                <p><strong>“Support Schedule”</strong> &nbsp; has the meaning set forth in Section 5.3;


                </p>
                <p><strong>“Support Services”</strong> &nbsp; has the meaning set forth in Section 5.3;


                </p>
                <p><strong>“Taxes”</strong> &nbsp; means any commodity tax, including sales, use, excise,
                    value-added, goods and services tax (GST), harmonized
                    sales tax (HST), provincial sales tax (PST), consumption, or
                    other similar tax, including penalties and interest, imposed,
                    levied, or assessed by any Governmental Authority;



                </p>
                <p><strong>“Term”</strong> &nbsp; has the meaning set forth in Section 11.2;


                </p>
                <p><strong>“Third-Party Materials”</strong> &nbsp; means materials and information, in any form or medium,
                    including any open-source or other software, documents,
                    data, content, specifications, products, equipment, or
                    components of or relating to the Services that are not
                    proprietary to the Licensor; and


                </p>
                <p><strong>“Website”</strong> &nbsp; means the Licensor’s website accessible at https://
                    maxmergesolutions.com, as amended from time to time,
                    through which the Licensor offers the Services to the
                    Licensee.


                </p>
                <h5><strong><u>2. Services.</u> </strong></h5>
                <p><strong><u>2.1 Access and Use.</u> </strong> Subject to and conditioned on the Licensee and its Authorized Users' strict
                    compliance with the terms and conditions of this Agreement, the Licensor hereby grants the
                    Licensee a non-exclusive, non-sublicensable, non-transferable (except in compliance with
                    Section 16.6) right to access and use the Services during the Term, solely for use by the
                    Licensee and its Authorized Users in accordance with the terms and conditions herein. Such
                    use is limited to the Licensee’s internal use only. The Licensor shall provide to the Licensee
                    the Access Credentials within a reasonable time following commencement of the Term. The
                    total number of Authorized Users shall not exceed the number set forth in the Order Form.
                    If the Licensee wishes to amend the total number of Authorized Users then, subject to any
                    rights or restrictions contained herein, the Licensee may request a change in writing by
                    contacting the Licensor. The Licensee accepts and acknowledges that approval of a change
                    in the number of Authorized Users is at the sole and absolute discretion of the Licensor and
                    that any changes to the number of Authorized Users shall be subject to an appropriate
                    adjustment of the Fees payable hereunder. If the Licensee requests a reduction in the
                    number of Authorized Users then the Fees payable shall be adjusted downwards on the
                    monthly anniversary of this Agreement. If the Licensee requests an increase in the number
                    of Authorized Users then the Fees payable shall immediately be adjusted upwards on a prorata basis.</p>
                <p><strong><u>2.2 Documentation Licence.</u> </strong> The Licensor hereby grants to the Licensee a non-exclusive, nonsublicensable, non-transferable (except in compliance with Section 16.6) licence to use the
                    Licensor Materials during the Term solely for the Licensee’s internal business purposes in
                    connection with its use of the Services.</p>
                <p><strong><u>2.3 Service and System Control.</u> </strong> Except as otherwise expressly provided in this Agreement, as
                    between the Parties:</p>
                <p><u>2.3.1 </u> The Licensor has and shall retain sole control over the operation, provision,
                    maintenance, and management of the Licensor Systems, the Licensor Materials,
                    and the Services; and </p>
                <p><u>2.3.2 </u> The Licensee has and shall retain sole control over the operation, maintenance,
                    and management of, and all access to and use of, the Licensee Systems, and sole
                    responsibility for all access to and use of the Licensor Materials by any Person by
                    or through the Services or the Licensee Systems or any other means controlled by
                    the Licensee or any Authorized User, including any: </p>
                <p><u>2.3.2.1  </u> information, instructions, or materials provided by any of them to the
                    Licensor;
                </p>
                <p><u>2.3.2.2  </u> Resultant Data obtained from any use of the Services or the Licensor
                    Materials; and

                </p>
                <p><u>2.3.2.3  </u> conclusions, decisions, or actions based on such use
                </p>
                <p><strong><u>2.4 Reservation of Rights.</u> </strong> Nothing in this Agreement grants any right, title, or interest in or to
                    (including any licence under) any IP Rights in or relating to, the Services, the Software, the
                    Licensor Materials, or any Third-Party Materials, whether expressly, by implication, estoppel,
                    or otherwise. All right, title, and interest in and to the Services, the Software, the Licensor
                    Materials, and any Third-Party Materials are and shall remain with the Licensor and the
                    respective rights holders in the Third-Party Materials.</p>
                <p><strong><u>2.5 Service Management.</u> </strong>The Licensee shall, throughout the Term, maintain within its
                    organization a service manager to serve as such Party's primary point of contact for day-today communications, consultation, and decision-making regarding this Agreement,
                    including providing all day-to-day consents and approvals on behalf of the Licensee under
                    this Agreement. The Licensee shall ensure its service manager has the requisite
                    organizational authority, skill, experience, and other qualifications to perform in such
                    capacity. If the Licensee’s service manager ceases to be employed or the Licensee
                    otherwise wishes to replace its service manager, the Licensee shall promptly name a new
                    service manager and notify the Licensor. </p>
                <p><strong><u>2.6 Changes.</u> </strong>The Licensor reserves the right, in its sole discretion, to make any changes to the
                    Services, the Software, and the Licensor Materials that it deems necessary or useful to: (a)
                    maintain or enhance: (i) the quality or delivery of the Services, (ii) the competitive strength
                    of or market for the Services, or (iii) the cost efficiency or performance of the Services; or
                    (b) to comply with applicable Law. Without limiting the foregoing, the Licensor may, at any
                    time during the Term, notify the Licensee of changes to the Services or the Software and
                    the Licensor may implement all such changes in accordance with its own internal procedures
                    and timelines. The Licensee’s sole remedy in respect of a change to the Services or the
                    Software, including its scope or specification, is to terminate this Agreement in accordance
                    with the terms herein. </p>
                <p><strong><u>2.7 Subcontractors.</u> </strong> The Licensor may, from time to time, in its sole discretion engage thirdparties to perform part or all of the Services (each, a “<b>Subcontractor</b>”).
                </p>
                <p><strong><u>2.8 Suspension or Termination of the Services. </u> </strong> The Licensor may, directly or indirectly,
                    suspend, terminate, or otherwise deny access to, or use of, all or any part of the Services or
                    the Licensor Materials by the Licensee, any Authorized Users or any other Person, without
                    incurring any resulting obligation or liability, if: (a) the Licensor receives a Governmental
                    Order that expressly or by reasonable implication requires the Licensor to do so; or (b) the
                    Licensor believes, in its sole discretion, that: (i) the Licensee or any Authorized Users have
                    failed to comply with any term of this Agreement or accessed or used the Services beyond
                    the scope of the rights granted or for a purpose not authorized under this Agreement or in
                    any manner that does not comply with any instruction or requirement of the Licensor, (ii) the
                    Licensee or any Authorized Users are, have been, or are likely to be involved in any
                    fraudulent, misleading, or unlawful activities, whether or not relating to or in connection with
                    any of the Services, or (iii) this Agreement expires or is terminated. This Section 2.8 does
                    not limit any of the Licensor’s other rights or remedies, whether at Law, in equity, or under
                    this Agreement.
                </p>
                <p><strong><u>2.9 Scope of the Services. </u> </strong> The Licensee accepts, acknowledges, and indemnifies the Licensor,
                    that the Services supplied by the Licensor are strictly for the purposes of the Processing of
                    the Licensee Data into the Resultant Data and the Processing is incidental to the Licensee’s
                    business and, at no point in time, is the Licensor supplying, representing, or offering the
                    Licensee, its Representatives, and its Authorized Users, with accounting, legal, or financial
                    services of any type, kind, or nature.
                </p>
                <h5><strong><u>3. Use Restrictions; Service Usage and Data Storage.
                </u> </strong></h5>
                <p><strong><u>3.1 Use Restrictions </u> </strong> The Licensee shall not, and shall not permit any other Person to, access
                    or use the Services or the Licensor Materials except as expressly permitted by this
                    Agreement and, in the case of Third-Party Materials, the applicable third-party licence
                    agreement. For purposes of clarity and without limiting the generality of the foregoing, the
                    Licensee shall not, except as this Agreement expressly permits:

                </p>
                <p><u>3.1.1 </u> copy, modify, or create derivative works or improvements of the Services, the
                    Software, or the Licensor Materials;



                </p>
                <p><u>3.1.2 </u> rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise
                    make available any Services, the Software, or the Licensor Materials to any Person,
                    including on or in connection with the internet or any time-sharing, service bureau,
                    software as a service, cloud, or other technology or service;


                </p>
                <p><u>3.1.3 </u> reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to
                    derive or gain access to the source code of the Services, the Software, or the
                    Licensor Materials, in whole or in part;



                </p>
                <p><u>3.1.4 </u> bypass or breach any security device, or protection used by the Services, the
                    Software, or the Licensor Materials or access or use the Services, the Software, or
                    the Licensor Materials other than by Authorized Users through the use of their own
                    then-valid Access Credentials;



                </p>
                <p><u>3.1.5 </u> input, upload, transmit, or otherwise provide to or through the Services, the
                    Software, or the Licensor Systems any information or materials that are unlawful or
                    injurious, or contain, transmit, or activate any Harmful Code;


                </p>
                <p><u>3.1.6 </u> damage, destroy, disrupt, disable, impair, interfere with, or otherwise impede or
                    harm in any manner the Services, the Software, the Licensor Systems, or the
                    Licensor’s provision of the Services to any third party, in whole or in part;


                </p>
                <p><u>3.1.7 </u> remove, delete, alter, or obscure any trademarks, documentation, warranties, or
                    disclaimers, or any copyright, trademark, patent, or other intellectual property or
                    proprietary rights notices from the Services, the Software, the Website, or the
                    Licensor Materials, including any copy thereof;


                </p>
                <p><u>3.1.8 </u> access or use the Services, the Software, or the Licensor Materials in any manner
                    or for any purpose that infringes, misappropriates, or otherwise violates any IP
                    Rights or other right of any third party (including by any unauthorized access to,
                    misappropriation, use, alteration, destruction, or disclosure of the data of any other
                    Licensor customer), or that violates any applicable Law;


                </p>
                <p><u>3.1.9 </u> access or use the Services, the Software, or the Licensor Materials for purposes of
                    competitive analysis of the Services, the Software, or the Licensor Materials, the
                    development, provision or use of a competing software service or product, or any
                    other purpose that is to the Licensor’s detriment or commercial disadvantage;


                </p>
                <p><u>3.1.10 </u> access or use the Services, the Software, or the Licensor Materials in, or in
                    association with, the design, construction, maintenance, or operation of any
                    hazardous environments, systems, applications, or products, any safety response
                    systems or other safety-critical applications, or any other use or application in which
                    the use or failure of the Services or the Software could lead to personal injury or
                    severe physical or property damage; or

                </p>
                <p><u>3.1.11 </u> otherwise access or use the Services, the Software, or the Licensor Materials
                    beyond the scope of the authorization granted under this Section 3.1.


                </p>
                <p><strong><u>3.2 Compliance Measures. </u> </strong> The Services, the Software, and the Licensor Systems contain, or
                    may contain, technological copy protection or other security features designed to prevent
                    unauthorized use of the Services, the Software, and the Licensor Systems including features
                    to protect against use of the Services and the Software: (i) beyond the scope of the licence
                    granted under Section 2; and (ii) prohibited under Section 3. The Licensee shall not, and
                    shall not attempt to, remove, disable, circumvent, or otherwise create or implement any
                    workaround to any such copy protection or security features.


                </p>
                <p><strong><u>3.3 Time Limited Data Storage. </u> </strong>The Licensee accepts and acknowledges that any Licensee
                    Data that is Processed by the Licensee and its Authorized Users using the Services and the
                    Software, including any Resultant Data, shall only be retained on the Licensor Systems for
                    a period of One Hundred and Eighty (180) Days from the time that the relevant Licensee Data is
                    uploaded through the Services to the Licensor Systems for Processing. The Licensee
                    accepts that it is solely the responsibility of the Licensee and its Authorized Users to
                    download, transfer, or otherwise store the Licensee Data and the Resultant Data from the
                    Licensor Systems to the Licensee Systems. The Licensee further accepts that, upon the
                    expiry of the One Hundred and Eighty (180) Days period, the relevant Licensee Data and any related
                    Resultant Data shall be automatically and irrevocably deleted and removed by the Licensor
                    Systems without recourse for recovery and without any liability by the Licensor to the
                    Licensee. The Licensee shall at all times, including after expiry of termination of this
                    Agreement, indemnify, defend, and hold harmless the Licensor, its Representatives, and its
                    Subcontractors, and each of its and their respective officers, directors, employees, agents,
                    successors, and permitted assigns from an against any and all Losses incurred by the
                    Licensee, its Representatives, and customers in connection with (i) the storage and
                    Processing of the Licensee Data on the Licensor Systems; (ii) the storage and processing
                    of the Resultant Data on the Licensor Systems; (iii) the deletion or removal, whether
                    automated or otherwise, by the Licensor of the Licensee’s Data and the Resultant Data; and
                    (iv) the storage, deletion, removal, and Processing of any Personal Information, whether
                    deliberate or incidental, on behalf of the Licensee.



                </p>
                <h5><strong><u>4. Licensee Obligations.
                </u> </strong></h5>

                <p><strong><u>4.1 Licensee Systems and Cooperation. </u> </strong>The Licensee shall at all times during the Term:



                </p>
                <p><u>4.1.1</u> set up, maintain, and operate in good repair and in accordance with commercially
                    reasonable practices commensurate with the risk and the type of business and work
                    of the Licensee all Licensee Systems on or through which the Services are
                    accessed or used; and




                </p>
                <p><u>4.1.2</u> provide all cooperation and assistance as the Licensor may reasonably request to
                    enable the Licensor to exercise its rights and perform its obligations under and in
                    connection with this Agreement.

                </p>
                <p><strong><u>4.2 Effect of Licensee Failure or Delay</u> </strong>The Licensor shall not be responsible or liable for any
                    delay or failure of performance caused, in whole or in part, by the Licensee’s delay in
                    performing, or failing to perform, any of its obligations under this Agreement (each, a
                    “<b>Licensee Failure</b>”).




                </p>
                <p><strong><u>4.3 Corrective Action and Notice</u> </strong>If the Licensee becomes aware of any actual or threatened
                    activity prohibited by Section 3.1, the Licensee shall, and shall cause its Authorized Users
                    to, immediately: (a) take all reasonable and lawful measures within their respective control
                    that are necessary to stop the activity or threatened activity and to mitigate its effects
                    (including, where applicable, by discontinuing and preventing any unauthorized access to
                    the Services, the Software, and the Licensor Materials and permanently erasing from their
                    systems and destroying any data to which any of them have gained unauthorized access;
                    and (b) notify the Licensor of any such actual or threatened activity.





                </p>
                <p><strong><u>4.4 Licensee’s Non-Solicitation</u> </strong> During the Term and for TWELVE (12) MONTHS after termination or expiry
                    of this Agreement, the Licensee shall not, and shall not assist any other Person to, directly
                    or indirectly, in any manner recruit or solicit (other than by general advertisement not directed
                    specifically to any Person or Persons) for employment or engagement as an independent
                    contractor any Person then or within the prior TWELVE (12) MONTHS employed or engaged
                    by the Licensor or any Subcontractor and involved in any respect with the Services, the
                    Software, or the performance of this Agreement. A general advertisement or notice of a job
                    listing or opening or other similar general publication of a job search or availability to fill
                    employment positions, including on the internet, shall not be construed as a solicitation or
                    inducement for the purposes of this Section 4.4, and the hiring of any such employees or
                    independent contractor who freely responds thereto shall not be a breach of this Section
                    4.4.
                </p>
                <p><strong><u>4.5 Breach of Licensee’s Non-Solicitation</u> </strong> In the event of a violation of Section 4.4, the Licensor shall be
                    entitled to liquidated damages equal to the compensation paid by the Licensor to the
                    applicable employee or contractor during the prior TWELVE (12) MONTHS, plus the
                    recruitment costs incurred by the Licensor in replacing such person and the Licensee agrees
                    that such liquidated damages represent a genuine pre-estimate of the Licensor’s loss.
                </p>
                <h5><strong><u>5. Service Levels.
                </u> </strong></h5>
                <p><strong><u>5.1 Availability.</u> </strong> Subject to the terms and conditions of this Agreement, the Licensor shall use
                    commercially reasonable efforts to make the Services and the Software available at least
                    NINETY-NINE (99%) percent of the time on the Licensor Systems as measured over the
                    course of each calendar month during the Term, excluding unavailability as a result of any
                    of the exceptions described below in Sections 5.1.1 to 5.1.7 inclusive. For the purposes of
                    calculating availability of the Services and the Software, the Services and the Software shall
                    not be considered unavailable when due, in whole or in part, to any:
                </p>
                <p><u>5.1.1 </u> act or omission by the Licensee or its Authorized Users that does not strictly comply
                    with this Agreement or the Licensor Materials;
                </p>
                <p><u>5.1.2 </u> Licensee Failure;

                </p>
                <p><u>5.1.3 </u> failures, interruptions, delays, or reductions in performance of the internet
                    connectivity of the Licensee or its Authorized Users;
                </p>
                <p><u>5.1.4 </u> Force Majeure Event;
                </p>
                <p><u>5.1.5 </u> failure, interruption, outage, or other problem with the software, hardware, system,
                    network, facility, or other matters not supplied by the Licensor under this
                    Agreement;

                </p>
                <p><u>5.1.6 </u> Scheduled Downtime; or
                </p>
                <p><u>5.1.7 </u> disabling, suspension, or termination of the Services under Section 2.8.
                </p>
                <p><strong><u>5.2 Scheduled Downtime.</u> </strong> The Licensor shall use commercially reasonable efforts to: (a)
                    schedule downtime for routine maintenance of the Services and the Software between the
                    hours of 7:00 P.M. and 7:00 A.M. Vancouver, Canada Time; and (b) give the Licensee at
                    least FORTY-EIGHT (48) HOURS prior notice of all scheduled outages of the Services or
                    the Software (the "Scheduled Downtime"). The Licensee and its Authorized Users shall be
                    deemed to have been notified of any Scheduled Downtime if the Licensor supplies such
                    notice by posting it on the Website or updating the Licensor Materials
                </p>
                <p><strong><u>5.3 Service Support.</u> </strong>The Services and the Software include the Licensor’s standard customer
                    support services (the "<b>Support Services</b>") in accordance with the Licensor’s service support
                    schedule then in effect, a current copy of which is available on the Website (or a successor
                    website address) (the "<b>Support Schedule</b>"). The Licensor may amend the Support
                    Schedule from time to time in its sole discretion and any changes to the Support Schedule
                    shall be deemed effective on the date the Licensor posts such change on the Website. Any
                    change to the Support Schedule shall not be deemed a breach of this Agreement. The
                    Licensee may purchase enhanced support services separately at the Licensor’s then current
                    rates.

                </p>
                <h5><strong><u>6. Data Backup.
                </u> </strong></h5>
                <p>The Services and the Software do not replace the need for the Licensee and its
                    Authorized Users to maintain regular data backups or redundant data archives. THE LICENSOR
                    HAS NO OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION,
                    DAMAGE, CORRUPTION, OR RECOVERY OF THE LICENSEE DATA, THE RESULTANT
                    DATA, OR PERSONAL INFORMATION FORMING PART OF THE LICENSEE’S DATA.</p>

                <h5><strong><u>7. Data Privacy and Security.
                </u> </strong></h5>
                <p><strong><u>7.1 Information Security Obligations.</u> </strong>The Licensor shall employ reasonable security measures
                    in accordance with applicable Law, and the Licensor’s data privacy and security policy as
                    amended from time to time, a current copy of which is available at the Website (the “<b>Privacy
                    and Security Policy</b>”). For the avoidance of doubt the Privacy and Security Policy does not
                    apply to any third party website or service linked to the Services, the Software, the Licensor
                    Materials, the Licensor Systems, the Website or recommended or referred to through the
                    same.

                </p>
                <p><strong><u>7.2 Collection and Use of Information.</u> </strong>In addition to the Licensee’s obligations pursuant to the
                    Privacy and Security Policy, the Licensee accepts and acknowledges that the Licensor may,
                    directly or indirectly, through (1) the Services, or (2) the services of others, collect and store
                    information regarding: (i) the use of the Services; (ii) the equipment on which the Services
                    is used or through which it otherwise is accessed; (iii) the geographical location(s) from
                    which the Services are accessed, whether by means of IP-based tracking, fingerprinting, or
                    otherwise; (iv) the time and number of Authorized Users who access the Services; and (v)
                    Personal Information. The Licensee agrees that the Licensor may use such information for
                    any purpose related to any use and supply of the Services, including but not limited to: (i)
                    improving the performance of the Services, the Software, or developing updates; (ii) bug
                    and issue tracking; (iii) maintenance and support services; (iv) compliance with all applicable
                    Laws; and (v) verifying compliance with the terms of this Agreement and enforcing the
                    Licensor’s rights, including all IP Rights in and to the Services, the Software, and the
                    Licensor’s Materials.


                </p>
                <p><strong><u>7.3 Data Breach Procedures.</u> </strong>The Licensor maintains a data breach plan in accordance with all
                    Laws and shall implement the procedures required under such data breach plan on the
                    occurrence of a data breach (as defined in such plan).


                </p>
                <p><strong><u>7.4 Licensee Control and Responsibility.</u> </strong>The Licensee has and will retain sole responsibility for:
                    (a) all Personal Information supplied by the Licensee or its Authorized Users to the Licensor
                    in connection with the Services, the Software, and the Processing; (b) all Licensee Data,
                    including its content and use; (c) all Resultant Data, including its content and use; (d) all
                    information, instructions, and materials provided by or on behalf of Licensee or any
                    Authorized User in connection with the Services; (e) Licensee Systems; (f) the security and
                    use of Access Credentials of the Licensee and its Authorized Users; and (g) all access to
                    and use of the Services, the Software, and the Licensor Materials directly or indirectly by or
                    through the Licensee Systems or its or its Authorized Users' Access Credentials, with or
                    without the Licensee’s knowledge or consent, including all results obtained from, and all
                    conclusions, decisions, and actions based on, such access or use


                </p>
                <p><strong><u>7.5 Access and Security.</u> </strong>The Licensee shall employ all physical, administrative, and technical
                    controls, screening, and security procedures and other safeguards necessary to: (a)
                    securely administer the distribution and use of all Access Credentials and protect against
                    any unauthorized access to, or use of, the Services; and (b) control the content and use of
                    Licensee Data, including the uploading or other provision of Licensee Data and Personal
                    Information for Processing by the Services and the Software, including any Resultant Data.

                </p>
                <h5><strong><u>8. Fees and Payment.
                </u> </strong></h5>
                <p><strong><u>8.1 Fees.</u> </strong>The Licensee shall pay the Licensor the fees, on a monthly subscription basis, as set
                    out in the Order Form (the “Fees”) and in accordance with this Section 8

                </p>
                <p><strong><u>8.2 Taxes.</u> </strong>The Fees and other amounts payable by the Licensee under this Agreement are
                    exclusive of Taxes and similar assessments. Without limiting the foregoing, the Licensee is
                    responsible for all goods and services tax (GST), provincial sales tax (PST), harmonized
                    sales tax (HST) (where applicable), service, use, and excise taxes, and any other similar
                    Taxes, taxes, duties, and charges of any kind imposed by any federal, provincial, or territorial
                    governmental or regulatory authority on any amounts payable by the Licensee hereunder,
                    other than any taxes imposed on the Licensor’s income.

                </p>
                <p><strong><u>8.3 Payment.</u> </strong>The Services are supplied by the Licensor to the Licensee on a monthly
                    subscription basis. By entering into this Agreement, the Licensee agrees that it is authorizing
                    recurring monthly payments, and payments shall be made to the Licensor by the method
                    that the Licensor has agreed to accept from the Licensee, until expiry or termination of the
                    Term. The Licensee shall cancel the Services before the next billing date to avoid being
                    charged an additional billing cycle to continue using the Services. By authorizing recurring
                    payments, the Licensee is authorizing the Licensor to process such payments as either
                    electronic debits, credit card payments, or funds transfers. The Fees shall be charged in
                    advance of the applicable subscription period for the Services.


                </p>
                <p><strong><u>8.4 Currency and Place of Payment.</u> </strong> Unless otherwise specified in the Order Form or this
                    Agreement, the Licensee shall make all payments hereunder in Canadian dollars. The place
                    of payment shall be Vancouver, British Columbia, Canada.


                </p>
                <p><strong><u>8.5 Late Payment.</u> </strong> If the Licensee fails to make any payment when due, then, in addition to all
                    other remedies that may be available to the Licensor, the Licensor may suspend
                    performance of the Services until all past due amounts, and interest thereon, have been
                    paid, without incurring any obligation or liability to the Licensee or any other Person by
                    reason of such suspension.


                </p>
                <p><strong><u>8.6 No Deductions or Set-Offs.</u> </strong> All amounts payable by the Licensee to the Licensor under this
                    Agreement shall be paid by the Licensee to the Licensor in full without any set-off,
                    recoupment, counterclaim, deduction, debit, or withholding for any reason (other than any
                    deduction or withholding of tax as may be required by applicable Law).


                </p>
                <p><strong><u>8.7 Fee Increases.</u> </strong>
                    The Licensor may increase the Fees, from time to time, by providing at least
                    TWENTY (20) Business Days notice to the Licensee and the Fee shall be deemed
                    automatically amended accordingly. The Licensee shall be deemed to have been notified of
                    any increases to the Fee if the Licensor supplies such notice by posting it on the Website or
                    updating the Licensor Materials.

                </p>
                <p><strong><u>8.8 Discounts/Promotions/Offers.</u> </strong>
                    The Licensor may, at its sole discretion, offer the Licensee
                    promotions, discounts, coupons, or rebates (each, a “<b>Discount</b>”) that amends the Fees. Any
                    Discount including, but not limited to, its amount, duration, eligibility requirements, criteria,
                    and redemption periods, shall be solely determined by the Licensor. The Licensor may,
                    without notice or reason and at any time amend or otherwise terminate any Discount.
                    Discounts are limited to one (1) per Licensee and may not be combined with any other offers.
                    Discounts have no cash value, are non-refundable, non-transferable, and may not be resold.

                </p>
                <p><strong><u>8.9 External Payment Processors.</u> </strong> The Licensee accepts and acknowledges that the Licensor
                    may, at its sole discretion, utilize third-party payment services providers for the purposes of
                    accepting and processing the Fees (each, a “<b>Payment Services Provider</b>”). The Licensee
                    accepts that any information, including financial information and Personal Information,
                    provided by the Licensee to a Payment Services Provider shall, at all times, be subject to
                    any terms and conditions of such Payment Services Provider and the Licensee waives any
                    claim or right of claim, whether in contract, tort, equity, or otherwise against the Licensor for
                    any Losses arising from, or related to, any acts or omissions, whether innocent or negligent,
                    caused by a Payment Services Provider. The Licensor receives limited information from a
                    Payment Services Provider and does not receive financial or Personal Information from a
                    Payment Services Provider other than (a) confirmation of successful payment or failure of
                    payment of the Fees, (b) numerical or alphanumerical transaction IDs or reference numbers,
                    (c) such payor information as is necessary to reference payment of the Fees to the Licensee,
                    and (d) the date, time, and currency information in respect of the Fees.
                    The current Third Party Payment Processor used by the Licensor is: <u>Stripe Payments</u>
                    The Licensee accepts that it is the sole responsibility of the Licensee to comply with the
                    terms and conditions of the relevant Payment Services Provider, as such terms and
                    conditions are amended by the Payment Services Provider from time to time.
                </p>

                <h5><strong><u>9. Intellectual Property Rights.
                </u> </strong></h5>
                <p><strong><u>9.1 Licensor.</u> </strong>
                    All right, title, and interest in and to the Services, the Software, the Licensor
                    Materials, including all IP Rights therein, are and shall remain with the Licensor and, with
                    respect to Third-Party Materials, the applicable third-party providers own all right, title, and
                    interest, including all IP Rights, in and to the Third-Party Materials. The Licensee
                    acknowledges and agrees that it has no right, licence, or authorization with respect to any
                    of the Services, the Software, the Licensor Materials, or Third-Party Materials (including any
                    IP Rights therein) except as expressly set forth in Section 2.1 or the applicable third-party
                    licence, in each case subject to Section 3.1. All other rights in and to the Services, the
                    Software, the Licensor Materials, and Third-Party Materials are expressly reserved by the
                    Licensor and the respective third-party licensors.

                </p>
                <p><strong><u>9.2 Licensee.</u> </strong>
                    All right, title, and interest in and to the Licensee’s Data, including all IP Rights
                    therein, are and shall remain with the Licensee. All right, title, and interest (including any IP
                    Rights therein) in the Resultant Data shall automatically vest upon creation by the Software
                    in the Licensee. For the duration of the Term and subject at all times Section 11.5.4 after
                    expiry or termination of this Agreement, the Licensee hereby unconditionally and irrevocably
                    grants all such rights and permissions in or relating to the Licensee’s Data, including
                    Personal Information, and the Resultant Data as are necessary or useful to the Licensor, its
                    Subcontractors, and the Licensor’s Personnel to enforce this Agreement and exercise the
                    Licensor’s, its Subcontractors’, and the Licensor’s Personnel’s rights and perform the
                    Licensor’s, its Subcontractors’, and the Licensor’s Personnel’s obligations hereunder
                    including, but not limited to, supplying the Services, the Software, the Processing, storing
                    the Licensee’s Data (including Personal Information) and monitoring compliance with this
                    Agreement.


                </p>
                <h5><strong><u>10. Confidentiality
                </u> </strong></h5>
                <p><strong><u>10.1 Confidential Information.</u> </strong>
                    In connection with this Agreement each Party (as the "<b>Disclosing
                        Party</b>") may disclose or make available Confidential Information to the other party (as the
                    "Receiving Party"). Subject to Section 10.1, "<b>Confidential Information</b>" means information
                    in any form or medium (whether oral, written, electronic, or other) that the Disclosing Party
                    considers confidential or proprietary, including Personal Information, Licensee Data,
                    Resultant Data, and including information consisting of, or relating to, the Disclosing Party’s
                    technology, trade secrets, know-how, business operations, plans, strategies, customers,
                    and pricing and information with respect to which the Disclosing Party has contractual or
                    other confidentiality obligations, in each case whether or not marked, designated, or
                    otherwise identified as "confidential. Without limiting the foregoing, the Licensor Materials
                    are the Confidential Information of the Licensor.


                </p>
                <p><strong><u>10.2 Exclusions.</u> </strong>
                    Confidential Information does not include information that the Receiving Party
                    can demonstrate by written or other documentary records:


                </p>
                <p><u>10.2.1 </u>
                    was rightfully known to the Receiving Party without restriction on use or disclosure
                    before such information's being disclosed or made available to the Receiving Party
                    in connection with this Agreement;

                </p>
                <p><u>10.2.2</u>
                    was or becomes generally known by the public other than by non-compliance with
                    this Agreement by the Receiving Party or any of its Representatives;


                </p>
                <p><u>10.2.3</u>
                    was or is received by the Receiving Party on a non-confidential basis from a third
                    party that, to the Receiving Party's knowledge, was not or is not, at the time of such
                    receipt, under any obligation to maintain its confidentiality; or


                </p>
                <p><u>10.2.4</u>
                    was or is independently developed by the Receiving Party without reference to or
                    use of any Confidential Information.


                </p>
                <p><strong><u>10.3 Protection of Confidential Information.</u> </strong>
                    As a condition to being provided with any disclosure
                    of or access to Confidential Information, the Receiving Party shall, at all times, including
                    after expiry or termination of this Agreement:


                </p>
                <p><u>10.3.1</u>
                    not access or use Confidential Information other than as necessary to exercise its
                    rights or perform its obligations under and in accordance with this Agreement;


                </p>
                <p><u>10.3.2</u>
                    except as may be permitted by and subject to its compliance with Section 10.4, not
                    disclose or permit access to Confidential Information other than to its
                    Representatives who: (i) need to know such Confidential Information for purposes
                    of the Receiving Party's exercise of its rights or performance of its obligations under
                    and in accordance with this Agreement; (ii) have been informed of the confidential
                    nature of the Confidential Information and the Receiving Party's obligations under
                    this Section 10.3; and (iii) are bound by written confidentiality and restricted use
                    obligations at least as protective of the Confidential Information as the terms set
                    forth in this Section 10.3;

                </p>
                <p><u>10.3.3</u>
                    safeguard the Confidential Information from unauthorized use, access or disclosure
                    using at least the degree of care it uses to protect its similarly sensitive information
                    and in no event less than a reasonable degree of care; and



                </p>
                <p><u>10.3.4</u>
                    ensure its Representatives' compliance with, and be responsible and liable for, any
                    of its Representatives' non-compliance with, the terms of this Section 10.


                </p>
                <p><strong><u>10.4 Compelled Disclosures. </u> </strong>


                </p>
                <p><u>10.4.1 </u>
                    If the Receiving Party or any of its Representatives is compelled by applicable Law
                    to disclose any Confidential Information, then, to the extent permitted by applicable
                    Law, the Receiving Party shall:

                </p>
                <p><u>10.4.1.1 </u>
                    promptly, and before such disclosure, notify the Disclosing Party in
                    writing of such requirement so that the Disclosing Party can seek a
                    protective order or other remedy or waive its rights under Section 10.3;
                    and

                </p>
                <p><u>10.4.1.2 </u>
                    provide reasonable assistance to the Disclosing Party in opposing such
                    disclosure or seeking an injunction, a protective order, or other
                    limitations on disclosure.

                </p>
                <p><u>10.4.2 </u>
                    If the Disclosing Party waives compliance or, after providing the notice and
                    assistance required under this Section 10.4, the Receiving Party remains required
                    by Law to disclose any Confidential Information, the Receiving Party shall disclose
                    only that portion of the Confidential Information that, on the advice of the Receiving
                    Party's legal counsel, the Receiving Party is legally required to disclose.

                </p>
                <h5><strong><u>11. Term and Termination.
                </u> </strong></h5>
                <p><strong><u>11.1 Term.</u> </strong>
                    This Agreement commences on date that:

                </p>
                <p><u>11.1.1 Term.</u>
                    the Licensee clicks or selects the “I ACCEPT” button on the Website and which
                    forms part of the Order Form; and

                </p>
                <p><u>11.1.2 Term.</u>
                    the Licensor receives the Fees from the Licensee (whichever is earlier) (the "<b>Initial Term</b>").
                </p>
                <p><strong><u>11.2 Renewal. </u> </strong>
                    This Agreement shall automatically renew, each month, on the monthly
                    anniversary of the Initial Term (the “Renewal Term”, and collectively with the Initial Term,
                    the “Term”) unless earlier terminated under this Agreement’s express provisions or by either
                    Party giving the other Party written notice of non-renewal prior to the next monthly renewal
                    of the Term.

                </p>
                <p><strong><u>11.3 No Pro-Rata Credit on Termination.  </u> </strong>
                    Termination by the Licensee of this Agreement in the
                    period between monthly renewals of the Term shall not entitle the Licensee to a pro-rata
                    refund or credit of the Fee. The Licensee and its Authorized Users shall continue to have
                    access to the Services until the expiry of the balance of the Term then remaining after the
                    Licensor has received notice of the Licensee’s termination of this Agreement.


                </p>
                <p><strong><u>11.4 Termination.  </u> </strong>
                    In addition to any other express termination rights set forth elsewhere in this
                    Agreement, the Licensor may terminate this Agreement, effective on written notice to the
                    Licensee, if the Licensee:


                </p>
                <p><u>11.4.1  </u>
                    fails to pay any amount when due hereunder, and such failure continues more than
                    THREE (3) Business Days after the Licensor’s delivery of written notice thereof; or



                </p>
                <p><u>11.4.2  </u>
                    breaches any of its obligations under this Agreement. Either Party may terminate this Agreement, effective immediately upon written notice to the
                    other Party, if the other Party:



                </p>
                <p><u>11.4.3  </u>
                    fails to pay its debts generally as they become due or otherwise acknowledges its
                    insolvency;

                </p>
                <p><u>11.4.4  </u>
                    ceases to carry on business in the ordinary course;


                </p>
                <p><u>11.4.5  </u>
                    makes a general assignment for the benefit of its creditors;

                </p>
                <p><u>11.4.6  </u>
                    has issued against it a bankruptcy order or otherwise becomes subject to any
                    involuntary proceeding under any domestic or foreign bankruptcy law; or


                </p>
                <p><u>11.4.7  </u>
                    commences or institutes any application, proceeding, or other action under any Law
                    relating to bankruptcy, insolvency, winding-up, reorganization, administration, plan
                    of arrangement, relief or protection of debtors, compromise of debts, or similar
                    Laws, seeking: (A) to have an order for relief entered with respect to it; (B) to
                    adjudicate it as bankrupt or insolvent; (C) reorganization, arrangement, adjustment,
                    winding-up, liquidation, dissolution, composition, compromise, arrangement, stay
                    of proceedings of creditors generally, or other relief with respect to it or its assets
                    or debts; or (D) appointment of a receiver, interim receiver, receiver and manager,
                    trustee, custodian, conservator, or other similar official for it or for all or any part of
                    its assets.

                </p>
                <p><strong><u>11.5 Effect of Termination or Expiry  </u> </strong>
                    Upon any expiration or termination of this Agreement, except
                    as expressly otherwise provided in this Agreement:

                </p>
                <p><u>11.5.1  </u>
                    all rights, licences, consents, and authorizations granted by either Party to the other
                    hereunder shall immediately terminate;



                </p>
                <p><u>11.5.2  </u>
                    the Licensor shall cease all use of any Licensee Data and the Licensee’s
                    Confidential Information and (i) return to the Licensee, or at the Licensor’s sole
                    discretion, destroy, all Licensee Data and Licensee’s Confidential Information,
                    including all documents and tangible materials containing, reflecting, incorporating,
                    or based on the Licensee Data or Licensee’s Confidential Information; and (ii)
                    irrevocably erase all Licensee Data and Licensee’s Confidential Information from
                    the Licensor Systems including any Resultant Data;



                </p>
                <p><u>11.5.3  </u>
                    the Licensee shall immediately cease all use of the Services, the Software, and the
                    Licensor Materials and (i) return to the Licensor, or at the Licensor’s written request
                    destroy, all documents and tangible materials containing, reflecting, incorporating.
                    or based on any of the Licensor Materials or the Licensor’s Confidential Information;
                    (ii) permanently erase all the Licensor Materials and the Licensor’s Confidential
                    Information from all computer systems that the Licensee directly or indirectly
                    controls; and (iii) upon the Licensor’s written request, and no later than TEN (10)
                    Business Days from the date of the Licensor’s written request, to certify to the
                    Licensor in a statutory declaration of a senior officer of Licensee that it has complied
                    with the requirements of this Section 11.5.3;


                </p>
                <p><u>11.5.4  </u>
                    notwithstanding anything to the contrary in this Agreement, with respect to
                    information and materials then in its possession or control: (i) the Receiving Party
                    may retain the Disclosing Party's Confidential Information in its then current state
                    and solely to the extent and for so long as required by applicable Law; (ii) the
                    Licensor may retain the Licensee Data, including Licensee’s Confidential
                    Information, and Resultant Data in its then current state and solely to the extent
                    and for so long as required by applicable Law; (iii) the Licensor may also retain
                    Licensee Data, including Licensee’s Confidential Information, and Resultant Data
                    in its backups, archives, and disaster recovery systems until such Licensee Data,
                    Licensee’s Confidential Information, and Resultant Data is deleted in the ordinary
                    course; and (iv) all information and materials described in this Section 11.5.4 shall
                    remain subject to all confidentiality, security, and other applicable requirements of
                    this Agreement; and


                </p>
                <p><u>11.5.5  </u>
                    the Licensor shall disable the Licensee and its Authorized Users access to the
                    Services, the Software, and the Licensor Materials.



                </p>
                <p><strong><u>11.6 Survival.  </u> </strong>
                    The rights and obligations of the Parties set forth in this Section 11.6, and Section
                    3.1 (Use Restrictions), Section 10 (Confidentiality), Section 11.5 (Effect of Termination or
                    Expiry), Section 12 (Representations and Warranties), Section 13 (Indemnification), Section
                    14 (Limitation of Liability), and Section 16 (Miscellaneous), and any right or obligation of the
                    Parties in this Agreement which, by its nature, should survive termination or expiration of
                    this Agreement, will survive any such termination or expiration of this Agreement.
                </p>

                <h5><strong><u>12. Representations and Warranties.
                </u> </strong></h5>
                <p><strong><u>12.1 Licensee’s Representations, Warranties, and Covenants.</u> </strong>
                    The Licensee represents and
                    warrants to the Licensor that:
                </p>

                <p><u>12.1.1  </u>
                    if the Licensee is a corporation, limited partnership, or sole proprietorship then the
                    Licensee is incorporated, formed, or otherwise registered and validly existing in the
                    jurisdiction of its incorporation or formation;

                </p>
                <p><u>12.1.2  </u>
                    if the Licensee is not a corporation, limited partnership, or sole proprietorship, then
                    the Licensee is an individual who is a duly registered, authorized, or licensed
                    mortgage specialist or mortgage broker in the jurisdiction in which the Licensee
                    conducts its business activities;
                </p>
                <p><u>12.1.3  </u>
                    it has all required power and capacity to enter into this Agreement, to grant the
                    rights and licences granted under this Agreement and to perform its obligations
                    under this Agreement;

                </p>
                <p><u>12.1.4  </u>
                    the acceptance of this Agreement by the Licensee or its Representative by clicking
                    the “I ACCEPT” button on the Licensor’s website has been duly authorized by all
                    necessary corporate action;
                </p>
                <p><u>12.1.5  </u>
                    this Agreement will constitute the legal, valid, and binding obligation of the
                    Licensee, enforceable by the Licensor in accordance with its terms, except as may
                    be limited by any applicable bankruptcy, insolvency, reorganization, arrangement,
                    moratorium, or similar laws related to or affecting creditors' rights generally or the
                    effect of general principles of equity;
                </p>
                <p><u>12.1.6  </u>
                    the Licensee owns or otherwise has, and will have, the necessary rights and
                    consents in and relating to the Licensee Data, Personal Information, and Licensee
                    Confidential Information so that, as received by the Licensor and Processed in
                    accordance with this Agreement, they do not and will not infringe, misappropriate,
                    or otherwise violate any IP Rights, or any privacy or other rights of any third party
                    or violate any applicable Law;
                </p>
                <p><u>12.1.7  </u>
                    the Licensee has a privacy policy regarding the collection, use, and disclosure of
                    Personal Information including the collection, use, and disclosure of Personal
                    Information for the purposes of using the Services and that the Licensee is, and
                    shall continue for the duration of the Term, to be in compliance with such privacy
                    policy;
                </p>
                <p><u>12.1.8  </u>
                    the Licensee has complied at all times with all applicable Laws regarding the
                    collection, retention, use, processing, disclosure, transfer, and protection of
                    Personal Information, and all cyber incident, information security, and data breach
                    notification and record-keeping requirements;

                </p>
                <p><u>12.1.9  </u>
                    the Licensee is in compliance with the terms of all contracts to which the Licensee
                    is a party relating to data privacy, security, or breach notification (including
                    provisions that impose conditions or restrictions on the collection, use, disclosure,
                    transmission, transfer, destruction, disposal, maintenance, storage, or
                    safeguarding of Personal Information);
                </p>
                <p><u>12.1.10  </u>
                    the execution, delivery, and performance of this Agreement and the consummation
                    of the contemplated transactions, including any transfer of Personal Information
                    resulting from such transactions, shall not violate any applicable Law as it currently
                    exists or as it existed at any time during which any Personal Information was
                    transferred by the Licensee to the Licensor for the purposes of supplying the
                    Services;
                </p>
                <p><u>12.1.11 </u>
                    the Licensee has established, implemented, and shall maintain for the duration of
                    the Term, policies, programs, and procedures that are in compliance with
                    applicable Laws, including administrative, technical, and physical safeguards, to
                    protect the confidentiality, integrity, and security of Personal Information in the
                    Licensee’s possession, custody, or control against unauthorized access, use,
                    modification, disclosure, or other misuse;
                </p>
                <p><u>12.1.12  </u>
                    the Licensee shall only use the Services and the Software strictly for business
                    purposes and the Services, the Software, including the Licensee Data and any
                    Resultant Data shall not be used for personal, family, or household use;
                </p>
                <p><u>12.1.13  </u>
                    the Licensee is not a consumer, as defined in the Business Practices and
                    Consumer Protection Act SBC 2004 as amended, replaced, superseded, from time
                    to time; and

                </p>
                <p><u>12.1.14  </u>
                    the Licensee is not a corporation, limited partnership, sole proprietorship, or
                    individual registered, authorized, or licensed mortgage specialist or mortgage
                    broker in Quebec.

                </p>
                <p><strong><u>12.2 Licensor’s Representations, Warranties, and Covenants.</u> </strong>
                    The Licensor represents and
                    warrants to the Licensee that:

                </p>
                <p><u>12.2.1  </u>
                    the Licensor will perform the Services using personnel of required skill, experience,
                    and qualifications and in a professional and workmanlike manner in accordance
                    with reasonable commercial standards and shall devote adequate resources to
                    meet its obligations under this Agreement; and


                </p>
                <p><u>12.2.2  </u>
                    the Services and the Software shall substantially contain the functionality described
                    in the Licensor Materials and, when properly accessed on a computer meeting the
                    specifications set forth in in the Licensor Materials, and operated in accordance
                    with, the in the Licensor Materials, shall substantially perform in accordance
                    therewith.
                    The warranties set forth in this Section 12.2 shall not apply and shall become null and void
                    if the Licensee breaches any provision of this Agreement, or if the Licensee, any of its
                    Authorized Users, or any other Person provided access to the Services or Software by
                    Licensee or any of its Authorized Users, whether or not in violation of this Agreement:

                </p>
                <p><u>12.2.3  </u>
                    uses the Services or the Software on or in connection with any hardware or
                    software not specified in the Licensor Materials;
                </p>
                <p><u>12.2.4  </u>
                    modifies or damages the Services, the Software, the Licensor Systems, the
                    Licensor Materials, or the media on which it is provided, including abnormal
                    physical or electrical stress; or

                </p>
                <p><u>12.2.5  </u>
                    misuses the Services, the Software, or the Licensor Systems, including any use of
                    the Services or the Software other than as specified in the Licensor Materials.

                </p>
                <p><strong><u>12.3 DISCLAIMER OF CONDITIONS AND WARRANTIES.</u> </strong>
                    EXCEPT FOR THE EXPRESS
                    WARRANTIES SET FORTH IN SECTION 12.1 AND SECTION 12.2, ALL SERVICES,
                    INCLUDING BUT NOT LIMITED TO, THE SERVICES, THE SOFTWARE, THE WEBSITE,
                    THE LICENSOR MATERIALS, THE LICENSOR SYSTEMS, ARE PROVIDED "AS IS" AND
                    THE LICENSOR HEREBY DISCLAIMS ALL CONDITIONS AND WARRANTIES,
                    WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE UNDER THIS
                    AGREEMENT, AND THE LICENSOR SPECIFICALLY DISCLAIMS ALL IMPLIED
                    CONDITIONS AND WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                    PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES
                    ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. WITHOUT
                    LIMITING THE FOREGOING, THE LICENSOR MAKES NO CONDITION OR WARRANTY
                    OF ANY KIND THAT THE SERVICES, THE SOFTWARE, THE LICENSOR MATERIALS,
                    OR ANY RESULTANT DATA THEREOF, SHALL (a) MEET THE LICENSEE’S OR ANY
                    OTHER PERSON'S REQUIREMENTS; (b) OPERATE WITHOUT INTERRUPTION; (c)
                    ACHIEVE ANY INTENDED RESULT; (d) BE COMPATIBLE OR WORK WITH ANY
                    SOFTWARE, SYSTEM, OR OTHER SERVICES; OR (e) BE SECURE, ACCURATE,
                    COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. ALL THIRD-PARTY
                    MATERIALS ARE PROVIDED "AS IS" AND ANY REPRESENTATION OR WARRANTY OF
                    OR CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY BETWEEN THE
                    LICENSEE AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY
                    MATERIALS.

                </p>
                <p><strong><u>12.4 Licensor’s Non-Solicitation.</u> </strong>
                    Except with the prior written consent of the Licensee or as may
                    be provided in any written agreement between the Parties entered into after or during the
                    Term of this Agreement, the Licensor agrees that during the Term and for a period of six (6)
                    months after the expiration or earlier termination of the Term, the Licensor and its
                    Representatives shall not, directly or indirectly, for itself or on behalf of another person or
                    entity, solicit business from or induce, influence, or encourage, any client, customer,
                    supplier, or other similar third party of the Licensee that became known to the Licensor,
                    directly or directly, pursuant to any Confidential Information or any discussions or
                    communications relating to the supply of the Services by the Licensor or its Representatives,
                    to the Licensee. Notwithstanding the foregoing, nothing in this Section 12.4 restricts the
                    Licensor from soliciting business from or engaging in business with any client, customer,
                    supplier, or other similar third party in the normal course of business, so long as the Licensor
                    does not use any Confidential Information to identify such client, customer, supplier, or other
                    similar third party or to communicate or negotiate with such client, customer, supplier, or
                    other similar third party.

                </p>
                <h5><strong><u>13. Indemnification.
                </u> </strong></h5>
                <p><strong><u>13.1 Licensee’s Indemnification.</u> </strong>
                    The Licensee shall indemnify, defend, and hold harmless the
                    Licensor, its Representatives, and its Subcontractors, and each of its and their respective
                    officers, directors, employees, agents, successors, and permitted assigns (each, a
                    "<b>Licensor Indemnitee</b>") from and against any and all Losses incurred by such Licensor
                    Indemnitee in connection with any Action by a third party that arises out of or relates to any:

                </p>
                <p><u>13.1.1  </u>
                    Licensee Data, including any Personal Information, Processing of Licensee Data
                    (including Personal Information) by or on behalf of the Licensor in accordance with
                    this Agreement;

                </p>
                <p><u>13.1.2  </u>
                    any other materials or information (including any documents, data, specifications,
                    software, content, or technology) provided by or on behalf of the Licensee or any
                    of its Authorized Users, including the Licensor’s compliance with any specifications
                    or directions provided by or on behalf of the Licensee or any of its Authorized Users
                    to the extent prepared without any contribution by the Licensor;

                </p>
                <p><u>13.1.3  </u>
                    allegation of facts that, if true, would constitute the Licensee’s breach of any of its
                    representations, warranties, covenants, or obligations under this Agreement; or

                </p>
                <p><u>13.1.4  </u>
                    negligence or more culpable act or omission (including recklessness or wilful
                    misconduct) by the Licensee, any of its Authorized Users, or any third party on
                    behalf of the Licensee or any of its Authorized Users, in connection with this
                    Agreement.
                </p>
                <p><strong><u>13.2 Indemnification Procedure.</u> </strong>
                    The Licensor shall notify the Licensee in writing of any Action for
                    which the Licensor believes it is entitled to be indemnified under Section 13.1. The Licensor
                    (the "<b>Indemnitee</b>") shall cooperate with the Licensee (the "<b>Indemnitor</b>") at the Indemnitor's
                    sole cost and expense. The Indemnitor shall immediately take control of the defence and
                    investigation of such Action and shall employ counsel to handle and defend the same, at the
                    Indemnitor's sole cost and expense. The Indemnitee's failure to perform any obligations
                    under this Section 13.2 shall not relieve the Indemnitor of its obligations under this Section
                    13. The Indemnitee may participate in and observe the proceedings at its own cost and
                    expense with counsel of its own choosing.

                </p>
                <p><strong><u>13.3 Mitigation.</u> </strong>
                    If any of the Services, the Software, or Licensor Materials are, or in Licensor’s
                    opinion are likely to be, claimed to infringe, misappropriate, or otherwise violate any thirdparty IP Rights, or if the Licensee’s or any of its Authorized Users use of the Services, the
                    Software, or the Licensor Materials is enjoined or threatened to be enjoined, the Licensor
                    may, at its sole discretion and option:

                </p>
                <p><u>13.3.1  </u>
                    modify or replace the Services, the Software, and the Licensor Materials, in whole
                    or in part, to seek to make the Services, the Software, and the Licensor Materials
                    (as so modified or replaced) non-infringing, while providing reasonably equivalent
                    features and functionality, in which case such modifications or replacements will
                    constitute the Services, the Software, and the Licensor Materials, as applicable,
                    under this Agreement; or
                </p>
                <p><u>13.3.2  </u>
                    by written notice to the Licensee, terminate this Agreement with respect to all or
                    part of the Services, the Software, and the Licensor Materials, and require the
                    Licensee to immediately cease any use of the Services, the Software, and Licensor
                    Materials or any specified part or feature thereof.
                </p>
                <h5><strong><u>14. Limitations of Liability.
                </u> </strong></h5>
                <p><strong><u>14.1 EXCLUSION OF DAMAGES.</u> </strong>
                    EXCEPT AS OTHERWISE PROVIDED IN SECTION 14.3, IN
                    NO EVENT SHALL THE LICENSOR OR ANY OF ITS REPRESENTATIVES,
                    SUBCONTRACTORS, SERVICE PROVIDERS, OR SUPPLIERS BE LIABLE UNDER OR
                    IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY
                    LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT
                    (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a) LOSS
                    OF PRODUCTION, USE, BUSINESS, REVENUE, OR PROFIT OR DIMINUTION IN
                    VALUE; (b) IMPAIRMENT, INABILITY TO USE, OR LOSS, INTERRUPTION, OR DELAY
                    OF THE SERVICES; (c) LOSS, DAMAGE, CORRUPTION, OR RECOVERY OF DATA; (d)
                    BREACH OF DATA OR SYSTEM SECURITY; OR (e) CONSEQUENTIAL, INCIDENTAL,
                    INDIRECT, SPECIAL, AGGRAVATED, PUNITIVE, OR EXEMPLARY DAMAGES,
                    REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY
                    OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE
                    OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY
                    AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.


                </p>
                <p><strong><u>14.2 CAP ON MONETARY LIABILITY.</u> </strong>
                    EXCEPT AS OTHERWISE PROVIDED IN SECTION
                    14.3, IN NO EVENT SHALL THE COLLECTIVE AGGREGATE LIABILITY OF THE
                    LICENSOR AND ITS REPRESENTATIVES, SUBCONTRACTORS, SERVICE
                    PROVIDERS, AND SUPPLIERS UNDER OR IN CONNECTION WITH THIS AGREEMENT
                    OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING
                    BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND
                    OTHERWISE, EXCEED ONE (1) MONTHS FEES. THE FOREGOING LIMITATION
                    APPLIES NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY
                    OF ITS ESSENTIAL PURPOSE.


                </p>
                <p><strong><u>14.3 Exceptions.</u> </strong>
                    The exclusions and limitations in Section 14.1 and Section 14.2 do not apply to
                    liability for the Licensor’s gross negligence or wilful misconduct.


                </p>
                <h5><strong><u>15. Force Majeure.
                </u> </strong></h5>
                <p><strong><u>15.1 No Breach or Default.</u> </strong>
                    In no event shall the Licensor be liable or responsible to the Licensee
                    or be deemed to have defaulted under or breached this Agreement, for any failure or delay
                    in fulfilling or performing any term of this Agreement when and to the extent such failure or
                    delay is caused by or results from acts beyond the Licensor’s reasonable control, including,
                    without limitation: (i) acts of God; (ii) flood, fire, earthquake, tsunami, or explosion; (iii)
                    epidemics, pandemics, including the 2019 novel coronavirus disease (COVID-19) pandemic
                    and any of its current or future mutations or variations; (iv) war, terrorism, invasion, riot, or
                    other civil unrest; (v) actions, embargoes, or blockades in effect on or after the date of this
                    Agreement; (vi) national or regional emergency; (vii) strikes, labour stoppages or
                    slowdowns, or other industrial disturbances; (viii) passage of Law or any action taken by a
                    governmental or public authority, including imposing an embargo, export or import
                    restriction, quota or other restriction or prohibition, or any complete or partial government
                    shutdown; (ix) national or regional shortage of adequate power or telecommunications or
                    transportation facilities; or (x) any other event that is beyond the reasonable control of the
                    Licensor (each of the foregoing, a "<b>Force Majeure Event</b>") .


                </p>
                <p><strong><u>15.2 Licensor’s Obligations.</u> </strong>
                    If the Licensor is affected by a Force Majeure Event then the Licensor
                    shall notify the Licensee, stating the period of time the occurrence is expected to continue
                    and shall use reasonable efforts to end the failure or delay and minimize the effects of such
                    Force Majeure Event. For the avoidance of doubt, minimization by the Licensor of such
                    Force Majeure Event shall not obligate the Licensor to take extraordinary steps or spend
                    funds, if any, beyond that which the Licensor, at its sole discretion, deems to be reasonable
                    in the circumstances.


                </p>
                <p><strong><u>15.3 Non-Affected Party.</u> </strong>
                    During a Force Majeure Event, the performance of the Licensee’s
                    obligations shall be suspended until such time as the Licensor resumes performance.


                </p>
                <p><strong><u>15.4 Right to Terminate.</u> </strong>
                    The Licensee may terminate this Agreement by giving written notice to
                    the Licensor if such failure or delay constituted by a Force Majeure Event continues for a
                    period of FIFTEEN (15) Business Days or more commencing from the date that the Licensor
                    notifies the Licensee of a Force Majeure Event. Unless this Agreement is terminated in
                    accordance with this Section 15.4, the Term of this Agreement shall continue and be
                    automatically renewed in accordance with Section 11.

                </p>
                <h5><strong><u>16. Miscellaneous
                </u> </strong></h5>
                <p><strong><u>16.1 Further Assurances.</u> </strong>
                    The Licensee shall, upon the reasonable request, of the Licensor
                    promptly execute such documents and perform such acts as may be necessary to give full
                    effect to the terms of this Agreement.

                </p>
                <p><strong><u>16.2 Relationship of the Parties. </u> </strong>
                    The relationship between the Parties is that of independent
                    contractors. Nothing contained in this Agreement shall be construed as creating any agency,
                    partnership, joint venture, or other form of joint enterprise, employment, or fiduciary
                    relationship between the Parties, and neither Party shall have authority to contract for or
                    bind the other Party in any manner whatsoever.

                </p>
                <p><strong><u>16.3 Public Announcements.</u> </strong>
                    Neither Party shall issue or release any announcement, statement,
                    press release, or other publicity or marketing materials relating to this Agreement or
                    otherwise use the other Party's trademarks, trade dress, brand names, logos, corporate
                    names, and domain names, or other similar designations of source, sponsorship,
                    association, or origin, in each case, without the prior written consent of the other Party.
                    Notwithstanding the foregoing, the Licensor may, without the Licensee’s consent, include
                    the Licensee’s name and other indicia in its lists of the Licensor’s current or former
                    customers of the Licensor in promotional and marketing materials, including the Website.


                </p>
                <p><strong><u>16.4 Notices.</u> </strong>
                    All notices, requests, consents, claims, demands, waivers, and other
                    communications hereunder shall be in writing and shall be deemed to have been given: (i)
                    when delivered by hand (with written confirmation of receipt); (ii) when received by the
                    addressee if sent by a nationally recognized overnight courier (receipt requested); (iii) on
                    the date sent by facsimile or e-mail (with confirmation of transmission) if sent during normal
                    business hours of the recipient, and on the next Business Day if sent after normal business
                    hours of the recipient; or (iv) on the SECOND (2) Business Day after the date mailed, by
                    certified or registered mail by the Canada Post Corporation, return receipt requested,
                    postage prepaid. Such communications must be sent to the respective parties at the
                    addresses set forth on the Order Form (or to such other address as may be designated by
                    a party from time to time in accordance with this Section).
                </p>
                <p><strong><u>16.5 Entire Agreement.</u> </strong>
                    This Agreement, together with the Order Form and the Privacy and
                    Security Policy, and all other documents that are incorporated by reference herein,
                    constitutes the sole and entire agreement between Licensee and Licensor with respect to
                    the subject matter contained herein, and supersedes all prior and contemporaneous
                    understandings, agreements, representations, and warranties, both written and oral, with
                    respect to such subject matter.


                </p>
                <p><strong><u>16.6 Assignment.</u> </strong>
                    The Licensee shall not assign or otherwise transfer any of its rights, or delegate
                    or otherwise transfer any of its obligations or performance, under this Agreement, in each
                    case whether voluntarily, involuntarily, by operation of law or otherwise, without the
                    Licensor's prior written consent, which consent the Licensor may give or withhold in its sole
                    discretion. For purposes of the preceding sentence, and without limiting its generality, any
                    amalgamation, arrangement, or reorganization involving the Licensee shall be deemed to
                    be a transfer of rights, obligations, or performance under this Agreement for which the
                    Licensor's prior written consent is required. No delegation or other transfer will relieve the
                    Licensee of any of its obligations or performance under this Agreement. Any purported
                    assignment, delegation, or transfer in violation of this Section 16.6 is void. The Licensor may
                    freely assign or otherwise transfer all or any of its rights, or delegate or otherwise transfer
                    all or any of its obligations or performance under this Agreement without the Licensee's
                    consent. This Agreement is binding upon and enures to the benefit of the parties hereto and
                    their respective permitted successors and assigns.

                </p>
                <p><strong><u>16.7 Successors and Assigns; No Third-Party Beneficiaries.</u> </strong>
                    This Agreement is for the sole
                    benefit of the Parties hereto and their respective permitted successors and permitted
                    assigns and nothing herein, express or implied, is intended to or shall confer on any other
                    Person any legal or equitable right, benefit, or remedy of any nature whatsoever under or by
                    reason of this Agreement.

                </p>
                <p><strong><u>16.8 Amendments and Modifications.</u> </strong>
                    This Agreement may be amended, modified, or
                    supplemented by the Licensor from time to time without notice and at its sole discretion. If a
                    change, in the Licensor’s sole discretion, is material, the Licensor shall notify the Licensee
                    of the change electronically (including without limitation, by e-mail or posting on the Website)
                    that this Agreement has been “updated” or using similar words. The Licensee accepts that
                    it shall be deemed to have been notified of any amendment, modification, or
                    supplementation by the Licensor if the Licensor supplies notice as detailed in this Section
                    16.8.
                </p>
                <p><strong><u>16.9 Waiver.</u> </strong>
                    No waiver by any Party of any of the provisions hereof shall be effective unless
                    explicitly set forth in writing and signed by the party so waiving. Except as otherwise set forth
                    in this Agreement, no failure to exercise, or delay in exercising, any right, remedy, power, or
                    privilege arising from this Agreement shall operate or be construed as a waiver thereof; nor
                    shall any single or partial exercise of any right, remedy, power, or privilege hereunder
                    preclude any other or further exercise thereof or the exercise of any other right, remedy,
                    power, or privilege.

                </p>
                <p><strong><u>16.10 Severability.</u> </strong>
                    If any term or provision of this Agreement is invalid, illegal, or unenforceable in
                    any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term
                    or provision of this Agreement or invalidate or render unenforceable such term or provision
                    in any other jurisdiction.

                </p>
                <p><strong><u>16.11 Governing Law.</u> </strong>
                    This Agreement and all related documents and all matters arising out of or
                    relating to this Agreement, whether sounding in contract, tort, or statute, are governed by,
                    and construed in accordance with, the laws of the Province of British Columbia and the
                    federal laws of Canada applicable therein, without giving effect to any choice or conflict of
                    law provision or rule (whether of the Province of British Columbia or any other jurisdiction)
                    that would cause the application of the laws of any jurisdiction other than those of the
                    Province of British Columbia.

                </p>
                <p><strong><u>16.12 Choice of Forum. </u> </strong>
                    Any legal suit, action, litigation, or proceeding of any kind whatsoever in
                    any way arising out of, from, or relating to this Agreement, including all related documents,
                    the services provided hereunder, and all contemplated transactions, shall be instituted in the
                    courts of the Province of British Columbia, and each Party irrevocably submits to the
                    exclusive jurisdiction of such courts in any such suit, action, litigation, or proceeding. Service
                    of process, summons, notice, or other document by mail to such Party's address set forth
                    herein shall be effective service of process for any suit, action, litigation, or other proceeding
                    brought in any such court. Each Party agrees that a final judgment in any such suit, action,
                    litigation, or proceeding is conclusive and may be enforced in other jurisdictions by suit on
                    the judgment or in any other manner provided by law. The Licensee irrevocably and
                    unconditionally waives any objection to the venue of any action or proceeding in such courts
                    and irrevocably waives and agrees not to plead or claim in any such court that any such
                    action or proceeding brought in any such court has been brought in an inconvenient forum.

                </p>
                <p><strong><u>16.13 Quebec</u> </strong>
                    The Services and the Software offered by the Licensor are not available to
                    businesses and/or individuals in Quebec. / Les Services et le Logiciel offerts par le
                    Concédant ne sont pas disponibles pour les entreprises et/ou les particuliers au Québec.
                </p>
                <p><strong><u>16.14 Equitable Relief.</u> </strong>
                    The Licensee acknowledges and agrees that a breach or threatened
                    breach by the Licensee of any of its obligations under Section 10 (Confidentiality), Section
                    3.1 (Use Restrictions), Section 4.3 (Corrective Action and Notice), or Section 7.3 (Licensee
                    Control and Responsibility) would cause the Licensor irreparable harm for which monetary
                    damages would not be an adequate remedy and the Licensee that, in the event of such
                    breach or threatened breach, the Licensor will be entitled to seek equitable relief, including
                    a restraining order, an injunction, specific performance, and any other relief that may be
                    available from any court, without any requirement to post a bond or other security, or to
                    prove actual damages or that monetary damages are not an adequate remedy. Such
                    remedies are not exclusive and are in addition to all other remedies that may be available
                    at law, in equity or otherwise.
                </p>
                <p><strong><u>16.15 Export Regulation. </u> </strong>
                    The Services may be subject to Canadian export control laws. The
                    Licensee shall not, directly or indirectly, use, utilize, access, download, upload, transmit, or
                    interact with the Licensor’s Systems, the Services, the Processing, or the Resultant Data, or
                    make the Services or the Licensor’s Material accessible from, any jurisdiction or country to
                    which export, re-export, or release is prohibited by law, rule, or regulation. The Licensee
                    shall comply with all applicable federal laws, regulations, and rules, and complete all
                    required undertakings (including obtaining any necessary export licence or other
                    governmental approval), before using, utilizing, accessing, downloading, uploading,
                    transmitting, or otherwise interacting with Licensor’s Systems, the Services, the Processing,
                    or the Resultant Data outside Canada.
                </p>
                <p><strong><u>16.16 Time of the Essence.</u> </strong>
                    Time shall be of the essence in this Agreement.

                </p>
                <p><strong><u>16.17 Interpretation.</u> </strong>
                    For purposes of this Agreement, (a) the words "include," "includes," and
                    "including" shall be deemed to be followed by the words "without limitation"; (b) the word "or"
                    is not exclusive; and (c) the words "herein," "hereof," "hereby," "hereto," and "hereunder"
                    refer to this Agreement as a whole. Unless the context otherwise requires, references
                    herein: (i) to Sections refer to the Sections of this Agreement; (ii) to an agreement,
                    instrument, or other document means such agreement, instrument, or other document as
                    amended, supplemented, and modified from time to time to the extent permitted by the
                    provisions thereof; and (iii) to a statute means such statute as amended from time to time
                    and includes any successor legislation thereto and any regulations promulgated thereunder.
                    This Agreement shall be construed without regard to any presumption or rule requiring
                    construction or interpretation against the party drafting an instrument or causing any
                    instrument to be drafted. The Order Form referred to herein shall be construed with, and as
                    an integral part of, this Agreement to the same extent as if they were set forth verbatim
                    herein. Unless otherwise stated, all dollar amounts referred to in this Agreement are stated
                    in Canadian dollars.
                </p>
                <p><strong><u>16.18 Language.</u> </strong>
                    The Parties confirm that it is their express wish that this Agreement, as well as
                    any other documents related to this Agreement, including notices, schedules, and
                    authorizations, have been and shall be drawn up in the English language only.


                </p>
                <p><strong><u>16.19 Headings. </u> </strong>
                    The headings in this Agreement are for reference only and do not affect the
                    interpretation of this Agreement.


                </p>
            </div>
            <HomeFooter mediumBreakpoint />
        </div>
    )
}