import React, { useEffect, useState } from 'react';
import { Container, FormControl, MenuItem, Select, Typography } from '@mui/material/';
import { useAuthContext } from "../Context/AuthContext";
import { Helmet } from 'react-helmet';

// Component Imports
import { MergeNav } from '../MergeProcess/components/MergeNav';
import { MergeManagementStyle } from './assets/MergeManagementStyle';
import Loader from '../MergeProfile/components/Loader';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Interface


export default function MergeManagement() {
    const { token, setToken } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [mergeProfiles, setMergeProfiles] = useState<any[]>([]);
    const [selectedProfileId, setselectedProfileId] = useState<any>(null);
    const [mergeDocuments, setMergeDocuments] = useState<any>(null);
    const [error, setError] = useState("");

    const classes = MergeManagementStyle();


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        setLoading(true);
        axios.get("./api/merge-task/profile").then((resp: any) => {
            setMergeProfiles(filterTasks(resp.data));
            setselectedProfileId(resp.data[0]?.id);
            fetchTable(resp.data[0]?.id)
            setLoading(false);
        }).catch(() => {
            setError("Unable to fetch data, please try again later");
            setLoading(false);
        })
    }, [])
    const filterTasks = (tasks: any) => {
        return tasks.filter((x: any) => new Date(x.purgeDateTime) > new Date(Date.now()));
    }
    const fetchTable = (mergerTaskId: number) => {
        axios.get(`api/merge-task/${mergerTaskId}`).then((result) => {
            setLoading(true);
            if (result.status === 200) {
                setMergeDocuments(result.data?.documents.map((x: any) => {
                    if (x.type == 1) {
                        x.type = `${x.type}00${x.typeVariation}`
                    }
                    return x;
                }))
                setLoading(false);

            }
        }).catch((e) => {
            setLoading(false);

        })
    }
    const onCategoryChange = (categoryId: number, row: any) => {
        setLoading(true);

        axios.put(`api/mergetaskdocument/ChangeCategory`,
            {
                MergeDocumentID: row.id,
                Type: categoryId,
            }).then((result) => {
                setLoading(true);
                if (result.status === 200 && result.data.success) {
                    if (result.data.type == 1) {
                        row.type = `${result.data.type}00${result.data.typeVariation}`
                    }
                    else 
                    {
                        row.type = result.data.type;
                    }                                                           
                }
                setLoading(false);
            }).catch((e) => {
                setLoading(false);

            })
    }
    const menuItems = mergeProfiles.map((profile, index) => (
        <MenuItem key={index} value={profile.id}>
            {profile.name}
        </MenuItem>
    ));
    const documentTypes = [
        { id: 1001, name: 'Income - 1' },
        { id: 1002, name: 'Income - 2' },
        { id: 1003, name: 'Income - 3' },
        { id: 1004, name: 'Income - 4' },
        { id: 2, name: 'Subject Property' },
        { id: 3, name: 'Non-Subject Property' },
        { id: 4, name: 'Net Worth' },
        { id: 5, name: 'Debt Payout' },
        { id: 6, name: 'Miscellaneous' },
        { id: 7, name: 'Down Payment' },
        { id: 8, name: 'Compliance' },
        { id: 9, name: 'Supporting' },

    ]
    const documentItems = documentTypes.map((document, index) => (
        <MenuItem key={index} value={document.id}>
            {document.name}
        </MenuItem>
    ));

    if (loading)
        return <Loader />

    return (
        <>
            <div style={{
                flexGrow: 1,
                height: '100vh',
                position: 'relative',
                zIndex: 10
            }}>
                <Helmet>
                    <title>Merge Management | Max Merge Solutions - Submitting mortgage documents for a faster turnaround</title>
                </Helmet>
                <MergeNav />
                <Container style={{
                    margin: 'auto',
                    backgroundColor: 'white',
                    marginTop: '4%',
                    padding: '5rem',
                    flexGrow: 1
                }}>
                    <Typography
                        variant="h5"
                        style={{
                            color: "black",
                            fontWeight: "bold",
                            padding: 0,
                            // marginTop: "45px",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "24px",
                            marginBottom: "2rem",
                        }}
                    >
                        Merge Management
                    </Typography>
                    <div className="merge-profile-body">
                        <h5>Choose your merge profile</h5>
                        <FormControl
                            fullWidth
                            style={{ padding: 0, overflow: "none" }}
                            variant="standard"
                        >
                            <Select
                                style={{ height: 46 }}
                                onChange={(e) => {
                                    setselectedProfileId(e.target.value);
                                    fetchTable(e.target.value);
                                }}
                                value={selectedProfileId}
                                className={classes.input}
                                id="merge-management-label"
                                variant="standard"
                                label="merge-managementFrequency"
                                MenuProps={{
                                    classes: { paper: classes.dropdownStyle },
                                    variant: "menu",
                                }}
                            // displayEmpty
                            >
                                {menuItems}
                            </Select>
                        </FormControl>
                        {mergeDocuments && <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Filename</StyledTableCell>
                                        <StyledTableCell align="right">Categories</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mergeDocuments.map((row: any) => (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell>{row.fileName}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <Select
                                                    style={{ height: 20 }}
                                                    onChange={(e) => {
                                                        onCategoryChange(e.target.value, row);
                                                    }}
                                                    value={row.type}
                                                    id="merge-management-label"
                                                    variant="standard"
                                                    label="merge-managementFrequency"
                                                    MenuProps={{
                                                        variant: "menu",
                                                    }}
                                                // displayEmpty
                                                >
                                                    {documentItems}
                                                </Select>
                                                {/* {row.typeName}{row.type == 1 ? `-${row.typeVariation}` : ''} */}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </div>
                </Container>
            </div>



        </>
    );
}