const compressionOptions = {
  pdf: [
    {
      type: "High Compression",
      description: "Smallest size, lower quality",
      quality: 2,
    },
    {
      type: "Medium Compression",
      description: "Medium Size, good quality",
      quality: 3,
    },
    {
      type: "Low Compression",
      description: "Larger size, best quality",
      quality: 4,
    },
  ],
  png: [
    {
      type: "High Compression",
      description: "Smallest size, lower quality",
      quality: 25,
    },
    {
      type: "Medium Compression",
      description: "Medium Size, good quality",
      quality: 50,
    },
    {
      type: "Low Compression",
      description: "Larger size, best quality",
      quality: 80,
    },
  ],
  jpeg: [
    {
      type: "High Compression",
      description: "Smallest size, lower quality",
      quality: 25,
    },
    {
      type: "Medium Compression",
      description: "Medium Size, good quality",
      quality: 50,
    },
    {
      type: "Low Compression",
      description: "Larger size, best quality",
      quality: 75,
    },
  ],
};

module.exports = { compressionOptions };
