import React from 'react'
import styled from "styled-components"
import useWindowDimensions from './windowDimensions'
import { Container} from "@mui/material";

const Title = () => {
    const {width} = useWindowDimensions();
    return (
        <TitleWrapper>
            <Container className="container small-container">
                {
                    width > 900 ? 
                    <h2>Learn All About The Benefits of<br/>Our Merging Tool</h2> 
                    :
                    <h2>Learn All About<br/>The Benefits of<br/>Our Merging Tool</h2>
                }
            </Container>
        </TitleWrapper>
    )
}

const TitleWrapper = styled.div`
    background-color: #00939F;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;

    h2 {
        color: #EFEFF1;
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 2px;
                 line-height: 55px;
    }

    @media screen and (min-width: 900px) {
        padding-top: 60px;
        padding-bottom: 20px;
        background: none;
        h2 {
            color: black;
            letter-spacing: 3px;
        }
    }
`

export default Title
