// @flow
import React, { useEffect, useState } from 'react';
import { Typography, Button, Grid, Box, FormControlLabel, RadioGroup, Radio, Input, Alert } from '@mui/material/';
import { MergeNav } from '../../MergeProcess/components/MergeNav';
import { downloadPDFUseStyles } from '../../MergeProcess/components/DownLoadPDF/assets/downloadPDFUseStyles';

// interfaces
import { global } from '../../../global';
import { useAuthContext } from '../../Context/AuthContext';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { IMergeTaskInfo } from '../../MergeProcess/assets/interfaces/IMergeTaskInfo';
import { IPaymentState, IPayout } from '../../MergeProcess/components/MergeDocs/assets/interfaces/IPaymentState';

export interface IDetails {
    underWriterName: null | string;
    propertyAddress: null | string;
    email: null | string;
    notes: null | string;   
    subject: null | string;
}

interface IDownloadPDF {
    location: {
        state: {
            mergeTaskInfo: IMergeTaskInfo;
            details: IDetails;
            setDetails: React.Dispatch<React.SetStateAction<IDetails>>;
            payments: IPaymentState[];
            payouts: IPayout[];
        }
    }
}

export function YearToDateOptions(props: IDownloadPDF) {
    const classes = downloadPDFUseStyles();
    const { location: { state: { mergeTaskInfo, details, setDetails, payments, payouts } } } = props;
    const { token, setToken } = useAuthContext();

    // form object instead of separate useState calls for each input

    return (
        <Box style={{ height: "100vh", width: "100vw" }}>

            <Box>
                <MergeNav active="none" />
            </Box>

            <Box className={classes.root}>
                {/* How would you like to use Max Merge Solutions? */}
                <Typography variant="h5" className={classes.mergeHeading}>Would You Like to Include a Year to Date Paystub Tracker?</Typography>
                <Box style={{ marginTop: "36px", display: "flex", flexDirection: "column" }}>
                    <Grid container justifyContent="center" className={classes.buttons}>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/merge-ytd-tracker', state: { mergeTaskInfo, details, setDetails, payments, payouts } }}><Button variant="contained" className={classes.buttonCustom} style={{ background: global.primary }}>Yes</Button></Link>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/lender-summary', state: { mergeTaskInfo, details, setDetails, payments, payouts } }}><Button variant="outlined" className={[classes.buttonCustom, classes.darkButton].join(' ')}>No</Button></Link>
                    </Grid>
                </Box>
            </Box>



        </Box>
    )
}

