import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container, Grid } from "@mui/material";
const Footer = () => {
  return (
    <FooterWrapper>
      <Container className="footer-container container small-container">
        <Grid container>
          <Grid className="address" xs={12} md={6} lg={9} item>
            <h2>Max Merge Solutions</h2>
            <p>Vancouver, BC</p>
            <p>info@maxmergesolutions.com</p>
            <p>(604) 765-1055</p>
          </Grid>
          <Grid direction="column" xs={12} md={6} lg={3} item container>
            <h3>Company</h3>
            <Link to="/about-us">About</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/Pricing">Pricing</Link>
            <Link to="/Registration">Sign Up</Link>
            <Link to="/about-us">About</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/sign-up">Sign Up</Link>
          </Grid>
        </Grid>
        <div className="copyright">
          <p>
            Copyright &copy; {new Date().getFullYear()} All rights reserved. Max
            Merge Solutions
          </p>
        </div>
      </Container>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background-color: #efeff1;
  padding-top: 50px;
  padding-bottom: 40px;
  line-height: 1.7;

  .address {
    margin-bottom: 30px;
  }

  .copyright {
      margin-top: 30px;
  }

  a {
      display: block;
      color: #030303;
      :hover {
          color: #030303;
      }
  }

  p {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
  }
`;

export default Footer;
