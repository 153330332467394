import { IPaymentState, IPayout } from "../components/MergeProcess/components/MergeDocs/assets/interfaces/IPaymentState";
import { forEach, groupBy } from 'lodash';


export const updatePayments = (payments: IPaymentState[], selectedDocument: number | null, key: any, value: string | number | boolean) => {
    const newPayments = [...payments];
    if (newPayments.length === 0 || newPayments.filter(payment => payment.documentId === selectedDocument).length === 0) {
        newPayments.push({
            financialInstitution: null,
            account: null,
            statementDate: null,
            largeDepositsExplained: null,
            giftedProceeds: null,
            salesProceedsBalance: null,
            statementBalance: null,
            totalBalance: null,
            documentId: selectedDocument,
            saved: false,
            order: null,

        })
    }
    return newPayments.map((payment: any) => {
        if (payment.documentId === selectedDocument) {
            payment[key] = value;
        }
        return payment;
    })
}


export const updatePayouts = (payouts: IPayout[], selectedDocument: number | null, key: any, value: string | number | boolean) => {
    const newPayouts = [...payouts];
    if (newPayouts.length === 0 || newPayouts.filter(payout => payout.documentId === selectedDocument).length === 0) {
        newPayouts.push({
            financialInstitution: null,
            statementDate: null,
            statementBalance: null,
            statementAttached: false,
            documentId: selectedDocument,
            saved: false,
            order: null,
        })
    }
    return newPayouts.map((payout: any) => {
        if (payout.documentId === selectedDocument) {
            payout[key] = value;
        }
        return payout;
    })
}



export const getBalance = (payment: any) => {
    let total = 0;


    if (!payment.statementBalance) {
        total += parseFloat(payment.salesProceedsBalance.toString()) + parseFloat(payment.giftedProceeds.toString());
    }
    else {
        total += parseFloat(payment.statementBalance.toString());
    }
    return total;
}

export const getPaymentValue = (payments: IPaymentState[], accountName: any) => {
    if (payments.length === 0) {
        return 0;
    }
    const paymentArray = payments.map(payment => {
        const date = payment.statementDate ? payment.statementDate.split('/') : ['0', '0', '0'];
        let currentTotal = 0;
        if (payment !== null) {
            currentTotal += payment.salesProceedsBalance ? parseFloat(payment.salesProceedsBalance.toString()) : 0
            currentTotal += payment.statementBalance ? parseFloat(payment.statementBalance.toString()) : 0
        }
        return {
            accountName: payment.account ? payment.account : null,
            name: payment.financialInstitution ?? null,
            salesProceedsBalance: payment.salesProceedsBalance ? payment.salesProceedsBalance : 0,
            statementBalance: payment.statementBalance,
            giftedProceeds: payment.giftedProceeds ? payment.giftedProceeds : 0,
            total: currentTotal,
            date: parseFloat(date[0]) * 30 + parseFloat(date[1]) + parseFloat(date[2]) * 365
        }
    });

    let accounts = groupBy(paymentArray, 'accountName');
    let ResultArr = [];

    // latest of each 
    for (let x in accounts)
    {
        if (x != 'null') {
            ResultArr.push(
                accounts[`${x}`].reduce((x, y): any => {

                    if (x.date > y.date) {
                        return x;
                    }
                    else {
                        return y;

                    }
                })

            );
        }
        else if (x == 'null')
        {
            for (let item of accounts[`${x}`])
            {
                 ResultArr.push(item);
            }
        }
    }


    let total = 0;
    for (let latestItems of ResultArr)
    {
        if (!latestItems.statementBalance) {
            total += parseFloat(latestItems.salesProceedsBalance.toString()) + parseFloat(latestItems.giftedProceeds.toString());
        }
        else
        {
            total += parseFloat(latestItems.statementBalance.toString());
        }
    }

    return total;
}
//  || payments.filter((payment: IPaymentState) => payment.documentId === selectedDocument && !Object.keys(payment).includes(key)).length === 0