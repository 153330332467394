import React from 'react'
import styled from "styled-components"
import { Grid } from "@mui/material";

const InfoCard = ({data: {title, text, image, position}, openModal}) => {
    return (
        <InfoCardWrapper position={position}>
            <div className="cnt">
                <Grid className={`position${position}`} item xs={12} sm={12} md={6} lg={5}>
                    <div className="img-wrapper">
                        <img src={image} alt="landing"/>
                    </div>
                </Grid>
            </div>   
                <div className="card-info">
                    <h3>{title}</h3>
                    <p>{text}</p>
                    <button className="btn-link" onClick={openModal}>
                        Get Started
                    </button>
                </div>
        </InfoCardWrapper>
    )
}
const InfoCardWrapper = styled.div`
    padding-bottom: 10px;
    padding-top: 50px;
    margin-bottom: ${(props)=> props.position === 2 ? "40px" : "0"};
    
    .cnt {
        display: grid;
        justify-content: center;
    }
    
    @media screen and (min-width: 700px) {
        display: flex;
        flex-direction: ${(props)=> props.position === 3 ? "row-reverse" : "row"};
    }

    .card-info {
        padding: 30px 70px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: ${(props)=> props.position === 3 ? "flex-end" : "flex-start"}; */
        align-items: flex-start;
        @media screen and (min-width: 700px) {
            padding: 30px 20px 50px;
        }
        h3 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        
        p {
            font-size: 17px;
            margin-bottom: 30px;
        }

        .btn-link {
            color: white;
            padding: 8px 18px;
            font-size: 15px;
            font-weight: 600;
            background-color: #00939F;
            :hover {
                color: white;
                text-decoration: none;
             }
        }

        @media screen and (min-width: 900px) {
            padding: 30px 50px 50px;
            width: 50%;
            align-items: flex-start;
            p {
                font-size: 18px;
            }
        }
    }


    .img-wrapper {
        width: 350px;
        position: relative;
        height: 100%;
        display: flex;
        &:after {
                content: "";
                position: absolute;
                background-color: #00939F;
                width: 80%;
                z-index: 0;
                height: 100%;
            }
    }

    img {
        width: 80%;
        z-index: 1;
    }

    .position2 {
        margin-bottom: 30px;
        .img-wrapper {
            justify-content: end;
            margin-right: 20px;
            &:after {
                left: 40px;
                bottom: -32px;
            }
        }       
    }

    .position3 {
         .img-wrapper {
            justify-content: start;
            margin-left: 20px;
            &:after {
                right: 40px;
                top: -32px;
            }
        }     
    }


    .position4 {
        margin-top: 28px;
         .img-wrapper {
            justify-content: end;
            margin-right: 20px;
            &:after {
                left: 40px;
                top: -32px;
            }
        }     
    }



    @media screen and (min-width: 900px) {
        .img-wrapper {
            width: 400px;
        }
    }
`
export default InfoCard
