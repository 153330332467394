// @flow
import React, { useEffect, useState } from 'react';
import { TextField, Grid, Button, Typography, CircularProgress, Box, Alert } from '@mui/material';
import axios from 'axios';
import { loginUseStyles } from '../Login/assets/loginUseStyles';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router';
import { ICheckoutForm } from './assets/ICheckoutForm';
import { IFormState } from './assets/IFormState';
import { checkoutUseStyles } from './assets/checkoutUseStyles';
import { useAuthContext } from '../Context/AuthContext';
import CustomTextField from './CustomTextField';

const qs = require('querystring');

export function CheckoutForm(props: ICheckoutForm) {
    const classes = checkoutUseStyles();
    const history = useHistory();
    const { token, setToken } = useAuthContext();
    const { userType, price, priceId, edit } = props
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [coupon, setCoupon] = useState<string>('');
    const [isValid, setIsValid] = useState<string>('');
    const [error, setError] = useState<string | boolean>(false);
    const [success, setSuccess] = useState(false);
    const [formState, setFormState] = useState<IFormState>({
        type: 'card',
            'card[number]': null,
            'card[cvc]': null,
            'card[exp_month]': null,
            'card[exp_year]': null,
            'billing_details[address][city]': null,
            'billing_details[address][country]': 'CA',
            'billing_details[address][line1]': null,
            'billing_details[address][line2]': null,
            'billing_details[address][postal_code]': null,
            'billing_details[address][state]': null,
        // : null,
        // guid: null,
        // muid: null,
        // sid: null,
        // payment_user_agent: null,
        // time_on_page: null,
        // key: "pk_test_51JtdvkAXzkZgZhmjPGtniptlr0PMeD6scxorNMKZjRh1SxGRZ7FdEIPXynygdX5O61P1w0Jt75YYhcdmXIUuDMD300jCC3WC0X",
        // Authorization: "Bearer sk_test_51JtdvkAXzkZgZhmjxXQwN5KUOJMtjHztohlvUgScQ4A58yKbmDnQSu8v1Sj0CPHAeyusN1ZLpgNxl8cK3O9LorI000QijeZ0Jv"
    })

    useEffect(() => {
        console.log('!edit', !edit);
        console.log('token.userType', token.userType);
        console.log('userType', userType);

        //if (!edit && (token.userType === 1 || token.userType === 2)) {
        if (!edit && token.userType === 2) {
            history.push('/merge-docs')
        }
    }, [])

    const subscribe = (result: any) => {
        console.log('posting');
        axios
            .post(`api/subscriptions`, {
                userTypeId: userType,
                tokenId: result.data.id,
                PostalCode: formState['billing_details[address][postal_code]'],
                AddressLine: formState['billing_details[address][line1]'],
                State: formState['billing_details[address][state]'],
                Coupon: coupon,
            })
            .then(subResult => {
                if (subResult.data.success) {
                    setSuccess(true);
                    setToken((prev: any) => ({ ...prev, userType: userType, customerId: subResult.data.subscription.customerId, subscriptionId: subResult.data.subscription.id }));
                    history.push('/merge-docs');
                }
                else {
                    setLoading(false);
                    setError(true);
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(true);
                console.log(`Error subscribing user ${userType}, token ${result.data.id}`)
            });
    }

    const updatePaymentMethod = (result: any) => {
        console.log('hello world')
        axios
            .put(`api/subscriptions`, {
                tokenId: result.data.id,
                PostalCode: formState['billing_details[address][postal_code]'],
                AddressLine: formState['billing_details[address][line1]'],
                State: formState['billing_details[address][state]'],
            })
            .then(result => {
                console.log("paymentMethodUpdated", result)
                setLoading(false);
                if (result.data.success) {
                    console.log('set token1....', result.data.subscription);
                    if (result.data.subscription !== null) {
                        console.log('set token2....');
                        setToken((prev: any) => ({ ...prev, userType: result.data.subscription.userType.id }));
                    }

                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false)
                    }, 1500)
                }
                if (!result.data.success) {
                    setError(true);
                }
            })
            .catch(e => {
                setLoading(false);
                setError(true);
                console.log(e.response.data);
            })
    }
    const ValidateCoupon = async () => {
        console.log(coupon);
        axios
            .post(`api/subscriptions/validatecoupon`, {
                CouponId: coupon,

            })
            .then(result => {
                if (result.data.success) {
                    setIsValid("Valid coupon code")
                    setTimeout(() => {
                        setIsValid("");
                    }, 3500)
                }
                else {
                    setIsValid("Invalid coupon code")
                    setTimeout(() => {
                        setIsValid("");
                    }, 3500)

                }
            })
            .catch(e => {
                setIsValid("Invalid coupon code")
                setTimeout(() => {
                    setIsValid("");
                }, 3500)

            })
    }

    const handleSubmit = async () => {
        setIsSubmitted(true);

        //console.log('isSubmitted', isSubmitted);
        //console.log('formState', formState);

        if (formState['card[number]'] == null ||
            formState['card[cvc]'] == null ||
            formState['card[exp_month]'] == null ||
            formState['card[exp_year]'] == null ||
            formState['billing_details[address][city]'] == null ||
            formState['billing_details[address][line1]'] == null ||
            formState['billing_details[address][postal_code]'] == null ||
            formState['billing_details[address][state]'] == null)
            return;

        setLoading(true);
        axios
            .post('https://api.stripe.com/v1/payment_methods',
                qs.stringify(formState)
                , {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_API_PK}`
                    }
                })
            .then((result) => {
                console.log("stripeRes:", result)
                if (edit) {
                    updatePaymentMethod(result);
                }
                if (!edit) {
                    subscribe(result);
                }
            })
            .catch((e) => {
                setError(true);
                setLoading(false);
                console.log('error:', e.response.message)
            })
    }

    return (
        <>
            {loading && (
                <Grid alignItems="center" justifyContent="center" container style={{ height: '50vh' }}>
                    <CircularProgress />
                </Grid>
            )}
            {!loading && (
                <>
                    <Typography className={classes.titleLogin} variant='h4'>Checkout</Typography>
                    <Grid container className={classes.form}>
                        <NumberFormat
                            className={classes.input}
                            placeholder="Enter Card Number"
                            label="Card Number"
                            customInput={CustomTextField}
                            format="#### #### #### ####"
                            onChange={(e) => setFormState(prev => ({ ...prev, "card[number]": e.target.value }))}
                            required={true}
                            isSubmitted={isSubmitted}
                        />
                        <Grid xs={12} columns={12} item container style={{ width: '100%' }} direction="row" justifyContent="space-between">
                            <Grid item xs={5}>
                                <NumberFormat
                                    className={classes.expiry}
                                    placeholder="MM/YY"
                                    label="Expiry"
                                    customInput={CustomTextField}
                                    format="##/##"
                                    onChange={(e) => setFormState(
                                        prev => ({ ...prev, "card[exp_month]": e.target.value[0] + e.target.value[1], "card[exp_year]": e.target.value[3] + e.target.value[4] }))
                                    }
                                    required={true}
                                    isSubmitted={isSubmitted}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <NumberFormat
                                    className={classes.cvc}
                                    placeholder="Enter CVC"
                                    label="CVC"
                                    customInput={CustomTextField}
                                    format="###"
                                    onChange={(e) => setFormState(prev => ({ ...prev, "card[cvc]": e.target.value }))}
                                    required={true}
                                    isSubmitted={isSubmitted}
                                />
                            </Grid>
                        </Grid>

                        <CustomTextField
                            placeholder="Required"
                            label="Address Line 1"
                            // format="### ###"
                            onChange={(e) => setFormState(prev => ({ ...prev, 'billing_details[address][line1]': e.target.value }))}
                            required={true}
                            isSubmitted={isSubmitted}
                        />
                        <CustomTextField
                            placeholder="Optional"
                            label="Address Line 2"
                            // format="### ###"
                            onChange={(e) => setFormState(prev => ({ ...prev, 'billing_details[address][line2]': e.target.value }))}
                            isSubmitted={isSubmitted}
                        />
                        <CustomTextField
                            placeholder="Enter City"
                            label="City"
                            onChange={(e) => setFormState(prev => ({ ...prev, 'billing_details[address][city]': e.target.value }))}
                            required={true}
                            isSubmitted={isSubmitted}
                        />
                        <CustomTextField
                            placeholder="Enter Province"
                            label="Province"
                            onChange={(e) => setFormState(prev => ({ ...prev, 'billing_details[address][state]': e.target.value }))}
                            required={true}
                            isSubmitted={isSubmitted}
                        />
                        <CustomTextField
                            placeholder="Enter Postal Code"
                            label="Postal Code"
                            // format="### ###"
                            onChange={(e) => setFormState(prev => ({ ...prev, 'billing_details[address][postal_code]': e.target.value }))}
                            required={true}
                            isSubmitted={isSubmitted}
                        />

                        <Grid xs={12} columns={12} item container style={{ width: '100%' }} direction="row" justifyContent="space-between">
                            <Grid item xs={7}>
                                <CustomTextField
                                    placeholder="Coupon Code if applicable"
                                    label="Coupon Code"
                                    // format="### ###"
                                    onChange={(e) => setCoupon(e.target.value)}
                                    isSubmitted={isSubmitted}
                                />
                            </Grid>
                            <Grid item xs={5} container justifyContent="center">
                                <Button variant="outlined" onClick={ValidateCoupon} className={classes.couponButton}>
                                    Check Coupon Code
                                </Button>
                            </Grid>
                        </Grid>
                        {/* <Alert severity="error">Incorrect username/password combination</Alert> */}
                    </Grid>
                    {success && <Alert severity="success">{edit ? 'Payment information updated successfully!' : 'Subscription created!'}</Alert>}
                    {isValid && <Alert severity="info">{isValid}</Alert>}
                    {error && <Alert severity="warning">Oops, something went wrong. Please contact support at 1-855-696-3743.</Alert>}
                    <Button style={success || error ? { marginTop: 7 } : {}} onClick={handleSubmit} variant="contained" className={classes.loginButton}>
                        {edit ? "Update" : "Pay Now"}
                    </Button>
                </>
            )}
        </>
    );
};