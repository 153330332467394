import * as React from "react";
import { useState, useEffect } from "react";
import { Typography, Button, Grid, Box } from "@mui/material/";
import { MergeNav } from "../MergeNav";
import { downloadPDFOverviewUseStyles } from "./assets/downloadPDFOverviewUseStyles";
import { DocumentAccordionList } from "./components/DocumentAccordionList";
import { documentData } from "./assets/testData";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";

import Loader from "./assets/Loader";

// interfaces
import { getIndividualItemsFromDocuments } from "./../../../../helpers/filters-and-sorters";
import {
    getBalance,
    getPaymentValue,
} from "./../../../../helpers/updatePayments";

import jsPDF from "jspdf";

import { IStandardProps } from "../../../interfaces/IStandardProps";
import { IDocumentData } from "../LenderSummarySheet/assets/interfaces/IDocumentData";
import { global } from "../../../../global";
import { useAuthContext } from "../../../Context/AuthContext";
import { Link } from "react-router-dom";
import axios from "axios";
import { IMergeTaskInfo } from "../../assets/interfaces/IMergeTaskInfo";
import {
    IPaymentState,
    IPayout,
} from "../MergeDocs/assets/interfaces/IPaymentState";
import { IDescription } from "../LenderSummarySheet/assets/interfaces/IDescription";
import { set } from "date-fns/esm/fp";

import * as ReactDOMServer from "react-dom/server";

interface IDownloadPDFOverview {
    location: {
        state: {
            mergeTaskInfo: IMergeTaskInfo;
            payments: IPaymentState[];
            payouts: IPayout[];
        };
    };
    descriptions: IDescription[];
    setDescriptions: React.Dispatch<React.SetStateAction<IDescription[]>>;
}

export function DownloadPDFOverview(props: any) {
    const classes = downloadPDFOverviewUseStyles();
    const history = useHistory();

    const { token, setToken } = useAuthContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [errorFile, setErrorFile] = useState<string>("");
    //nested deconstruction to save mergeTaskInfo as a variable
    const {
        location: {
            state: {
                mergeTaskInfo,
                payments,
                payouts,
                dialog,
                setSizeDialog,
                setSizeSelected,
                fromUnderwriter,
                details,
                fromLenderSummary,
                note,
                results,
                currentYearStartDate,
                probationBool,
                descriptions,
                attachedDoc,
            },
        },
        setDescriptions,
    } = props;
    const [documents, setDocuments] = useState<IDocumentData[]>([]);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    useEffect(() => {
        axios.get("api/users/logged-user").then((res) => {
            setEmail(res.data.email);
            console.log(res.data);
            setName(`${res.data.firstName} ${res.data.lastName}`);
        });
    }, []);

    useEffect(() => {
        console.log("jjjjj", attachedDoc);
    }, [attachedDoc]);
    const getTask = () => {
        setLoading(true);
        if (fromLenderSummary) {
            let notes = "";

            //descriptions.filter(des => des.id && desIndex.includes(des.id)).forEach(des => notes += `${des.value} <br/>`)
            let attached = "";
            //attached += ReactDOMServer.renderToStaticMarkup(<Typography variant="h6" style={{ fontSize: '18px', marginBottom: '18px', width: '577px' }}>Subject Property Address: {details.propertyAddress} </Typography>);

            attached += attachedDoc;
            console.log("attached", attached);
            if (results !== [] && typeof results !== 'undefined') {
                attached += ReactDOMServer.renderToStaticMarkup(<Box style={{ marginTop: 48, marginBottom: 0 }}>* The information used for the year-to-date paystub tracker is derived from information entered by the user and is intended as a guide only:</Box>);
                results.forEach((result: any, index: number) => {
                    attached += ReactDOMServer.renderToStaticMarkup(
                        <div>
                            <br />
                            <Typography
                                style={{
                                    marginLeft: "0px",
                                    fontSize: "17px",
                                    marginRight: "7px",
                                    display: "inline",
                                }}
                            >
                                <strong>{result.borrower}:</strong>
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: "17px",
                                    marginTop: "5px",
                                    marginLeft: "15px",
                                    marginBottom: "10px",
                                    lineHeight: "1.5",
                                }}
                            >
                                {result.amount
                                    ? "Gross Regular Earning: $" +
                                    new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    }).format(result.amount)
                                    : ""}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: "17px",
                                    marginTop: "0px",
                                    marginLeft: "15px",
                                    marginBottom: "10px",
                                    lineHeight: "1.5",
                                }}
                            >
                                {result.ytd
                                    ? "Year to Date Gross Earnings: $" +
                                    new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    }).format(result.ytd)
                                    : ""}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: "17px",
                                    marginTop: "0px",
                                    marginLeft: "15px",
                                    marginBottom: "10px",
                                    lineHeight: "1.5",
                                }}
                            >
                                Based on frequency: Paystub {result.currentPeriod} out of{" "}
                                {result.totalPeriod}{" "}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: "17px",
                                    marginTop: "0px",
                                    marginLeft: "15px",
                                    marginBottom: "10px",
                                    lineHeight: "1.5",
                                }}
                            >
                                {result.income
                                    ? (result.currentYearStartDate ? "Annualized Projected Income: $" : "Income tracking as: $") +
                                    new Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                    }).format(result.income)
                                    : ""}
                            </Typography>

                            {result.currentYearStartDate && (
                                <Typography
                                    style={{
                                        fontSize: "17px",
                                        marginTop: "0px",
                                        marginLeft: "15px",
                                        marginBottom: "10px",
                                        lineHeight: "1.5",
                                    }}
                                >{`Employment Start Date: ${result.currentYearStartDate.toLocaleDateString(
                                    "en-US",
                                    { month: "long", day: "numeric", year: "numeric" }
                                )}`}</Typography>
                            )}
                            {result.currentYearStartDate && result.probationBool && (
                                <Typography
                                    style={{
                                        fontSize: "17px",
                                        marginTop: "0px",
                                        marginLeft: "15px",
                                        marginBottom: "10px",
                                        lineHeight: "1.5",
                                    }}
                                >{`Probationary Period: ${result.probationBool == "yes" ? "Yes" : "No"
                                    }`}</Typography>
                            )}
                        </div>
                    );
                });
            }

            if (note) notes += note;

            const emailInfo = {
                email: email,
                name: name,
                mergeId: mergeTaskInfo.id,
                underWriterEmail: details.email,
                underWriterName: details.underWriterName,
                Subject: details.subject,
                propertyAddress: details.propertyAddress,
                notes: notes,
                items: attached,
            };

            const url = `/api/merge-task/${mergeTaskInfo.id}/merge`;
            const method = "POST";

            axios
                .request({
                    url: url,
                    method,
                    data: emailInfo,
                    responseType: "blob",
                })
                .then((res) => {
                    console.log(res);
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "merged.zip");
                    document.body.appendChild(link);
                    link.click();
                    setLoading(false);
                    setTimeout(() => {
                        const fromDownload = true;
                        history.push({ pathname: "/save-merge", state: { fromDownload, mergeTaskInfo } });
                    }, 3000);
                })
                .catch(async (error: any) => {
                    if (error.response) {
                        const errorResponse = JSON.parse(await error.response.data.text());

                        setError(errorResponse.message.replace(/ *\([^)]*\) */g, ""));
                        console.log(errorResponse);
                        setErrorFile(errorResponse.id);
                    }
                    setLoading(false);
                });
        } else {
            const url = `/api/merge-task/${mergeTaskInfo.id}/merge`;
            const method = "GET";

            axios
                .request({
                    url: url,
                    method,
                    responseType: "blob",
                })
                .then((res) => {
                    console.log(res);
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "merged.zip");
                    document.body.appendChild(link);
                    link.click();
                    setLoading(false);
                    setTimeout(() => {
                        const fromDownload = true;
                        history.push({ pathname: "/save-merge", state: { fromDownload, mergeTaskInfo } });
                    }, 3000);
                })
                .catch(async (error: any) => {
                    if (error.response) {
                        const errorResponse = JSON.parse(await error.response.data.text());

                        setError(errorResponse.message.replace(/ *\([^)]*\) */g, ""));
                        console.log(errorResponse);
                        setErrorFile(errorResponse.id);
                    }
                    setLoading(false);
                });
        }
    };

    return (
        <Box>
            <Helmet>
                <title>
                    PDF Overview | Merge Documents | Max Merge Solutions - A document
                    handling tool for mortgage professionals{" "}
                </title>
            </Helmet>

            <Box>
                <MergeNav active="none" />
            </Box>

            <Box className={classes.root}>
                <Typography
                    variant="h5"
                    className={classes.mergeHeading}
                >
                    Download PDF
                </Typography>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                        variant="h5"
                        style={{ fontWeight: 700, fontSize: "18px", marginBottom: "16px" }}
                    >
                        Documents Overview
                    </Typography>
                    <Box>
                        <DocumentAccordionList
                            errorFile={errorFile}
                            setError={setError}
                            tiles={mergeTaskInfo.tiles}
                            documentType={"one"}
                            documents={mergeTaskInfo.documents}
                            handleDelete={() => 0}
                            id={1}
                            mergeTaskInfo={mergeTaskInfo}
                            split={false}
                            descriptions={descriptions}
                            setDescriptions={setDescriptions}
                        />
                    </Box>
                    {loading && <Loader />}
                    {error && (
                        <h5 style={{ marginTop: "1.5rem", color: "red", width: "30rem" }}>
                            {" "}
                            {error}. Further compression is not possible. Please delete
                            problem file to continue.
                        </h5>
                    )}

                    <Grid
                        container
                        justifyContent="space-between"
                        className={classes.buttons}
                    >
                        <Link
                            to={{
                                pathname: "/download-pdf",
                                state: {
                                    mergeTaskInfo,
                                    payments,
                                    payouts,
                                    details,
                                    fromLenderSummary: fromLenderSummary,
                                },
                            }}
                        >
                            <Button
                                variant="outlined"
                                className={[classes.buttonCustom, classes.darkButton].join(" ")}
                            >
                                Back
                            </Button>
                        </Link>
                        <Button
                            variant="contained"
                            onClick={getTask}
                            className={classes.buttonCustom}
                            style={{ background: global.primary }}
                        >
                            Download
                        </Button>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
