import React, { useState } from 'react';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import { Box, Typography, Slide } from '@mui/material';
import { global } from '../../../global';


const quotes = [
    {
        content: 'I have been using this product for my merging needs when saving or moving files into my application platform. I have tried other merge programs but ran into some limitations, like merging e-signed documents and locked bank statements. Max Merge Solutions addressed these limitations, which is now saving me precious time. My fav feature is this product also allows me to choose the file size. My application platform has a file size limitation of 10 MB per file. So now I can process files and compliance much quicker. My efficiency has greatly improved, and I am now getting paid faster by my compliance team. For example, I recently merged over 60 documents into 6 labelled files, all under 10 MB, in under 20 seconds. Game changer.',
        author: 'Scott D.',
        company: 'Mortgage Professional'
    },
    {
        content: 'When I first heard what Max Merge was, I was invited to join a demo my colleague was already attending - and I wasn\'t sold. I didn\'t really understand the capabilities, and to be honest, wasn\'t giving it my full attention... until I saw the features. And I got more and more excited about it. Is there anything more frustrating than getting in - going to upload the conditions - and your package is too large to upload to the portal ? Or packaging the docs together nicely in your own PDF software ? For me, it \'s the ultimate frustration. We already work so hard to get the deal together, help the client understand, gather the documents and software and tech issues are such a pain! Max Merge is a custom solution for us, and it really is EASY to use. I am the biggest skeptic - and it has been a value to my business. The YTD calculator already saved me from going back and stressing out a client because we were able to verify the exact income, they are on track to make! Really grateful for this system.',
        author: 'Katherine M.',
        company: 'Mortgage Professional'
    },
    {
        content: 'Since I have started using Max Merge Solutions, I have been able to process and submit my compliance documents in record time! I like that I can merge and condense large files into a sizable and organized format that is broken into specific categories. I recently learnt about the YTD tracker as well, which made it so easy to see how much my clients are on pace to make, using info from a recent paystub. Thanks for creating this super user - friendly software, and I would highly recommend trying it out if you\'re in the mortgage business.',
        author: 'Jason P.',
        company: 'Mortgage Professional'
    },
    {
        content: 'One of the great things I took away from MPC this year is Max Merge Solutions! Document collection, storage and upload to lenders and for compliance are way faster. Thanks to Max Merge Solutions for making my days more efficient!',
        author: 'Lisa H.',
        company: 'Mortgage Professional'
    },
    {
        content: 'As a Mortgage Broker, I was spending a lot of time on documents, organizing etc. Max Merge Solutions is a one- stop shop where that solved this problem and can do so much more to save time. This product has made my life easier with the processing of my clients\' documents.',
        author: 'M. KhunKhun',
        company: 'Mortgage Professional'
    },
    {
        content: 'From an underwriting and lender perspective, receiving supporting broker documents merged, organized, labelled, and in order makes such a time difference. As a result, helping both you and your clients with quicker lender turnaround times.',
        author: 'Sr. Underwriter',
        company: 'Credit Union'
    },
    {
        content: 'The product is extraordinary. Yes, the organization is wonderful, but the saved time is the true selling feature. Time taken by a broker having to struggle through the mechanics of assembling a document package can be better allocated to meeting with clients and networking with one\'s database.',
        author: 'Business Development Manager (BDM)',
        company: 'Monoline Lender'
    },
]

interface Props {
  quotes?: {
    content: string;
    author: string;
    company: string;
  }
}

export function Carousel(props: Props) {
  const [slideIndex, setSlideIndex] = useState(0);
  const [direction, setDirection] = useState<"left" | "right" | "up" | "down">("right");

  const handleSlideBack = () => {
    if (slideIndex > 0) {
      setDirection("left");
      setSlideIndex(slideIndex - 1);
    }
  }

  const handleSlideForward = () => {
    if (slideIndex < quotes.length - 1) {
      setDirection("right");
      setSlideIndex(slideIndex + 1);
    }
  }

  const Slides = () => {
    return <>
      {quotes.map((item, index) =>
          <Slide style={{ fontSize: '18px' }} timeout={500} direction={direction} in={index === slideIndex} mountOnEnter unmountOnExit>
          <Box style={{ display: 'flex' }}>
            {/* <img style={{ alignSelf: 'center', margin: '0 10px 100px 160px', width: '30px', height: '30px' }} src={quote} alt="quote symbol" /> */}
            <img style={{ width: '30px', height: '30px', margin: '0 10px 0 0' }} src="https://res.cloudinary.com/maxmergesolutions/image/upload/v1633539058/quote.png" alt="quote symbol" />
            <Box>
              <Typography style={{ marginBottom: '27px', fontFamily: global.fontFamily }}>{item.content}</Typography>
              <Typography style={{ marginBottom: '3px', fontWeight: 'bold', fontFamily: global.fontFamily }}>-{item.author}</Typography>
              <Typography style={{ color: global.neutral, fontWeight: 'bold', fontFamily: global.fontFamily }}>{item.company}</Typography>
            </Box>
          </Box>
        </Slide>
      )}
    </>
  }

  return (
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '88px 25px 0 25px' }}>
          <ArrowBackIos style={{ color: "#7F8284", cursor: "pointer" }} onClick={handleSlideBack} />
      <Box style={{ display: 'flex', alignItems: 'center', margin: '88px 200px 0 200px', position: 'relative', width: '100%' }}>
        <Slides />
      </Box>
          <ArrowForwardIos style={{ color: "#7F8284", cursor: "pointer" }} onClick={handleSlideForward} />
    </Box>
  )
};